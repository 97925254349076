import { CorePackages, FlowActions } from '../enums';

export const flowPackage = {
  id: CorePackages.Flow,
  name: 'Workflow',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Packages',
    logo: `workflow.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: FlowActions.Trigger,
        name: 'Trigger Workflow',
        description: 'Trigger a Workflow',
        input: {
          type: 'object',
          required: ['flowId'],
          properties: {
            flowId: {
              type: 'string',
              title: 'Workflow',
              description: 'The Workflow to trigger.',
              attributes: {
                'ui:component': 'flows'
              }
            },
            data: {
              type: 'string',
              title: 'Data',
              description: 'Pass data to the triggered Workflow.'
            },
            triggerWait: {
              type: 'boolean',
              title: 'Wait to Finish',
              description:
                "Wait for the triggered Workflow to finish before continuing this Workflow; allowing access to the triggered Workflow's output.",
              default: false
            }
          }
        },
        output: {
          type: 'object',
          required: ['success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'If the Workflow was triggered successfully'
            },
            data: {
              type: 'object',
              properties: {
                runId: {
                  type: 'string',
                  title: 'Event ID',
                  description: 'The event ID of the Workflow run.'
                }
              }
            }
          }
        }
      },
      {
        id: 'DEBUG_NODE',
        name: 'Debug',
        description: 'Debug input / outputs of a Workflow.',
        input: {
          type: 'object',
          required: ['input'],
          properties: {
            input: {
              type: 'object',
              title: 'Inputs',
              description: 'The inputs to debug.',
              attributes: {
                'ui:component': 'key-value'
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['success', 'data'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'If the Workflow was triggered successfully'
            },
            data: {
              type: 'object',
              properties: {
                runId: {
                  type: 'object',
                  title: 'Results',
                  description: 'The results of the debug.'
                }
              }
            }
          }
        }
      }
    ]
  }
};
