import { axios } from 'core/Axios';
import {
  DataListConnectionRevisionsParams,
  DataListConnectionRevisionsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_CONNECTION_REVISIONS;
export const buildQueryKey = (params?: DataListConnectionRevisionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListConnectionRevisionsParams
): Promise<DataListConnectionRevisionsResponse> => {
  return axios
    .get<DataListConnectionRevisionsResponse>(Route.LIST_CONNECTION_REVISIONS, {
      params
    })
    .then(data => data.data);
};

export const useListConnectionRevisions = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataListConnectionRevisionsResponse,
  DataListConnectionRevisionsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
