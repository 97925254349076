import { DateActions } from '../enums';

export const datePackage = {
  id: 'CORE_DATE',
  name: 'Date',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `time.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: DateActions.Trigger,
        name: 'Schedule',
        type: 'trigger',
        input: {
          type: 'object',
          required: ['expression', 'enabled'],
          properties: {
            expression: {
              type: 'string',
              title: 'Schedule',
              attributes: {
                'ui:component': 'cron',
                'ui:order': 0
              }
            },
            enabled: {
              type: 'boolean',
              title: 'Enabled',
              description: 'Enable or disable the schedule.',
              default: true,
              'ui:order': 1
            }
          }
        },
        output: {
          type: 'object',
          required: ['time'],
          properties: {
            data: {
              type: 'object',
              properties: {
                time: {
                  type: 'string',
                  title: 'Time of Execution'
                }
              }
            }
          }
        }
      }
    ]
  }
};
