import { axios } from 'core/Axios';
import { DataGetEventParams, DataGetEventResponse } from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_EVENT;
export const buildQueryKey = (params?: DataGetEventParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetEventParams
): Promise<DataGetEventResponse> => {
  return axios
    .get<DataGetEventResponse>(Route.GET_EVENT, { params })
    .then(data => data.data);
};

export const useGetEvent = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetEventResponse, DataGetEventParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
