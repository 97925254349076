import { axios } from 'core/Axios';
import { DataPutEventBody, DataPutEventResponse } from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_EVENT;

export const req = async (
  data: DataPutEventBody
): Promise<DataPutEventResponse> => {
  return axios
    .put<DataPutEventResponse>(Route.PUT_EVENT, data)
    .then(data => data.data);
};

export const usePutEvent = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataPutEventResponse, DataPutEventBody> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_EVENTS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
