import React, { FC } from 'react';
import classNames from 'classnames';
import { Pluralize } from 'shared/utils/Pluralize';
import { ReactComponent as Arrow } from 'assets/svg/single-left-arrow.svg';
import { ReactComponent as DoubleArrow } from 'assets/svg/double-left-arrow.svg';
import { range } from 'd3-array';
import css from './Pager.module.css';
import { ResultsPerPage } from './ResultsPerPage';

type PagerProps = {
  page: number;
  limit: number;
  limitKey?: string;
  limitOptions?: number[];
  totalItems?: number;
  totalPages?: number;
  showTotal?: boolean;
  className?: any;
  onPageChange: (page: number) => void;
  onLimitChange?: (perPage: number) => void;
};

const getShowRange = (page: number, totalPages: number) => {
  let startPage = Math.max(page - 3, 0);
  let endPage = Math.min(page + 3, totalPages);
  if (startPage === 0 && endPage !== totalPages) {
    endPage = startPage + 6;
  } else if (endPage === totalPages && startPage !== 0) {
    startPage = endPage - 6;
  }

  return { startPage, endPage };
};

export const Pager: FC<PagerProps> = ({
  page,
  limit,
  limitKey,
  limitOptions = [10, 25, 50],
  totalItems,
  totalPages,
  showTotal = false,
  className,
  onPageChange,
  onLimitChange
}) => {
  const pages = !totalPages
    ? range(totalItems / limit)
    : range(totalPages);
  const disablePrev = page === 0;
  const disableNext = page === pages.length - 1;
  const { startPage, endPage } = getShowRange(page, pages.length - 1);

  return (
    <div className={classNames(css.pagination, className)}>
      <DoubleArrow
        title="first"
        className={classNames(css.prevArrow, { [css.active]: !disablePrev })}
        onClick={() => {
          if (!disablePrev) {
            onPageChange(0);
          }
        }}
      />
      <Arrow
        title="previous"
        className={classNames(css.prevArrow, {
          [css.active]: !disablePrev
        })}
        onClick={() => {
          if (!disablePrev) {
            onPageChange(page - 1);
          }
        }}
      />
      {startPage >= 2 && <div className={css.overflow}>...</div>}
      {pages.map((p, idx) => {
        if (idx >= startPage && idx <= endPage) {
          return (
            <div
              key={p}
              className={classNames(css.page, {
                [css.active]: p === page
              })}
              onClick={() => onPageChange(p)}
              title={`Page ${p + 1}`}
            >
              {p + 1}
            </div>
          );
        }
        return null;
      })}
      {endPage <= pages.length - 3 && <div className={css.overflow}>...</div>}
      <Arrow
        title="next"
        className={classNames(css.nextArrow, css.flipArrow, {
          [css.active]: !disableNext
        })}
        onClick={() => {
          if (!disableNext) {
            onPageChange(page + 1);
          }
        }}
      />
      <DoubleArrow
        title="last"
        className={classNames(css.lastArrow, css.flipArrow, {
          [css.active]: !disableNext
        })}
        onClick={() => {
          if (!disableNext) {
            onPageChange(pages.length - 1);
          }
        }}
      />
      <ResultsPerPage
        limit={limit}
        limitKey={limitKey}
        limitOptions={limitOptions}
        onLimitChange={onLimitChange}
      />
      {showTotal && totalItems && (
        <span className={css.count}>
          {`${totalItems.toLocaleString()} `}
          <Pluralize singular="Result" count={totalItems} showCount={false} />
        </span>
      )}
    </div>
  );
};
