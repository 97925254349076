import { Helper } from '.';

export const numberHelpers: Helper[] = [
  {
    name: '$random',
    label: 'Random',
    invariant: true,
    description:
      'Returns a pseudo random number greater than or equal to zero and less than one (0 ≤ n < 1)',
    examples: ['$random() => 0.7973541067127'],
    returnType: 'number',
    reference: 'https://docs.jsonata.org/numeric-functions#random'
  }
];
