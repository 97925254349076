import {
  ContentState,
  EditorChangeType,
  EditorState,
  Modifier,
  SelectionState
} from 'draft-js';
import { findWithRegex } from './tokenStrategy';
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';
import { TokenEntityOption, TokenOption } from '../types';
import { modifierDeserializer } from 'shared/form/Modifiers/utils';
import { getUpdatedModifier } from 'core/utils/modifiers';

/**
 * Create a new entity.
 */
export const createEntity = (
  contentState: ContentState,
  selection: SelectionState,
  entityData: TokenEntityOption
) => {
  const newContentState = contentState.createEntity(
    'TOKEN',
    'IMMUTABLE',
    entityData
  );

  const entityKey = newContentState.getLastCreatedEntityKey();

  return Modifier.replaceText(
    newContentState,
    selection,
    entityData.text,
    undefined,
    entityKey
  );
};

/**
 * Select the last entity.
 */
export const selectLastEntity = (
  editorState: EditorState,
  contentState: ContentState,
  selection: SelectionState,
  start: number
) => {
  const newEntity = contentState.getEntity(
    contentState.getLastCreatedEntityKey()
  );

  const tokenData: TokenEntityOption = newEntity.getData();
  const cursorOn = start + (tokenData?.text?.length || 0);

  return EditorState.forceSelection(
    editorState,
    selection.merge({
      anchorOffset: cursorOn,
      focusOffset: cursorOn
    }) as SelectionState
  );
};

/**
 * Crawl the current content and insert any new entities. Happens
 * on load and on text typing.
 */
export const addEntityToEditorState = (
  editorState: EditorState,
  blockKey: string,
  tokens?: TokenOption[]
) => {
  let newEditorState = editorState;
  const currentContent = newEditorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(blockKey);
  const plainText = currentBlock.getText();
  const textLen = plainText.length;

  const range = findWithRegex(currentBlock);
  for (const { start, end } of range) {
    const entityKey = currentBlock.getEntityAt(start);

    if (entityKey === null) {
      const text = plainText.substring(start, end);
      const selection = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: start,
        focusKey: blockKey,
        focusOffset: end
      });

      let data;
      try {
        // safely handle bad parsing
        data = modifierDeserializer(text)[0];
      } catch {
        continue;
      }

      const prop = data.value;
      // If the prop is something like source.output.foo, lastProperty is foo
      // for display purposes
      const lastProperty = prop?.trim().split('.').pop();
      const modifier = data.modifier;
      const params = data.params;
      const additionalModifiers = data.additionalModifiers;
      const token = tokens?.find(t => t.value === prop?.trim());
      let parentIcon;

      // find token with same parent to use as icon
      if (!token) {
        const parentId = prop?.trim().split('.').shift();
        const parentToken = tokens?.find(t => t.value.startsWith(parentId));
        if (parentToken) {
          parentIcon = parentToken?.icon;
        }
      }

      // Need to determine if the modifier has had map applied to its items
      const updatedModifier = getUpdatedModifier(data, tokens);

      const newContentState = createEntity(currentContent, selection, {
        // If there is no text, use the prop, for invariants use the modifier name
        text: token?.text || lastProperty || modifier,
        value: prop,
        valueType: token?.valueType,
        subType: token?.subType,
        description: token?.description,
        icon: token?.icon || parentIcon,
        found: token !== undefined,
        group: token?.group,
        source: updatedModifier?.source || data.source,
        applyMap: updatedModifier?.applyMap,
        modifier: updatedModifier?.modifier || modifier,
        params: updatedModifier?.params || params,
        additionalModifiers:
          updatedModifier?.additionalModifiers || additionalModifiers
      } as TokenEntityOption);

      newEditorState = EditorState.push(
        editorState,
        newContentState,
        'apply-entity'
      );

      newEditorState = selectLastEntity(
        newEditorState,
        newContentState,
        selection,
        start
      );

      // If we are at the end, lets insert a space
      if (textLen === end) {
        newEditorState = insertSpace(newEditorState);
      }
    }
  }

  return newEditorState;
};

/**
 * Given an entity key, remove it from the stack.
 */
export const removeEntity = (
  editorState: EditorState,
  entityKey: string,
  block: any,
  blockKey: string
) => {
  const contentState = editorState.getCurrentContent();
  const selection = getTokenSelection(entityKey, block, blockKey);

  const textWithoutRange = Modifier.removeRange(
    contentState,
    selection as SelectionState,
    'backward'
  );

  return EditorState.push(editorState, textWithoutRange, 'remove-range');
};

/**
 * Removes a token.
 */
export const removeToken = ({
  getEditorState,
  setEditorState,
  block,
  entityKey,
  blockKey
}: any) => {
  const editorState = getEditorState();
  const newEditorState = removeEntity(editorState, entityKey, block, blockKey);
  setEditorState(newEditorState);
  return newEditorState;
};

export const getWordBoundaries = ({ text, position, lineIndex }) => {
  // Get the text on the current line to determine boundaries for
  const split = text.split('\n');
  const words = split[lineIndex];

  // Find closest occurance of whitespace before and after the current cursor position
  let start = 0;
  let end = words.length;
  for (let i = position - 1; i >= 0; i -= 1) {
    if (/\s/.test(words[i])) {
      start = i + 1;
      break;
    }
  }

  for (let i = position; i <= words.length; i += 1) {
    if (/\s/.test(words[i])) {
      end = i;
      break;
    }
  }

  return { start, end };
};

const getStateDetails = (editorState: EditorState) => {
  const selectionState = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const anchorKey = editorState.getSelection().getAnchorKey();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  // Get what line of the editor user is on
  const currentBlockIndex = currentContent
    .getBlockMap()
    .keySeq()
    .findIndex(k => k === anchorKey);
  const cursorPosition = selectionState.getStartOffset();
  const contextText = currentContent.getPlainText();
  const normalizedValue = contextText.replace(/\r\n|\n|\r/g, '\n');

  return {
    cursorPosition,
    normalizedValue,
    currentContentBlock,
    selectionState,
    lineIndex: currentBlockIndex
  };
};

/**
 * Get the selection range given a prev selection state.
 */
export const getInsertRange = (editorState: EditorState) => {
  const {
    cursorPosition,
    normalizedValue,
    lineIndex,
    currentContentBlock,
    selectionState
  } = getStateDetails(editorState);

  if (selectionState.getStartOffset() === selectionState.getEndOffset()) {
    // There is nothing selected, get the start/end index of the word the cursor is associated with
    // and insert based on that
    const { start, end } = getWordBoundaries({
      text: normalizedValue,
      position: cursorPosition,
      lineIndex
    });
    return { start, end, currentContentBlock };
  }

  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  return { start, end, currentContentBlock };
};

/**
 * Given the editor state and a previous selection state, find the current text.
 */
export const getSelection = (editorState: EditorState) => {
  const { cursorPosition, normalizedValue, lineIndex, currentContentBlock } =
    getStateDetails(editorState);

  const { start, end } = getWordBoundaries({
    text: normalizedValue,
    position: cursorPosition,
    lineIndex
  });

  return currentContentBlock.getText().slice(start, end);
};

/**
 * Add a space after a selection.
 */
export const insertSpace = (editorState: EditorState) => {
  const selection = editorState.getSelection();

  const newContentState = Modifier.insertText(
    editorState.getCurrentContent(),
    selection,
    ' '
  );

  return EditorState.push(
    editorState,
    newContentState,
    'insert-text' as EditorChangeType
  );
};

/**
 * Given a token, insert it into the current position.
 */
export const addToken = (editorState: EditorState, token: TokenOption) => {
  const currentContent = editorState.getCurrentContent();

  // Remove the the start selection
  const { start, end, currentContentBlock } = getInsertRange(editorState);
  const selection = new SelectionState({
    anchorKey: currentContentBlock.getKey(),
    anchorOffset: start,
    focusKey: currentContentBlock.getKey(),
    focusOffset: end
  });

  editorState = EditorState.forceSelection(editorState, selection);

  // Replace selection with our new entity
  const newContentState = createEntity(currentContent, selection, {
    ...token,
    found: true
  } as TokenEntityOption);

  editorState = EditorState.push(editorState, newContentState, 'apply-entity');

  // Select the last entity we created
  editorState = selectLastEntity(
    editorState,
    newContentState,
    selection,
    start
  );

  // Insert a space after the last token
  editorState = insertSpace(editorState);

  return editorState;
};

/**
 * Get the selected token.
 */
export const getTokenSelection = (
  entityKey: string,
  block: any,
  blockKey: string
) => {
  const getBlock = getRangesForDraftEntity(block, entityKey);

  const entitySelection = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: getBlock.start,
    focusKey: blockKey,
    focusOffset: getBlock.end,
    hasFocus: true,
    isBackward: false
  });

  return entitySelection.merge({
    anchorOffset: getBlock[0].start,
    focusOffset: getBlock[0].end
  }) as SelectionState;
};

/**
 * Add a helper that is not tied to a token.
 */
export const addHelper = (
  editorState: EditorState,
  selection: SelectionState,
  tokens: TokenOption[],
  serializedHelper: string
) => {
  const currentContent = editorState.getCurrentContent();

  // TODO: This affects the text of datastores when modifier is applied to it
  const newContentState = Modifier.replaceText(
    currentContent,
    selection,
    serializedHelper
  );

  let newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-characters'
  );

  // TODO: This is kinda a hacky way to update the editor state w/ the new token
  // need to come back and make it correct by removing entity/adding it and selecting it
  newEditorState = processEditorState(newEditorState, tokens);

  return newEditorState;
};

/**
 * Select a token.
 */
export const selectToken = ({
  getEditorState,
  setEditorState,
  entityKey,
  block,
  blockKey
}: any) => {
  let editorState = getEditorState();
  const selection = getTokenSelection(entityKey, block, blockKey);
  editorState = EditorState.forceSelection(editorState, selection);
  setEditorState(editorState);
};

/**
 * Process the editor state.
 */
export const processEditorState = (
  editorState: EditorState,
  tokens: TokenOption[]
) => {
  const contentState = editorState.getCurrentContent();
  let newEditorState = editorState;

  contentState.getBlockMap().map(originalBlock => {
    if (originalBlock) {
      const blockKey = originalBlock.getKey();
      const ranges = findWithRegex(originalBlock);
      return ranges.forEach(
        () =>
          (newEditorState = addEntityToEditorState(
            newEditorState,
            blockKey,
            tokens
          ))
      );
    }

    return originalBlock;
  });

  return newEditorState;
};
