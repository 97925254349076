import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import css from './RadioGroup.module.css';

export type RadioItemProps = {
  value: any;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  children?: any;
  onChange?: (val: any) => void;
};

export const RadioItem: FC<RadioItemProps> = ({
  className,
  checked,
  disabled,
  value,
  children,
  onChange
}) => (
  <div
    className={classNames(css.item, className, { [css.disabled]: disabled })}
    onClick={() => {
      if (!disabled) {
        onChange(value);
      }
    }}
  >
    <div
      className={classNames(css.circle, {
        [css.checked]: checked
      })}
    >
      <AnimatePresence>
        {checked && (
          <motion.div
            className={css.check}
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: {
                scale: { duration: 0.15 },
                opacity: { duration: 0.15 }
              }
            }}
            exit={{
              scale: 0,
              opacity: 0,
              transition: {
                scale: { duration: 0.15 },
                opacity: { duration: 0.15 }
              }
            }}
          />
        )}
      </AnimatePresence>
    </div>
    <span>{children}</span>
  </div>
);
