import React, {
  forwardRef,
  Ref,
  InputHTMLAttributes,
  ReactNode,
  FC
} from 'react';
import classNames from 'classnames';
import css from './ListItem.module.css';

export type ListItemProps = {
  subtitle?: ReactNode;
  icon?: ReactNode;
  suffix?: ReactNode;
  active?: boolean;
  divider?: boolean;
  disablePadding?: boolean;
  disableGutters?: boolean;
  disableBackground?: boolean;
  size?: 'small' | 'medium';
} & Omit<InputHTMLAttributes<HTMLDivElement>, 'size'>;

export type ListItemRef = {
  ref?: Ref<HTMLDivElement>;
};

export const ListItem: FC<ListItemProps & ListItemRef> = forwardRef(
  (
    {
      size = 'medium',
      className,
      children,
      icon,
      active,
      disabled,
      subtitle,
      disableGutters,
      divider,
      disablePadding,
      disableBackground,
      suffix,
      onClick,
      ...rest
    },
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      {...rest}
      role="listitem"
      ref={ref}
      onClick={e => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      className={classNames(className, css.listItem, {
        [css.small]: size === 'small',
        [css.medium]: size === 'medium',
        [css.divider]: divider,
        [css.disabled]: disabled === true,
        [css.disablePadding]: disablePadding,
        [css.disableGutters]: disableGutters,
        [css.disableBackground]: disableBackground,
        [css.hasSubtitle]: subtitle,
        [css.clickable]: onClick !== undefined,
        [css.active]: active
      })}
    >
      {icon && <div className={css.listItemIcon}>{icon}</div>}
      <div className={css.listItemText}>
        {subtitle && <h4 className={css.listItemSubtitle}>{subtitle}</h4>}
        {children}
      </div>
      {suffix && <div className={css.listItemSuffix}>{suffix}</div>}
    </div>
  )
);
