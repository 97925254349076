import { createContext, useContext } from 'react';

export type ThemeType = 'light' | 'dark';
export interface ThemeConfiguration {
  name: 'dark' | 'light';
  colors: {
    [key: string]: any;
  };
  variables: {
    [key: string]: string;
  };
  components: {
    [key: string]: any;
  };
  isDark: boolean;
  setTheme: (theme: ThemeType) => void;
}

export const ThemeContext = createContext<ThemeConfiguration>({
  name: 'light',
  variables: {},
  colors: {},
  components: {},
  isDark: true,
  setTheme: () => undefined
});

export const { Provider: ThemeProvider, Consumer: ThemeConsumer } =
  ThemeContext;

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error(
      '`useTheme` hook must be used within a `ThemeContext` component'
    );
  }

  context.isDark = context.name === 'dark';

  return context;
};
