import React, {
  FC,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  Ref
} from 'react';
import { useDebounce } from 'react-use';
import { Card } from 'shared/layout/Card';
import {
  buildSelections,
  buildGroups,
  getSelection,
  buildKeyboardGroups,
  findKeyboardSelection
} from './utils';
import { CascaderPanel } from './CascaderPanel';
import { CascaderDetail } from './CascaderDetail';
import { CascaderBreadcrumbs } from './CascaderBreadcrumbs';
import { SearchBar } from 'shared/form/SearchBar';
import { CascaderAdditionalActions, CascaderItem } from './types';
import css from './Cascader.module.css';
import { useKeyboardNav } from 'shared/utils/KeyboardNav';

export type CascaderProps = {
  items: CascaderItem[];
  keyword?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  value?: string;
  additionalDetail?: (item?: CascaderItem) => React.ReactNode;
  additionalActions?: CascaderAdditionalActions[];
  searchPlaceholder?: string;
  collapseDepth?: number;
  panelRef?: Ref<HTMLDivElement>;
  onSelection?: (item: CascaderItem) => void;
  onActivate?: (item: CascaderItem) => void;
  onSearch?: (searchText: string) => void;
  onHome?: () => void;
};

export const Cascader: FC<CascaderProps> = ({
  style,
  items,
  keyword,
  value,
  disabled = false,
  additionalDetail,
  additionalActions,
  searchPlaceholder = 'Search...',
  collapseDepth = 3,
  panelRef,
  onSelection,
  onActivate,
  onSearch,
  onHome
}) => {
  const [hover, setHover] = useState<CascaderItem | null>(null);
  const [hoverDetail, setHoverDetail] = useState<CascaderItem | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const listRef = useRef<HTMLDivElement | null>(null);

  const [internalValue, setInternalValue] = useState<CascaderItem | null>(
    getSelection(items, value)
  );

  const groups = useMemo(
    () => buildGroups(items, internalValue),
    [internalValue, items]
  );

  const selections = useMemo(
    () => buildSelections(groups, internalValue),
    [internalValue, groups]
  );

  const keyboardGroups = useMemo(() => buildKeyboardGroups(groups), [groups]);

  const [offset, setOffset] = useKeyboardNav({
    ref: listRef,
    items: keyboardGroups,
    onSelect: onKeyboardSelect,
    onKeyDown
  });

  const onItemClick = useCallback(
    (item: CascaderItem) => {
      if (item.onClick) {
        item.onClick(item);
      } else {
        setInternalValue(item);
        onActivate?.(item);
      }
      const selectedCoords = findKeyboardSelection(keyboardGroups, item.path);
      if (selectedCoords) {
        setOffset(selectedCoords);
      }
    },
    [keyboardGroups, onActivate, setOffset]
  );

  useEffect(() => {
    onSearch?.(searchText);

    // Deselect on search
    if (searchText) {
      setInternalValue(null);
    }
  }, [onSearch, searchText]);

  useEffect(() => {
    if (offset.y > -1) {
      const item = keyboardGroups[offset.x]?.[offset.y];
      if (item) {
        setHoverDetail(keyboardGroups[offset.x][offset.y]);
      } else {
        setOffset({ x: 0, y: -1 });
      }
    }
  }, [keyboardGroups, offset, setOffset]);

  useDebounce(
    () => {
      if (keyword || searchText) {
        // Select the first item after stopping typing
        const item = groups?.[0]?.items?.[0];
        setInternalValue(item);
      } else if (!value) {
        setInternalValue(null);
      }
    },
    200,
    [keyword, searchText]
  );

  const onHomeClick = useCallback(() => {
    setSearchText('');
    setInternalValue(null);
    onHome?.();
  }, [onHome]);

  function onKeyboardSelect(item: CascaderItem) {
    onItemClick(item);
  }

  function onKeyDown(event) {
    if (event.key === 'ArrowRight') {
      if (offset.y > -1) {
        const item = keyboardGroups[offset.x][offset.y];
        if (item.items) {
          const itemSelected = selections.some(s => s.path === item.path);
          if (!itemSelected) {
            onItemClick(item);
          }
          setOffset({ x: offset.x + 1, y: 0 });
        }
      }
    } else if (event.key === 'ArrowLeft') {
      if (offset.x > 0) {
        const prevGroup = keyboardGroups[offset.x - 1];
        const selectionPaths = selections.map(s => s.path);
        const itemSelectedIndex = prevGroup.findIndex(i =>
          selectionPaths.includes(i.path)
        );

        if (itemSelectedIndex > -1) {
          // Remove the selection if there is one
          if (!keyboardGroups[offset.x - 2]) {
            setInternalValue(null);
          } else {
            const parent = keyboardGroups[offset.x - 2].find(
              i => i.path === internalValue?.parent
            );
            if (parent) {
              setInternalValue(parent);
            }
          }
          setOffset({ x: offset.x - 1, y: itemSelectedIndex });
        }
      }
    } else if (event.key === 'Tab') {
      // On tab, focus into the details panel if the current item is selectable
      console.log('tab');
    } else if (event.key === 'Escape') {
      setOffset({ x: 0, y: 0 });
      setHoverDetail(null);
      setInternalValue(null);
    }
  }

  return (
    <Card className={css.container} disablePadding style={style}>
      <div className={css.breadcrumbs}>
        <CascaderBreadcrumbs
          selections={selections}
          isSearching={searchText?.length > 0 || keyword?.length > 0}
          onSelection={setInternalValue}
          onHomeClick={onHomeClick}
        />
        <div className={css.push} />
        {onSearch && (
          <span className={css.searchBar}>
            <SearchBar
              autoFocus={true}
              hover
              placeholder={searchPlaceholder}
              value={searchText}
              size="small"
              onChange={setSearchText}
              onClear={() => setSearchText('')}
            />
          </span>
        )}
      </div>
      <div className={css.panels} ref={listRef}>
        <div ref={panelRef} />
        {groups.map((group, i) => (
          <CascaderPanel
            key={group.text}
            group={group}
            selections={selections}
            hoverDetail={hoverDetail}
            keyword={keyword || searchText}
            disabled={disabled}
            collapsed={
              selections.length >= collapseDepth &&
              i <= selections.length - collapseDepth
            }
            onItemSelect={onItemClick}
            onItemHover={setHover}
          />
        ))}
        <CascaderDetail
          selection={internalValue}
          hover={hover || hoverDetail}
          disabled={disabled}
          additionalDetail={additionalDetail}
          additionalActions={additionalActions}
          onSelection={onSelection}
          onHover={setHoverDetail}
        />
      </div>
    </Card>
  );
};
