import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { Calendar } from 'shared/form/Calendar';
import { Input } from 'shared/form/Input';
import { Popover } from 'shared/layers/Tooltip';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import css from './DateTimeInput.module.css';
import { useTooltipState } from 'realayers';

export type DateTimeType = 'date' | 'date-time';

export type DateTimeInputProps = {
  value?: Date | string;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  type?: DateTimeType;
  onChange?: (value: Date) => void;
  onBlur?: () => void;
};

export const DateTimeInput: FC<DateTimeInputProps> = ({
  value,
  min,
  max,
  type = 'date',
  disabled,
  onChange,
  onBlur
}) => {
  const { deactivateAllTooltips } = useTooltipState();

  const { date, time, hours, minutes } = useMemo(() => {
    if (!value) {
      return {
        date: '',
        time: '',
        hours: 0,
        minutes: 0
      };
    }

    const m = moment(value);
    return {
      date: m.clone().startOf('day').format('YYYY-MM-DD'),
      time: m.format('HH:mm'),
      hours: m.hours(),
      minutes: m.minutes()
    };
  }, [value]);

  const { minDate, maxDate } = useMemo(() => {
    let minDate;
    let maxDate;

    if (min) {
      const m = moment(min);
      minDate = m.startOf('day').format('YYYY-MM-DD');
    }

    if (max) {
      const m = moment(max);
      maxDate = m.startOf('day').format('YYYY-MM-DD');
    }

    return {
      minDate,
      maxDate
    };
  }, [min, max]);

  const calendar = useMemo(() => {
    const calDate = date
      ? moment(date).startOf('day').toDate()
      : moment().startOf('day').toDate();

    return (
      <Calendar
        min={min}
        max={max}
        value={calDate}
        disabled={disabled}
        onChange={newDate => {
          if (type === 'date') {
            onChange(newDate);
          } else {
            const v = moment(newDate);
            v.add(hours, 'hours');
            v.add(minutes, 'minutes');
            onChange(v.toDate());
          }
          deactivateAllTooltips();
        }}
      />
    );
  }, [
    date,
    deactivateAllTooltips,
    disabled,
    hours,
    max,
    min,
    minutes,
    onChange,
    type
  ]);

  return (
    <div className={css.container}>
      <div className={css.date}>
        <Input
          type="date"
          fullWidth
          value={date}
          min={minDate}
          max={maxDate}
          disabled={disabled}
          onChange={event => {
            const newDate = moment(event.target.value).startOf('day');
            if (type === 'date') {
              onChange(newDate.toDate());
            } else {
              newDate.add(hours, 'hours');
              newDate.add(minutes, 'minutes');
              onChange(newDate.toDate());
            }
          }}
          suffix={
            !disabled && (
              <Popover content={calendar}>
                <CalendarIcon className={css.infoIcon} />
              </Popover>
            )
          }
        />
      </div>
      {type === 'date-time' && (
        <div className={css.time}>
          <Input
            type="time"
            value={time}
            disabled={disabled}
            fullWidth={true}
            onBlur={onBlur}
            onChange={event => {
              const [hour, minute] = event.target.value.split(':');
              const v = moment(date || new Date());
              v.add(hour, 'hours');
              v.add(minute, 'minute');
              onChange(v.toDate());
            }}
          />
        </div>
      )}
    </div>
  );
};
