import { axios } from 'core/Axios';
import {
  UsagePageInfoParams,
  UsagePageInfoResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.USER_USAGE_PAGE_INFO;
export const buildQueryKey = (params?: UsagePageInfoParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: UsagePageInfoParams
): Promise<UsagePageInfoResponse> => {
  return axios
    .get<UsagePageInfoResponse>(Route.USER_USAGE_PAGE_INFO, { params })
    .then(data => data.data);
};

export const useUsagePageInfo = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<UsagePageInfoResponse, UsagePageInfoParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
