import React, {
  Fragment,
  FC,
  useState,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { Block } from 'shared/layout/Block';
import { Pluralize } from 'shared/utils/Pluralize';
import { ReactComponent as DotsIcon } from 'assets/svg/vertical-dots.svg';
// import {
//   getUIAttribute,
//   getIsRequired,
//   hasUnresolvedDependenciesContext,
//   hasDependencies,
//   getTypeByView,
//   getComponentType,
//   getViewByType
// } from './utils';
import { FormikProps } from 'formik';
import { CustomFieldComponents, JsonSchemaFormProps } from './JsonSchemaForm';
import Linkify from 'react-linkify-always-blank';
import { Menu } from 'shared/layers/Menu';
import { ListItem, List } from 'shared/layout/List';
import { ConnectionStatus } from 'core/types/API';
import { fieldComponents } from './fieldComponents';
import { TokenOption } from 'shared/form/TokenEditor/types';
import css from './JsonSchemaForm.module.css';
import { useAuth } from 'core/Auth';
import { InputViews } from './utils/enums/inputViews';
import { defaultableTypes, overridableTypes } from './utils/inputTypes';
import { Button } from 'shared/elements/Button';
import {
  getComponentType,
  getIsRequired,
  getTypeByView,
  getUIAttribute,
  getViewByType,
  hasDependencies,
  hasUnresolvedDependenciesContext
} from './utils/form';

export type JsonSchemaFormInputProps<T> = {
  property: string;
  tokens?: TokenOption[];
  fieldDependencies?: any;
} & Partial<FormikProps<any>> &
  Partial<JsonSchemaFormProps<T>>;

export const JsonSchemaFormInput: FC<
  JsonSchemaFormInputProps<CustomFieldComponents>
> = ({
  schema,
  flowId,
  property,
  tokens,
  touched,
  errors,
  values,
  disabled = false,
  context = {},
  components = [],
  allowOverride = false,
  allowModifiers = true,
  allowFlowTrigger = true,
  allowExpressions = true,
  shouldTrim = true,
  preferNative = false,

  // TODO: These need to be decoupled from this component
  fieldDependencies,
  stores = [],
  flows = [],
  onPortUpdate,

  setValues,
  setFieldTouched,
  setFieldValue,
  setFieldError,
  onComponentToggle
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const field = schema.properties[property];
  const dependencies = fieldDependencies[property];
  const { title, description, examples, placeholder, tooltip } = field;
  const label = title !== false ? title || property : null;
  const required = getIsRequired(schema, property);
  const fieldDisabled = getUIAttribute(field.attributes, 'ui:disabled', false);
  const fieldsFilter = getUIAttribute(field.attributes, 'ui:field-types', null);
  const tokenTypes = getUIAttribute(field.attributes, 'ui:tokenTypes', null);
  const fieldProps = getUIAttribute(field.attributes, 'ui:fieldProps', {});
  const allowTriggers =
    allowFlowTrigger && (!tokenTypes || tokenTypes.includes('trigger'));
  const hasError = !!errors[property];
  const value = values[property];

  const { isFp } = useAuth();
  const customComponent = components?.find(({ id }) => id === property);
  const [componentType, setComponentType] = useState<string>(
    getComponentType(field, value, preferNative)
  );
  const isOverridable = useMemo(
    () =>
      allowOverride &&
      (fieldProps.allowOverride == null || fieldProps.allowOverride) &&
      overridableTypes.includes(componentType),
    [allowOverride, componentType, fieldProps.allowOverride]
  );

  const type = customComponent ? customComponent.type : componentType;

  const [view, setView] = useState<InputViews>(getViewByType(type, field));

  const passedTokens = useMemo(() => {
    let passed = tokens;
    if (tokenTypes) {
      passed = [];
      for (const tokenType of tokenTypes) {
        if (tokenType.includes('store:')) {
          // We are looking for specific store types - either store:array or store:object
          // so we need to get the stores that match the filter and get all nested tokens for those stores
          const filteredTopLevel = tokens.filter(
            t =>
              tokenType === `${t.type}:${t.valueType}` && !t.value.includes('.')
          );
          if (filteredTopLevel.length) {
            passed.push(...filteredTopLevel);
            const storeIds = filteredTopLevel.map(t => t.value);
            passed.push(
              ...tokens.filter(t => {
                const tokenStoreId = t.value.split('.')[0];
                return t.type === 'store' && storeIds.includes(tokenStoreId);
              })
            );
          }
        } else {
          passed.push(
            ...tokens.filter(
              t =>
                tokenType === t.type || tokenType === `${t.type}:${t.valueType}`
            )
          );
        }
      }
    }

    return passed;
  }, [tokenTypes, tokens]);

  const setComponent = useCallback(
    (type: string | null) => {
      onComponentToggle({ id: property, type });
      setMenuActive(false);
    },
    [onComponentToggle, property]
  );

  const onViewClick = useCallback(
    (view: InputViews) => {
      setComponent(getTypeByView(view));
      setComponentType(getComponentType(field, value));
      setView(view);
    },
    [setComponent, field, value]
  );

  const isCustom = !!customComponent;
  const canDefault = useMemo(
    () => defaultableTypes.includes(getComponentType(field, value)),
    [field, value]
  );

  // TODO: Decouple connections from this component
  const dependentAsyncHasWrongConnection = useMemo(
    () =>
      type === 'select:remote' &&
      context?.connection?.status?.toLowerCase() !==
        ConnectionStatus.Active.toLowerCase() &&
      hasDependencies(fieldDependencies, property),
    [type, context, fieldDependencies, property]
  );

  if (
    (!isCustom &&
      (hasUnresolvedDependenciesContext(fieldDependencies, property) ||
        dependentAsyncHasWrongConnection)) ||
    getUIAttribute(field.attributes, 'ui:hidden', false)
  ) {
    return null;
  }

  // Some input fields should only be visible to internal users, check if the
  // field is internal only and if the user is from Flashpoint
  if (getUIAttribute(field.attributes, 'ui:internal', false) && !isFp) {
    return null;
  }

  const Component = fieldComponents[type];

  return (
    <Block label={label} required={required} tooltip={tooltip}>
      <div className={css.inputContainer}>
        {Component !== undefined ? (
          <Component
            {...fieldProps}
            dependencies={dependencies}
            tokens={passedTokens}
            allTokens={tokens}
            placeholder={placeholder}
            disabled={fieldDisabled || disabled}
            allowModifiers={
              allowModifiers &&
              (fieldProps.allowModifiers == null || fieldProps.allowModifiers)
            }
            allowFlowTrigger={
              allowTriggers &&
              (fieldProps.allowTriggers == null || fieldProps.allowTriggers)
            }
            allowExpressions={
              allowExpressions &&
              (fieldProps.allowExpressions == null ||
                fieldProps.allowExpressions)
            }
            shouldTrim={shouldTrim}
            value={value}
            context={context}
            flowId={flowId}
            field={field}
            hasError={hasError}
            property={property}
            stores={stores}
            flows={flows}
            fieldsFilter={fieldsFilter}
            setValues={setValues}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            onPortUpdate={onPortUpdate}
          />
        ) : (
          <Fragment>No component found for {type}</Fragment>
        )}
        {isOverridable && (
          <div className={css.advancedBtnContainer}>
            <Button
              ref={buttonRef}
              color="primary"
              disableMargins
              disablePadding
              size="small"
              className={css.advancedBtn}
              title="Change input type"
              onClick={() => setMenuActive(!menuActive)}
            >
              <DotsIcon className={css.codeIcon} />
            </Button>
            <Menu
              reference={buttonRef}
              visible={menuActive}
              placement="bottom-end"
              onClose={() => setMenuActive(false)}
            >
              <List>
                {Object.keys(InputViews).map((inputView: string) =>
                  inputView.toLowerCase() === InputViews.Default &&
                  !canDefault ? null : (
                    <ListItem
                      key={inputView}
                      onClick={() => onViewClick(InputViews[inputView])}
                      active={view === InputViews[inputView]}
                    >
                      {inputView}
                    </ListItem>
                  )
                )}
              </List>
            </Menu>
          </div>
        )}
      </div>
      {hasError && (
        <p className={css.validationError}>
          {/* Rewrite error message for single error on inline error message */}
          <>
            {(errors[property] as string).includes('and none was provided')
              ? 'Required.'
              : errors[property]}
          </>
        </p>
      )}
      {description && (
        <p className={css.description}>
          <Linkify properties={{ target: '_blank' }}>{description}</Linkify>
        </p>
      )}
      {examples?.length > 0 && (
        <div className={css.description}>
          <Pluralize
            showCount={false}
            singular="Example"
            count={examples.length}
          />
          :
          <Fragment>
            {examples.length > 2 ? (
              <ul>
                {examples.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            ) : (
              <Fragment>
                &nbsp;
                <span
                  dangerouslySetInnerHTML={{ __html: examples.join(', ') }}
                />
              </Fragment>
            )}
          </Fragment>
        </div>
      )}
    </Block>
  );
};
