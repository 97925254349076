export const nativeTypes = [
  'date',
  'time',
  'date-time',
  'file',
  'files',
  'code:json'
];
export const tokenTypes = [
  'string',
  'ipv4',
  'ipv6',
  'integer',
  'number',
  'uri'
];
export const mustacheTypes = ['code:handlebars', 'mustache', 'code:jsonata'];
export const defaultableTypes = [
  ...nativeTypes,
  'select:remote',
  'remote-select',
  'array',
  'select:all',
  'select:field',
  'password',
  'json'
];
export const overridableTypes = [...tokenTypes, ...defaultableTypes];
export const allTypes = [...overridableTypes, ...mustacheTypes];
