import { KeyValueData } from './KeyValue';
import shortid from '@getcrft/shortid';

export const strategies = {
  object: {
    serialize: (values: KeyValueData[]) =>
      values.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
    deseralize: (values: any) =>
      Object.entries(values).map(([key, value], index) => ({
        key,
        value,
        index
      }))
  },
  array: {
    serialize: (values: KeyValueData[]) =>
      values.map(({ key, value, createdAt, index, id }) => ({
        key,
        value,
        createdAt: createdAt || new Date().toISOString(),
        index,
        id: id || shortid()
      })),
    deseralize: (values: any[]) => {
      if (Array.isArray(values)) {
        return values.map(
          ({ key, value, createdAt, index: specifiedIndex, id }, index) => ({
            key,
            value,
            createdAt,
            index: specifiedIndex != null ? specifiedIndex : index,
            id: id || shortid()
          })
        );
      }

      return [];
    }
  }
};
