import { axios } from 'core/Axios';
import {
  DataListUploadsParams,
  DataListUploadsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_UPLOADS;
export const buildQueryKey = (params?: DataListUploadsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListUploadsParams
): Promise<DataListUploadsResponse> => {
  return axios
    .get<DataListUploadsResponse>(Route.LIST_UPLOADS, { params })
    .then(data => data.data);
};

export const useListUploads = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<DataListUploadsResponse, DataListUploadsParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
