import { axios } from 'core/Axios';
import {
  DataListUsersParams,
  DataListUsersResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_USERS;
export const buildQueryKey = (params?: DataListUsersParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListUsersParams
): Promise<DataListUsersResponse> => {
  return axios
    .get<DataListUsersResponse>(Route.LIST_USERS, { params })
    .then(data => data.data);
};

export const useListUsers = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 9999 }
}: QueryApiHookProps<DataListUsersResponse, DataListUsersParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    {
      keepPreviousData: true,
      ...options
    }
  );
};
