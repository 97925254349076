import { axios } from 'core/Axios';
import {
  DataListFlowRevisionsParams,
  DataListFlowRevisionsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_FLOW_REVISIONS;
export const buildQueryKey = (params?: DataListFlowRevisionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListFlowRevisionsParams
): Promise<DataListFlowRevisionsResponse> => {
  return axios
    .get<DataListFlowRevisionsResponse>(Route.LIST_FLOW_REVISIONS, { params })
    .then(data => data.data);
};

export const useListFlowRevisions = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListFlowRevisionsResponse,
  DataListFlowRevisionsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
