import { axios } from 'core/Axios';
import {
  DataListUserGroupsParams,
  DataListUserGroupsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_USER_GROUPS;
export const buildQueryKey = (params?: DataListUserGroupsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListUserGroupsParams
): Promise<DataListUserGroupsResponse> => {
  return axios
    .get<DataListUserGroupsResponse>(Route.LIST_USER_GROUPS, { params })
    .then(data => data.data);
};

export const useListUserGroups = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListUserGroupsResponse,
  DataListUserGroupsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    {
      keepPreviousData: true,
      ...options
    }
  );
};
