import React, { FC } from 'react';
import { motion } from 'framer-motion';
import css from './Loader.module.css';

const paths = [
  'M52.81,66.22c-10.22,21.3-15.15,44.9-17.13,56.58,2.6,8.73,5.17,19.68,5.17,28.84,0,18.53-10.52,25.43-10.52,25.43s-10.51-6.9-10.51-25.43c0-9.21,2.37-20.29,5.2-29,4.66-16.28,26-63.22,26-63.22s-4.38-15.71-8.7-28.05l-.8-2.26A70.31,70.31,0,0,1,39.2,42C38.5,24.9,30.33,0,30.33,0s0,10.26-15.16,56.08C8.5,76.21,0,99.38,0,119.16c0,49.75,30.33,68.26,30.33,68.26s30.34-18.51,30.34-68.26C60.67,102.59,57.3,83.86,52.81,66.22Z'
];

// TODO: Make there only be one instance of this ever...
export const Loader: FC = () => (
  <div className={css.loader}>
    <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.67 187.42">
      <defs>
        <filter id="glow" x="-30%" y="-30%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="10 10" result="glow" />
          <feMerge>
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
          </feMerge>
        </filter>
      </defs>
      <motion.path
        d={paths[0]}
        className={css.glow}
        animate={{
          opacity: [0.5, 0.7, 0.5],
          scale: [0.9, 1.2, 0.9]
        }}
        transition={{
          duration: 1,
          delay: 0.3,
          repeat: Infinity
        }}
      />
      <path className={css.logo} d={paths[0]} />
    </motion.svg>
  </div>
);
