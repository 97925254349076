import React, { FC, useEffect, useMemo, useState } from 'react';
import shortid from '@getcrft/shortid';
import { Button } from 'shared/elements/Button';
import classNames from 'classnames';
import { ExtendedConditionRule } from 'shared/form/ConditionInput';
import { ConditionInputItemWrapper } from 'shared/form/ConditionInput/ConditionInputItemWrapper';
import { Block } from 'shared/layout/Block';
import { ConditionComparator, ConditionValueRule } from '@getcrft/jsonata-ext';
import css from './BranchInput.module.css';
import { TokenEditor } from 'shared/form/TokenEditor';
import { DateTypes, NumericTypes, StringTypes } from 'core/types';
import { getOptionValue } from 'shared/form/Select';

export type BranchInputProps = {
  value?: ExtendedConditionRule[];
  disabled?: boolean;
  fieldOptions?: any[];
  valueOptions?: any[];
  allowTrigger?: boolean;
  allowExpressions?: boolean;
  allowModifiers?: boolean;
  onChange?: (value: ExtendedConditionRule[]) => void;
  onPortUpdate: (conditionId: string, toAdd: boolean) => void;
};

const emptyCondition = (
  index: number,
  variable: string,
  comparator: ConditionComparator
) => ({
  id: shortid(),
  label: `Branch ${index + 1}`,
  variable,
  comparator,
  value: ''
});

export const BranchInput: FC<BranchInputProps> = ({
  value,
  disabled,
  fieldOptions,
  allowExpressions,
  allowModifiers,
  allowTrigger,
  onChange,
  onPortUpdate,
  ...rest
}) => {
  const [variable, setVariable] = useState<string>(null);
  const [comparator, setComparator] =
    useState<ConditionComparator>('stringEquals');
  const [internalValue, setInternalValue] = useState<ExtendedConditionRule[]>(
    []
  );
  const hasMultiple = internalValue.length > 1;

  const comparatorTypeEquals = useMemo(
    () => ({
      ...StringTypes.reduce(
        (acc, cur) => ({ ...acc, [cur]: 'stringEquals' }),
        {}
      ),
      ...NumericTypes.reduce(
        (acc, cur) => ({ ...acc, [cur]: 'numericEquals' }),
        {}
      ),
      ...DateTypes.reduce(
        (acc, cur) => ({ ...acc, [cur]: 'timestampEquals' }),
        {}
      ),
      boolean: 'booleanEquals',
      object: 'objectEquals',
      array: 'arrayEquals'
    }),
    []
  );

  useEffect(() => {
    if (internalValue.length === 0) {
      if (value?.length) {
        setInternalValue(value);

        // set the variable and comparator based on value
        const val = value[0] as ConditionValueRule;
        if (val) {
          setVariable(val.variable);
          setComparator(val.comparator);
        }
      } else {
        const condition = emptyCondition(0, variable, comparator);
        const updatedValue = [condition];
        setInternalValue(updatedValue);
        onChange(updatedValue);
        onPortUpdate(condition.id, true);
      }
    }
  }, [value, internalValue, onChange, onPortUpdate, variable, comparator]);

  useEffect(() => {
    if (internalValue.length > 0) {
      const val = internalValue[0] as ConditionValueRule;
      if (variable && val && variable !== val.variable) {
        // Determine the comparator based on the field
        let curComparator = comparator;
        const token = getOptionValue(fieldOptions, variable);
        if (token) {
          if (token.type && comparatorTypeEquals[token.type]) {
            curComparator = comparatorTypeEquals[token.type];
            setComparator(curComparator);
          } else if (token.valueType && comparatorTypeEquals[token.valueType]) {
            curComparator = comparatorTypeEquals[token.valueType];
            setComparator(curComparator);
          } else {
            setComparator('stringEquals');
          }
        }

        // Cycle through all statements and update the variable
        const updatedValue = internalValue.map(v => ({
          ...v,
          variable,
          comparator: curComparator
        }));
        setInternalValue(updatedValue);
        onChange(updatedValue);
      } else if (!variable && val.variable) {
        // Field has been removed, remove the variable and the comparator from all statements
        // TODO: Decide whether we should clear the value when the field is changed
        setComparator(null);
        const updatedValue = internalValue.map(v => ({
          ...v,
          variable: null,
          comparator: null,
          value: ''
        }));
        setInternalValue(updatedValue);
        onChange(updatedValue);
      }
    }
  }, [
    comparator,
    comparatorTypeEquals,
    fieldOptions,
    internalValue,
    onChange,
    variable
  ]);

  useEffect(() => {
    if (variable) {
      const token = getOptionValue(fieldOptions, variable);
      if (token) {
        if (token.type && comparatorTypeEquals[token.type]) {
          setComparator(comparatorTypeEquals[token.type]);
        } else if (token.valueType && comparatorTypeEquals[token.valueType]) {
          setComparator(comparatorTypeEquals[token.valueType]);
        }
      }
    }
  }, [variable, fieldOptions, comparatorTypeEquals]);

  return (
    <div className={css.container}>
      <Block label="Field" required={true}>
        <TokenEditor
          disabled={disabled}
          value={variable ? `{{${variable}}}` : ''}
          allowFlowTrigger={allowTrigger}
          allowExpressions={allowExpressions}
          allowModifiers={allowModifiers}
          singleSelection
          tokens={fieldOptions}
          placeholder="Select a field to compare..."
          onChange={v => {
            setVariable((v || '').replace('{{', '').replace('}}', ''));
          }}
        />
      </Block>
      <div className={classNames(css.conditions)}>
        {internalValue.map((v, i) => (
          <ConditionInputItemWrapper
            {...rest}
            key={v.id}
            condition={v}
            disabled={disabled}
            allowComplex={false}
            allowTrigger={allowTrigger}
            allowExpressions={allowExpressions}
            allowModifiers={allowModifiers}
            hasMultiple={hasMultiple}
            canDelete={hasMultiple}
            type="branch"
            showLabel
            fieldOptions={fieldOptions}
            onDelete={() => {
              const updatedValue = [...internalValue];
              updatedValue.splice(i, 1);
              setInternalValue(updatedValue);
              onChange(updatedValue);
              onPortUpdate(v.id, false);
            }}
            onChange={updated => {
              const updatedValue = [...internalValue];
              updatedValue[i] = updated;
              setInternalValue(updatedValue);
              onChange(updatedValue);
            }}
          />
        ))}
      </div>
      <Button
        className={css.addBtn}
        fullWidth={true}
        variant="outline"
        disableMargins={true}
        disabled={disabled}
        onClick={() => {
          const condition = emptyCondition(
            internalValue.length,
            variable,
            comparator
          );
          const updatedValue = [...internalValue, condition];
          setInternalValue(updatedValue);
          onChange(updatedValue);
          onPortUpdate(condition.id, true);
        }}
      >
        Add Branch
      </Button>
    </div>
  );
};
