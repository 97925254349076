import React, { FC, useRef, useEffect } from 'react';
import Uppy, { UploadResult, UppyOptions } from '@uppy/core';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './UploadDashboard.css';
import { useTheme } from 'shared/utils/Theme';

type UploadDashboardProps = {
  options: UppyOptions;
  height?: string;
  width?: string;
  onUpload: (event: UploadResult) => any;
};

export const UploadDashboard: FC<UploadDashboardProps> = ({
  options,
  height = '300px',
  width = '100%',
  onUpload
}) => {
  const uppy = useRef<Uppy>(new Uppy(options));
  const { name } = useTheme();

  useEffect(() => {
    uppy.current.on('complete', onUpload);

    return () => {
      // eslint-disable-next-line
      uppy?.current?.off('complete', onUpload);
    };
  }, [uppy, onUpload]);

  return (
    <Dashboard
      uppy={uppy.current}
      theme={name}
      height={height}
      width={width}
      proudlyDisplayPoweredByUppy={false}
    />
  );
};
