import { axios } from 'core/Axios';
import {
  DataUpdateEventGroupBody,
  DataUpdateEventGroupResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_EVENT_GROUP;

export const req = async (
  data: DataUpdateEventGroupBody
): Promise<DataUpdateEventGroupResponse> => {
  return axios
    .patch<DataUpdateEventGroupResponse>(Route.UPDATE_EVENT_GROUP, data)
    .then(data => data.data);
};

export const useUpdateEventGroup = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataUpdateEventGroupResponse,
  DataUpdateEventGroupBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_EVENT_GROUPS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
