import React, { FC } from 'react';
import { Datastore } from 'core/types/API';
import { Button } from 'shared/elements/Button';
import { keyInput, ValidatedInput } from 'shared/form/Input';
import { Select, SelectOption } from 'shared/form/Select';
import { UserGroupSelect } from 'shared/internal/UserGroupSelect';
import { Block } from 'shared/layout/Block';
import css from './DatastoreGeneralView.module.css';
import { DatastorePreview } from '../../DatastorePreview';

type DatastoreGeneralViewProps = {
  newStore: boolean;
  values: Omit<Datastore, 'value' | 'id'> & {
    value: any;
  };
  options: { label: string; value: string }[];
  groups?: any[];
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleSubmit: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onClose: () => void;
  onDelete: () => void;
  onViewChange: (view: 'general' | 'json') => void;
};

export const DatastoreGeneralView: FC<DatastoreGeneralViewProps> = ({
  newStore,
  values,
  options,
  groups,
  dirty,
  isValid,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  onClose,
  onDelete,
  onViewChange
}) => (
  <form onSubmit={handleSubmit}>
    <Block
      label="Workflow"
      tooltip="The Workflows that this Datastore will be accessible to"
    >
      <Select
        name="flowId"
        placeholder="Key"
        value={values.flowId}
        disabled={isSubmitting}
        onChange={val => setFieldValue('flowId', val)}
        onBlur={handleBlur}
      >
        {options.map(v => (
          <SelectOption value={v.value} key={v.value}>
            {v.label}
          </SelectOption>
        ))}
      </Select>
    </Block>
    <Block label="Name" required={true}>
      <ValidatedInput
        fullWidth={true}
        autoFocus={true}
        name="name"
        value={values.name}
        disabled={isSubmitting}
        validation={keyInput}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Block>
    <Block label="Value" required={true}>
      {values.value && <DatastorePreview value={values.value} header={false} />}
      <Button
        fullWidth
        variant="filled"
        color="default"
        disabled={isSubmitting}
        onClick={() => onViewChange('json')}
      >
        Edit datastore value
      </Button>
    </Block>
    {groups?.length > 0 && values.flowId === '' && (
      <Block label="Sharing">
        <UserGroupSelect
          name="group"
          clearable
          groups={groups}
          value={values.group}
          disabled={isSubmitting}
          onChange={val => setFieldValue('group', val)}
          onBlur={handleBlur}
        />
      </Block>
    )}
    <div className={css.buttonGroup}>
      <div>
        {!newStore && (
          <Button
            className={css.deleteBtn}
            variant="outline"
            color="default"
            onClick={onDelete}
            disabled={isSubmitting}
          >
            Delete
          </Button>
        )}
      </div>
      <div>
        <Button
          type="submit"
          variant="filled"
          color="primary"
          // seems weird to need the extra 'dirty' check, but isValid is true
          // initially which seems wrong to me
          disabled={!dirty || !isValid || isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
        <Button
          variant="outline"
          color="default"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </div>
    </div>
  </form>
);
