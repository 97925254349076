import { Helper } from '.';

export const mathHelpers: Helper[] = [
  {
    name: '$sum',
    label: 'Sum',
    description: `Add an array of numbers and return the total.`,
    examples: ['$sum([5,1,3,7,4]) => 20'],
    valueType: 'array',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/aggregation-functions#sum'
  },
  {
    name: '$max',
    label: 'Max',
    description: `Returns the largest number from an array of numbers.`,
    examples: [`$max([5,1,3,7,4]) => 7`],
    valueType: 'array',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/aggregation-functions#max'
  },
  {
    name: '$min',
    label: 'Min',
    description: `Returns the smallest number from an array of numbers.`,
    examples: [`$min([5,1,3,7,4]) => 1`],
    valueType: 'array',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/aggregation-functions#min'
  },
  {
    name: '$average',
    label: 'Average',
    description: `Returns the average value from an array of numbers.`,
    examples: [`$average([5,1,3,7,4]) => 4`],
    valueType: 'array',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/aggregation-functions#average'
  }
];
