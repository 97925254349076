import { axios } from 'core/Axios';
import {
  DataGetPackageParams,
  DataGetPackageResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_PACKAGE;
export const buildQueryKey = (params?: DataGetPackageParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetPackageParams
): Promise<DataGetPackageResponse> => {
  return axios
    .get<DataGetPackageResponse>(Route.GET_PACKAGE, { params })
    .then(data => data.data);
};

export const useGetPackage = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetPackageResponse, DataGetPackageParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
