import { CorePackages, EmailActions } from '../enums';

export const emailPackage = {
  id: CorePackages.Email,
  name: 'Email',
  categories: ['Core'],
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `email.svg`
  },
  actions: {
    items: [
      {
        id: EmailActions.Send,
        name: 'Send Email',
        description:
          'Send email to the Recipient with specified Subject and Body.',
        input: {
          type: 'object',
          required: ['to', 'subject', 'body'],
          properties: {
            to: {
              type: 'string',
              title: 'To',
              description: 'Recipient address.',
              attributes: {
                'ui:order': 0,
                'ui:component': 'array'
              },
              examples: ['test@crft.app']
            },
            replyTo: {
              type: 'string',
              title: 'Reply To',
              description: 'Reply to address.',
              attributes: {
                'ui:order': 1
              },
              examples: ['reply-to@crft.app']
            },
            subject: {
              type: 'string',
              title: 'Subject',
              description: 'Email subject.',
              attributes: {
                'ui:order': 2
              },
              examples: ['Hello']
            },
            body: {
              type: 'string',
              title: 'Body',
              description: 'Email body.',
              attributes: {
                'ui:order': 3
              },
              examples: ['So... <b>Hello!</b>']
            },
            isHTML: {
              type: 'boolean',
              title: 'HTML',
              description: 'Is HTML body.',
              attributes: {
                'ui:order': 4
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of email sending.'
            }
          }
        }
      },
      {
        id: EmailActions.Trigger,
        name: 'Receive Email',
        type: 'trigger',
        required: ['from', 'enabled'],
        input: {
          type: 'object',
          properties: {
            from: {
              type: 'string',
              title: 'Email',
              description: 'The Email your function should invoke.',
              default: `{{flow.id}}-{{action.id}}@${process.env.REACT_APP_EMAIL_DOMAIN}`,
              attributes: {
                'ui:order': 0,
                'ui:component': 'template'
              }
            },
            allowed: {
              type: 'string',
              title: 'Allowed Emails/Domains',
              description:
                'Email addresses or domains that can trigger this Workflow to run.',
              attributes: {
                'ui:order': 1,
                'ui:component': 'array'
              },
              examples: ['test@crft.app', 'www.crft.app']
            },
            enabled: {
              type: 'boolean',
              title: 'Enabled',
              description: 'Enable or disable the triggering.',
              default: true
            }
          }
        },
        output: {
          type: 'object',
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of email triggering.'
            },
            data: {
              type: 'object',
              properties: {
                to: {
                  type: 'string',
                  title: 'To',
                  description: 'Email trigger address.',
                  examples: ['p7le7bhdeti+w8ixju_eso-@mail.crft.app']
                },
                from: {
                  type: 'string',
                  title: 'From',
                  description:
                    'Email sender, as taken from the message headers.',
                  examples: ['sender@mail.com']
                },
                subject: {
                  type: 'string',
                  title: 'Subject',
                  description: 'Email subject.',
                  examples: ['Hello!']
                },
                html: {
                  type: 'string',
                  title: 'HTML',
                  description: 'Email body in HTML formatting.',
                  examples: ['Some Text']
                },
                text: {
                  type: 'string',
                  title: 'Text',
                  description: 'Email body in plaintext formatting.',
                  examples: ['Some Text']
                },
                headers: {
                  type: 'string',
                  title: 'Headers',
                  description: 'The Email headers.'
                },
                dkim: {
                  type: 'string',
                  title: 'DKIM',
                  description:
                    'A string containing the verification results of any DKIM and domain keys signatures in the message.',
                  examples: ['{@gmail.com : pass}']
                },
                sender_ip: {
                  type: 'string',
                  title: 'Sender IP',
                  description: 'Email sender IP address.',
                  examples: ['209.85.166.54']
                },
                spam_report: {
                  type: 'string',
                  title: 'Spam report',
                  description: 'Spam Assassin’s spam report.',
                  examples: [
                    'Spam detection software, running on the system "mx0047p1mdw1.sendgrid.net"'
                  ]
                },
                envelope: {
                  type: 'string',
                  title: 'Envelope',
                  description: 'A string containing the SMTP envelope.',
                  examples: [
                    `{"to":["p7le7bhdeti+w8ixju_eso-@mail.crft.app"],"from":"sender@mail.com"}`
                  ]
                },
                spam_score: {
                  type: 'string',
                  title: 'Spam Score',
                  description:
                    'Spam Assassin’s rating for whether or not this is spam.',
                  examples: ['0.022']
                },
                charsets: {
                  type: 'string',
                  title: 'Charsets',
                  description:
                    'A string containing the character sets of the fields extracted from the message.',
                  examples: [
                    `{"to":"UTF-8","html":"UTF-8","subject":"UTF-8","from":"UTF-8","text":"UTF-8"}`
                  ]
                },
                uploads: {
                  type: 'array',
                  title: 'Attachments',
                  description: 'List of attachments',
                  items: {
                    type: 'string',
                    title: 'Attachment',
                    description: 'Email attachment'
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
};
