import React, { FC, forwardRef, Ref } from 'react';
import { motion } from 'framer-motion';

export const itemVariants = {
  initial: {
    y: -20,
    opacity: 0,
    transition: {
      when: 'beforeChildren'
    }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      when: 'beforeChildren'
    }
  },
  exit: {
    y: -20,
    opacity: 0
  }
};

export const MotionItem: FC<any & { ref?: Ref<HTMLDivElement> }> = forwardRef(({ children, ...rest }, ref) => (
  <motion.div variants={itemVariants} {...rest} ref={ref}>
    {children}
  </motion.div>
));
