import { axios } from 'core/Axios';
import {
  DataPutFlowRevisionBody,
  DataPutFlowRevisionResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_FLOW_REVISION;

export const req = async (
  data: DataPutFlowRevisionBody
): Promise<DataPutFlowRevisionResponse> => {
  return axios
    .put<DataPutFlowRevisionResponse>(Route.PUT_FLOW_REVISION, data)
    .then(data => data.data);
};

export const usePutFlowRevision = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataPutFlowRevisionResponse,
  DataPutFlowRevisionBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
