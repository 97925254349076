import classNames from 'classnames';
import React, { FC } from 'react';
import { Dialog as LayersDialog, DialogProps } from 'realayers';
import css from './Dialog.module.css';
import { DialogHeader } from './DialogHeader';

export const Dialog: FC<Partial<DialogProps>> = ({
  header,
  className,
  ...rest
}) => (
  <LayersDialog
    {...rest}
    header={typeof header === 'string' ? <h3>{header}</h3> : header}
    headerElement={<DialogHeader />}
    className={classNames(css.dialog, className)}
  />
);
