import sortBy from 'lodash/sortBy';
import { KeyboardCoordinates } from 'shared/utils/KeyboardNav';
import { CascaderGroup, CascaderItem } from './types';

export function buildGroups(tree: CascaderItem[], selection?: CascaderItem) {
  const result: CascaderGroup[] = [
    {
      text: 'Home',
      items: tree
    }
  ];

  if (selection) {
    const selectedPath = selection.path.split('.');

    let last = tree;
    for (const selection of selectedPath) {
      const item = last?.find(i => i.id === selection);

      if (item?.items) {
        result.push({
          text: item.text,
          items: sortBy(item.items, 'text')
        });

        last = item.items;
      }
    }
  }

  return result;
}

export function buildSelections(
  groups: CascaderGroup[],
  selection?: CascaderItem
) {
  const result = [];

  if (selection?.path) {
    const split = selection.path.split('.');

    let i = 0;
    for (const group of groups) {
      const cur = split[i];
      const token = group.items.find(
        g => g.id === cur || g.path === selection.path
      );
      if (token) {
        result.push(token);
      }
      i++;
    }
  }

  return result;
}

export function buildKeyboardGroups(groups: CascaderGroup[]) {
  return groups.map(g => g.items);
}

export function getSelection(items: CascaderItem[], value?: string | null) {
  let found: CascaderItem | null = null;

  if (value && items?.length) {
    function findSelection(items: CascaderItem[]) {
      for (const item of items) {
        // TODO: Fix this hacky nodes prefix
        if (item.path === value) {
          found = item;
          break;
        } else if (item.items?.length && !found) {
          findSelection(item.items);
        }
      }
    }

    findSelection(items);
  }

  return found;
}

export function findKeyboardSelection(
  items: CascaderItem[][],
  path: string | null
) {
  let coords: KeyboardCoordinates | null = null;

  if (path && items.length) {
    for (let xI = 0; xI < items.length; xI += 1) {
      const row = items[xI];
      const yI = row.findIndex(i => i.path === path);
      if (yI !== -1) {
        coords = { x: xI, y: yI };
        return coords;
      }
    }
  }

  return coords;
}
