import { axios } from 'core/Axios';
import {
  DataGetApiKeyParams,
  DataGetApiKeyResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_API_KEY;
export const buildQueryKey = (params?: DataGetApiKeyParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetApiKeyParams
): Promise<DataGetApiKeyResponse> => {
  return axios
    .get<DataGetApiKeyResponse>(Route.GET_API_KEY, { params })
    .then(data => data.data);
};

export const useGetApiKey = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetApiKeyResponse, DataGetApiKeyParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
