import React, { FC } from 'react';
import classNames from 'classnames';
import css from './Tree.module.css';

export interface TreeProps {
  className?: any;
  style?: React.CSSProperties;
  children?: any;
}

export const Tree: FC<TreeProps> = ({ children, className, ...rest }) => (
  <div className={classNames(css.tree, className)} {...rest}>
    <ul className={css.container}>{children}</ul>
  </div>
);
