import React, { FC, useMemo } from 'react';
import { UploadsList } from './UploadsList';
import { buildGetUploadQueryKey } from 'shared/hooks/api';
import { useQueries } from 'react-query';
import { req as getUpload } from 'shared/hooks/api/crudUpload/useGetUpload';

type UploadsListContainerProps = {
  ids: string[];
  onDelete?: (id: string) => void;
};

export const UploadsListContainer: FC<UploadsListContainerProps> = ({
  ids = [],
  onDelete = () => undefined
}) => {
  const results = useQueries(
    ids.map(id => ({
      queryKey: buildGetUploadQueryKey({ id }),
      queryFn: () => getUpload({ id })
    }))
  );

  const isLoading = useMemo(() => results.some(r => r.isLoading), [results]);

  if (isLoading) {
    return null;
  }

  const data = results.map(r => r.data);

  return <UploadsList list={data} onDelete={onDelete} />;
};
