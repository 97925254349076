import { valueTypeToBase } from 'core/types';
import { getModifierFromName } from 'core/utils/modifiers';
import { Helper } from '../helpers';
import { Modifier } from '../types';

export const parseData = (meta: Helper, params: string[]) => {
  let data: any = {};

  if (!meta?.params?.properties) {
    return data;
  }

  Object.keys(meta.params.properties).forEach((k, i) => {
    const defaultVal = meta.params.properties[k].default;
    if (defaultVal !== undefined) {
      data[k] = defaultVal;
    }

    if (params && params[i] !== undefined) {
      data[k] = params[i];
    }
  });

  return data;
};

export const findMeta = (helper: Helper, token: Modifier) => {
  let result = helper;

  if (!result) {
    result = getModifierFromName(token.modifier);
  }

  return result;
};

export const getModifierTokenDescription = (
  modifier?: Modifier,
  isModifier?: boolean
) => {
  if (!modifier) {
    return '';
  }

  let description = `This ${
    !isModifier ? 'token represents' : 'modifier returns'
  } `;
  const { subType, returnSubType } = modifier;
  const type =
    subType || returnSubType
      ? 'array'
      : valueTypeToBase(modifier.returnType || modifier.valueType);
  let innerType = type;

  if (type === 'array') {
    if (isModifier) {
      innerType = returnSubType
        ? `list of ${returnSubType}s`
        : `list of values`;
    } else {
      innerType = subType ? `list of ${subType}s` : `list of values`;
    }
  }

  const typeBeginsWithVowel = ['a', 'e', 'i', 'o', 'u'].includes(
    innerType?.slice(0, 1)
  );
  description += typeBeginsWithVowel ? `an ${innerType}.` : `a ${innerType}.`;

  if (type === 'array') {
    description += ` Modifiers that accept array ${
      type !== 'array'
        ? ` or ${type || '"any"'}`
        : ` or ${isModifier ? returnSubType || '"any"' : subType || '"any"'}`
    } parameters can be applied to the token by locating and clicking on them below. If the selected modifier does not accept arrays, it will be applied to each of the array's individual items.`;
  } else {
    description += ` Modifiers that accept ${
      type || '"any"'
    } parameters can be applied to this token by locating and clicking on them below.`;
  }

  return description;
};
