import { CorePackages, ManualApprovalActions } from '../enums';

export const approvalPackage = {
  id: CorePackages.ManualApprovals,
  name: 'Manual Approvals',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: 'approve.svg'
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: ManualApprovalActions.ManualApprovalEmail,
        name: 'Approve via Email',
        type: 'approval',
        description: 'Sends Email with approve instructions.',
        input: {
          type: 'object',
          required: ['email', 'subject', 'body'],
          properties: {
            email: {
              type: 'string',
              title: 'Address',
              description: 'Recipient email address',
              attributes: {
                'ui:order': 0
              }
            },
            subject: {
              type: 'string',
              title: 'Subject',
              description: 'Subject of the email',
              default: 'Manual Approval Requested',
              attributes: {
                'ui:order': 1
              }
            },
            body: {
              type: 'string',
              title: 'Body',
              description: 'Message details of the approval',
              default:
                'The "{{flow.name}}" Workflow is requesting your approval to proceed.',
              attributes: {
                'ui:order': 2
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of action execution'
            },
            data: {
              type: 'object',
              properties: {
                status: {
                  title: 'Status',
                  type: 'string',
                  examples: ['Approve', 'Decline']
                }
              }
            }
          }
        }
      }
    ]
  }
};
