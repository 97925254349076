import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { ConditionComplexRule, ConditionValueRule } from '@getcrft/jsonata-ext';
import { useDebounce } from 'react-use';
import { ReactComponent as CollapseIcon } from 'assets/svg/collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/svg/arrow-right.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { Button } from 'shared/elements/Button';
import { Block } from 'shared/layout/Block';
import { Input } from '../Input';
import { Collapse } from 'shared/layout/Collapse';
import { ExtendedConditionRule } from './ConditionInput';
import classNames from 'classnames';
import { ConditionInputItem, OperatorGroup } from './ConditionInputItem';
import css from './ConditionInputItemWrapper.module.css';
import { Datastore, Flow } from 'core/types/API';

export type ConditionInputItemWrapperProps = {
  allowComplex: boolean;
  allowTrigger: boolean;
  allowExpressions?: boolean;
  allowModifiers?: boolean;
  canDelete: boolean;
  condition: ExtendedConditionRule;
  type: 'condition' | 'branch';
  disabled?: boolean;
  hasMultiple?: boolean;
  showLabel?: boolean;
  fieldOptions?: any[];
  valueOptions?: any[];
  operatorOptions?: OperatorGroup[];
  conditionRule?: 'and' | 'or';
  stores?: Datastore[];
  flows?: Flow[];
  onDelete: () => void;
  onChange: (updated: ExtendedConditionRule) => void;
};

export const ConditionInputItemWrapper: FC<ConditionInputItemWrapperProps> = ({
  allowComplex,
  canDelete = false,
  conditionRule,
  hasMultiple,
  showLabel = true,
  condition,
  disabled,
  type: conditionType,
  onDelete,
  onChange,
  ...rest
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [internalValue, setInternalValue] =
    useState<ExtendedConditionRule>(condition);
  const [isComplex, setIsComplex] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(condition.label);

  useDebounce(() => onChange({ ...internalValue, label }), 200, [label]);

  useEffect(() => {
    setInternalValue(condition);
  }, [condition]);

  const renderConditions = useCallback(
    (conditions: ConditionValueRule[], type: 'and' | 'or' | 'simple') =>
      conditions?.map((v, i) => (
        <Block
          label={
            i === 0
              ? `${conditionType === 'condition' ? 'Expression' : 'Equals'}`
              : ''
          }
          key={i}
          className={css.conditionRow}
        >
          <ConditionInputItem
            {...rest}
            value={v}
            type={conditionType}
            disabled={disabled}
            onChange={next => {
              if (type === 'simple') {
                const updated = {
                  id: internalValue.id,
                  label: internalValue.label,
                  ...next
                };

                setInternalValue(updated);
                onChange(updated);
              } else {
                const complexRule = internalValue as ConditionComplexRule;
                const updated = {
                  ...internalValue,
                  id: internalValue.id,
                  label: internalValue.label,
                  [type]: complexRule[type].map((vv, ii) =>
                    ii === i ? next : vv
                  )
                };

                setInternalValue(updated);
                onChange(updated);
              }
            }}
          />
          {conditions.length > 1 && (
            <Fragment>
              <Button
                variant="text"
                className={css.deleteIconBtn}
                onClick={() => {
                  const complexRule = internalValue as ConditionComplexRule;
                  const newRules = complexRule[type].filter(
                    (v, ii) => ii !== i
                  );
                  if (newRules.length > 1) {
                    const updated = {
                      ...internalValue,
                      id: internalValue.id,
                      label: internalValue.label,
                      [type]: newRules
                    };

                    setInternalValue(updated);
                    onChange(updated);
                  } else {
                    const updated = {
                      id: internalValue.id,
                      label: internalValue.label,
                      ...newRules[0]
                    };

                    setInternalValue(updated);
                    onChange(updated);
                  }
                }}
              >
                <DeleteIcon className={css.deleteIcon} />
              </Button>
            </Fragment>
          )}
        </Block>
      )),
    [conditionType, rest, disabled, internalValue, onChange]
  );

  useEffect(() => {
    if (!hasMultiple) {
      setExpanded(true);
    }
  }, [hasMultiple]);

  useEffect(() => {
    const hasComplexRules =
      (internalValue as ConditionComplexRule).and?.length ||
      (internalValue as ConditionComplexRule).or?.length;
    setIsComplex(hasComplexRules > 0);
  }, [internalValue]);

  return (
    <div className={classNames(css.condition, { [css.multiple]: hasMultiple })}>
      {hasMultiple && (
        <div className={css.actions}>
          <div className={css.icons}>
            {expanded && (
              <Button
                size="small"
                variant="text"
                disableMargins
                title="Collapse"
                className={css.button}
                onClick={() => setExpanded(false)}
              >
                <CollapseIcon className={css.icon} />
              </Button>
            )}
            {!expanded && (
              <Button
                size="small"
                disableMargins
                variant="text"
                title="Expand"
                className={css.button}
                onClick={() => setExpanded(true)}
              >
                <ExpandIcon className={css.icon} />
              </Button>
            )}
            {canDelete && expanded && (
              <Button
                size="small"
                variant="text"
                disableMargins
                disabled={disabled}
                title="Delete Condition"
                className={css.button}
                onClick={onDelete}
              >
                <DeleteIcon className={css.icon} />
              </Button>
            )}
          </div>
        </div>
      )}
      <div className={css.inputs}>
        {showLabel && (
          <Block label="Label" required={true}>
            <Input
              value={internalValue.label}
              fullWidth={true}
              disabled={disabled}
              onChange={event => {
                if (event.target.value !== internalValue.label) {
                  const updated = {
                    ...internalValue,
                    label: event.target.value
                  };
                  setInternalValue(updated);
                  setLabel(event.target.value);
                }
              }}
            />
          </Block>
        )}
        <Collapse className={css.container} expanded={expanded}>
          <div
            className={classNames(css.conditions, {
              [css.complexConditions]: allowComplex && isComplex
            })}
          >
            {allowComplex && isComplex && (
              <Button
                title="Toggle Condition Type"
                variant="text"
                size="small"
                disabled={!!conditionRule}
                className={css.typeBtn}
                onClick={() => {
                  const complexRule = internalValue as ConditionComplexRule;
                  if (complexRule.and?.length) {
                    const updated = {
                      ...internalValue,
                      id: internalValue.id,
                      label: internalValue.label,
                      or: complexRule.and,
                      and: undefined
                    };

                    setInternalValue(updated);
                    onChange(updated);
                  } else {
                    const updated = {
                      ...internalValue,
                      id: internalValue.id,
                      label: internalValue.label,
                      and: complexRule.or,
                      or: undefined
                    };

                    setInternalValue(updated);
                    onChange(updated);
                  }
                }}
              >
                {conditionRule
                  ? conditionRule.toUpperCase()
                  : (internalValue as ConditionComplexRule).and?.length > 0
                  ? 'AND'
                  : 'OR'}
              </Button>
            )}
            <div className={css.conditionsList}>
              {isComplex ? (
                <Fragment>
                  {renderConditions(
                    (internalValue as ConditionComplexRule).and,
                    'and'
                  )}
                  {renderConditions(
                    (internalValue as ConditionComplexRule).or,
                    'or'
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {renderConditions(
                    [internalValue as ConditionValueRule],
                    'simple'
                  )}
                </Fragment>
              )}
              {allowComplex && (
                <Button
                  className={css.addBtn}
                  size="small"
                  variant="text"
                  disableMargins={true}
                  disabled={disabled}
                  onClick={() => {
                    if (isComplex) {
                      const complexRule = internalValue as ConditionComplexRule;
                      const type = conditionRule
                        ? conditionRule.toLowerCase()
                        : complexRule.and?.length > 0
                        ? 'and'
                        : 'or';
                      const updated = {
                        ...internalValue,
                        id: internalValue.id,
                        label: internalValue.label,
                        [type]: [
                          ...complexRule[type],
                          {
                            variable: null,
                            comparator: null,
                            value: ''
                          }
                        ]
                      };
                      setInternalValue(updated);
                      onChange(updated);
                    } else {
                      const simpleRule = internalValue as ConditionValueRule;
                      const updated = {
                        ...internalValue,
                        id: internalValue.id,
                        label: internalValue.label,
                        and: [
                          {
                            variable: simpleRule.variable,
                            comparator: simpleRule.comparator,
                            value: simpleRule.value,
                            view: simpleRule.view
                          },
                          {
                            variable: null,
                            comparator: null,
                            value: ''
                          }
                        ]
                      };
                      setInternalValue(updated);
                      onChange(updated);
                    }
                  }}
                >
                  <PlusIcon />
                  Add Condition
                </Button>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
