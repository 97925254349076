import React, { FC } from 'react';
import copy from 'copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg';
import { useNotification } from 'shared/layers/Notification';
import css from './TemplateBox.module.css';

type TemplateBoxProps = {
  value: any;
};

export const TemplateBox: FC<TemplateBoxProps> = ({ value }) => {
  const { addSuccess } = useNotification();

  return (
    <div
      className={css.templateBox}
      title="Double click to copy to clipboard."
      onClick={event => {
        event.preventDefault();
        copy(value);
        addSuccess(`Copied ${value} to your clipboard.`);
      }}
    >
      <span>{value}</span>
      <CopyIcon className={css.copyIcon} />
    </div>
  );
};
