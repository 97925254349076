import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import css from './LoadingDots.module.css';

type LoadingDotsProps = {
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const speed = 0.2;

export const LoadingDots: FC<LoadingDotsProps> = ({
  className,
  size = 'small'
}) => (
  <motion.div
    className={classNames(className, css.container, {
      [css.small]: size === 'small',
      [css.medium]: size === 'medium',
      [css.large]: size === 'large'
    })}
  >
    <motion.div
      animate={{
        opacity: [0, 1, 0],
        scale: [1, 2, 2, 1, 1]
      }}
      transition={{
        duration: speed * 4,
        ease: 'easeInOut',
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: speed,
        delay: speed
      }}
    />
    <motion.div
      animate={{
        opacity: [0, 1, 0],
        scale: [1, 2, 2, 1, 1]
      }}
      transition={{
        duration: speed * 4,
        ease: 'easeInOut',
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: speed,
        delay: speed * 2
      }}
    />
    <motion.div
      animate={{
        opacity: [0, 1, 0],
        scale: [1, 2, 2, 1, 1]
      }}
      transition={{
        duration: speed * 4,
        ease: 'easeInOut',
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: speed,
        delay: speed * 3
      }}
    />
  </motion.div>
);
