import { Children } from 'react';
import { SelectOption, SelectOptionProps } from './SelectOption';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { getModifierFromName } from 'core/utils/modifiers';

export interface GroupOptions {
  groups: GroupOption[];
  itemsCount: number;
  hasGroups: boolean;
}

export interface GroupOption {
  offset: number;
  index: number;
  items: SelectOptionProps[];
  name: string;
}

export function getGroups(options: SelectOptionProps[]): GroupOptions {
  if (!options?.length) {
    return {
      groups: [],
      itemsCount: 0,
      hasGroups: false
    };
  }

  const groupsMap = groupBy(options, t => t.group);
  const groupNames = Object.keys(groupsMap);

  if (groupNames.length === 1 && groupNames[0] === 'undefined') {
    return {
      groups: [],
      itemsCount: options.length,
      hasGroups: false
    };
  }

  let index = 0;
  const groups = Object.entries(groupsMap).map(([key, value], index) => ({
    offset: 0,
    index,
    items: value,
    name: key
  }));

  // Increment offsets for groups
  for (const group of groups) {
    group.offset = index;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const _item of group.items) {
      index++;
    }
  }

  return {
    groups,
    itemsCount:
      groups?.length !== 0
        ? sumBy(groups, g => g.items.length)
        : options.length,
    hasGroups: groups?.length !== 0
  };
}

export function createOptions(children) {
  const arr = Children.toArray(children);
  return arr
    .filter((child: any) => child.type?.name === SelectOption.name)
    .map((child: any) => child.props);
}

export const stringifyListValues = (values: SelectOptionProps[]) =>
  values ? Array.from(values, v => v.value).join(',') : undefined;

export function getValuesArray(value: string) {
  if (!value) {
    return [];
  }

  const values = value.split(',');
  return Array.from(values, val => ({
    label: val,
    value: val
  }));
}

export function getOptionValue(options: any[], value: any) {
  let optValue;

  if (value?.match(/^\$[\S]*\(.*\)$/g)) {
    // The value has a modifier on it. Determine the outermost modifier and get the return type to use for operator options
    const outerModifier = value.match(/^\$[^(]*/g);
    if (outerModifier) {
      const modifier = getModifierFromName(outerModifier[0]);
      return modifier
        ? {
            valueType: modifier.returnType
          }
        : undefined;
    }
  } else {
    for (const o of options) {
      // This is for operators
      if (o.options) {
        for (const oo of o.options) {
          if (oo.value === value) {
            optValue = oo;
            break;
          }
        }
      } else if (o.value === value) {
        optValue = o;
        break;
      }
    }
  }

  return optValue;
}
