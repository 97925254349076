import { axios } from 'core/Axios';
import {
  UsageFlowAverageCountParams,
  UsageFlowAverageCountResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.FLOW_AVERAGE_ACTION_COUNT;
export const buildQueryKey = (params?: UsageFlowAverageCountParams) => {
  return [baseQueryKey, params];
};

export const req = async ({
  flowId,
  limit,
  from,
  to
}: UsageFlowAverageCountParams): Promise<UsageFlowAverageCountResponse> => {
  return axios
    .get<UsageFlowAverageCountResponse>(
      Route.FLOW_AVERAGE_ACTION_COUNT + flowId,
      { params: { from, to, limit } }
    )
    .then(data => data.data);
};

export const useAverageActionCount = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  UsageFlowAverageCountResponse,
  UsageFlowAverageCountParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
