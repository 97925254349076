import { axios } from 'core/Axios';
import {
  DataGetFlowUsageParams,
  DataGetFlowUsageResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_FLOW_USAGE;
export const buildQueryKey = (params?: DataGetFlowUsageParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetFlowUsageParams
): Promise<DataGetFlowUsageResponse> => {
  return axios
    .get<DataGetFlowUsageResponse>(Route.GET_FLOW_USAGE, { params })
    .then(data => data.data);
};

export const useGetFlowUsage = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetFlowUsageResponse,
  DataGetFlowUsageParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
