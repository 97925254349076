import { CorePackages, DataActions } from '../enums';

export const dataPackage = {
  id: CorePackages.Data,
  name: 'Data',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `grid.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: DataActions.ExtractAndModify,
        name: 'Extract and Modify',
        description: 'Query and manipulate data.',
        input: {
          type: 'object',
          required: ['expression'],
          properties: {
            expression: {
              type: 'string',
              title: 'Expression'
            }
          }
        },
        output: {
          type: 'object',
          required: ['success', 'data'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of setting the value'
            },
            data: {
              type: 'object',
              title: 'Data',
              description: 'The result of the transform.'
            }
          }
        }
      }
    ]
  }
};
