import { axios } from 'core/Axios';
import {
  DataPutConnectionBody,
  DataPutConnectionResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_CONNECTION;

export const req = async (
  data: DataPutConnectionBody
): Promise<DataPutConnectionResponse> => {
  return axios
    .put<DataPutConnectionResponse>(Route.PUT_CONNECTION, data)
    .then(data => data.data);
};

export const usePutConnection = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataPutConnectionResponse,
  DataPutConnectionBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_CONNECTIONS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
