import { axios } from 'core/Axios';
import {
  DataDelEventGroupParams,
  DataDelEventGroupResponse,
  DataListEventGroupsResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_EVENT_GROUP;

export const req = async (
  params: DataDelEventGroupParams
): Promise<DataDelEventGroupResponse> => {
  return axios
    .delete<DataDelEventGroupResponse>(Route.DEL_EVENT_GROUP, { params })
    .then(data => data.data);
};

export const useDelEventGroup = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataDelEventGroupResponse,
  DataDelEventGroupParams
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_EVENT_GROUPS);
      const previousData = client.getQueriesData(Route.LIST_EVENT_GROUPS);
      client.setQueriesData(
        Route.LIST_EVENT_GROUPS,
        (oldData: DataListEventGroupsResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_EVENT_GROUPS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
