import React, {
  forwardRef,
  Ref,
  FC,
  Children,
  ReactNode,
  ReactElement
} from 'react';
import classNames from 'classnames';
import { CloneElement } from 'rdk';
import css from './RadioGroup.module.css';
import { RadioItemProps } from './RadioItem';

type RadioGroupProps = {
  ref?: Ref<HTMLDivElement>;
  orientation?: 'vertical' | 'horizontal';
  className?: string;
  children?: any;
  onChange: (value: any) => void;
};

export const RadioGroup: FC<RadioGroupProps> = forwardRef(
  (
    { className, orientation = 'vertical', onChange, children },
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={classNames(className, css.radioGroup, {
        [css.vertical]: orientation === 'vertical',
        [css.horizontal]: orientation === 'horizontal'
      })}
    >
      {Children.toArray(children).map(c => {
        const childChildren =
          (c as ReactElement).props && (c as ReactElement).props.children;

        return (
          <CloneElement<RadioItemProps & { children: ReactNode }>
            element={c}
            children={childChildren}
            onChange={onChange}
          />
        );
      })}
    </div>
  )
);
