import React, { FC } from 'react';
import { Dialog } from '../Dialog';
import { Button } from 'shared/elements/Button';
import { HoldButton } from './HoldButton';
import css from './HoldConfirmDialog.module.css';

export type HoldConfirmDialogProps = {
  title?: string;
  text: any;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const HoldConfirmDialog: FC<HoldConfirmDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  text = 'Hold to confirm'
}) => (
  <Dialog size="350px" header={title} open={open} onClose={onCancel}>
    <div className={css.textContainer}>{text}</div>
    <HoldButton onConfirm={onConfirm} />
    <div className={css.holdMsg}>Click and hold to confirm</div>
    <footer className={css.btnContainer}>
      <Button
        type="button"
        variant="text"
        className={css.cancelBtn}
        disablePadding={true}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </footer>
  </Dialog>
);
