import { axios } from 'core/Axios';
import { Datastore } from 'core/types/API';
import {
  DataListDatastoresResponse,
  DataPutDatastoreBody,
  DataPutDatastoreResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_DATASTORE;

export const req = async (
  data: DataPutDatastoreBody
): Promise<DataPutDatastoreResponse> => {
  return axios
    .put<DataPutDatastoreResponse>(Route.PUT_DATASTORE, data)
    .then(data => data.data);
};

export const usePutDatastore = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataPutDatastoreResponse,
  DataPutDatastoreBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_DATASTORES);
      const previousData = client.getQueriesData(Route.LIST_DATASTORES);
      client.setQueriesData(
        Route.LIST_DATASTORES,
        (oldData: DataListDatastoresResponse) => {
          oldData.items.push(params as Datastore);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_DATASTORES);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
