import { Helper } from '.';

export const dateHelpers: Helper[] = [
  {
    name: '$now',
    label: 'Now',
    description:
      'Generates a UTC timestamp in YYYY-MM-DDThh:mm:ss.SSSZ format and returns it as a string.',
    invariant: true,
    examples: [`$now => "2017-05-15T03:12:59.000Z"`],
    returnType: 'datetime',
    params: {
      type: 'object',
      properties: {
        format: {
          type: 'string',
          title: 'Format',
          description:
            'Timestamp is formatted according to the representation specified in the string.',
          default: 'YYYY-MM-DDThh:mm:ss.SSSZ',
          examples: ['YYYY-MM-DD HH:MM:SS', 'MM/DD/YYYY hh:mm:ss A'],
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/date-time-functions#now'
  },
  {
    name: '$fromMillis',
    label: 'From Milliseconds',
    description:
      'Convert the number representing milliseconds since the Unix Epoch (1 January, 1970 UTC) to a formatted string representation of the timestamp as specified by the format string.',
    examples: [
      `$fromMillis(1510067557000) => "11/07/2017 3:12:37 PM"`,
      `$fromMillis(1510067557000, 'YYYY-MM-DD HH:MM:SS') => "2017-11-07 15:12:37"`
    ],
    valueType: 'number',
    returnType: 'string',
    params: {
      type: 'object',
      properties: {
        format: {
          type: 'string',
          title: 'Format',
          description:
            'Timestamp is formatted according to the representation specified in the string.',
          default: 'MM/DD/YYYY hh:mm:ss A',
          examples: ['YYYY-MM-DD HH:MM:SS'],
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/date-time-functions#frommillis'
  },
  {
    name: '$toMillis',
    label: 'To Milliseconds',
    description:
      'Convert a timestamp string to the number of milliseconds since the Unix Epoch (1 January, 1970 UTC) as a number.',
    examples: [`$toMillis("2017-11-07T15:07:54.972Z") => 1510067274972`],
    valueType: 'datetime',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/date-time-functions#tomillis'
  },
  {
    name: '$relativeDate',
    label: 'Relative Date',
    description: 'Apply a relative offset to a date and return the new date.',
    examples: [
      `$relativeDate("2017-11-07T15:07:54.972Z", 1, 'd')`,
      `$relativeDate("2017-11-07T15:07:54.972Z", 1, 'd', 'MM/DD/YY)`
    ],
    valueType: 'datetime',
    returnType: 'string',
    params: {
      type: 'object',
      required: ['date', 'increment', 'duration'],
      properties: {
        increment: {
          type: 'number',
          title: 'Increment',
          description:
            'Increment of the offset - a positive or negative number.',
          examples: [1, -4],
          attributes: {
            'ui:order': 0
          }
        },
        duration: {
          type: 'string',
          title: 'Duration',
          description:
            'Duration of the increment based on MomentJS format options https://momentjs.com/docs/#/manipulating/.',
          examples: ['d', 'M'],
          attributes: {
            'ui:order': 1
          }
        },
        format: {
          type: 'string',
          title: 'Format',
          description:
            'Format of the date using MomentJS format options https://momentjs.com/docs/#/displaying/format/.',
          default: 'MM/DD/YYYY hh:mm:ss A',
          examples: ['MM/DD/YYYY', 'MM/DD/YYYY hh:mm:ss A', 'x'],
          attributes: {
            'ui:order': 2
          }
        }
      }
    }
  },
  {
    name: '$relativeDate',
    label: 'Relative Date',
    description: 'Apply a relative offset to a date and return the new date.',
    examples: [
      `$relativeDate("2017-11-07T15:07:54.972Z", 1, 'd')`,
      `$relativeDate("2017-11-07T15:07:54.972Z", 1, 'd', 'MM/DD/YY)`
    ],
    invariant: true,
    valueType: 'datetime',
    returnType: 'string',
    params: {
      type: 'object',
      required: ['date', 'increment', 'duration'],
      properties: {
        date: {
          type: 'string',
          title: 'Date',
          description: 'Date value to compare against.',
          examples: ['2017-11-07T15:07:54.972Z', 'now'],
          default: 'now',
          attributes: {
            'ui:order': 0
          }
        },
        increment: {
          type: 'number',
          title: 'Increment',
          description:
            'Increment of the offset - a positive or negative number.',
          examples: [1, -4],
          attributes: {
            'ui:order': 1
          }
        },
        duration: {
          type: 'string',
          title: 'Duration',
          description:
            'Duration of the increment based on MomentJS format options https://momentjs.com/docs/#/manipulating/.',
          examples: ['d', 'M'],
          attributes: {
            'ui:order': 2
          }
        },
        format: {
          type: 'string',
          title: 'Format',
          description:
            'Format of the date using MomentJS format options https://momentjs.com/docs/#/displaying/format/.',
          default: 'MM/DD/YYYY hh:mm:ss A',
          examples: ['MM/DD/YYYY', 'MM/DD/YYYY hh:mm:ss A', 'x'],
          attributes: {
            'ui:order': 3
          }
        }
      }
    }
  },
  {
    name: '$dateDifference',
    label: 'Date Difference',
    description: 'Return number of time units between dates',
    examples: [
      `$dateDifference('2017-06-09T03:22:13.009Z', '2020-11-07T15:07:54.972Z', 'days', false)`
    ],
    valueType: 'datetime',
    returnType: 'number',
    params: {
      type: 'object',
      required: ['end', 'unitOfTime'],
      properties: {
        end: {
          type: 'string',
          title: 'Date To',
          description: 'Date of end interval limit.',
          examples: ['2020-11-07T15:07:54.972Z'],
          attributes: {
            'ui:order': 0
          }
        },
        unitOfTime: {
          type: 'array',
          title: 'Unit of Time',
          items: {
            type: 'string',
            enum: ['years', 'months', 'weeks', 'days', 'hours', 'minutes']
          },
          attributes: {
            'ui:order': 1
          }
        },
        round: {
          type: 'boolean',
          title: 'Round',
          default: true,
          attributes: {
            'ui:order': 2
          }
        }
      }
    }
  }
];
