import { FC } from 'react';
import { useAuth } from './useAuth';

type GuardProps = {
  children: any;
  role: 'user' | 'admin' | 'crft';
};

export const Guard: FC<GuardProps> = ({ children, role }) => {
  const { user, isFp } = useAuth();

  // TODO: Make this pull the role correctly
  if (user) {
    if (role === 'crft' && !isFp) {
      return null;
    }
  }

  if (typeof children === 'function') {
    return children();
  }

  return children;
};
