import React, { FC, useState } from 'react';
import {
  StoreValueType,
  Datastore,
  SaveDatastoreInput,
  UpdateDatastoreInput
} from 'core/types/API';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ExtendedDataStore } from './DatastoreDrawer';
import { DatastoreGeneralView, DatastoreJsonView } from './views';
import { RouteLeavingGuard } from 'shared/utils/RouteLeavingGuard';
import { DeleteDataStoreConfirm } from '../DeleteDataStoreConfirm';

type NewEditStoreProps = {
  flowId?: string;
  groups?: any[];
  storeGroups: any[];
  store?: ExtendedDataStore;
  connectedFlows?: {
    id: string;
    name: string;
  }[];
  onCreate: (data: SaveDatastoreInput) => Promise<void>;
  onUpdate: (data: UpdateDatastoreInput) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onClose: () => void;
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  group: Yup.mixed(),
  value: Yup.mixed(),
  flowId: Yup.mixed()
});

export const NewEditStore: FC<NewEditStoreProps> = ({
  storeGroups = [],
  groups = [],
  flowId,
  store,
  connectedFlows,
  onCreate,
  onUpdate,
  onDelete,
  onClose
}) => {
  const [view, setView] = useState<'general' | 'json'>('general');
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const {
    values,
    dirty,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm
  } = useFormik<Omit<Datastore, 'id' | 'value'> & { value: any }>({
    initialValues: {
      name: store?.name || '',
      value: store?.value || [],
      type: store?.type || StoreValueType.Single,
      flowId: store?.flowId || flowId || '',
      group: store?.group?.name || ''
    },
    validationSchema,
    // index needed for pagination, but not saving
    onSubmit: async values => {
      let updatedValue;
      if (store) {
        updatedValue = {
          ...values,
          value:
            values.value && typeof values.value !== 'string'
              ? JSON.stringify(values.value)
              : values.value,
          id: store.id
        };
        resetForm(updatedValue);
        await onUpdate(updatedValue);
      } else {
        updatedValue = {
          ...values,
          value:
            values.value && typeof values.value !== 'string'
              ? JSON.stringify(values.value)
              : values.value
        };
        resetForm(updatedValue);
        await onCreate(updatedValue);
      }
      onClose();
    }
  });

  const options = [{ name: 'Global', id: '' }, ...storeGroups].map(s => ({
    label: s.name,
    value: s.id
  }));

  return (
    <div>
      {view === 'general' && (
        <DatastoreGeneralView
          newStore={!store}
          values={values}
          options={options}
          groups={groups}
          dirty={dirty}
          isValid={isValid}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          onClose={onClose}
          onDelete={() => setConfirmDelete(true)}
          onViewChange={setView}
        />
      )}
      {view === 'json' && (
        <DatastoreJsonView
          value={values.value}
          onSave={(value, type) => {
            setFieldValue('value', value);
            setFieldValue(
              'type',
              type === 'array' ? StoreValueType.List : StoreValueType.Single
            );
            setView('general');
          }}
          onViewChange={setView}
        />
      )}
      <DeleteDataStoreConfirm
        open={confirmDelete}
        flows={connectedFlows || []}
        onConfirm={() => {
          onDelete(store.id);
          setConfirmDelete(false);
          onClose();
        }}
        onCancel={() => setConfirmDelete(undefined)}
      />
      <RouteLeavingGuard
        when={dirty}
        title="Unsaved Changes"
        message="There are unsaved changes. Are you sure you want to leave?"
      />
    </div>
  );
};
