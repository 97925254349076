import { axios } from 'core/Axios';
import {
  DataDelFlowUsageParams,
  DataDelFlowUsageResponse,
  DataListFlowUsagesResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_FLOW_USAGE;

export const req = async (
  data: DataDelFlowUsageParams
): Promise<DataDelFlowUsageResponse> => {
  return axios
    .put<DataDelFlowUsageResponse>(Route.DEL_FLOW_USAGE, data)
    .then(data => data.data);
};

export const useDelFlowUsage = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataDelFlowUsageResponse,
  DataDelFlowUsageParams
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_FLOW_USAGES);
      const previousData = client.getQueriesData(Route.LIST_FLOW_USAGES);
      client.setQueriesData(
        Route.LIST_FLOW_USAGES,
        (oldData: DataListFlowUsagesResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
