import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Button } from 'shared/elements/Button';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

import css from './EditBar.module.css';
import { Menu } from 'shared/layers/Menu';
import { List, ListItem } from 'shared/layout/List';
import { ConnectedOverlay, ConnectedOverlayContentRef } from 'rdk';
import { TokenCascader } from 'shared/form/TokenEditor/TokenCascader';
import { useKeyboardNav } from 'shared/utils/KeyboardNav';
import { TokenOption } from 'shared/form/TokenEditor';

type EditBarProps = {
  allowTokens?: boolean;
  tokens?: TokenOption[];
  stores?: any[];
  flows?: any[];
  onAdd: () => void;
  onAddToken?: (token: TokenOption) => void;
  onImport: () => void;
};

export const EditBar: FC<EditBarProps> = ({
  allowTokens,
  tokens,
  stores,
  flows,
  onAdd,
  onAddToken,
  onImport
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [tokenPickerVisible, setTokenPickerVisible] = useState(false);
  const [inputWidth, setInputWidth] = useState<number | null>(null);
  const buttonRef = useRef(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<ConnectedOverlayContentRef | null>(null);
  const cascaderPanelRef = useRef<HTMLDivElement | null>(null);

  useKeyboardNav({
    ref: containerRef,
    items: [],
    onKeyDown: event => {
      if (
        menuVisible &&
        cascaderPanelRef.current &&
        (event.key === 'ArrowUp' || event.key === 'ArrowDown')
      ) {
        (cascaderPanelRef.current.nextSibling as HTMLElement).focus();
      }
    }
  });

  useEffect(() => {
    const onResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setInputWidth(Math.max(width, 350));
      }
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Fragment>
      <div className={css.editBar} ref={containerRef}>
        <hr />
        <Button
          ref={buttonRef}
          className={css.menuBtn}
          title="Insert"
          onClick={() => setMenuVisible(!menuVisible)}
        >
          <PlusIcon />
        </Button>
        <Menu
          visible={menuVisible}
          placement="left"
          onClose={() => setMenuVisible(false)}
          reference={buttonRef}
          className={css.menu}
        >
          <List>
            <ListItem disablePadding className={css.groupItem}>
              <List>
                <ListItem onClick={onAdd}>Add Value</ListItem>
                {allowTokens && (
                  <ListItem onClick={() => setTokenPickerVisible(true)}>
                    Add Token
                  </ListItem>
                )}
                <ListItem onClick={onImport}>Import Values</ListItem>
              </List>
            </ListItem>
          </List>
        </Menu>
      </div>
      <ConnectedOverlay
        placement="bottom"
        open={tokenPickerVisible}
        reference={containerRef}
        ref={overlayRef}
        closeOnEscape={true}
        content={() => (
          <TokenCascader
            tokens={tokens}
            keyword=""
            className={css.cascader}
            style={{
              minWidth: `${inputWidth}px`,
              width: inputWidth > 400 ? `${inputWidth}px` : `450px`,
              height: '250px'
            }}
            allowModifiers={false}
            allowFlowTrigger={true}
            allowExpressions={false}
            supplementalData={{
              stores,
              flows
            }}
            panelRef={cascaderPanelRef}
            onSelectToken={token => {
              onAddToken(token);
              setTokenPickerVisible(false);
            }}
          />
        )}
        onClose={() => setTokenPickerVisible(false)}
      />
    </Fragment>
  );
};
