import { axios } from 'core/Axios';
import {
  DataRunActionSyncUIBody,
  DataRunActionSyncUIResponse
} from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.RUN_ACTION_SYNC_UI;

export const req = async (
  data: DataRunActionSyncUIBody
): Promise<DataRunActionSyncUIResponse> => {
  return axios
    .post<DataRunActionSyncUIResponse>(Route.RUN_ACTION_SYNC_UI, data)
    .then(data => data.data);
};

export const useRunActionSyncUI = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataRunActionSyncUIResponse,
  DataRunActionSyncUIBody
> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
