import React, { FC, Fragment, useCallback } from 'react';
import { List, ListItem } from 'shared/layout/List';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { SelectOptionProps } from './SelectOption';
import Highlighter from 'react-highlight-words';
import { SelectValue } from './Select';
import { GroupOptions, GroupOption } from './utils';
import css from './SelectMenu.module.css';

export interface SelectMenuProps {
  id?: string;
  options: SelectOptionProps[];
  selectedOption?: SelectOptionProps | SelectOptionProps[];
  style?: React.CSSProperties;
  disabled?: boolean;
  groups?: GroupOptions;
  createable?: boolean;
  multiple?: boolean;
  index: number;
  inputSearchText: string;
  filterable?: boolean;
  loading?: boolean;
  className?: string;
  onSelectedChange: (option: SelectValue) => void;
}

export const SelectMenu: FC<Partial<SelectMenuProps>> = ({
  style,
  disabled,
  createable,
  selectedOption,
  options,
  loading,
  index,
  filterable,
  groups,
  multiple,
  inputSearchText,
  className,
  onSelectedChange
}) => {
  const trimmedText = inputSearchText.trim();

  const checkOptionSelected = useCallback(
    (option: SelectOptionProps) => {
      if (multiple) {
        if (Array.isArray(selectedOption)) {
          return selectedOption.find(o => o.value === option.value);
        }

        return false;
      }

      return (selectedOption as SelectOptionProps)?.value === option.value;
    },
    [selectedOption, multiple]
  );

  const renderListItems = (items: SelectOptionProps[], group?: GroupOption) =>
    items.map((o, i) => (
      <ListItem
        key={`${group?.name}-${o.value}`}
        disabled={disabled || o.disabled}
        className={classNames(css.option, {
          [css.selected]: checkOptionSelected(o),
          [css.active]: index === i + (group?.offset || 0)
        })}
        onClick={() => onSelectedChange(o)}
      >
        {o.menuLabel ? (
          o.menuLabel
        ) : (
          <Highlighter
            searchWords={[inputSearchText]}
            autoEscape={true}
            textToHighlight={o.children}
          />
        )}
      </ListItem>
    ));

  return (
    <motion.div
      style={style}
      className={classNames(css.menu, className)}
      initial={{
        opacity: 0,
        y: -20,
        pointerEvents: 'none'
      }}
      animate={{
        opacity: 1,
        y: 0,
        pointerEvents: 'auto',
        transition: {
          when: 'beforeChildren'
        }
      }}
      exit={{
        y: -20,
        opacity: 0,
        pointerEvents: 'none'
      }}
    >
      <List>
        {options?.length === 0 && createable && trimmedText && !loading && (
          <ListItem
            onClick={() =>
              onSelectedChange({
                value: trimmedText.toLowerCase(),
                children: trimmedText.toLowerCase()
              })
            }
          >
            Create option "{trimmedText.toLowerCase()}"
          </ListItem>
        )}
        {options?.length === 0 &&
          !createable &&
          filterable &&
          trimmedText &&
          !loading && <ListItem>No option(s) for "{trimmedText}"</ListItem>}
        {options?.length === 0 &&
          !createable &&
          filterable &&
          !trimmedText &&
          !loading && <ListItem>No option(s) available</ListItem>}
        {groups.hasGroups
          ? groups.groups.map(g => (
              <Fragment key={g.name}>
                {g.name === 'undefined' ? (
                  renderListItems(g.items, g)
                ) : (
                  <ListItem
                    subtitle={g.name}
                    disablePadding
                    className={css.groupItem}
                  >
                    <List>{renderListItems(g.items, g)}</List>
                  </ListItem>
                )}
              </Fragment>
            ))
          : renderListItems(options)}
      </List>
    </motion.div>
  );
};
