import { axios } from 'core/Axios';
import {
  DataUpdateApiKeyBody,
  DataUpdateApiKeyResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_API_KEY;

export const req = async (
  data: DataUpdateApiKeyBody
): Promise<DataUpdateApiKeyResponse> => {
  return axios
    .patch<DataUpdateApiKeyResponse>(Route.UPDATE_API_KEY, data)
    .then(data => data.data);
};

export const useUpdateApiKey = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataUpdateApiKeyResponse,
  DataUpdateApiKeyBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_API_KEYS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
