import React, { FC, ReactNode, useMemo, useState } from 'react';
import defaultTo from 'lodash/defaultTo';
import { Dialog } from '../Dialog';
import { Button } from 'shared/elements/Button';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { ReactComponent as CheckmarkCircleIcon } from 'assets/svg/ok.svg';
import { ReactComponent as WarningIcon } from 'assets/svg/warning.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg';
import classNames from 'classnames';
import {
  fieldComponents,
  FormFieldProps
} from 'shared/form/JsonSchemaForm/fieldComponents';
import css from './ConfirmDialog.module.css';

type ConfirmTypes =
  | 'default'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'prompt';

type ConfirmDialogProps = Partial<FormFieldProps> & {
  title: string;
  text: any;
  disabled?: boolean;
  open: boolean;
  className?: any;
  showConfirm?: boolean;
  showCancel?: boolean;
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  type?: ConfirmTypes;
  required?: boolean;
  placeholder?: string;
  initialValue?: string;
  icon?: ReactNode;
  // inputType can be any of the options in fieldComponents, but will need to pass all necessary props (like field)
  inputType?: string;
  onConfirm?: (value?: string) => void;
  onCancel?: () => void;
};

const getValues = (input: Partial<ConfirmDialogProps>) => {
  const { type } = input;

  if (type === 'info') {
    return {
      showConfirm: defaultTo(input.showConfirm, true),
      showCancel: defaultTo(input.showCancel, false),
      confirmButtonText: defaultTo(input.confirmButtonText, 'Ok'),
      icon: defaultTo(input.icon, <InfoIcon />),
      className: defaultTo(input.className, css.info)
    };
  } else if (type === 'success') {
    return {
      showConfirm: defaultTo(input.showConfirm, true),
      showCancel: defaultTo(input.showCancel, false),
      confirmButtonText: defaultTo(input.confirmButtonText, 'Ok'),
      icon: defaultTo(input.icon, <CheckmarkCircleIcon />),
      className: defaultTo(input.className, css.success)
    };
  } else if (type === 'warning') {
    return {
      showConfirm: defaultTo(input.showConfirm, true),
      showCancel: defaultTo(input.showCancel, true),
      confirmButtonText: defaultTo(input.confirmButtonText, 'Yes'),
      cancelButtonText: defaultTo(input.cancelButtonText, 'No'),
      icon: defaultTo(input.icon, <WarningIcon />),
      className: defaultTo(input.className, css.warning)
    };
  } else if (type === 'error') {
    return {
      showConfirm: defaultTo(input.showConfirm, true),
      showCancel: defaultTo(input.showCancel, true),
      confirmButtonText: defaultTo(input.confirmButtonText, 'Yes'),
      cancelButtonText: defaultTo(input.cancelButtonText, 'No'),
      icon: defaultTo(input.icon, <ErrorIcon />),
      className: defaultTo(input.className, css.danger)
    };
  } else if (type === 'prompt') {
    return {
      inputType: 'string:text',
      showConfirm: defaultTo(input.showConfirm, true),
      showCancel: defaultTo(input.showCancel, true),
      confirmButtonText: defaultTo(input.confirmButtonText, 'Ok'),
      cancelButtonText: defaultTo(input.cancelButtonText, 'Cancel'),
      className: defaultTo(input.className, css.prompt)
    };
  }

  return {
    showConfirm: defaultTo(input.showConfirm, true),
    showCancel: defaultTo(input.showCancel, true),
    confirmButtonText: defaultTo(input.confirmButtonText, 'Yes'),
    cancelButtonText: defaultTo(input.cancelButtonText, 'No'),
    className: defaultTo(input.className, css.default)
  };
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  disabled,
  text,
  type = 'default',
  ...rest
}) => {
  const [inputVal, setInputVal] = useState<any | null>(null);
  const values = useMemo(() => getValues({ ...rest, type }), [rest, type]);
  const header = useMemo(
    () => (
      <h4 className={css.title}>
        {values.icon} {title}
      </h4>
    ),
    [values, title]
  );
  const Component = useMemo(
    () => (type === 'prompt' ? fieldComponents[values.inputType] : null),
    [type, values]
  );

  const confirmDisabled =
    disabled || (type === 'prompt' && rest.required && !inputVal);

  return (
    <Dialog
      size="500px"
      header={header}
      open={open}
      onClose={onCancel}
      className={classNames(css.confirm, values.className)}
    >
      {text}
      {Component && (
        <Component
          autoFocus
          setFieldError={() => null}
          setValues={() => null}
          setFieldTouched={() => null}
          onPortUpdate={() => null}
          {...rest}
          disabled={disabled}
          setFieldValue={(_field, value) => setInputVal(value)}
        />
      )}
      <footer className={css.footer}>
        {values.showConfirm && (
          <Button
            color="primary"
            variant="filled"
            size="medium"
            disabled={confirmDisabled}
            onClick={() => onConfirm(inputVal)}
          >
            {values.confirmButtonText}
          </Button>
        )}
        {values.showCancel && (
          <Button
            color="secondary"
            variant="filled"
            disabled={disabled}
            size="medium"
            onClick={onCancel}
          >
            {values.cancelButtonText}
          </Button>
        )}
      </footer>
    </Dialog>
  );
};
