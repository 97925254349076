export enum CorePackages {
  ManualApprovals = 'CORE_MANUAL_APPROVALS',
  Datastore = 'CORE_DATASTORE',
  Data = 'CORE_DATA',
  Date = 'CORE_DATE',
  Email = 'CORE_EMAIL',
  Flow = 'CORE_FLOW',
  Logic = 'CORE_LOGIC',
  Sms = 'CORE_SMS',
  Webhook = 'CORE_WEBHOOK'
}
