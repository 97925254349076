import { axios } from 'core/Axios';
import { DataRunActionBody, DataRunActionResponse } from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.RUN_ACTION;

export const req = async (
  data: DataRunActionBody
): Promise<DataRunActionResponse> => {
  return axios
    .post<DataRunActionResponse>(Route.RUN_ACTION, data)
    .then(data => data.data);
};

export const useRunAction = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataRunActionResponse, DataRunActionBody> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
