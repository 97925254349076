import React, { useCallback, FC } from 'react';
import { useNotification } from 'shared/layers/Notification';
import * as Sentry from '@sentry/browser';
import { Button } from 'shared/elements/Button';
import { useAuth } from 'core/Auth';
import { ErrorComponent } from './ErrorComponent';
import { datadogRum } from '@datadog/browser-rum';

// TODO: Figure out better way to import this w/o doing this...
const ReactErrorBoundary = require('react-error-boundary').ErrorBoundary;

export const ErrorBoundary: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { addError } = useNotification();
  const { user } = useAuth();

  const onError = useCallback(
    (error: Error) => {
      if (process.env.NODE_ENV !== 'development') {
        Sentry.withScope(scope => {
          scope.setTag('type', 'react');
          Sentry.captureException(error);
        });

        datadogRum.addError(error, {
          type: 'react'
        });

        addError('An unexpected error occurred', {
          body: (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                Sentry.showReportDialog({
                  eventId: Sentry.lastEventId(),
                  user: {
                    name: user.name,
                    email: user.email
                  }
                });
              }}
            >
              Report Error
            </Button>
          )
        });
      }
    },
    [addError, user]
  );

  return (
    <ReactErrorBoundary FallbackComponent={ErrorComponent} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};
