import React, { FC, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { List } from 'shared/layout/List';
import { CascaderPanelItem } from './CascaderPanelItem';
import classNames from 'classnames';
import { CascaderGroup, CascaderItem } from '../types';
import css from './CascaderPanel.module.css';

const emptyItem = {
  text: 'No Results Found',
  value: '',
  path: '',
  selectable: false
};

type CascaderPanelProps = {
  group: CascaderGroup;
  disabled?: boolean;
  selections?: CascaderItem[];
  hoverDetail?: CascaderItem;
  collapsed?: boolean;
  keyword?: string;
  onItemHover: (item: CascaderItem | null) => void;
  onItemSelect: (item: CascaderItem) => void;
};

export const CascaderPanel: FC<CascaderPanelProps> = ({
  group,
  disabled,
  selections,
  hoverDetail,
  collapsed,
  keyword,
  onItemHover,
  onItemSelect
}) => {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const [hoverItem, setHoverItem] = useState<CascaderItem | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);

  useDebounce(
    () => {
      onItemHover(hoverItem);
      setIsCollapsed(!hoverItem && collapsed);
    },
    200,
    [hoverItem, collapsed]
  );

  const ids = selections?.map(s => s.path) || [];

  // If using keyboard navigation, hoverDetail may be selected
  const fullyCollapsed =
    isCollapsed &&
    !(hoverDetail && group.items.some(i => i.path === hoverDetail.path));

  return (
    <List
      className={classNames(css.panel, {
        [css.collapsed]: fullyCollapsed
      })}
      ref={panelRef}
      tabIndex={-1}
    >
      {group.items.map((item, i) => (
        <CascaderPanelItem
          key={item.path}
          item={item}
          disabled={disabled}
          keyword={keyword}
          active={item.path === hoverDetail?.path}
          collapsed={fullyCollapsed}
          selected={ids.includes(item.path)}
          onHover={setHoverItem}
          onClick={onItemSelect}
        />
      ))}
      {group.items.length === 0 && (
        <CascaderPanelItem
          key={emptyItem.path}
          item={emptyItem}
          disabled={true}
          collapsed={isCollapsed}
        />
      )}
    </List>
  );
};
