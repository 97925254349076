import { axios } from 'core/Axios';
import {
  DataDelApiKeyParams,
  DataDelApiKeyResponse,
  DataListApiKeysResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_API_KEY;

export const req = async (
  params: DataDelApiKeyParams
): Promise<DataDelApiKeyResponse> => {
  return axios
    .delete<DataDelApiKeyResponse>(Route.DEL_API_KEY, { params })
    .then(data => data.data);
};

export const useDelApiKey = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataDelApiKeyResponse, DataDelApiKeyParams> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_API_KEYS);
      const previousData = client.getQueriesData(Route.LIST_API_KEYS);
      client.setQueriesData(
        Route.LIST_API_KEYS,
        (oldData: DataListApiKeysResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_API_KEYS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
