import { axios } from 'core/Axios';
import {
  DataGetFlowSchemaParams,
  DataGetFlowSchemaResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_FLOW_SCHEMA;
export const buildQueryKey = (params?: DataGetFlowSchemaParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetFlowSchemaParams
): Promise<DataGetFlowSchemaResponse> => {
  return axios
    .get<DataGetFlowSchemaResponse>(Route.GET_FLOW_SCHEMA, { params })
    .then(data => data.data);
};

export const useGetFlowSchema = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetFlowSchemaResponse,
  DataGetFlowSchemaParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
