import React, { FC, useEffect } from 'react';
import css from './ErrorComponent.module.css';

export const ErrorComponent: FC<{ error: any }> = ({ error }) => {
  const isRefresh = error?.toString()?.indexOf('Chunk') > -1;

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isRefresh]);

  return (
    <div className={css.container}>
      {isRefresh ? (
        <div>
          <h2>...Updating Workflow...</h2>
        </div>
      ) : (
        <div>
          <h2>An unexpected error occurred</h2>
        </div>
      )}
    </div>
  );
};
