import { parseTemplate } from '@getcrft/jsonata-ext';
import { AsyncSelectContext } from '../AsyncSelectInput';
import { InputViews } from './enums/inputViews';
import { mustacheTypes, nativeTypes, tokenTypes } from './inputTypes';

export const getUIAttribute = (attributes, name: string, defaultValue) =>
  attributes?.[name] !== undefined ? attributes[name] : defaultValue;

export const getAsyncContext = attributes =>
  ({
    actionId: getUIAttribute(attributes, 'ui:remote-data', ''),
    valueKey: getUIAttribute(attributes, 'ui:remote-data-value', ''),
    labelKey: getUIAttribute(attributes, 'ui:remote-data-label', '')
  } as AsyncSelectContext);

export const getIsRequired = (schema, prop: string) =>
  schema.required?.find(k => k === prop) !== undefined;

export const hasUnresolvedDependenciesContext = (deps, fieldName: string) => {
  if (!hasDependencies(deps, fieldName)) {
    return false;
  }

  return Object.values(deps[fieldName]).some(value => !value);
};

export const hasDependencies = (deps, fieldName: string) => {
  return Object.values(deps[fieldName]).length > 0;
};

const getInitialValueOfType = (type: string) => {
  switch (type) {
    case 'string':
    case 'string:text':
    case 'cron':
    case 'code':
    case 'code:text':
    case 'code:json':
    case 'array':
    case 'integer':
    case 'ipv4':
    case 'datastore':
    case 'ipv6':
    case 'number':
    case 'mustache':
    case 'password':
    case 'uri':
    case 'file':
    case 'json':
      return '';
    case 'key-value':
    case 'files':
      return [];
    case 'object':
      return {};
    case 'boolean':
      return false;
    case 'condition':
    case 'branch':
    default:
      return null;
  }
};

export const getInitialValues = ({ schema, context, value }) => {
  let values = {};

  if (schema?.properties) {
    Object.keys(schema.properties).forEach(property => {
      let newValue;

      const inputMeta = schema.properties[property];
      const componentType = getUIAttribute(
        inputMeta.attributes,
        'ui:component',
        inputMeta.type
      );

      if (inputMeta.default !== undefined && componentType === 'template') {
        newValue = parseTemplate(inputMeta.default, context);
      } else if (value && typeof value[property] !== 'undefined') {
        newValue = value[property];
      } else {
        if (inputMeta.default !== undefined) {
          newValue = inputMeta.default;
        } else {
          newValue = getInitialValueOfType(componentType);
        }
      }

      values[property] = newValue;
    });
  }

  return values;
};

export const initDependenciesContext = obj => {
  const initDependenciesContext = {};

  if (obj?.properties) {
    const properties = obj.properties;
    const formFields = (properties && Object.keys(properties)) || [];

    formFields.forEach((fieldName: string) => {
      const requiredFields = getUIAttribute(
        properties[fieldName].attributes,
        'ui:requires',
        []
      );

      initDependenciesContext[fieldName] = {};

      requiredFields.forEach(key => {
        initDependenciesContext[fieldName][key] = null;
      });
    });
  }

  return initDependenciesContext;
};

export const getTypeByView = (view: InputViews) => {
  const typesByView = {
    default: null,
    tokens: 'string',
    expression: 'code:jsonata'
  };

  return typesByView[view];
};

export const getComponentType = (
  field: any,
  value: any,
  preferNative?: boolean
): string => {
  let componentType = getUIAttribute(
    field.attributes,
    'ui:component',
    field.type
  );

  if (['files', 'file'].includes(componentType)) {
    // If there is a specified file input, we want to use the correct view.
    // If reference to a specific node's value, use token
    // If array, use the default file uploader
    if (
      value &&
      ((Array.isArray(value) && value.length) ||
        (typeof value === 'string' && !value.includes('.')))
    ) {
      return componentType;
    } else if (typeof value === 'string' && value.includes('.')) {
      return 'string';
    }
  }

  if (
    preferNative != null &&
    !preferNative &&
    nativeTypes.includes(componentType)
  ) {
    componentType = 'string';
  }

  return componentType;
};

export const getViewByType = (
  componentType: string,
  field: any
): InputViews => {
  const view = InputViews.Tokens;

  if (tokenTypes.includes(componentType)) {
    return InputViews.Tokens;
  }

  if (mustacheTypes.includes(componentType)) {
    return InputViews.Expression;
  }

  if (
    nativeTypes.includes(componentType) ||
    field?.attributes?.['ui:component']
  ) {
    return InputViews.Default;
  }

  return view;
};
