import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Helpers, Helper } from './helpers';
import { SearchBar } from 'shared/form/SearchBar';
import { TokenEntityOption } from 'shared/form/TokenEditor/types';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { useFuzzy } from '@reaviz/react-use-fuzzy';
import Highlighter from 'react-highlight-words';
import { MotionGroup, MotionItem } from 'shared/utils/Motion';
import css from './ModifierGroup.module.css';
import { valueTypeToBase } from 'core/types';
import { Tooltip } from 'shared/layers/Tooltip';
import { Button } from 'shared/elements/Button';
import sortBy from 'lodash/sortBy';

export type ModifierGroupProps = {
  helperGroups: Helpers[];
  token?: TokenEntityOption;
  onChange?: (helper: Helper) => void;
};

export const ModifierGroup: FC<ModifierGroupProps> = ({
  helperGroups,
  token,
  onChange = () => undefined
}) => {
  const filteredHelperGroups = useMemo(() => {
    const helpers = [];
    const tokenToCheck =
      token?.additionalModifiers && token?.additionalModifiers?.length
        ? token.additionalModifiers[token.additionalModifiers.length - 1]
        : token;
    // Get the return type of the last modifier to get options
    const modifierReturnType =
      tokenToCheck?.returnType ||
      valueTypeToBase(
        tokenToCheck?.returnSubType ||
          tokenToCheck?.subType ||
          tokenToCheck?.valueType ||
          tokenToCheck?.value
      );
    const modifierSubType =
      tokenToCheck?.returnSubType || tokenToCheck?.subType;

    const remainingGroup = {
      label: 'Other',
      options: [],
      description:
        "These are the remaining modifiers that don't match the type of the previous modifier/token value. Sometimes those types are incorrect, so we want to provide these as an option if needed. Please note, using one of these modifiers may make the modifier testing incorrect."
    };
    for (const group of helperGroups) {
      if (group.options) {
        let helperGroup = { label: group.label, options: [] };

        for (const helper of group.options) {
          // TODO: Need to fix the types here...
          if (helper.invariant && !tokenToCheck?.value) {
            helperGroup.options.push(helper);
          } else if (!helper.invariant && tokenToCheck?.value) {
            const modifierValueCheck = modifierReturnType === helper.valueType;
            const anyValueCheck =
              modifierReturnType === 'any' ||
              helper.valueType === 'any' ||
              (tokenToCheck.valueType || tokenToCheck.value) === 'any';
            const stringDateCheck =
              (modifierReturnType === 'string' &&
                helper.valueType === 'datetime') ||
              (modifierReturnType === 'datetime' &&
                helper.valueType === 'string');
            const isArrayCheck =
              modifierSubType && helper.valueType === 'array';
            const isSubTypeCheck =
              modifierSubType && modifierSubType === helper.valueType;

            if (
              (modifierValueCheck ||
                anyValueCheck ||
                stringDateCheck ||
                isArrayCheck ||
                isSubTypeCheck) &&
              helper.valueType
            ) {
              helperGroup.options.push(helper);
            } else {
              remainingGroup.options.push(helper);
            }
          }
        }

        if (helperGroup.options.length) {
          helpers.push(helperGroup);
        }
      }
    }

    if (remainingGroup.options.length) {
      const sortedOptions = sortBy(remainingGroup.options, 'label');
      helpers.push({ ...remainingGroup, options: sortedOptions });
    }

    return helpers;
  }, [helperGroups, token]);

  const [selected, setSelected] = useState<any>(filteredHelperGroups[0]);
  const [filteredHelpers, setFilteredHelpers] =
    useState<any[]>(filteredHelperGroups);
  const { result, search, resetSearch, keyword } = useFuzzy<any>(
    filteredHelperGroups,
    {
      keys: ['options.label', 'options.description'],
      location: 0,
      isCaseSensitive: false,
      includeMatches: true
    }
  );

  const onFilter = useCallback(
    (value: string) => {
      let filtered = [];

      if (value) {
        search(value);

        for (const r of result) {
          let filteredOptions = [];
          let optionIndexes = [];
          if (r.matches) {
            r.matches.forEach(m => {
              if (!optionIndexes.includes(m.refIndex)) {
                filteredOptions.push(r.options[m.refIndex]);
                optionIndexes.push(m.refIndex);
              }
            });

            filtered.push({ label: r.label, options: filteredOptions });
          } else {
            filtered = result;
          }
        }
      } else {
        resetSearch();
        filtered = filteredHelperGroups;
      }

      const filteredSelected = filtered.find(h => h?.label === selected?.label);
      if (filteredSelected) {
        setSelected(filteredSelected);
      } else {
        setSelected(filtered[0]);
      }

      setFilteredHelpers(filtered);
    },
    [filteredHelperGroups, search, result, resetSearch, selected]
  );

  const renderGroups = useCallback(
    group => {
      const hasOptions = filteredHelpers.find(h => h.label === group.label);

      return (
        <div
          key={`group-${group.label}`}
          className={classNames(css.group, {
            [css.selected]: selected?.label === group.label,
            [css.disabled]: !hasOptions
          })}
          onClick={() => {
            if (hasOptions) {
              const selected = filteredHelpers.find(
                f => f.label === group.label
              );
              setSelected(selected);
            }
          }}
        >
          {group.label}
        </div>
      );
    },
    [filteredHelpers, selected]
  );

  const renderSelected = useCallback(
    option => {
      const { name, label, description, valueType, returnType, reference } =
        option;
      return (
        <MotionItem
          layout
          key={`helper-${name}`}
          className={css.modifier}
          onClick={() => onChange(option)}
        >
          <label>
            <Highlighter
              highlightClassName={css.labelHighlight}
              searchWords={keyword.split(' ')}
              autoEscape={true}
              textToHighlight={label}
            />
            <span className={css.definition}>
              {`( ${valueType ? valueType : ''} ) => ${returnType}`}
            </span>
            {reference && (
              <Tooltip content="View modifier reference material">
                <Button
                  variant="text"
                  size="small"
                  disablePadding
                  onClick={event => {
                    event.stopPropagation();
                    window.open(reference, '_blank', 'noopener noreferrer');
                  }}
                >
                  <InfoIcon className={css.infoIcon} />
                </Button>
              </Tooltip>
            )}
          </label>
          <div>
            <Highlighter
              searchWords={keyword.split(' ')}
              autoEscape={true}
              textToHighlight={description}
            />
          </div>
        </MotionItem>
      );
    },
    [onChange, keyword]
  );

  useEffect(() => {
    setFilteredHelpers(filteredHelperGroups);
    setSelected(filteredHelperGroups[0]);
  }, [filteredHelperGroups]);

  return (
    <div className={css.groupContainer}>
      <div className={css.search}>
        <SearchBar
          value={keyword}
          autoFocus={true}
          placeholder="Search all modifiers..."
          onChange={onFilter}
          onClear={() => onFilter('')}
          size="small"
        />
      </div>
      <div className={css.groups}>{filteredHelperGroups.map(renderGroups)}</div>
      {selected && (
        <MotionGroup className={css.modifiers}>
          {selected.description && (
            <div className={css.description}>{selected.description}</div>
          )}
          {selected.options.map(renderSelected)}
        </MotionGroup>
      )}
    </div>
  );
};
