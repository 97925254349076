import { Helper } from '.';

export const logicHelpers: Helper[] = [
  {
    name: '$condition',
    label: 'Conditional',
    description: 'Displays a certain value off a boolean',
    invariant: true,
    examples: ['$condition(2 > 1, "true", "false") => "true"'],
    returnType: 'any',
    params: {
      type: 'object',
      properties: {
        condition: {
          type: 'string',
          condition: 'Picture',
          description: 'The condition to determine result',
          examples: [`'2 > 1'`],
          attributes: {
            'ui:order': 0
          }
        },
        then: {
          type: 'string',
          condition: 'Then',
          description: 'The value to return on true',
          attributes: {
            'ui:order': 1
          }
        },
        else: {
          type: 'string',
          condition: 'Else',
          description: 'The value to return on false',
          attributes: {
            'ui:order': 2
          }
        }
      }
    }
  }
];
