import React, { Fragment, useState, FC, useMemo, useCallback } from 'react';
import { JsonSchemaForm } from 'shared/form/JsonSchemaForm';
import isEmpty from 'lodash/isEmpty';
import { Helper } from './helpers';
import { Modifier } from './types';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import { TokenEntityOption, TokenOption } from 'shared/form/TokenEditor/types';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Pluralize } from 'shared/utils/Pluralize';
import css from './ModifierEditor.module.css';
import { Tooltip } from 'shared/layers/Tooltip';
import { Button } from 'shared/elements/Button';
import { findMeta, parseData } from './utils';
import { Toggle } from 'shared/form/Toggle';
import { Block } from 'shared/layout/Block';
import { valueTypeToBase } from 'core/types';
import { getUpdatedModifier } from 'core/utils/modifiers';
import { ModifierDescription } from './ModifierDescription';

export type ModifierEditorProps = {
  helper?: Helper;
  token?: TokenEntityOption;
  tokens: TokenOption[];
  isNew?: boolean;
  onSave: (helper: Modifier) => void;
  onDeleteHelper?: () => void;
};

export const ModifierEditor: FC<ModifierEditorProps> = ({
  token,
  tokens,
  helper,
  isNew = false,
  onDeleteHelper = () => undefined,
  onSave = () => undefined
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [internalHelper] = useState<Helper>(helper);
  const internalToken = useMemo(
    () => getUpdatedModifier(token, tokens),
    [token, tokens]
  );
  const [applyMapToggle, setApplyMapToggle] = useState<boolean | null>(null);
  const showApplyMapToggle = useMemo(
    () => internalToken?.showApplyMapToggle,
    [internalToken?.showApplyMapToggle]
  );
  const helperMeta: Helper = useMemo(() => {
    return findMeta(internalHelper, internalToken);
  }, [internalHelper, internalToken]);
  const applyMap = useMemo(() => {
    if (!isNew) {
      return internalToken.applyMap;
    }
    // Identify if a token can/should be applied to every element in it
    const toCheck = internalToken?.additionalModifiers
      ? internalToken.additionalModifiers[
          internalToken.additionalModifiers.length - 1
        ].returnType
      : internalToken?.returnType ||
        internalToken?.valueType ||
        internalToken?.value;
    const tokenType = valueTypeToBase(toCheck);
    return (
      tokenType === 'array' && !['array', 'any'].includes(helperMeta?.valueType)
    );
  }, [helperMeta?.valueType, internalToken, isNew]);

  const [internalData, setInternalData] = useState<Modifier>(
    isNew ? {} : parseData(helperMeta, internalToken?.params)
  );

  const internalModifier = useMemo(() => {
    if (helperMeta) {
      const data = parseData(helperMeta, isNew ? [] : internalToken?.params);
      // Identify if a token can/should be applied to every element in it
      const toCheckToken = internalToken?.additionalModifiers
        ? internalToken.additionalModifiers[
            internalToken.additionalModifiers.length - 1
          ]
        : internalToken;
      const tokenType = valueTypeToBase(
        toCheckToken?.returnType ||
          toCheckToken?.valueType ||
          toCheckToken?.value
      );
      const showApplyMapToggle =
        tokenType === 'array' &&
        ((toCheckToken?.returnSubType &&
          toCheckToken?.returnSubType === 'array') ||
          (!toCheckToken?.returnSubType &&
            toCheckToken?.subType &&
            toCheckToken?.subType === 'array') ||
          ['any'].includes(helperMeta?.valueType));

      const shouldApplyMap =
        applyMapToggle != null || showApplyMapToggle
          ? applyMapToggle != null
            ? applyMapToggle
            : true
          : applyMap;
      const newModifier: Modifier = {
        modifier: helperMeta?.name,
        returnType: helperMeta.returnType,
        returnSubType: helperMeta.returnSubType,
        applyMap: shouldApplyMap,
        showApplyMapToggle
      };

      if (internalToken?.value) {
        newModifier.value = internalToken.value;
      }

      if (internalToken?.valueType) {
        newModifier.valueType = internalToken.valueType;
      }

      if (internalToken?.subType) {
        newModifier.subType = internalToken.subType;
      }

      if (shouldApplyMap) {
        // We are applying the modifier to all elements of the array, so need to update things
        newModifier.returnType = 'array';
        newModifier.valueType = 'array';
        newModifier.returnSubType = helperMeta.returnType;
      }

      if (!isEmpty(internalData)) {
        newModifier.params = Object.values(internalData);
      } else if (!isEmpty(data)) {
        newModifier.params = Object.values(data);
      }

      return newModifier;
    }

    return internalToken;
  }, [
    applyMap,
    applyMapToggle,
    helperMeta,
    internalData,
    isNew,
    internalToken
  ]);

  useEffectOnce(() => {
    saveChanges();
  });

  useUpdateEffect(() => {
    if (dirty) {
      saveChanges();
    }
  }, [dirty, internalData]);

  const saveChanges = useCallback(() => {
    if (helperMeta) {
      onSave(internalModifier);
      setDirty(false);
    }
  }, [helperMeta, internalModifier, onSave]);

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <div className={css.modifierConfig}>
          {helperMeta ? (
            <Fragment>
              <div className={css.modifierLabel}>
                {helperMeta.label}
                <span className={css.definition}>
                  {`( ${
                    helperMeta.valueType ? helperMeta.valueType : ''
                  } ) => ${helperMeta.returnType}`}
                </span>
                {helperMeta.reference && (
                  <Tooltip content="View modifier reference material">
                    <Button
                      variant="text"
                      size="small"
                      disablePadding
                      onClick={() => {
                        window.open(
                          helperMeta.reference,
                          '_blank',
                          'noopener noreferrer'
                        );
                      }}
                    >
                      <InfoIcon className={css.infoIcon} />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <p className={css.description}>{helperMeta.description}</p>
              {helperMeta.examples?.length > 0 && (
                <div className={css.description}>
                  <Pluralize
                    showCount={false}
                    singular="Example"
                    count={helperMeta.examples.length}
                  />
                  :
                  <Fragment>
                    {helperMeta.examples.length > 2 ? (
                      <ul>
                        {helperMeta.examples.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    ) : (
                      <i> {helperMeta.examples.join(', ')}</i>
                    )}
                  </Fragment>
                </div>
              )}
              {helperMeta.params && (
                <JsonSchemaForm
                  allowOverride={false}
                  allowFlowTrigger={false}
                  allowExpressions={false}
                  allowModifiers={false}
                  shouldTrim={false}
                  value={internalData}
                  schema={helperMeta.params}
                  options={helperMeta.options || []}
                  tokenOptions={tokens}
                  onChange={data => {
                    setInternalData(data);
                    setDirty(true);
                  }}
                />
              )}
              {showApplyMapToggle && (
                <Block
                  label="Apply Modifier to All Items"
                  tooltip="If toggled on, this modifier will be applied to every element in the array and will return an array of modified values"
                >
                  <Toggle
                    checked={applyMapToggle != null ? applyMapToggle : true}
                    onChange={val => {
                      setApplyMapToggle(val);
                      setDirty(true);
                    }}
                  />
                </Block>
              )}
            </Fragment>
          ) : (
            <p>Editing this Modifier is not supported</p>
          )}
        </div>
        <div className={css.modifierActions}>
          <TrashIcon
            className={css.actionIcons}
            onClick={onDeleteHelper}
            title="Remove modifier"
          />
        </div>
      </div>
      <ModifierDescription modifier={internalModifier} isModifier />
    </div>
  );
};
