import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Popover, Tooltip } from 'shared/layers/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import copy from 'copy-to-clipboard';
import { useNotification } from 'shared/layers/Notification';
import { Collapse } from 'shared/layout/Collapse';
import css from './Block.module.css';

type BlockProps = {
  expandable?: boolean;
  label?: any;
  tooltip?: any;
  tooltipComponent?: 'tooltip' | 'popover';
  disableMargin?: boolean;
  required?: boolean;
  clipboard?: any;
  children?: any;
  className?: any;
  onTitleClick?: (event) => void;
};

export const Block: FC<BlockProps> = ({
  expandable = false,
  label,
  children,
  disableMargin,
  className,
  tooltip,
  tooltipComponent = 'tooltip',
  required,
  clipboard,
  onTitleClick
}) => {
  const { addSuccess } = useNotification();
  const [expand, setExpand] = useState<boolean>(true);

  const renderInfoIcon = useCallback(
    () => (
      <InfoIcon
        className={css.infoIcon}
        onDoubleClick={() => {
          if (clipboard) {
            copy(clipboard);
            addSuccess(`Copied "${clipboard}" to your clipboard`);
          }
        }}
      />
    ),
    [addSuccess, clipboard]
  );

  if (tooltip) {
    return (
      <section
        className={classNames(css.container, className, {
          [css.disableMargin]: disableMargin
        })}
      >
        <label
          className={classNames(css.label, { [css.expandable]: expandable })}
          onClick={e => {
            setExpand(expand => !expand);
            onTitleClick?.(e);
          }}
        >
          {label}
          {`${required ? ' *' : ''}`}
          {tooltipComponent === 'popover' ? (
            <Popover content={tooltip}>{renderInfoIcon()}</Popover>
          ) : (
            <Tooltip content={tooltip}>{renderInfoIcon()}</Tooltip>
          )}
        </label>
        <Collapse expanded={!expandable || expand}>{children}</Collapse>
      </section>
    );
  } else {
    return (
      <section className={classNames(css.container, className)}>
        {label && (
          <label
            className={css.label}
            onClick={e => {
              setExpand(expand => !expand);
              onTitleClick?.(e);
            }}
          >
            {label}
            {`${required ? ' *' : ''}`}
          </label>
        )}
        <Collapse expanded={!expandable || expand}>{children}</Collapse>
      </section>
    );
  }
};
