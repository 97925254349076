import { axios } from 'core/Axios';
import {
  DataListPackageConnectionsParams,
  DataListPackageConnectionsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_PACKAGE_CONNECTIONS;
export const buildQueryKey = (params?: DataListPackageConnectionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListPackageConnectionsParams
): Promise<DataListPackageConnectionsResponse> => {
  return axios
    .get<DataListPackageConnectionsResponse>(Route.LIST_PACKAGE_CONNECTIONS, {
      params
    })
    .then(data => data.data);
};

export const useListPackageConnections = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListPackageConnectionsResponse,
  DataListPackageConnectionsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
