import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button } from 'shared/elements/Button';
import { getWeeks } from './utils';
import classNames from 'classnames';
import { Moment } from 'shared/data/Moment';
import moment from 'moment';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { useHotkeys } from 'reakeys';
import css from './Calendar.module.css';

type CalendarProps = {
  value?: Date;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  onChange?: (value: Date) => void;
};

export const Calendar: FC<CalendarProps> = ({
  value,
  min,
  max,
  disabled,
  onChange
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewValue, setViewValue] = useState<Date>(value || new Date());
  const weeks = useMemo(() => getWeeks(viewValue), [viewValue]);

  useEffect(() => {
    // If the value changes, update the view value
    if (moment(value).isValid()) {
      setViewValue(value);
    }
  }, [value]);

  const setValue = useCallback(
    (nextValue: Date) => {
      if (!disabled) {
        setViewValue(nextValue);
        onChange?.(nextValue);
      }
    },
    [onChange, disabled]
  );

  const setViewMonth = useCallback(
    (increment: number) => {
      setViewValue(moment(viewValue).add(increment, 'month').toDate());
    },
    [viewValue]
  );

  useHotkeys([
    {
      name: 'Previous Month',
      description: 'Paginate to the previous month',
      keys: 'shift+left',
      category: 'General',
      ref: containerRef,
      callback: event => {
        event.preventDefault();
        setViewMonth(-1);
      }
    },
    {
      name: 'Next Month',
      description: 'Paginate to the next month',
      keys: 'shift+right',
      category: 'General',
      ref: containerRef,
      callback: event => {
        event.preventDefault();
        setViewMonth(1);
      }
    },
    {
      name: 'Today',
      description: 'Set calendar to Today',
      keys: 'shift+t',
      category: 'General',
      ref: containerRef,
      callback: event => {
        event.preventDefault();
        onChange(new Date());
      }
    }
  ]);

  return (
    <div
      className={classNames(css.container, { [css.disabled]: disabled })}
      ref={containerRef}
      tabIndex={-1}
    >
      <div className={css.header}>
        <Button
          variant="text"
          disablePadding
          className={css.leftArrow}
          title="Previous Month ( ⇪ + ← )"
          onClick={() => setViewMonth(-1)}
        >
          <ArrowLeft />
        </Button>
        <h4>
          <Moment date={viewValue} format="MMMM YYYY" allowToggle={false} />
        </h4>
        <Button
          variant="text"
          disablePadding
          title="Next Month ( ⇪ + → )"
          onClick={() => setViewMonth(1)}
        >
          <ArrowRight />
        </Button>
      </div>
      {weeks.map((week, i) => (
        <div key={`week-${i}`} className={css.week}>
          {week.map((day, ii) => (
            <Button
              key={`day-${ii}`}
              className={classNames(css.day, {
                [css.outside]: day.isNextMonth || day.isPreviousMonth,
                [css.selected]:
                  value && moment(value).startOf('day').isSame(day.date),
                [css.today]: day.isToday
              })}
              tabIndex={-1}
              variant="text"
              disableMargins={true}
              disabled={
                disabled ||
                (min && moment(day.date).isSameOrBefore(min)) ||
                (max && moment(day.date).isSameOrAfter(max))
              }
              title={day.formattedDate}
              onClick={() => setValue(day.date)}
            >
              {day.dayOfMonth}
            </Button>
          ))}
        </div>
      ))}
    </div>
  );
};
