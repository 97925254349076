import React, { FC } from 'react';
import { Popover as LayersPopover, PopoverProps } from 'realayers';
import css from './Popover.module.css';

export const Popover: FC<PopoverProps> = props => (
  <LayersPopover
    {...props}
    disablePadding={true}
    popoverClassName={css.popover}
  />
);
