import React from 'react';
import { Token } from '../Token';
import { TokenOption, TokenEntityOption } from '../types';
import { tokenStrategy } from './tokenStrategy';
import { removeToken, selectToken, processEditorState } from './utils';
import { EditorState } from 'draft-js';

export type TokenClickProps = {
  entityKey: string;
  blockKey: string;
  block: any;
} & TokenEntityOption;

export interface TokenPluginConfig {
  allowAnyToken: boolean;
  disabled: boolean;
  tokens?: TokenOption[];
  // Pass in get/set editorstate manually because handleCleanState setting a new editorState messes up tokens
  getEditorState: () => EditorState;
  setEditorState: (editorState: EditorState) => void;
  onTokenClick?: (token: TokenClickProps) => void;
}

export const createTokenPlugin = ({
  tokens,
  allowAnyToken,
  disabled,
  getEditorState,
  setEditorState,
  onTokenClick
}: TokenPluginConfig) => ({
  decorators: [
    {
      strategy: tokenStrategy,
      component: ({
        entityKey,
        contentState,
        offsetKey,
        blockKey,
        children,
        ...rest
      }) => {
        const data: TokenEntityOption = contentState
          .getEntity(entityKey)
          .getData();
        const block = children[0].props.block;
        const token = tokens
          ? tokens.find(t => t.value === data.value?.trim())
          : undefined;

        return (
          <Token
            token={data}
            disabled={disabled}
            subtext={token ? token.subtext : undefined}
            allowAnyToken={allowAnyToken}
            offsetKey={offsetKey}
            allowRemoval={!disabled}
            onTokenClick={token => {
              if (!disabled) {
                if (onTokenClick) {
                  onTokenClick({
                    ...token,
                    entityKey,
                    block,
                    blockKey
                  });
                }

                selectToken({
                  getEditorState,
                  setEditorState,
                  ...rest,
                  block,
                  blockKey,
                  entityKey
                });
              }
            }}
            onRemove={() => {
              if (!disabled) {
                removeToken({
                  getEditorState,
                  setEditorState,
                  ...rest,
                  entityKey,
                  block,
                  blockKey
                });
              }
            }}
          />
        );
      }
    }
  ],
  onChange: (editorState: EditorState) =>
    processEditorState(editorState, tokens)
});
