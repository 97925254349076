import shortid from '@getcrft/shortid';
import { getActionForId, getImageForVendor } from './actionBuilder';
import { modifierDeserializer } from 'shared/form/Modifiers';
import isString from 'lodash/isString';
import { EdgeData, NodeData, PortSide } from 'reaflow';
import { ClientFlow } from 'core/types/Flow';
import {
  ChoiceRule,
  Flow,
  FlowSchema,
  Group,
  Step,
  Trigger,
  User
} from 'core/types/API';
import { DatastoreActions, LogicActions } from './enums';
import { parseChoiceVariables } from './parseChoiceVariables';
import { parseNestedQuoting } from 'core/utils/parser';
import { handleDisabledDependents } from './graph';

/**
 * Takes a Flow and assembles into a model that the graph
 * can read and mashes metadata about the Actions too.
 */
export function flowDeserializer(flow: Flow, packages: any[]): ClientFlow {
  if (!flow) {
    return {
      id: shortid(),
      name: 'New Workflow',
      description: '',
      instructions: '',
      trackRevisions: true,
      requireRevisionComments: false,
      dataScrubbing: true,
      disabled: true,
      failureAlert: false,
      version: 1,
      failureAlertEmails: [],
      nodes: [],
      edges: [],
      tags: [],
      // TODO: Remove triggers
      triggers: [],
      schema: {
        items: []
      },
      revisions: {
        items: []
      }
    };
  }

  const result: ClientFlow = {
    id: flow.id,
    name: flow.name,
    // TODO: Remove triggers
    triggers: [],
    version: flow.version,
    forkCount: flow.forkCount,
    requireRevisionComments: flow.requireRevisionComments,
    // Default revision tracking for new
    trackRevisions: flow.trackRevisions === null ? true : flow.trackRevisions,
    // Default scrubbing for new
    dataScrubbing: flow.dataScrubbing === null ? true : flow.dataScrubbing,
    template: flow.template,
    disabled: flow.disabled,
    owner: flow.owner as unknown as User,
    group: flow.group as Group,
    tags: flow.tags || [],
    lastRun: flow.lastRun,
    schema: parseSchema(flow.schema),
    description: flow.description || '',
    instructions: flow.instructions || '',
    usage: flow.usage,
    created: flow.created
      ? {
          id: flow.created.id,
          name: flow.created.name,
          picture: flow.created.picture,
          date: flow.created.date
        }
      : undefined,
    updated: flow.updated
      ? {
          id: flow.updated.id,
          name: flow.updated.name,
          picture: flow.updated.picture,
          date: flow.updated.date
        }
      : undefined,
    nodes: [],
    edges: [],
    failureAlert: flow.failureAlert,
    failureAlertEmails: flow.failureAlertEmails || []
  };

  if (flow.triggers) {
    const { nodes, edges } = buildTriggers(flow.triggers, packages);
    result.nodes.push(...nodes);
    result.edges.push(...edges);
  }

  if (flow.nodes) {
    const { nodes, edges } = buildFlowNodes(flow, flow.nodes, packages);
    const disabledNodes = nodes.filter(n => n.data?.disabled);
    let updatedNodes = [...nodes];

    for (const disabledNode of disabledNodes) {
      // Update nodes that are dependant on a disabled node
      const changedDisabledNodes = handleDisabledDependents(
        disabledNode,
        updatedNodes,
        edges
      );
      for (const changedDisabledNode of changedDisabledNodes) {
        updatedNodes = updatedNodes.map(n =>
          n.id === changedDisabledNode.id ? changedDisabledNode : n
        );
      }
    }

    result.nodes.push(...updatedNodes);
    result.edges.push(...edges);
  }

  return result;
}

function getDependsOn(input: string): string[] {
  const formattedInput =
    typeof input === 'string' ? input : JSON.stringify(input);
  return input
    ? modifierDeserializer(formattedInput).reduce(
        (a, { source, sourceValue }) =>
          source ? a.concat({ source, sourceValue }) : a,
        []
      )
    : [];
}

function buildTriggers(triggers: Trigger[], packages: any[]) {
  const nodes = [];
  const edges = [];

  for (const trigger of triggers) {
    const actionMeta = getActionForId(packages, trigger.actionId);
    const id = trigger.key || shortid();

    if (!actionMeta) {
      console.error(`Failed to find trigger metadata ${trigger}`);
      continue;
    }

    nodes.push({
      id,
      text: trigger.name,
      icon: {
        url: getImageForVendor({
          pkg: actionMeta.pkg,
          action: actionMeta.action
        }),
        height: 25,
        width: 25
      },
      ports: [
        {
          id: `${id}-from`,
          width: 10,
          height: 10,
          className: 'defaultPort',
          side: 'SOUTH'
        },
        {
          id: `${id}-to`,
          width: 10,
          height: 10,
          side: 'NORTH',
          hidden: true
        }
      ],
      data: {
        id,
        label: trigger.name,
        type: 'trigger',
        connectionId: trigger.connectionId,
        actionId: trigger.actionId,
        // TODO: Figure out a better way here...
        input: parseNestedQuoting(trigger.input, '{}'),
        // TODO: Implement depends on
        dependsOn: [],
        logo: getImageForVendor({
          pkg: actionMeta.pkg,
          action: actionMeta.action
        }),
        schema: {
          inputComponents: trigger.schema?.inputComponents,
          outputComponent: trigger.schema?.outputComponent || null
        },
        ...actionMeta
      }
    });

    if (trigger.next) {
      for (const next of trigger.next) {
        edges.push({
          id: shortid(),
          from: id,
          to: next,
          fromPort: `${id}-from`,
          toPort: `${next}-to`
        });
      }
    }
  }

  return {
    nodes,
    edges
  };
}

function buildFlowNodes(
  flow: Flow,
  rootNodes: Step[],
  packages: any[],
  parent?: string
) {
  const nodes: NodeData[] = [];
  const edges: EdgeData[] = [];

  for (const node of rootNodes) {
    if (node.map) {
      const actionMeta = getActionForId(packages, LogicActions.Loop);
      const itemsExpression = node.map.itemsExpression;
      const isAsync = node.map.isAsync;

      const mapNode: any = {
        id: node.key,
        parent,
        height: 150,
        width: 250,
        ports: [
          {
            id: `${node.key}-from`,
            width: 10,
            height: 10,
            className: 'defaultPort',
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'map',
          disabled: node.disabled,
          label: node.map.name,
          // TODO: Implement depends on
          dependsOn: [],
          input: {
            maxConcurrency: node.map.maxConcurrency,
            itemsExpression,
            isAsync
          },
          schema: {
            inputComponents: node.map.schema?.inputComponents,
            outputComponent: node.map.schema?.outputComponent || null
          },
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          parent,
          ...actionMeta
        }
      };

      if (node.map.nodes) {
        const result = buildFlowNodes(
          flow,
          node.map.nodes,
          packages,
          mapNode.id
        );

        edges.push(...result.edges);
        nodes.push(...result.nodes);
      }

      nodes.push(mapNode);

      if (node.map.next) {
        for (const next of node.map.next) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-from`,
            toPort: `${next}-to`
          });
        }
      }
    } else if (node.task) {
      let actionMeta: any = getActionForId(packages, node.task.actionId);

      if (!actionMeta) {
        // This is necessary for when actions have their ids updated within their integrations
        // Need to set empty action details to let user know of issues
        actionMeta = {
          action: {
            input: {}
          },
          pkg: {}
        };
      }

      nodes.push({
        id: node.key,
        text: node.task.name,
        parent,
        icon: {
          url: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          height: 25,
          width: 25
        },
        ports: [
          {
            id: `${node.key}-from`,
            width: 10,
            height: 10,
            className: 'defaultPort',
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'action',
          label: node.task.name,
          disabled: node.disabled,
          retry: node.task.retry,
          // TODO: Figure out a better way here...
          input: transformInput(node),
          dependsOn: getDependsOn(node.task?.input),
          timeout: node.task.timeout,
          schema: {
            inputComponents: node.task.schema?.inputComponents,
            outputComponent: node.task.schema?.outputComponent || null
          },
          connectionId: node.task.connectionId,
          actionId: node.task.actionId,
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          parent,
          ...actionMeta
        }
      });

      if (node.task.next) {
        for (const next of node.task.next) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-from`,
            toPort: `${next}-to`
          });
        }
      }
    } else if (node.approval) {
      const actionMeta: any = getActionForId(packages, node.approval.actionId);

      // TODO: Improve handling the double quoting via
      let input = node.approval?.input || '{}';
      if (isString(input)) {
        input = JSON.parse(input);
      }

      nodes.push({
        id: node.key,
        text: node.approval.name,
        parent,
        icon: {
          url: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          height: 25,
          width: 25
        },
        ports: [
          {
            id: `${node.key}-positive`,
            width: 10,
            height: 10,
            className: 'positivePort',
            side: 'SOUTH'
          },
          {
            id: `${node.key}-negative`,
            width: 10,
            className: 'negativePort',
            height: 10,
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'approval',
          disabled: node.disabled,
          label: node.approval.name,
          input,
          dependsOn: getDependsOn(node.approval?.input),
          timeout: node.approval.timeout,
          connectionId: node.approval.connectionId,
          actionId: node.approval.actionId,
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          parent,
          ...actionMeta
        }
      });

      if (node.approval.choice.approveNext) {
        for (const next of node.approval.choice.approveNext) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-positive`,
            toPort: `${next}-to`
          });
        }
      }

      if (node.approval.choice.declineNext) {
        for (const next of node.approval.choice.declineNext) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-negative`,
            toPort: `${next}-to`
          });
        }
      }
    } else if (node.choice && LogicActions.Condition === node.choice.actionId) {
      // Manually find the choice meta package using hardcoded id CORE_CONDITION.
      const actionMeta = getActionForId(
        packages,
        node.choice.actionId || LogicActions.Condition
      );

      const choiceNode: NodeData = {
        id: node.key,
        text: node.choice.name,
        parent,
        icon: {
          url: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          height: 25,
          width: 25
        },
        ports: [
          {
            id: `${node.key}-negative`,
            width: 10,
            height: 10,
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'choice',
          label: node.choice.name,
          disabled: node.disabled,
          actionId: node.choice.actionId,
          dependsOn: [],
          input: {},
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          ...actionMeta,
          parent
        }
      };

      if (node.choice.default) {
        for (const next of node.choice.default) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-negative`,
            toPort: `${next}-to`,
            text: 'Default'
          });
        }
      }

      if (node.choice.choices) {
        let dependsOn: any[] = [];
        const choices: ChoiceRule[] = [];

        for (const [i, choice] of node.choice.choices.entries()) {
          const dupChoice = {
            ...choice,
            id: choice.id || shortid(),
            label: choice.label || `Condition ${i + 1}`
          };
          dependsOn = dependsOn.concat(
            parseChoiceVariables(dependsOn, dupChoice)
          );

          // Add the choice's port
          const port = {
            id: `${node.key}-${dupChoice.id}-positive`,
            width: 10,
            height: 10,
            className: 'positivePort',
            side: 'SOUTH' as PortSide
          };
          choiceNode.ports.splice(-2, 0, port);

          for (const next of dupChoice.next) {
            edges.push({
              id: shortid(),
              from: node.key,
              to: next,
              parent,
              fromPort: port.id,
              toPort: `${next}-to`,
              text: dupChoice.label
            });
          }

          // Handle choice complex conditions
          if (dupChoice.and?.length) {
            const ands = [];
            for (const and of dupChoice.and) {
              ands.push({
                ...and,
                view: and.view ? parseNestedQuoting(and.view, '{}') : undefined
              });
            }
            dupChoice.and = ands;
          }

          if (dupChoice.or?.length) {
            const ors = [];
            for (const or of dupChoice.or) {
              ors.push({
                ...or,
                view: or.view ? parseNestedQuoting(or.view, '{}') : undefined
              });
            }
            dupChoice.or = ors;
          }

          choices.push({
            ...dupChoice,
            // TODO: Figure out a better way here...
            view: dupChoice.view
              ? parseNestedQuoting(dupChoice.view, '{}')
              : undefined
          });
        }

        choiceNode.data.dependsOn = dependsOn;
        choiceNode.data.input = {
          conditions: choices
        };
      }

      nodes.push(choiceNode);
    } else if (node.choice && node.choice.actionId === LogicActions.Branch) {
      // Manually find the choice meta package using hardcoded id CORE_CONDITION.
      const actionMeta = getActionForId(
        packages,
        node.choice.actionId || LogicActions.Condition
      );

      const choiceNode: NodeData = {
        id: node.key,
        text: node.choice.name,
        // height: 150,
        // width: 250,
        parent,
        icon: {
          url: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          height: 25,
          width: 25
        },
        ports: [
          {
            id: `${node.key}-negative`,
            width: 10,
            height: 10,
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'choice',
          label: node.choice.name,
          disabled: node.disabled,
          actionId: node.choice.actionId,
          dependsOn: [],
          input: {},
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          ...actionMeta,
          parent
        }
      };

      if (node.choice.default) {
        for (const next of node.choice.default) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-negative`,
            toPort: `${next}-to`,
            text: 'Default'
          });
        }
      }

      if (node.choice.choices) {
        let dependsOn: any[] = [];
        const choices: ChoiceRule[] = [];

        for (const choice of node.choice.choices) {
          const dupChoice = {
            ...choice
          };
          dependsOn = dependsOn.concat(
            parseChoiceVariables(dependsOn, dupChoice)
          );

          // Add the choice's port
          const port = {
            id: `${node.key}-${choice.id}-positive`,
            width: 10,
            height: 10,
            className: 'positivePort',
            side: 'SOUTH' as PortSide
          };
          choiceNode.ports.splice(-2, 0, port);

          for (const next of choice.next) {
            edges.push({
              id: shortid(),
              from: node.key,
              to: next,
              parent,
              fromPort: port.id,
              toPort: `${next}-to`,
              text: choice.label
            });
          }

          // Handle choice complex conditions
          if (dupChoice.and?.length) {
            const ands = [];
            for (const and of dupChoice.and) {
              ands.push({
                ...and,
                view: and.view ? parseNestedQuoting(and.view, '{}') : undefined
              });
            }
            dupChoice.and = ands;
          }

          if (dupChoice.or?.length) {
            const ors = [];
            for (const or of dupChoice.or) {
              ors.push({
                ...or,
                view: or.view ? parseNestedQuoting(or.view, '{}') : undefined
              });
            }
            dupChoice.or = ors;
          }

          choices.push({
            ...dupChoice,
            // TODO: Figure out a better way here...
            view: dupChoice.view
              ? parseNestedQuoting(dupChoice.view, '{}')
              : undefined
          });
        }

        choiceNode.data.dependsOn = dependsOn;
        choiceNode.data.input = {
          conditions: choices
        };
      }

      nodes.push(choiceNode);
    } else if (node.choice) {
      // Manually find the choice meta package using hardcoded id CORE_CONDITION.
      const actionMeta = getActionForId(
        packages,
        node.choice.actionId || LogicActions.Condition
      );

      const choiceNode: NodeData = {
        id: node.key,
        text: node.choice.name,
        parent,
        icon: {
          url: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          height: 25,
          width: 25
        },
        ports: [
          {
            id: `${node.key}-positive`,
            width: 10,
            height: 10,
            className: 'positivePort',
            side: 'SOUTH'
          },
          {
            id: `${node.key}-negative`,
            width: 10,
            height: 10,
            className: 'negativePort',
            side: 'SOUTH'
          },
          {
            id: `${node.key}-to`,
            width: 10,
            height: 10,
            side: 'NORTH',
            hidden: true
          }
        ],
        data: {
          id: node.key,
          type: 'choice',
          label: node.choice.name,
          disabled: node.disabled,
          actionId: node.choice.actionId,
          dependsOn: [],
          input: {},
          logo: getImageForVendor({
            pkg: actionMeta.pkg,
            action: actionMeta.action
          }),
          ...actionMeta,
          parent
        }
      };

      if (node.choice.default) {
        for (const next of node.choice.default) {
          edges.push({
            id: shortid(),
            from: node.key,
            to: next,
            parent,
            fromPort: `${node.key}-negative`,
            toPort: `${next}-to`
          });
        }
      }

      if (node.choice.choices) {
        let dependsOn: any[] = [];
        for (const choice of node.choice.choices) {
          const dupChoice = {
            ...choice
          };
          dependsOn = dependsOn.concat(
            parseChoiceVariables(dependsOn, dupChoice)
          );

          for (const next of choice.next) {
            edges.push({
              id: shortid(),
              from: node.key,
              to: next,
              parent,
              fromPort: `${node.key}-positive`,
              toPort: `${next}-to`
            });
          }

          // Handle choice complex conditions
          if (dupChoice.and?.length) {
            const ands = [];
            for (const and of dupChoice.and) {
              ands.push({
                ...and,
                view: and.view ? parseNestedQuoting(and.view, '{}') : undefined
              });
            }
            dupChoice.and = ands;
          }

          if (dupChoice.or?.length) {
            const ors = [];
            for (const or of dupChoice.or) {
              ors.push({
                ...or,
                view: or.view ? parseNestedQuoting(or.view, '{}') : undefined
              });
            }
            dupChoice.or = ors;
          }

          choiceNode.data.input =
            choiceNode.data.actionId === LogicActions.ListHasItems
              ? {
                  expression: dupChoice.expression
                }
              : {
                  condition: {
                    ...dupChoice,
                    // TODO: Figure out a better way here...
                    view: dupChoice.view
                      ? parseNestedQuoting(dupChoice.view, '{}')
                      : undefined
                  }
                };
        }

        choiceNode.data.dependsOn = dependsOn;
      }

      nodes.push(choiceNode);
    }
  }

  return {
    nodes,
    edges
  };
}

function parseSchema(schema: Partial<FlowSchema>) {
  if (!schema || !schema.items) {
    schema = {
      items: []
    };
  }

  const copy = {
    items: []
  };

  if (schema.items.length) {
    for (const item of schema.items) {
      copy.items.push({
        ...item,
        input: item.input
          ? parseNestedQuoting(JSON.stringify(item.input))
          : null,
        output: item.output
          ? parseNestedQuoting(JSON.stringify(item.output))
          : null
      });
    }
  }

  return copy;
}

function transformInput(node: Step) {
  const id = node.task.actionId;
  let input =
    typeof node.task?.input === 'string'
      ? parseNestedQuoting(node.task?.input, '{}')
      : node.task?.input;

  if (input) {
    if (id === DatastoreActions.FilterList) {
      const { store, ...rest } = input;
      input = {
        store,
        condition: [rest.condition]
      };
    } else if (id === LogicActions.ListFilter) {
      const { data, ...rest } = input;
      input = {
        data,
        condition: [rest.condition]
      };
    }
  }

  return input;
}
