import { helpers } from 'shared/form/Modifiers';
import { TokenEntityOption, TokenOption } from 'shared/form/TokenEditor';

export const getModifierFromName = (name: string) => {
  for (const group of helpers) {
    for (const h of group.options) {
      if (name === h.name) {
        return h;
      }
    }
  }

  return null;
};

export const getUpdatedModifier = (
  token: TokenEntityOption,
  tokens: TokenOption[]
) => {
  // Need to determine if the modifier on this token is a mapped function applied to all elements in an array
  if (token.modifier === '$map') {
    const param = token.params?.[0];
    if (param) {
      // Check to see if it matches the format function($v) { $<modifier>($v[, parameters]) }
      const regex = new RegExp(
        /^function\(\$v\) { (\$[\s\S]*)\((\$v[\s\S]*)\) }$/g
      );
      if (param.match(regex)) {
        const [, modifier, params] = regex.exec(param);
        const splitParams = params.split('$v, ');
        const updatedParams =
          splitParams.length > 1
            ? splitParams.slice(1).map(p => {
                // If parameter contains a token, need to wrap it in {{}}
                const splitBySpaces = p.match(/(?:[^\s"]+|"[^"]*")+/g) || [];
                for (const split of splitBySpaces) {
                  const foundToken = tokens.find(t => t.value === split);
                  if (foundToken) {
                    p = p.replace(split, `{{${split}}}`);
                  }
                }

                return p;
              })
            : [];
        const updatedToken = {
          ...token,
          modifier,
          params: updatedParams,
          applyMap: true
        };
        return updatedToken;
      }
    }
  }

  return token;
};
