import {
  compileTemplate,
  MuttonCompiledNodeType,
  MuttonCompiledExpressionNode,
  MuttonCompiledLiteralNode
} from '@getcrft/mutton';
import jsonata, { ExprNode } from 'jsonata';
import { Modifier } from '../types';

// This can be removed when > jsonata@1.8.3 is released
interface FullExprNode extends ExprNode {
  arguments?: FullExprNode[];
  name?: string;
  nextFunction?: string;
  procedure?: ExprNode;
  body?: FullExprNode;
  steps?: FullExprNode[];
  condition?: ExprNode;
  then?: ExprNode;
  else?: ExprNode;
}

export const modifierDeserializer = (modifier: string): Modifier[] => {
  if (!modifier) {
    return [];
  }

  const getArgTypeValue = (
    arg: FullExprNode,
    escapedExpression: string,
    inLambda?: boolean
  ) => {
    switch (arg.type) {
      case 'path':
        // If the path is within a function, it can be one of the passed function variables, requiring a $
        // Need to check the original expression to see if the path has a $ in front
        // function($v, $i, $a) {
        //   $v.type = "Vendor Specific Advisory URL"
        // }
        // $v.type will be determined to be a path, so need to maintain the $
        const path = arg.steps
          .map(n => (!isValid(n.value) ? `\`${n.value}\`` : n.value))
          .join('.');
        const pathIndex = escapedExpression.indexOf(path);
        const isVariable =
          pathIndex > 0 && escapedExpression[pathIndex - 1] === '$';

        return `${isVariable ? '$' : ''}${path}`;
      case 'function':
        // Lets get recursive with it
        const aM = getModifier(arg, escapedExpression);
        return [aM];
      case 'string':
        return inLambda ? JSON.stringify(arg.value) : arg.value;
      case 'variable':
        // restore stripped $
        return `$${arg.value}`;
      case 'value':
        return arg.value;
      case 'regex':
        // jsonata only allows i and m regex flags, so need to remove any others
        // arg.value is a regex, not a string so make a new regex with new flags
        const flags = arg.value.flags;
        const safeFlags = flags.replace(/[^im]/g, '');
        const reg = arg.value.source.replace(/\\+"/g, '"');
        return new RegExp(reg, safeFlags);
      case 'binary':
        return `${getArgTypeValue(arg.lhs, escapedExpression, inLambda)} ${
          arg.value
        } ${getArgTypeValue(arg.rhs, escapedExpression, inLambda)}`;
      case 'bind':
        return `${getArgTypeValue(arg.lhs, escapedExpression, inLambda)} ${
          arg.value
        } ${getArgTypeValue(arg.rhs, escapedExpression, inLambda)}`;
      case 'block':
        return `(${arg.expressions
          .map(e => getArgTypeValue(e, escapedExpression, inLambda))
          .join('; ')};)`;
      case 'condition':
        return `${getArgTypeValue(
          arg.condition,
          escapedExpression
        )} ? ${getArgTypeValue(arg.then, escapedExpression, inLambda)}${
          arg.else
            ? ` : ${getArgTypeValue(arg.else, escapedExpression, inLambda)}`
            : ''
        }`;
    }

    return arg.value?.toString() || '';
  };

  const getModifier = (
    node: FullExprNode,
    escapedExpression: string
  ): Modifier => {
    try {
      let m: Modifier = {};

      // Moment or things like it
      if (node.type === 'path') {
        const modifiers = node.steps.reduce((m, n) => {
          return m.concat(getModifier(n, escapedExpression));
        }, []);
        m = modifiers.shift();
        if (modifiers.length > 0) {
          if (!m.params) {
            m.params = [];
          }
          modifiers.forEach(n => {
            if (n.value) {
              m.params.push(n.value);
            }
          });
        }
        return m;
      }

      // Grab Modifier
      if (node.procedure?.value) {
        // jsonata ast strips dollar sign
        m.modifier = '$' + node.procedure.value;
      }

      // Follow on functions
      if (node.nextFunction) {
        m.params = [node.nextFunction];
      }

      // Proc args
      if (node.arguments?.length) {
        const firstArg = node.arguments.shift();
        if (firstArg.type === 'function') {
          m.additionalModifiers = getArgTypeValue(firstArg, escapedExpression);
        } else {
          m.value = getArgTypeValue(firstArg, escapedExpression);
        }

        // We have params if there are array items left
        if (node.arguments.length) {
          if (!m.params) {
            m.params = [];
          }
          m.params = [
            ...node.arguments.map(n => {
              if (n.type === 'path') {
                // This is a token within a modifier param

                return `{{${n.steps
                  .map(n => (!isValid(n.value) ? `\`${n.value}\`` : n.value))
                  .join('.')}}}`;
              } else if (n.type === 'lambda') {
                const args = n.arguments
                  ? n.arguments
                      .map(a => getArgTypeValue(a, escapedExpression))
                      .join(', ')
                  : '';

                if (n.body.type === 'lambda') {
                  const funcArgs = n.body.body.arguments
                    ? n.body.body.arguments
                        .map(a => getArgTypeValue(a, escapedExpression, true))
                        .join(', ')
                    : '';
                  return `function(${args}) { ${
                    getModifier(n.body.body, escapedExpression).modifier
                  }(${funcArgs}) }`;
                } else if (n.body.type === 'binary') {
                  return `function(${args}) { ${getArgTypeValue(
                    n.body,
                    escapedExpression,
                    true
                  )} }`;
                } else if (n.body.type === 'block') {
                  return `function(${args}) { ${getArgTypeValue(
                    n.body,
                    escapedExpression,
                    true
                  )} }`;
                }
              }

              return getArgTypeValue(n, escapedExpression);
            }),
            ...m.params
          ];
        }
      }

      return m;
    } catch (error) {
      console.error('getModifier error: ', error);
    }
  };

  const findSourceAction = (m: Modifier) => {
    const source = m?.value?.match(/^a[a-z0-9]{14}/g);
    if (source?.length > 0) {
      m.source = source[0];
      const valueSplit = m.value.split('.');
      m.sourceValue = valueSplit[valueSplit.length - 1].replace(/"/g, '');
    }
  };

  const compiledTemplate = compileTemplate(modifier);

  return compiledTemplate.nodes.map<Modifier>(node => {
    let m: Modifier = {};

    switch (node.type) {
      case MuttonCompiledNodeType.EXPRESSION:
        const n = node as MuttonCompiledExpressionNode;

        // Expression and modifier check
        if (isModifierSyntax(n.expression)) {
          try {
            const ast = jsonata(n.expression).ast() as FullExprNode;
            m = getModifier(ast, n.expression);
          } catch (e) {
            console.error(`Error parsing!`, n.expression, e);
          }
          if (m?.additionalModifiers) {
            m.additionalModifiers = flattenAdditionalModifiers(
              m.additionalModifiers
            );
            // Find our nested value
            /* m.additionalModifiers.some(n => {
              if (n.value) {
                m.value = n.value;
                delete n.value;
                return true;
              }
              return false;
            }); */

            // We have to inverse the order to get this right
            // It would be great if we could get the AST in inside out order
            // this is so ugly, sigh.
            const newParent = m.additionalModifiers.pop();
            newParent.additionalModifiers = [];
            newParent.additionalModifiers.push(
              ...m.additionalModifiers.splice(0, m.additionalModifiers.length)
            );
            delete m.additionalModifiers;
            newParent.additionalModifiers.push({ ...m });
            m = newParent;
          }
        } else {
          m.value = n.expression;
        }

        // Find source action id
        findSourceAction(m);

        return m;
      case MuttonCompiledNodeType.LITERAL:
        m.value = (node as MuttonCompiledLiteralNode).literal;
        // Find source action id
        findSourceAction(m);

        return m;
      default:
        return {} as Modifier;
    }
  });
};

export const flattenAdditionalModifiers = (am: Modifier[]): Modifier[] => {
  try {
    return am.reduce((flat, n) => {
      const { additionalModifiers, ...node } = n;
      if (additionalModifiers?.length > 0) {
        flat.push(node);
        // Lets get recursive with it again
        return flat.concat(flattenAdditionalModifiers(additionalModifiers));
      }
      return flat.concat(node);
    }, []);
  } catch (error) {
    console.error(error);
  }
};

// this could be regex
function isModifierSyntax(text: string): boolean {
  if (!text) {
    return false;
  }

  return text.includes('$') && text.includes('(');
}

/**
 * Detect if a string has invalid json key chars or space.
 * Reference: https://stackoverflow.com/questions/11896599/javascript-code-to-check-special-characters
 */
function isValid(str: string) {
  return !/[ ~`!#$%\\^&*+=\-\\[\]\\';,/{}|\\":<>\\?]/g.test(str);
}
