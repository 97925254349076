import React, { Fragment, useEffect, useState, FC, useCallback } from 'react';
import { Button } from 'shared/elements/Button';
import { helpers, Helper } from './helpers';
import { ModifierEditor } from './ModifierEditor';
import { ModifierGroup } from './ModifierGroup';
import {
  findMeta,
  flattenAdditionalModifiers,
  modifierSerializer
} from './utils';
import { Modifier } from './types';
import { TokenEntityOption, TokenOption } from 'shared/form/TokenEditor/types';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import css from './Modifiers.module.css';
import { TokenEditor } from 'shared/form/TokenEditor';
import { Block } from 'shared/layout/Block';
import classNames from 'classnames';
import { parseTemplate } from '@getcrft/jsonata-ext';
import set from 'lodash/set';
import { valueTypeToBase } from 'core/types';
import { JsonTree } from 'shared/data/Tree';
import { DateTimeInput } from 'shared/form/DateTimeInput';
import cloneDeep from 'lodash/cloneDeep';
import { ModifierDescription } from './ModifierDescription';

export type ModifierProps = {
  token?: TokenEntityOption;
  tokens?: TokenOption[];
  onClose?: () => void;
  onAddModifier: (result: string) => void;
};

type ModifierExampleProps = {
  expression: string;
  inputValue?: any;
  value?: any;
};

const getDefaultValue = (
  type: string,
  currentValue?: any,
  isArray?: boolean
) => {
  let defaultValue;

  if (currentValue) {
    defaultValue = currentValue;
  } else if (type === 'array' || isArray) {
    defaultValue = [];
  } else if (type === 'object') {
    defaultValue = {};
  } else if (type === 'datetime') {
    defaultValue = new Date().toISOString();
  } else {
    defaultValue = '';
  }

  return defaultValue;
};

const getTokenInputDetails = (token: TokenEntityOption, currentValue?: any) => {
  const type =
    token?.subType || token?.returnSubType
      ? 'array'
      : valueTypeToBase(token?.valueType || token?.type);
  const initialValue = getDefaultValue(
    type,
    currentValue,
    !!token?.returnSubType || !!token?.subType
  );

  return { initialValue, type };
};

const getSetParamTokens = (
  modifier: Modifier,
  values: ModifierExampleProps[],
  valuesIndex: number,
  dataObj: { [key: string]: any }
) => {
  const paramTokenInputs = [];
  let data = dataObj;

  // Determine if params contain tokens
  if (modifier.params) {
    const helper = findMeta(null, modifier);
    for (let i = 0; i < modifier.params.length; i += 1) {
      const param = modifier.params[i];
      // Use the helper param to set type and name
      const helperParamProperties = helper.params.properties;
      const helperParamProperty = Object.keys(helperParamProperties).sort(
        (a, b) =>
          helperParamProperties[a].attributes['ui:order'] -
          helperParamProperties[b].attributes['ui:order']
      )[i];
      const helperParam = helperParamProperties[helperParamProperty];

      if (typeof param === 'string' && param.match(/{{[\s\S]*}}/g)) {
        // Update the parseTokenCopy's params to use the new parameter name for reference
        modifier.params[i] = `{{${helper.name.slice(
          1
        )}_${helperParamProperty}}}`;
        if (param.match(/^{{[\s\S]*}}$/g)) {
          // It is a single token
          const inputValue = getDefaultValue(
            helperParam.type || 'string',
            values[valuesIndex]?.inputValue?.[i]?.inputValue
          );
          data = set(
            data,
            `${helper.name.slice(1)}_${helperParamProperty}`,
            inputValue
          );
          paramTokenInputs.push({
            type: helperParam.type || 'string',
            label: helperParam.title,
            inputValue
          });
        } else {
          const inputValue = getDefaultValue(
            'string',
            values[valuesIndex]?.inputValue?.[i]?.inputValue
          );
          data = set(
            data,
            `${helper.name.slice(1)}_${helperParamProperty}`,
            inputValue
          );
          paramTokenInputs.push({
            type: 'string',
            label: helperParam.title,
            inputValue
          });
        }
      } else {
        paramTokenInputs.push(null);
      }
    }
  }

  return { paramTokenInputs, data };
};

export const Modifiers: FC<ModifierProps> = ({
  token,
  tokens = [],
  onAddModifier,
  onClose = () => undefined
}) => {
  const [selectedToken, setSelectedToken] = useState<Modifier>(token);
  const [internalToken, setInternalToken] = useState<Modifier>(token);
  const [serializedToken, setSerializedToken] = useState<string>();
  const [activeHelper, setActiveHelper] = useState<Helper | null>(null);
  const [dirty, setDirty] = useState<boolean>(false);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const { initialValue, type } = getTokenInputDetails(
    internalToken as TokenEntityOption
  );
  const [selectedTokenType, setSelectedTokenType] = useState<string>(type);
  const [selectedTokenSubType, setSelectedTokenSubType] = useState<string>(
    selectedToken?.subType
  );
  const [exampleValues, setExampleValues] = useState<ModifierExampleProps[]>([
    { expression: `{{${internalToken?.value}}}`, inputValue: initialValue }
  ]);

  useEffect(() => {
    const newToken = { ...internalToken };
    if (newToken.additionalModifiers) {
      let result = [];
      let reversed = [...internalToken.additionalModifiers].reverse();
      reversed.forEach(
        modifier => (result = [{ ...modifier, additionalModifiers: result }])
      );
      newToken.additionalModifiers = flattenAdditionalModifiers(result);
    }

    setSerializedToken(modifierSerializer(newToken));
  }, [internalToken]);

  const onSaveModifiers = useCallback(() => {
    onAddModifier(serializedToken);
  }, [serializedToken, onAddModifier]);

  const parseExpressions = useCallback(
    (parseToken: Modifier, values?: ModifierExampleProps[]) => {
      if (!parseToken?.value) {
        return;
      }

      const parseTokenCopy = cloneDeep(parseToken);

      const exampleValuesToUse = values || exampleValues;
      const input = exampleValuesToUse[0].inputValue;
      const newValues: ModifierExampleProps[] = [
        {
          expression: `{{${parseTokenCopy.value}}}`,
          inputValue: input
        }
      ];
      const hasExampleInput = !!exampleValuesToUse[0].inputValue;
      // Apply new value to jsonata expressions
      let data = set({}, parseTokenCopy.value, input);
      if (parseTokenCopy.modifier) {
        const { paramTokenInputs, data: updatedData } = getSetParamTokens(
          parseTokenCopy,
          exampleValuesToUse,
          1,
          data
        );
        data = updatedData;

        const tokenWithoutAdditionalModifiers = {
          ...parseTokenCopy,
          additionalModifiers: undefined
        };
        const stepOneExpression = modifierSerializer(
          tokenWithoutAdditionalModifiers
        );

        if (paramTokenInputs.some(i => i && i.inputValue == null)) {
          // If there is a param that has a token but no input value, skip parsing the template
          newValues.push({
            expression: stepOneExpression,
            inputValue: paramTokenInputs
          });
        } else {
          const stepOneValue = hasExampleInput
            ? parseTemplate(stepOneExpression, data)
            : null;
          newValues.push({
            expression: stepOneExpression,
            value: stepOneValue,
            inputValue: paramTokenInputs.length ? paramTokenInputs : undefined
          });
        }
      }

      if (parseTokenCopy.additionalModifiers) {
        for (let i = 0; i < parseTokenCopy.additionalModifiers.length; i += 1) {
          const modifier = parseTokenCopy.additionalModifiers[i];
          // 1 + i + 1 is to represent additional modifiers starting at index 2 in the exampleValuesToUse array
          const { paramTokenInputs, data: updatedData } = getSetParamTokens(
            modifier,
            exampleValuesToUse,
            1 + i + 1,
            data
          );
          data = updatedData;

          const modifiers = parseTokenCopy.additionalModifiers.slice(0, i + 1);
          const tokenWithAdditionalModifiers = {
            ...parseTokenCopy,
            additionalModifiers: modifiers
          };
          const stepExpression = modifierSerializer(
            tokenWithAdditionalModifiers
          );

          if (paramTokenInputs.some(i => i && i.inputValue == null)) {
            // If there is a param that has a token but no input value, skip parsing the template
            newValues.push({
              expression: stepExpression,
              inputValue: paramTokenInputs
            });
          } else {
            const stepValue = hasExampleInput
              ? parseTemplate(stepExpression, data)
              : null;
            newValues.push({
              expression: stepExpression,
              value: stepValue,
              inputValue: paramTokenInputs.length ? paramTokenInputs : undefined
            });
          }
        }
      }

      setExampleValues(newValues);
    },
    [exampleValues]
  );

  const renderTestInput = useCallback(
    ({ type, inputValue, index, label, paramIndex }) => (
      <Block
        key={`${index}-${label}`}
        label={label || 'Test Input'}
        tooltip="By providing a test input value, you can see the resulting value after a modifier is applied"
        className={classNames(css.block, css.input)}
      >
        {type === 'datetime' && (
          <DateTimeInput
            type="date-time"
            value={inputValue}
            onChange={v => {
              const newExampleValues = [...exampleValues];
              if (paramIndex != null) {
                newExampleValues[index].inputValue[paramIndex].inputValue =
                  v.toISOString();
              } else {
                newExampleValues[index].inputValue = v.toISOString();
              }
              setExampleValues(newExampleValues);
              parseExpressions(internalToken, newExampleValues);
            }}
          />
        )}
        {type !== 'datetime' && (
          <JsonTree
            data={inputValue}
            type={
              internalToken.returnSubType || internalToken.subType
                ? 'array'
                : type
            }
            editable
            showCount
            showEmpty
            showType="icons"
            onUpdateData={data => {
              const newExampleValues = [...exampleValues];
              if (paramIndex != null) {
                newExampleValues[index].inputValue[paramIndex].inputValue =
                  data;
              } else {
                newExampleValues[index].inputValue = data;
              }
              setExampleValues(newExampleValues);
              parseExpressions(internalToken, newExampleValues);
            }}
          />
        )}
      </Block>
    ),
    [exampleValues, internalToken, parseExpressions]
  );

  const renderConnector = useCallback(
    (index: number) => {
      // Modifier/Additional Modifier details
      let modifierTestInputs = [];
      if (index !== 0) {
        const modInputs = exampleValues[index]?.inputValue;
        if (modInputs && modInputs.length) {
          for (let i = 0; i < modInputs.length; i += 1) {
            const modInput = modInputs[i];
            if (modInput) {
              modifierTestInputs.push(
                renderTestInput({
                  type: modInput.type,
                  inputValue: modInput.inputValue,
                  index,
                  label: modInput.label,
                  paramIndex: i
                })
              );
            }
          }
        }
      }

      // Initial token details
      const { initialValue, type } = getTokenInputDetails(
        selectedToken as TokenEntityOption,
        exampleValues[0]?.inputValue
      );

      return (
        <div className={css.connectorContainer}>
          <div className={css.expression}>
            {exampleValues[index]?.expression != null && (
              <Block label="Expression" className={css.block}>
                {exampleValues[index]?.expression}
              </Block>
            )}
          </div>
          <div className={css.connector}>
            <div className={css.square} />
            <div
              className={classNames(css.line, {
                [css.input]: index === 0,
                [css.result]: index !== 0 && exampleValues[index] != null
              })}
            />
            <div className={css.square} />
          </div>
          <div>
            {index === 0 &&
              renderTestInput({
                type,
                inputValue: initialValue,
                index,
                label: undefined,
                paramIndex: undefined
              })}
            {index !== 0 && modifierTestInputs.length > 0 && modifierTestInputs}
            {index !== 0 && exampleValues[index]?.value != null && (
              <Block label="Result" className={css.block} key={index}>
                <pre>{JSON.stringify(exampleValues[index].value, null, 2)}</pre>
              </Block>
            )}
          </div>
        </div>
      );
    },
    [exampleValues, selectedToken, renderTestInput]
  );

  const renderAddlModifiers = useCallback(
    (h, i: number) => {
      if (!h) {
        return null;
      }

      return (
        <Fragment key={`editor-${h.modifier}-${h.params?.join('-')}`}>
          {renderConnector(i + 1)}
          <ModifierEditor
            token={h as TokenEntityOption}
            tokens={tokens}
            onDeleteHelper={() => {
              const additionalModifiers = [
                ...internalToken.additionalModifiers
              ];
              additionalModifiers.splice(i, 1);

              const newToken = { ...internalToken };
              if (additionalModifiers.length) {
                newToken.additionalModifiers = additionalModifiers;
              } else {
                delete newToken.additionalModifiers;
              }

              setInternalToken(newToken);
              setDirty(true);
              parseExpressions(newToken);
            }}
            onSave={data => {
              const additionalModifiers = [
                ...internalToken.additionalModifiers
              ];
              additionalModifiers[i] = data;
              const newToken = {
                ...internalToken,
                additionalModifiers
              };

              setInternalToken(newToken);

              setDirty(true);
              parseExpressions(newToken);
            }}
          />
        </Fragment>
      );
    },
    [internalToken, parseExpressions, renderConnector, tokens]
  );

  return (
    <Fragment>
      {token && (
        <div className={css.currentToken}>
          <div className={css.tokenWrapper}>
            <TokenEditor
              className={css.tokenEditor}
              value={selectedToken?.value ? `{{${selectedToken.value}}}` : ''}
              tokens={tokens}
              allowFlowTrigger={false}
              allowExpressions={false}
              allowModifiers={false}
              singleSelection
              onChange={val => {
                const valValue = val.replace('{{', '').replace('}}', '');
                const newToken = tokens.find(t => t.value === valValue);
                setInternalToken(newToken);
                setSelectedToken(newToken);
                setDirty(true);
                const { initialValue, type } = getTokenInputDetails(
                  newToken as TokenEntityOption
                );
                setSelectedTokenType(type);
                setExampleValues([
                  {
                    expression: `{{${newToken?.value}}}`,
                    inputValue: initialValue
                  }
                ]);
              }}
            />
            {selectedToken?.value && (
              <ModifierDescription
                modifier={selectedToken}
                isModifier={false}
                selectedTokenType={selectedTokenType}
                selectedTokenSubType={selectedTokenSubType}
                onTokenTypeChange={type => {
                  setSelectedTokenType(type);

                  if (type) {
                    const newToken = {
                      ...selectedToken,
                      valueType: type,
                      subType: type === 'array' ? selectedToken.subType : null
                    };
                    const { initialValue } = getTokenInputDetails(
                      newToken as TokenEntityOption
                    );
                    setExampleValues([
                      {
                        expression: `{{${newToken?.value}}}`,
                        inputValue: initialValue
                      }
                    ]);
                    setSelectedToken(newToken);
                    setInternalToken({
                      ...internalToken,
                      valueType: type,
                      subType: type === 'array' ? internalToken.subType : null
                    });

                    if (type !== 'array') {
                      setSelectedTokenSubType(null);
                    }
                  }
                }}
                onTokenSubTypeChange={subType => {
                  setSelectedTokenSubType(subType);
                  setSelectedToken({
                    ...selectedToken,
                    subType
                  });
                  setInternalToken({
                    ...internalToken,
                    subType
                  });
                }}
              />
            )}
          </div>
        </div>
      )}
      {(!token || internalToken) && (
        <Fragment>
          {internalToken?.modifier ? (
            <Fragment>
              {token && renderConnector(0)}
              <ModifierEditor
                token={internalToken as TokenEntityOption}
                tokens={tokens}
                onDeleteHelper={() => {
                  const newToken = { ...token };
                  delete newToken.modifier;
                  delete newToken.params;
                  delete newToken.additionalModifiers;
                  delete newToken.returnType;
                  delete newToken.returnSubType;
                  setInternalToken(newToken);
                  setActiveHelper(null);
                  setDirty(true);
                  parseExpressions(newToken);
                }}
                onSave={data => {
                  const newToken = { ...data };
                  if (internalToken?.additionalModifiers?.length) {
                    newToken.additionalModifiers =
                      internalToken.additionalModifiers;
                  }
                  setInternalToken(newToken);
                  setActiveHelper(null);
                  setDirty(true);
                  parseExpressions(newToken);
                }}
              />
              {internalToken?.additionalModifiers?.length > 0 && (
                <Fragment>
                  {internalToken.additionalModifiers.map(renderAddlModifiers)}
                </Fragment>
              )}
              {showEmpty && (
                <Fragment>
                  {renderConnector(
                    (internalToken?.additionalModifiers?.length || 0) + 1
                  )}
                  {!activeHelper ? (
                    <ModifierGroup
                      token={internalToken as TokenEntityOption}
                      helperGroups={helpers}
                      onChange={setActiveHelper}
                    />
                  ) : (
                    <ModifierEditor
                      token={internalToken as TokenEntityOption}
                      tokens={tokens}
                      helper={activeHelper}
                      isNew={true}
                      onSave={data => {
                        const newToken = { ...internalToken };
                        if (!newToken.additionalModifiers) {
                          newToken.additionalModifiers = [];
                        }
                        newToken.additionalModifiers.push(data);
                        setInternalToken(newToken);
                        setActiveHelper(null);
                        setDirty(true);
                        setShowEmpty(false);
                        parseExpressions(newToken);
                      }}
                    />
                  )}
                </Fragment>
              )}
              {!activeHelper && !showEmpty && (
                <div className={css.addContainer}>
                  {renderConnector(
                    (internalToken?.additionalModifiers?.length || 0) + 1
                  )}
                  <Button
                    title="Add an additional Modifier"
                    className={css.addBtn}
                    color="secondary"
                    onClick={() => setShowEmpty(true)}
                  >
                    <PlusIcon className={css.addIcon} />
                  </Button>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {internalToken && renderConnector(0)}
              {!activeHelper ? (
                <ModifierGroup
                  token={internalToken as TokenEntityOption}
                  helperGroups={helpers}
                  onChange={setActiveHelper}
                />
              ) : (
                <ModifierEditor
                  token={internalToken as TokenEntityOption}
                  tokens={tokens}
                  helper={activeHelper}
                  isNew={true}
                  onSave={data => {
                    setInternalToken(data);
                    setActiveHelper(null);
                    setDirty(true);
                    parseExpressions(data);
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {/* {serializedToken && (
        <span>{serializedToken}</span>
      )} */}
      {dirty && internalToken && !activeHelper && (
        <footer className={css.footer}>
          <Button
            className={css.saveBtn}
            variant="filled"
            color="primary"
            onClick={() => {
              onSaveModifiers();
              onClose();
            }}
          >
            Save
          </Button>
        </footer>
      )}
    </Fragment>
  );
};
