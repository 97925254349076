import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  HoldConfirmDialog,
  HoldConfirmDialogProps
} from 'shared/layers/Dialog/HoldConfirmDialog';
import css from './DeleteDataStoreConfirm.module.css';
import { Pluralize } from 'shared/utils/Pluralize';

type DeleteDataStoreConfirmProps = Omit<HoldConfirmDialogProps, 'text'> & {
  flows: { id: string; name: string }[];
};

export const DeleteDataStoreConfirm: FC<DeleteDataStoreConfirmProps> = ({
  flows,
  open,
  onConfirm = () => undefined,
  onCancel = () => undefined
}) => (
  <HoldConfirmDialog
    text={
      <Fragment>
        <p>Are you sure you want to delete this Datastore value?</p>
        {flows.length > 0 && (
          <p>
            <span className={css.flowWarn}>
              <Pluralize
                singular="This Workflow uses "
                plural="These Workflows use "
                count={flows.length}
                showCount={false}
              />
              this DataStore and will break:
              <br />
              {flows.map(({ id, name }, index) => (
                <Link key={id} className={css.flow} to={`/workflows/${id}`}>
                  {name}
                  {index < flows.length - 1 ? ', ' : ''}
                </Link>
              ))}
            </span>
          </p>
        )}
      </Fragment>
    }
    open={open}
    onConfirm={onConfirm}
    onCancel={onCancel}
  />
);
