import Axios from 'axios';
import { getAuthToken } from './getAuthToken';
import { enrichModels } from './enrichModels';
import { getSerializedParameters } from './axiosUtils';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  paramsSerializer: {
    serialize: getSerializedParameters
  }
});

// Inject auth token into every request
axios?.interceptors.request.use(async config => {
  if (!config.url.includes('obfuscated-context.json')) {
    const token = await getAuthToken();
    config.headers = {
      ...config.headers,
      Authorization: token
    };
  }

  return config;
});

// Enrich incoming data with user and group information
axios?.interceptors.response.use(res => {
  let response = enrichModels(res);

  return response;
});
