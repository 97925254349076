import { axios } from 'core/Axios';
import { DataPutApiKeyBody, DataPutApiKeyResponse } from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_API_KEY;

export const req = async (
  data: DataPutApiKeyBody
): Promise<DataPutApiKeyResponse> => {
  return axios
    .put<DataPutApiKeyResponse>(Route.PUT_API_KEY, data)
    .then(data => data.data);
};

export const usePutApiKey = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataPutApiKeyResponse, DataPutApiKeyBody> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_API_KEYS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
