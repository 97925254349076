import React, { FC } from 'react';
import CountUp, { CountUpProps } from 'react-countup';
import { DataSizeProps } from './DataSize';
import { formatSize } from './utils';

export const CountUpDataSize: FC<DataSizeProps & Omit<CountUpProps, 'end'>> = ({
  size = 0,
  ...rest
}) => {
  const [num, suffix] = formatSize(size);
  return <CountUp end={num as number} suffix={suffix as string} {...rest} />;
};
