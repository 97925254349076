import React, { FC, forwardRef, Ref } from 'react';
import classNames from 'classnames';
import css from './Button.module.css';
import { motion } from 'framer-motion';

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<{}>,
  'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> & {
  color?: 'default' | 'primary' | 'secondary';
  variant?: 'filled' | 'outline' | 'text';
  fullWidth?: boolean;
  disableMargins?: boolean;
  disablePadding?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const Button: FC<ButtonProps & { ref?: Ref<HTMLButtonElement> }> =
  forwardRef(
    (
      {
        children,
        type,
        fullWidth,
        color,
        variant,
        size,
        className,
        disableMargins,
        disablePadding,
        disabled,
        ...rest
      }: ButtonProps,
      ref: Ref<HTMLButtonElement>
    ) => (
      <motion.button
        {...rest}
        disabled={disabled}
        ref={ref}
        whileTap={{ scale: disabled ? 1 : 0.9 }}
        type={type || 'button'}
        className={classNames(className, css.btn, {
          [css.default]: !color || color === 'default',
          [css.primary]: color === 'primary',
          [css.secondary]: color === 'secondary',
          [css.filled]: !variant || variant === 'filled',
          [css.outline]: variant === 'outline',
          [css.text]: variant === 'text',
          [css.fullWidth]: fullWidth,
          [css.small]: size === 'small',
          [css.medium]: !size || size === 'medium',
          [css.large]: size === 'large',
          [css.disableMargins]: disableMargins,
          [css.disablePadding]: disablePadding
        })}
      >
        {children}
      </motion.button>
    )
  );
