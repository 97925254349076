import { axios } from 'core/Axios';
import {
  DataGetFlowEventSignedQueryParams,
  DataGetFlowEventSignedQueryResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_FLOW_EVENT_SIGNED_QUERY;
export const buildQueryKey = (params?: DataGetFlowEventSignedQueryParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetFlowEventSignedQueryParams
): Promise<DataGetFlowEventSignedQueryResponse> => {
  return axios
    ?.get<DataGetFlowEventSignedQueryResponse>(
      `${process.env.REACT_APP_REST_API_URL_OLD}/file/${params.flowId}/${params.runId}/download`,
      { params }
    )
    .then(data => data);
};

export const useGetFlowEventSignedQuery = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetFlowEventSignedQueryResponse,
  DataGetFlowEventSignedQueryParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
