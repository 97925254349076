import { axios } from 'core/Axios';
// import { Datastore } from 'core/types/API';
import {
  // DataListDatastoresResponse,
  DataUpdateDatastoreBody,
  DataUpdateDatastoreResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_DATASTORE;

export const req = async (
  data: DataUpdateDatastoreBody
): Promise<DataUpdateDatastoreResponse> => {
  return axios
    .patch<DataUpdateDatastoreResponse>(Route.UPDATE_DATASTORE, data)
    .then(data => data.data);
};

export const useUpdateDatastore = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataUpdateDatastoreResponse,
  DataUpdateDatastoreBody
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      // console.log(params)
      // await client.cancelQueries(Route.LIST_DATASTORES);
      // console.log(client.getQueriesData(Route.LIST_DATASTORES))
      // // @ts-ignore
      // const previousData = client.getQueriesData(Route.LIST_DATASTORES)[0][1].items;
      // const previousItem = previousData.find(({ id }) => id === params.id)
      // const updatedItem = {
      //   ...previousItem,
      //   ...params,
      //   value: params.value ? JSON.parse(params.value) : previousItem.value
      // } as Datastore;
      // client.setQueriesData(
      //   Route.LIST_DATASTORES,
      //   (oldData: DataListDatastoresResponse) => {
      //     oldData.items = oldData.items.map(item =>
      //       item.id === params.id ? updatedItem : item
      //     );
      //     return oldData;
      //   }
      // );
      // client.setQueriesData(Route.GET_DATASTORE, () => updatedItem);

      // return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_DATASTORES);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
