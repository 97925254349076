import React, { FC } from 'react';
import classNames from 'classnames';
import { NotificationOptions } from './NotificationsContext';
import { motion } from 'framer-motion';
import { Button } from 'shared/elements/Button';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useTimeoutFn } from 'react-use';
import isString from 'lodash/isString';
import css from './Notification.module.css';

type NotificationProps = NotificationOptions & {
  id: number;
  onClose: (id: number) => void;
};

const notificationStyles = {
  default: css.default,
  error: css.error,
  success: css.success,
  warning: css.warning
};

export const Notification: FC<NotificationProps> = ({
  id,
  title,
  showClose,
  body,
  timeout,
  className,
  style,
  onClose
}) => {
  const [, cancel, reset] = useTimeoutFn(() => onClose(id), timeout);

  return (
    <motion.div
      layout={true}
      initial={{ opacity: 0, y: 50, scale: 0.5 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      className={classNames(
        css.notification,
        className,
        notificationStyles[style!]
      )}
      onMouseEnter={() => cancel()}
      onMouseLeave={() => reset()}
    >
      <div className={css.content}>
        {title && <div className={css.header}>{title}</div>}
        {body && (
          <div className={css.body}>
            {isString(body) ? (
              <span dangerouslySetInnerHTML={{ __html: body } as any} />
            ) : (
              body
            )}
          </div>
        )}
      </div>
      <div className={css.close}>
        {showClose && (
          <Button
            onClick={() => onClose(id)}
            className={css.closeButton}
            variant="text"
          >
            <CloseIcon className={css.closeIcon} />
          </Button>
        )}
      </div>
    </motion.div>
  );
};
