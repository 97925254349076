import { axios } from 'core/Axios';
import { DataPutFlowBody, DataPutFlowResponse } from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_FLOW;

export const req = async (
  data: DataPutFlowBody
): Promise<DataPutFlowResponse> => {
  return axios
    .put<DataPutFlowResponse>(Route.PUT_FLOW, data)
    .then(data => data.data);
};

export const usePutFlow = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataPutFlowResponse, DataPutFlowBody> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
