import { FC, ReactElement } from 'react';
import { isIframe } from './utils';
import queryString from 'query-string';
import { useLocation } from 'react-router';

type ModuleProps = {
  /**
   * Component to display if not in an iframe
   **/
  baseComponent: ReactElement;
  /**
   * Component to display if in an iframe
   **/
  moduleComponent: ReactElement;
};

/**
 * Handles displaying the correct component depending on
 * if it is being displayed in an iframe or not
 **/
export const Module: FC<ModuleProps> = ({ baseComponent, moduleComponent }) => {
  const location = useLocation();
  const isDev = process.env.NODE_ENV === 'development';
  const qs = queryString.parse(location.search) as {
    m?: string;
  };

  return isIframe || (isDev && qs.m) ? moduleComponent : baseComponent;
};
