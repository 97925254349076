import React, { FC, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import { ArrayInput } from '../ArrayInput';
import { DatastoreDrawer, getType } from 'shared/internal/Datastore';
import { StoreValueType } from 'core/types/API';
import css from './DataStoreInput.module.css';
import { Button } from 'shared/elements/Button';

type DataStoreInputProps = {
  type?: StoreValueType;
  flowId?: string;
  stores: any;
  flows: any[];
  field: any;
  disabled?: boolean;
  value: any;
  placeholder?: string;
  property: any;
  isObject?: boolean;
  onChange: (value) => void;
  onBlur: () => void;
};

export const buildDatastoreOptions = (
  flows: any[],
  stores: any[],
  type: StoreValueType,
  flowId?: string,
  isObject?: boolean
) => {
  const typeFilteredStores = type
    ? stores.filter(store => store.type === type)
    : stores;
  const objectFilteredStores = isObject
    ? typeFilteredStores.filter(store => {
        const storeValueType = getType(store.value);
        return storeValueType === 'object';
      })
    : typeFilteredStores;
  const flowFilteredStores = flowId
    ? objectFilteredStores.filter(
        store => !store.flowId || store.flowId === flowId
      )
    : objectFilteredStores;

  const sorted = orderBy(
    [...flowFilteredStores],
    ['flowId', item => item.name.toLowerCase()],
    ['desc', 'asc']
  );

  const groups = groupBy(sorted, 'flowId');

  const groupedByFlow = Object.keys(groups).map(k => {
    if (k !== 'null') {
      const flow = flows.find(f => f.id === k);
      const label =
        flowId && flow?.id === flowId ? `This Workflow` : flow?.name;

      return {
        // TODO: This is probably not the best but the
        // current Flow is filtered from this list for the Flows trigger
        // to prevent the user from triggering circulars
        label,
        options: groups[k].map(o => ({
          label: o.name,
          value: o.id
        }))
      };
    } else {
      return {
        label: 'Global',
        options: groups[k].map(o => ({
          label: o.name,
          value: o.id
        }))
      };
    }
  });

  const result = orderBy(
    groupedByFlow,
    item => (item.label === 'Global' ? '' : item.label),
    ['asc']
  );

  // TODO: Come back and cleanup this logic
  return result.reduce((acc, cur) => {
    acc.push(...cur.options.map(o => ({ ...o, group: cur.label })));
    return acc;
  }, []);
};

export const DataStoreInput: FC<DataStoreInputProps> = ({
  type,
  flowId,
  stores,
  flows,
  disabled,
  field,
  value,
  placeholder,
  property,
  isObject,
  onChange = () => undefined,
  onBlur = () => undefined
}) => {
  const [newDataStoreOpen, setNewDataStoreOpen] = useState<boolean>(false);
  const groupedDatastores = useMemo(
    () => buildDatastoreOptions(flows, stores, type, flowId, isObject),
    [stores, type, flows, flowId, isObject]
  );

  return (
    <div className={css.container}>
      <ArrayInput
        field={field}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        property={property}
        onChange={onChange}
        onBlur={onBlur}
        valuesOverride={groupedDatastores}
      />
      <Button
        variant="text"
        size="small"
        disablePadding
        disabled={disabled}
        className={css.createBtn}
        color="primary"
        onClick={() => setNewDataStoreOpen(true)}
      >
        Create new Datastore
      </Button>
      <DatastoreDrawer
        open={newDataStoreOpen}
        flowId={flowId}
        flows={flows}
        storeGroups={flows}
        onCreate={id => onChange({ value: id })}
        onClose={() => setNewDataStoreOpen(false)}
      />
    </div>
  );
};
