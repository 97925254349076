import { jsonataThemeRules } from './jsonata';
import { darkTheme, lightTheme } from 'shared/utils/Theme';

export const dark = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    { token: 'variable.name', foreground: darkTheme.colors['blurple']['500'] },
    { token: 'variable.other', foreground: darkTheme.colors['blurple']['500'] },
    ...jsonataThemeRules
  ],
  colors: {
    'editor.foreground': darkTheme.colors['neutral']['0'],
    'editor.background': darkTheme.colors['slate']['300'],
    'editor.lineHighlightBackground': darkTheme.colors['slate']['300']
  }
};

export const light = {
  base: 'vs',
  inherit: true,
  rules: [
    { token: 'variable.name', foreground: lightTheme.colors['blurple']['500'] },
    {
      token: 'variable.other',
      foreground: lightTheme.colors['blurple']['500']
    }
  ],
  colors: {}
};
