export const jsonataTokens = {
  tokenizer: {
    root: [
      [/\/\*.*\*\//, 'jsonata-comment'],
      [/'.*'/, 'jsonata-string'],
      [/".*"/, 'jsonata-string'],
      [/\$[a-zA-Z0-9_]*/, 'jsonata-variable'],
      [/[a-zA-Z0-9_]+/, 'jsonata-names']
    ]
  }
};

const brackets = [
  { open: '(', close: ')' },
  { open: '[', close: ']' },
  { open: '{', close: '}' },
  { open: '"', close: '"' },
  { open: "'", close: "'" },
  { open: '`', close: '`' }
];

export const jsonataConfig = {
  brackets: [
    ['(', ')'],
    ['[', ']'],
    ['{', '}']
  ],
  autoClosingPairs: brackets,
  surroundingPairs: brackets,
  indentationRules: {
    // ^(.*\*/)?\s*\}.*$
    decreaseIndentPattern: /^((?!.*?\/\*).*\*\/)?\s*[}\])].*$/,
    // ^.*\{[^}"']*$
    increaseIndentPattern: /^((?!\/\/).)*(\{[^}"'`]*|\([^)"'`]*|\[[^\]"'`]*)$/
  },
  insertSpaces: true,
  tabSize: 2
};

export const jsonataThemeRules = [
  { token: 'jsonata-string', foreground: '#d4d4d4' },
  { token: 'jsonata-comment', foreground: '#608b4e' },
  { token: 'jsonata-variable', foreground: '#ce9178' },
  { token: 'jsonata-names', foreground: '#569cd6' }
];
