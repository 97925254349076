import { RefObject, useCallback, useState, useEffect } from 'react';

export type KeyboardCoordinates = {
  x: number;
  y: number;
};

type KeyboardNavInputs = {
  ref: RefObject<HTMLElement>;
  items: any[][];
  disabled?: boolean;
  activeCoords?: KeyboardCoordinates;
  onSelect?: (item: any, offset: KeyboardCoordinates) => void;
  onKeyDown?: (event: any) => void;
};

const unselectedCoords = {
  x: 0,
  y: -1
};

export const useKeyboardNav = ({
  ref,
  disabled,
  items,
  activeCoords,
  onSelect,
  onKeyDown
}: KeyboardNavInputs) => {
  const [offset, setOffset] = useState<KeyboardCoordinates>(
    activeCoords || unselectedCoords
  );

  useEffect(() => {
    if (activeCoords) {
      setOffset(activeCoords);
    }
  }, [activeCoords]);

  const keydownHandler = useCallback(
    event => {
      if (!disabled) {
        // event.preventDefault();

        const { key } = event;
        if (key === 'ArrowUp') {
          setOffset({
            x: offset.x,
            y: Math.max(offset.y - 1, 0)
          });
        } else if (key === 'ArrowDown') {
          setOffset({
            x: offset.x,
            y: Math.min(offset.y + 1, items[offset.x]?.length - 1)
          });
        } else if (key === 'ArrowLeft') {
          const x = Math.max(offset.x - 1, 0);
          const y = items[x]?.[offset.y]?.length > 0 ? 0 : offset.y;
          setOffset({
            x,
            y
          });
        } else if (key === 'ArrowRight') {
          const x = Math.min(offset.x + 1, items.length - 1);
          const y = items[x]?.[offset.y]?.length > 0 ? 0 : offset.y;
          setOffset({
            x,
            y
          });
        } else if (key === 'Enter') {
          onSelect?.(items[offset.x]?.[offset.y], offset);
        } else if (key === 'Escape') {
          ref.current?.blur();
        } else {
          event.returnValue = true;
        }

        onKeyDown?.(event);
      }
    },
    [offset, items, ref, disabled, onKeyDown, onSelect]
  );

  const blurHandler = useCallback(() => {
    if (!disabled) {
      if (activeCoords !== undefined) {
        setOffset(activeCoords);
      } else {
        setOffset(unselectedCoords);
      }
    }
  }, [activeCoords, disabled]);

  useEffect(() => {
    const cur = ref.current;
    cur?.addEventListener('keydown', keydownHandler);
    cur?.addEventListener('blur', blurHandler);

    return () => {
      cur?.removeEventListener('keydown', keydownHandler);
      cur?.removeEventListener('blur', blurHandler);
    };
  }, [keydownHandler, blurHandler, ref]);

  return [offset, setOffset] as [
    KeyboardCoordinates,
    (o: KeyboardCoordinates) => void
  ];
};
