import { axios } from 'core/Axios';
import { DataRunFlowBody, DataRunFlowResponse } from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.RUN_FLOW;

export const req = async (
  data: DataRunFlowBody
): Promise<DataRunFlowResponse> => {
  return axios
    .post<DataRunFlowResponse>(Route.RUN_FLOW, data)
    .then(data => data.data);
};

export const useRunFlow = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataRunFlowResponse, DataRunFlowBody> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
