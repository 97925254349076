import React, { FC, Fragment } from 'react';
import { Tooltip } from 'shared/layers/Tooltip';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import ellipsize from 'ellipsize';
import { Img } from 'shared/elements/Img';
import { TokenEntityOption } from '../types';
import css from './Token.module.css';

const MAX_LENGTH = 20;
const MAX_TOOLTIP_LENGTH = 256;

export type TokenProps = {
  token: TokenEntityOption;
  subtext?: string;
  offsetKey: string;
  allowRemoval: boolean;
  allowAnyToken: boolean;
  errorText?: string;
  disabled?: boolean;
  onRemove: (token: TokenEntityOption) => void;
  onTokenClick?: (token: TokenEntityOption) => void;
};

export const Token: FC<TokenProps> = ({
  offsetKey,
  token,
  subtext,
  allowRemoval,
  allowAnyToken,
  disabled,
  errorText = 'Invalid Token',
  onRemove = () => undefined,
  onTokenClick = () => undefined
}) => {
  const text = token.text;
  const isInvalid = !allowAnyToken && !token.found;
  let tooltipLabel = text;

  if (token.group) {
    tooltipLabel = `${token.group}: ${text}`;
  }

  return (
    <span
      data-offset-key={offsetKey}
      onMouseDown={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Tooltip
        content={() => {
          if (token.description) {
            return (
              <div className={css.tooltipContainer}>
                <h4>{tooltipLabel}</h4>
                <p className={css.desc}>{token.description}</p>
                {token.source && (
                  <p className={css.modifier}>Source: {token.source}</p>
                )}
                {token.modifier && (
                  <p className={css.modifier}>
                    Modifier: {token.modifier}{' '}
                    {token.params?.length > 0 ? ` - ${token.params}` : ''}
                    {token.additionalModifiers?.length > 0 && (
                      <Fragment>{` ${token.additionalModifiers
                        .map(h => h.modifier)
                        .join(', ')}`}</Fragment>
                    )}
                  </p>
                )}
              </div>
            );
          } else if (isInvalid) {
            return (
              <div className={css.tooltipContainer}>
                <h4>{text}</h4>
                <p className={css.errorText}>{errorText}</p>
                {token.source && (
                  <p className={css.modifier}>Source: {token.source}</p>
                )}
                {token.modifier && (
                  <p className={css.modifier}>
                    Modifier: {token.modifier}{' '}
                    {token.params ? ` - ${token.params}` : ''}
                  </p>
                )}
              </div>
            );
          } else {
            return (
              <div className={css.tooltipContainer}>
                <h4>{ellipsize(text, MAX_TOOLTIP_LENGTH)}</h4>
                {token.source && (
                  <p className={css.modifier}>Source: {token.source}</p>
                )}
                {token.modifier && (
                  <p className={css.modifier}>
                    Modifier: {token.modifier}{' '}
                    {token.params?.length > 0 ? ` - ${token.params}` : ''}
                    {token.additionalModifiers?.length > 0 && (
                      <Fragment>{` - ${token.additionalModifiers
                        .map(h => h.modifier)
                        .join(', ')}`}</Fragment>
                    )}
                  </p>
                )}
              </div>
            );
          }
        }}
        modifiers={{
          offset: {
            offset: '0, 8px'
          }
        }}
      >
        <span
          className={classNames(css.tag, {
            [css.invalid]: isInvalid,
            [css.unremoveable]: !allowRemoval,
            [css.disabled]: disabled
          })}
          onClick={event => {
            if (!disabled) {
              event.preventDefault();
              onTokenClick(token);
            }
          }}
        >
          {token.icon && (
            <Img src={token.icon} className={css.icon} alt={text} />
          )}
          <span>{ellipsize(text, MAX_LENGTH)}</span>
          {token.modifier && (
            <span
              className={css.modifierIcon}
              role="img"
              aria-label="Modifier Icon"
            >
              ⚡︎
            </span>
          )}
          {allowRemoval && (
            <button
              type="button"
              className={css.btn}
              onClick={event => {
                if (!disabled) {
                  event.stopPropagation();
                  event.preventDefault();
                  onRemove(token);
                }
              }}
            >
              <CloseIcon className={css.closeicon} />
            </button>
          )}
        </span>
      </Tooltip>
    </span>
  );
};
