import { datastorePackage } from './datastore';
import { datePackage } from './date';
import { emailPackage } from './email';
import { logicPackage } from './logic';
import { webhookPackage } from './webhook';
import { flowPackage } from './flow';
import { approvalPackage } from './approval';
import { dataPackage } from './data';
import clone from 'lodash/clone';

export * from './datastore';
export * from './date';
export * from './email';
export * from './logic';
export * from './webhook';
export * from './flow';
export * from './data';
export * from './utils';

// TODO: Add Type
export const corePackages: any[] = [
  datastorePackage,
  datePackage,
  emailPackage,
  logicPackage,
  webhookPackage,
  flowPackage,
  approvalPackage,
  dataPackage
];

export const coreActions = corePackages.reduce((list, pack) => {
  const actions = pack.actions.items;
  const packageData = clone(pack);
  delete packageData.actions;

  actions.forEach(action => (action['package'] = packageData));

  return [...list, ...actions];
}, []);
