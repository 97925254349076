import React, { FC, Fragment, useCallback, useRef } from 'react';
import { Input } from 'shared/form/Input';
import { Select, SelectOption } from 'shared/form/Select';
import { ConditionValueRule } from '@getcrft/jsonata-ext';
import { DateTimeInput } from 'shared/form/DateTimeInput';
import moment from 'moment';
import css from './ConditionInputTimestamp.module.css';
import { TokenEditor } from 'shared/form/TokenEditor';

export type ConditionDateView = {
  type?: 'last' | 'next' | 'specific' | 'field';
  increment?: number;
  operator?: 'Y' | 'Q' | 'M' | 'w' | 'd' | 'h' | 'm';
};

export type ConditionInputTimestampProps = {
  value: ConditionValueRule;
  disabled?: boolean;
  valueOptions?: any[];
  hasEnumValue: boolean;
  onChange?: (value: ConditionValueRule) => void;
};

const operatorTypes = [
  { label: 'Minutes', value: 'm' },
  { label: 'Hours', value: 'h' },
  { label: 'Days', value: 'd' },
  { label: 'Weeks', value: 'w' },
  { label: 'Months', value: 'M' },
  { label: 'Quarters', value: 'Q' },
  { label: 'Years', value: 'Y' }
];

export const ConditionInputTimestamp: FC<ConditionInputTimestampProps> = ({
  value,
  disabled,
  valueOptions,
  hasEnumValue,
  onChange
}) => {
  // TODO: This is a hack to improve typing performance
  const lastValRef = useRef<string | null>(null);

  const viewVal = value?.view as ConditionDateView;
  const showIncrement =
    value?.view?.type === 'next' || value?.view?.type === 'last';
  const showCalendar = value?.view?.type === 'specific';
  const showField = value?.view?.type === 'field';

  const onFieldChange = useCallback(
    (nextValue: ConditionValueRule) => {
      const view = nextValue.view;
      if (view.type !== 'specific' && value.view?.type !== 'specific') {
        nextValue.value = new Date().toISOString();
      }

      if (view?.type === 'next' || view?.type === 'last') {
        if (view.increment && view.operator) {
          const offset = view?.type === 'last' ? '-' : '';
          nextValue.value = `$relativeDate($now, "${offset}${view.increment}", "${view.operator}", "YYYY-MM-DDTHH:mm:ss.SSS[Z]")`;
        } else {
          nextValue.value = '';
        }
      }

      onChange(nextValue);
    },
    [value, onChange]
  );

  let staticDate;
  if (showCalendar) {
    if (value.value) {
      const d = moment((value.value as string).replace(/['"]+/g, ''));
      if (d.isValid()) {
        staticDate = d.toDate();
      }
    }

    if (!staticDate) {
      staticDate = new Date();
    }
  }

  return (
    <div>
      <Select
        clearable
        disabled={disabled}
        placeholder="Select value type..."
        menuClassName={css.timestampMenu}
        value={value?.view?.type}
        onChange={v =>
          onFieldChange({
            ...value,
            view: {
              type: v
            }
          })
        }
      >
        <SelectOption value="last">Last</SelectOption>
        <SelectOption value="next">Next</SelectOption>
        <SelectOption value="specific">Specific Date</SelectOption>
        <SelectOption value="field">Field Value</SelectOption>
      </Select>
      <p className={css.description}>
        The type of filter to apply. For example: <i>Last</i> could be used to
        perform a filter for the last 5 days.
      </p>
      <br />
      {showCalendar && (
        <div className={css.calendar}>
          <DateTimeInput
            disabled={disabled}
            type="date-time"
            value={staticDate}
            onChange={v =>
              onFieldChange({
                ...value,
                value: `'${v.toISOString()}'`
              })
            }
          />
        </div>
      )}
      {showIncrement && (
        <Fragment>
          <Input
            type="number"
            fullWidth
            placeholder="Type a number increment..."
            disabled={disabled}
            value={viewVal?.increment}
            onChange={event =>
              onFieldChange({
                ...value,
                view: {
                  ...value.view,
                  increment: parseInt(event.target.value, 10)
                }
              })
            }
          />
          <p className={css.description}>
            The amount to increment. For example: 5.
          </p>
          <br />
          <Select
            clearable
            disabled={disabled}
            placeholder="Select increment type..."
            value={viewVal?.operator}
            menuClassName={css.timestampMenu}
            onChange={v =>
              onFieldChange({
                ...value,
                view: {
                  ...value.view,
                  operator: v
                }
              })
            }
          >
            {operatorTypes.map(v => (
              <SelectOption value={v.value} key={v.value}>
                {v.label}
              </SelectOption>
            ))}
          </Select>
          <p className={css.description}>
            The type of duration of the increment. Example: days.
          </p>
        </Fragment>
      )}
      {showField && !hasEnumValue && (
        <TokenEditor
          menuClassName={css.tokenMenu}
          disabled={disabled}
          value={(value?.value as string) || ''}
          allowExpressions={true}
          allowModifiers={true}
          tokens={valueOptions}
          placeholder="Value"
          onAddModifier={v =>
            onChange({
              ...value,
              value: v
            })
          }
          onBlur={() => {
            if (lastValRef.current !== null) {
              onChange({
                ...value,
                value: lastValRef.current
              });
              lastValRef.current = null;
            }
          }}
          onChange={v => {
            lastValRef.current = v;
          }}
        />
      )}
    </div>
  );
};
