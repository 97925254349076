import React, { FC, Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import css from './Breadcrumbs.module.css';

type BreadcrumbsProps = {
  children: ReactNode[] | ReactNode;
} & React.HTMLAttributes<HTMLUListElement>;

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  children,
  className,
  ...rest
}) => (
  <ul {...rest} className={classNames(css.breadcrumbs, className)}>
    {React.Children.map(children, (crumb, index) => (
      <Fragment key={index}>
        {crumb && <li className={css.crumb}>{crumb}</li>}
      </Fragment>
    ))}
  </ul>
);
