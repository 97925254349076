import { axios } from 'core/Axios';
import {
  DataGetActionParams,
  DataGetActionResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_ACTION;
export const buildQueryKey = (params?: DataGetActionParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetActionParams
): Promise<DataGetActionResponse> => {
  return axios
    .get<DataGetActionResponse>(Route.GET_ACTION, { params })
    .then(data => data.data);
};

export const useGetAction = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetActionResponse, DataGetActionParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
