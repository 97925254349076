import React, { FC, useMemo, useState } from 'react';
import { Modifier } from './types';
import { getModifierTokenDescription } from './utils';
import { ReactComponent as CollapseIcon } from 'assets/svg/collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/svg/expand.svg';
import css from './ModifierDescription.module.css';
import { Collapse } from 'shared/layout/Collapse';
import { Block } from 'shared/layout/Block';
import { Select, SelectOption } from 'shared/form/Select';

interface ModifierDescriptionProps {
  modifier?: Modifier;
  isModifier?: boolean;
  selectedTokenType?: string;
  selectedTokenSubType?: string;
  onTokenTypeChange?: (type: string) => void;
  onTokenSubTypeChange?: (type: string) => void;
}

export const ModifierDescription: FC<ModifierDescriptionProps> = ({
  modifier,
  isModifier,
  selectedTokenType,
  selectedTokenSubType,
  onTokenTypeChange,
  onTokenSubTypeChange
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const tokenDescription = useMemo(
    () => getModifierTokenDescription(modifier, isModifier),
    [modifier, isModifier]
  );

  return isModifier ? (
    <p className={css.description}>{tokenDescription}</p>
  ) : (
    <div>
      <p className={css.description}>{tokenDescription}</p>
      <br />
      <div className={css.typeContainer}>
        <div className={css.header} onClick={() => setIsExpanded(!isExpanded)}>
          <span className={css.label}>Incorrect Token Type?</span>
          {isExpanded && <CollapseIcon className={css.arrowIcon} />}
          {!isExpanded && <ExpandIcon className={css.arrowIcon} />}
        </div>
        <Collapse className={css.listemItemItems} expanded={isExpanded}>
          <p className={css.description}>
            Occasionally, the token's identified type is incorrect. In this
            instance, choose the proper token type which will update the
            available modifiers and test input.
          </p>
          <br />
          <div className={css.selection}>
            <Block label="Token Type">
              <Select
                className={css.input}
                value={selectedTokenType}
                onChange={v => {
                  if (v !== selectedTokenType) {
                    onTokenTypeChange(v);
                  }
                }}
              >
                <SelectOption value="string">String</SelectOption>
                <SelectOption value="number">Number</SelectOption>
                <SelectOption value="datetime">Date</SelectOption>
                <SelectOption value="array">Array</SelectOption>
                <SelectOption value="object">Object</SelectOption>
              </Select>
            </Block>
            {selectedTokenType === 'array' && (
              <Block label="Subtoken Type">
                <Select
                  className={css.input}
                  value={selectedTokenSubType}
                  onChange={v => {
                    if (v !== selectedTokenSubType) {
                      onTokenSubTypeChange(v);
                    }
                  }}
                >
                  <SelectOption value="string">String</SelectOption>
                  <SelectOption value="number">Number</SelectOption>
                  <SelectOption value="datetime">Date</SelectOption>
                  <SelectOption value="array">Array</SelectOption>
                  <SelectOption value="object">Object</SelectOption>
                </Select>
              </Block>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
