import React, {
  FC,
  useState,
  useCallback,
  forwardRef,
  Ref,
  useImperativeHandle,
  Fragment
} from 'react';
import { KeyValue, KeyValueItemProps, KeyValueData } from './KeyValue';
import { List } from 'shared/layout/List';
import { Button } from 'shared/elements/Button';
import { useUpdateEffect } from 'react-use';
import { strategies } from './utils';
import { Datastore, Flow } from 'core/types/API';
import css from './KeyValues.module.css';

export type KeyValuesProps = {
  value: any[] | object;
  stores?: Datastore[];
  flows?: Flow[];
  showAddButton?: boolean;
  itemCommands?: (data: KeyValueData) => React.ReactNode;
  addPosition?: 'top' | 'bottom';
  strategy?: { serialize: any; deseralize: any };
  onChange?: (data: any) => void;
} & KeyValueItemProps;

export type KeyValuesRef = {
  showNewVisible: boolean;
  setShowNew: (val: boolean) => void;
};

export const KeyValues: FC<KeyValuesProps & { ref?: Ref<KeyValuesRef> }> =
  forwardRef(
    (
      {
        valueOptions,
        value,
        disabled = false,
        showHeader = false,
        showAddButton = true,
        addPosition = 'bottom',
        keyOptions = [],
        itemCommands,
        allowFlowTrigger = true,
        allowExpressions = true,
        strategy = strategies.object,
        onChange = () => undefined,
        ...rest
      },
      ref: Ref<KeyValuesRef>
    ) => {
      const [showNew, setShowNew] = useState<boolean>(false);
      const [internalValues, setInternalValues] = useState<KeyValueData[]>(
        strategy.deseralize(value)
      );
      const shouldShowHeader = showHeader && internalValues.length === 0;

      useImperativeHandle(ref, () => ({
        setShowNew,
        showNewVisible: showNew
      }));

      useUpdateEffect(() => {
        setInternalValues(strategy.deseralize(value));
      }, [value, strategy]);

      const onValueChange = useCallback(
        (changeValue: KeyValueData) => {
          const clone = [...internalValues];
          const cloneIndex = clone.findIndex(
            v => v.index === changeValue.index
          );
          clone[cloneIndex] = { ...changeValue };
          setInternalValues(clone);
          onChange(strategy.serialize(clone));
        },
        [internalValues, onChange, strategy]
      );

      const onDeleteValue = useCallback(
        (deleteValue: KeyValueData) => {
          const clone = [...internalValues].filter(
            v => v.index !== deleteValue.index
          );
          setInternalValues(clone);
          onChange(strategy.serialize(clone));
        },
        [internalValues, onChange, strategy]
      );

      const onNewChange = useCallback(
        (changeValue: KeyValueData) => {
          if (changeValue.key && changeValue.value) {
            const newValues =
              addPosition === 'top'
                ? [changeValue, ...internalValues]
                : [...internalValues, changeValue];

            setInternalValues(newValues);
            onChange(strategy.serialize(newValues));
            setShowNew(false);
          }
        },
        [addPosition, internalValues, onChange, strategy]
      );

      const renderNewBlock = () => (
        <Fragment>
          {showNew && (
            <KeyValue
              {...rest}
              key="new"
              data={{ key: '', value: '', index: internalValues.length }}
              keyOptions={keyOptions}
              valueOptions={valueOptions}
              disabled={disabled}
              allowFlowTrigger={allowFlowTrigger}
              allowExpressions={allowExpressions}
              showHeader={shouldShowHeader}
              commands={itemCommands}
              onDelete={() => setShowNew(false)}
              onChange={onNewChange}
            />
          )}
        </Fragment>
      );

      const renderNewButton = () => (
        <Fragment>
          {!disabled && showAddButton && (
            <Button
              className={css.addBtn}
              variant="outline"
              fullWidth
              disableMargins
              disabled={showNew}
              onClick={() => setShowNew(true)}
            >
              Add Value
            </Button>
          )}
        </Fragment>
      );

      return (
        <div className={css.container}>
          {addPosition === 'top' && renderNewButton()}
          <List>
            {addPosition === 'top' && renderNewBlock()}
            {internalValues?.map(kv => (
              <KeyValue
                {...rest}
                key={`kv-${kv.id || kv.key}`}
                data={kv}
                keyOptions={keyOptions}
                valueOptions={valueOptions}
                disabled={disabled}
                allowFlowTrigger={allowFlowTrigger}
                allowExpressions={allowExpressions}
                showHeader={shouldShowHeader}
                commands={itemCommands}
                onChange={onValueChange}
                onDelete={onDeleteValue}
              />
            ))}
            {addPosition === 'bottom' && renderNewBlock()}
          </List>
          {addPosition === 'bottom' && renderNewButton()}
        </div>
      );
    }
  );
