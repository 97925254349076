import React, { FC, Fragment, useMemo, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { ConditionValueRule } from '@getcrft/jsonata-ext';
import moment from 'moment';
import css from './ConditionInputTimestampWrapper.module.css';
import capitalize from 'lodash/capitalize';
import { Button } from 'shared/elements/Button';
import { ConditionInputTimestamp } from './ConditionInputTimestamp';
import { Menu } from 'shared/layers/Menu';
import { Token } from 'shared/form/TokenEditor';

export type ConditionDateView = {
  type?: 'last' | 'next' | 'specific' | 'field';
  increment?: number;
  operator?: 'Y' | 'Q' | 'M' | 'w' | 'd' | 'h' | 'm';
};

export type ConditionInputTimestampProps = {
  value: ConditionValueRule;
  disabled?: boolean;
  valueOptions?: any[];
  hasEnumValue: boolean;
  onChange?: (value: ConditionValueRule) => void;
};

export const OperatorTypes = [
  { label: 'Minutes', value: 'm' },
  { label: 'Hours', value: 'h' },
  { label: 'Days', value: 'd' },
  { label: 'Weeks', value: 'w' },
  { label: 'Months', value: 'M' },
  { label: 'Quarters', value: 'Q' },
  { label: 'Years', value: 'Y' }
];

export const ConditionInputTimestampWrapper: FC<
  ConditionInputTimestampProps
> = ({ value, disabled, valueOptions, hasEnumValue, onChange }) => {
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const [innerDisabled, setInnerDisabled] = useState<boolean>(true);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const valueText = useMemo(() => {
    const viewVal = value?.view;

    if (!viewVal) {
      return 'Click to add value';
    } else if (viewVal.type === 'specific') {
      let staticDate;
      if (value.value) {
        const d = moment((value.value as string).replace(/['"]+/g, ''));
        if (d.isValid()) {
          staticDate = d.toDate();
        }
      }

      if (!staticDate) {
        staticDate = new Date();
      }

      return staticDate.toLocaleString();
    } else if (['last', 'next'].includes(viewVal.type)) {
      const operator = OperatorTypes.find(t => t.value === viewVal.operator);

      return `${capitalize(viewVal.type || '--')} ${
        viewVal.increment || '--'
      } ${operator?.label || '--'}`;
    } else {
      if (!value.value) {
        return 'Click to add value';
      }

      return (
        <div>
          {reactStringReplace(
            value.value as string,
            /({{.*?}})/gm,
            (match, i) => {
              const foundToken = valueOptions.find(
                o => o.value === match.replace('{{', '').replace('}}', '')
              );

              if (foundToken) {
                const tokenEl = (
                  <Token
                    key={i}
                    token={foundToken}
                    disabled
                    subtext={foundToken.subtext}
                    allowAnyToken
                    offsetKey="a"
                    allowRemoval={false}
                    onRemove={() => undefined}
                  />
                );

                return tokenEl;
              }

              return <span key={i}>{match}</span>;
            }
          )}
        </div>
      );
    }
  }, [value, valueOptions]);

  return (
    <Fragment>
      <Button
        ref={buttonRef}
        variant="text"
        disabled={disabled}
        onClick={() => {
          setInnerDisabled(true);
          setShowInputs(true);
          // TODO: Figure out why this is needed - without setting the ConditionInputTimestamp as disabled
          // initially, the select field will gain focus, causing its select menu to be open on Popover open
          setTimeout(() => {
            setInnerDisabled(disabled);
          }, 100);
        }}
      >
        {valueText}
      </Button>
      <Menu
        placement="bottom-end"
        visible={showInputs}
        onClose={e => {
          const menuEl = e?.target?.closest('[class*=timestampMenu]');
          const calendarEl = e?.target?.closest('[class*=Calendar]');
          const tokenEl = e?.target?.closest('[class*=tokenMenu]');
          if (!menuEl && !calendarEl && !tokenEl) {
            setShowInputs(false);
          }
        }}
        reference={buttonRef}
      >
        <div className={css.inputs}>
          <ConditionInputTimestamp
            value={value}
            valueOptions={valueOptions}
            disabled={innerDisabled}
            hasEnumValue={hasEnumValue}
            onChange={onChange}
          />
        </div>
      </Menu>
    </Fragment>
  );
};
