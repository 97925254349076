import { Helper } from '.';

export const urlHelpers: Helper[] = [
  {
    name: '$urlProtocol',
    label: 'Protocol',
    description: `Return the protocol of a URL (i.e. - "http").`,
    examples: [`$urlProtocol("https://mozilla.org/") => "https:"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$urlHost',
    label: 'Host',
    description: `Return the hostname and port number of a URL.`,
    examples: [`$urlHost("https://mozilla.org/") => "mozilla.org"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$urlHostname',
    label: 'Hostname',
    description: `Return the hostname of a URL without the port number.`,
    examples: [`$urlHostname("https://mozilla.org/") => "mozilla.org"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$urlPort',
    label: 'Port',
    description: `Return the port number of a URL.`,
    examples: [`$urlPort("https://mozilla:3000/") => "3000"`],
    valueType: 'string',
    returnType: 'number'
  },
  {
    name: '$urlPathname',
    label: 'Pathname',
    description: `Returns the path of a URL (i.e. - "/path/to/page").`,
    examples: [
      `$urlPathname("https://www.localhost:3000/foo/bar") => "/foo/bar"`
    ],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$urlQuery',
    label: 'Query',
    description: `Returns the query string from a URL (i.e. - "?my=query").`,
    examples: [
      `$urlQuery("https://www.localhost:3000/foo/bar?baz=true") => { baz: true }`
    ],
    valueType: 'string',
    returnType: 'object'
  },
  {
    name: '$urlOrigin',
    label: 'Base URL',
    description: `Returns the base portion of a URL (i.e. - "https://flashpoint.io").`,
    examples: [
      `$urlOrigin("https://www.localhost:3000/foo/bar?baz=true") => "https://www.localhost:3000"`
    ],
    valueType: 'string',
    returnType: 'string'
  }
];
