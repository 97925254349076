import React, { FC, useCallback, useRef, useState } from 'react';
import { useUnmount } from 'react-use';
import { Button } from 'shared/elements/Button';
import { Connection } from 'core/types/API';
import { useNotification } from 'shared/layers/Notification';
import { useDataTestConnection } from 'shared/hooks/api';

type OAuthInputContainerProps = {
  url: string;
  connection: Connection;
  onChange: (result: any) => void;
};

const BASE_URL = `${window.location.origin}${process.env.PUBLIC_URL}`;
const WINDOW_PARAMS = `scrollbars=no,resizable=yes,status=no,titlebar=no,location=no,toolbar=no,menubar=no,width=500,height=700`;

export const OAuthInputContainer: FC<OAuthInputContainerProps> = ({
  url,
  connection,
  onChange
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { addSuccess, addError } = useNotification();
  // TODO: We don't support oauth currently so this is
  // just a placeholder action so I can remove the apollo intance
  const { mutateAsync: authorizeConnectionMutation } = useDataTestConnection();

  const popupRef = useRef<any>(null);
  const formattedUrl = url.replace(
    'redirect_uri=%2Foauth2%2Fcallback',
    `redirect_uri=${encodeURIComponent(BASE_URL)}%2Foauth2%2Fcallback`
  );

  const receiveMessage = useCallback(
    async (event: MessageEvent) => {
      if (event.data?.source === 'oauth') {
        window.removeEventListener('message', receiveMessage, false);
        popupRef.current?.close();

        // TODO: Make more generic
        if (event.data?.payload) {
          try {
            // const result = await authorizeConnectionMutation({
            //   input: JSON.stringify(event.data.payload),
            //   connectionId: connection.id,
            //   packageConnectionId: connection.packageConnectionId
            // });
            // @ts-ignore
            const result = await authorizeConnectionMutation();

            if (result.errors?.length) {
              throw new Error(result.errors.map(e => e.message).join(','));
            }

            if (!result.output) {
              throw new Error(`Failed to get authorization data`);
            }

            const parsedOutput = JSON.parse(result.output);
            onChange(parsedOutput);
            addSuccess(`🎉 Authorization successful`);
          } catch (e) {
            addError(`🤬 Authorization failed`);
            onChange(null);
          }
        } else {
          addError(`🤬 Authorization failed`);
          onChange(null);
        }
      }

      setLoading(false);
    },
    [authorizeConnectionMutation, addError, addSuccess, onChange]
  );

  const onClick = useCallback(() => {
    setLoading(true);
    window.addEventListener('message', receiveMessage, false);
    popupRef.current = window.open(
      formattedUrl,
      'OAuth2 Authorization',
      WINDOW_PARAMS
    );
    popupRef.current.onbeforeunload = () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [formattedUrl, receiveMessage]);

  useUnmount(() => {
    window.removeEventListener('message', receiveMessage, false);
    popupRef.current?.close();
  });

  return (
    <Button
      fullWidth={false}
      color="primary"
      disabled={loading}
      disableMargins
      onClick={onClick}
    >
      Authorize
    </Button>
  );
};
