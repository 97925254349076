import {
  CorePackages,
  DatastoreActions,
  EmailActions,
  SmsActions
} from 'core/Flows/enums';
import { Action, Package } from 'core/types/API';

const CORE_PACKGAES = Object.values(CorePackages);

const ALLOW_CORE_TEST_ACTIONS = [
  EmailActions.Send,
  SmsActions.Send,
  DatastoreActions.Get,
  DatastoreActions.GetList,
  DatastoreActions.FindInList,
  DatastoreActions.FilterList,
  DatastoreActions.DedupeList
];

const IGNORE_PACKAGED_TEST_ACTIONS = ['slack/send-manual-approval'];

export function isActionTestable(pkg: Package, action: Action) {
  if (action?.type === 'trigger' || !pkg) {
    return false;
  }

  if (
    isCorePackage(pkg) &&
    !ALLOW_CORE_TEST_ACTIONS.includes(action.id as any)
  ) {
    return false;
  }

  if (IGNORE_PACKAGED_TEST_ACTIONS.includes(action.id)) {
    return false;
  }

  return true;
}

export function isActionLinkable(pkg: Package) {
  if (!pkg || isCorePackage(pkg)) {
    return false;
  }

  return true;
}

export function isCorePackage(pkg: Package) {
  return pkg && CORE_PACKGAES.includes(pkg.id as any);
}
