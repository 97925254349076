import { axios } from 'core/Axios';
import {
  DataUpdateUploadBody,
  DataUpdateUploadResponse
} from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_UPLOAD;

export const req = async (
  data: DataUpdateUploadBody
): Promise<DataUpdateUploadResponse> => {
  return axios
    .put<DataUpdateUploadResponse>(Route.UPDATE_UPLOAD, data)
    .then(data => data.data);
};

export const useUpdateUpload = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataUpdateUploadResponse,
  DataUpdateUploadBody
> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
