import capitalize from 'lodash/capitalize';
import { ReactComponent as WorkflowIcon } from 'assets/svg/workflow.svg';
import { ReactComponent as LightningIcon } from 'assets/svg/lightning-bolt.svg';
import { ReactComponent as MicrochipIcon } from 'assets/svg/microchip.svg';
import { ReactComponent as ImportIcon } from 'assets/svg/import.svg';
import { ReactComponent as ExportIcon } from 'assets/svg/export.svg';
import { ReactComponent as LoopIcon } from 'assets/svg/loop-white.svg';
import { getTypeIconComponent } from 'shared/internal/TypeIcons';
import { CascaderItem } from 'shared/data/Cascader/types';
import {
  actionToken,
  datastoreToken,
  eventToken,
  expressionToken,
  flowToken,
  nodesToken,
  flowTriggerToken
} from './tokenDefaults';
import { matchType } from '@getcrft/jsonata-ext';

export function buildTree(tokens: CascaderItem[]) {
  const map = tokens.reduce((acc, token) => {
    token.path
      .split('.')
      .reduce((result, val) => (result[val] = result[val] || {}), acc);
    return acc;
  }, {});

  function toTree(o: { [key: string]: any }, parent?: string) {
    return Object.entries(o).map(([k, v]) => {
      const fullPath = `${parent ? parent + '.' : ''}${k}`;
      const token = tokens.find(t => t.path === fullPath);
      const isLoopItem = k === 'item' && fullPath === 'nodes.item';
      const text = isLoopItem ? 'Loop Item' : token?.text || capitalize(k);
      const description = isLoopItem
        ? 'The value of the current loop item'
        : token?.description;
      const icon = isLoopItem ? LoopIcon : token?.icon;

      const r = {
        ...token,
        text,
        description,
        id: k,
        icon,
        value: fullPath.replace(/^nodes\.|^store\./gm, ''),
        path: fullPath,
        parent,
        selectable: token?.selectable || isLoopItem,
        items: toTree(v, fullPath)
      };

      !r.items.length && delete r.items;

      return r as CascaderItem;
    });
  }

  return toTree(map) as CascaderItem[];
}

export function buildTokenTree(
  tokens: any[],
  { allowFlowTrigger = true, allowExpressions = true, ...rest }
) {
  const toMap = [...tokens];

  if (allowFlowTrigger) {
    toMap.push(flowTriggerToken);
  }

  if (allowExpressions) {
    toMap.push({
      ...expressionToken,
      onClick: rest?.onInsertExpression
    });
  }

  if (tokens.filter(t => t.type === 'input' || t.type === 'output').length) {
    toMap.push(nodesToken);
  }

  if (tokens.filter(t => t.group === 'Action').length) {
    toMap.push(actionToken);
  }

  if (tokens.filter(t => t.group === 'Datastore').length) {
    toMap.push(datastoreToken);
  }

  if (tokens.filter(t => t.group === 'Workflow').length) {
    toMap.push(flowToken);
  }

  if (tokens.filter(t => t.group === 'Event').length) {
    toMap.push(eventToken);
  }

  const transformed = transformTokens(toMap);

  return buildTree(transformed);
}

export function getValueTypeForExample(token: CascaderItem) {
  let valueType = token.valueType;

  if (token.schema?.examples?.length) {
    const [example] = token.schema.examples;
    const matches = matchType(example);
    if (matches?.length) {
      valueType = matches[0];
    }
  }

  return valueType;
}

export function transformTokens(tokens: CascaderItem[]) {
  const result = [...tokens] as any[];

  for (const token of tokens) {
    const node = token?.node;

    if (node) {
      const isInput = token.type === 'input';
      const isOutput = token.type === 'output';

      if (isInput || isOutput) {
        const hasNode = result.find(t => t.path === `nodes.${node.id}`);
        if (!hasNode) {
          result.push({
            text: node.label,
            value: node.id,
            path: `nodes.${node.id}`,
            type: `node`,
            icon: token.icon,
            node,
            selectable: true,
            // Attach action description
            description: node.action?.description
          });
        }

        if (isInput) {
          const hasInput = result.find(t => t.value === `${node.id}.input`);
          if (!hasInput) {
            result.push({
              text: 'Input',
              value: `${node.id}.input`,
              type: `input`,
              icon: ImportIcon,
              selectable: true,
              node
            });
          }
        }

        if (isOutput) {
          const hasOutput = result.find(t => t.value === `${node.id}.output`);
          if (!hasOutput) {
            result.push({
              text: 'Output',
              value: `${node.id}.output`,
              type: `output`,
              icon: ExportIcon,
              selectable: true,
              node
            });
          }
        }
      }
    }
  }

  return result.map(t => {
    if (t.type === 'store') {
      const valueType = getValueTypeForExample(t);
      return {
        ...t,
        path: `store.${t.value}`,
        selectable: true,
        icon: valueType ? getTypeIconComponent(valueType) : t.icon
      } as CascaderItem;
    } else if (t.type === 'output' || t.type === 'input') {
      const valueType = getValueTypeForExample(t);
      return {
        ...t,
        description: t?.schema?.description || t.description,
        icon: valueType ? getTypeIconComponent(valueType, t.subType) : t.icon,
        path: `nodes.${t.value}`,
        selectable: true,
        valueType
      } as CascaderItem;
    } else if (t.type === 'flow') {
      return {
        ...t,
        path: t.value,
        selectable: true,
        icon: t.valueType ? getTypeIconComponent(t.valueType) : WorkflowIcon
      } as CascaderItem;
    } else if (t.type === 'event') {
      return {
        ...t,
        path: t.value,
        selectable: true,
        icon: t.valueType ? getTypeIconComponent(t.valueType) : LightningIcon
      } as CascaderItem;
    } else if (t.type === 'action') {
      return {
        ...t,
        path: t.value,
        selectable: t.value !== 'action',
        icon: t.valueType ? getTypeIconComponent(t.valueType) : MicrochipIcon
      } as CascaderItem;
    } else if (t.type === 'loop') {
      return {
        ...t,
        selectable: true,
        value: t.value,
        path: `nodes.${t.value}`
      } as CascaderItem;
    } else if (t.value?.startsWith('item.')) {
      const valueType = getValueTypeForExample(t);
      return {
        ...t,
        icon: valueType ? getTypeIconComponent(valueType) : t.icon,
        selectable: true,
        path: `nodes.${t.value}`
      } as CascaderItem;
    } else if (t.value === 'item') {
      return {
        ...t,
        icon: LoopIcon,
        selectable: true,
        path: `nodes.${t.value}`
      } as CascaderItem;
    }

    return {
      ...t,
      selectable: t.selectable != null ? t.selectable : true,
      path: t.path || t.value
    } as CascaderItem;
  });
}
