import { ContentBlock, ContentState } from 'draft-js';
import { compileTemplate } from '@getcrft/mutton';

type StrategyCallback = (start: number, end: number) => void;

interface RegexMatch {
  start: number;
  end: number;
}

export const findWithRegex = (block: ContentBlock) => {
  let text = block.getText();
  const ranges: RegexMatch[] = [];

  if (text) {
    const compiled = compileTemplate(text);
    for (const node of compiled.nodes) {
      if (node.type === 'expression') {
        ranges.push({
          start: node.start,
          end: node.end + 1
        });
      }
    }
  }

  return ranges;
};

export const tokenStrategy = (
  contentBlock: ContentBlock,
  callback: StrategyCallback,
  contentState: ContentState
) =>
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'TOKEN'
    );
  }, callback);
