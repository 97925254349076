import React, { FC } from 'react';
import { Drawer as LayersDrawer, DrawerProps } from 'realayers';
import css from './Drawer.module.css';

export const Drawer: FC<Partial<DrawerProps>> = ({ header, ...rest }) => (
  <LayersDrawer
    {...rest}
    header={typeof header === 'string' ? <h3>{header}</h3> : header}
    className={css.drawer}
  />
);
