import React, { FC, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useUnmount } from 'react-use';
import css from './HoldConfirmDialog.module.css';

const rad = 50 - 1 / 2;
const circumference = Math.PI * (rad * 2);

type HoldButtonProps = {
  onConfirm: () => void;
};

export const HoldButton: FC<HoldButtonProps> = ({ onConfirm }) => {
  const timeout = useRef(null);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  useUnmount(() => {
    clearTimeout(timeout.current);
  });

  function cancelTap() {
    clearTimeout(timeout.current);
    if (!finished) {
      setStarted(false);
    }
  }

  return (
    <motion.div
      className={css.confirmButton}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <svg className={css.svgBtn} width="110" height="110">
        <circle className={css.circle1} cx="50" cy="50" r="49" />
        <motion.circle
          className={css.circle2}
          cx="50"
          cy="50"
          r="49"
          animate={started ? 'finish' : 'start'}
          initial={{ strokeDasharray: `0, ${circumference}` }}
          variants={{
            start: { strokeDasharray: `0, ${circumference}` },
            finish: { strokeDasharray: `${circumference}, 0` }
          }}
          onTapStart={() => {
            setStarted(true);
            timeout.current = setTimeout(() => {
              onConfirm();
              setFinished(true);
            }, 1800);
          }}
          onTapCancel={cancelTap}
          onMouseLeave={cancelTap}
          onMouseUp={cancelTap}
          transition={{ duration: 2 }}
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width="42px"
        height="42px"
        className={css.svgCheck}
      >
        <motion.path
          animate={started ? 'finish' : 'start'}
          initial={{ fill: 'var(--color-global-colors-neutral-0)' }}
          variants={{
            start: { fill: 'var(--color-global-colors-neutral-0)' },
            finish: { fill: 'var(--color-borders-border-interactive-selected)' }
          }}
          transition={{ duration: 3 }}
          d="M 14.5 2.792969 L 5.5 11.792969 L 1.851563 8.148438 L 1.5 7.792969 L 0.792969 8.5 L 1.148438 8.851563 L 5.5 13.207031 L 15.207031 3.5 Z"
        />
      </svg>
    </motion.div>
  );
};
