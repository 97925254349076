import { Modifier } from '../types';

const ESCAPE_CHAR = '\\';

export const modifierSerializer = (modifier: Modifier) => {
  const getParams = (params: (string | number)[]): string =>
    params?.length
      ? params
          .map(item => {
            // Unterminated quotes break mutton - hello" or /"/ will fail even if the context makes sense
            // Escape any quotes that are within a regex or if the string contains one or three instances of a quote
            if (isNaN(+item)) {
              let stringItem = item.toString();
              let isRegex = stringItem.match(/\/.*\//g);

              if (isRegex) {
                // JSONata only allows i and m regex flags, need to remove any extras
                const split = stringItem.split('/');
                const flags = split[split.length - 1];
                const safeOptions = flags.replace(/[^im]/g, '');
                split[split.length - 1] = safeOptions;

                stringItem = split.join('/');
                stringItem = stringItem
                  .replace(/\\([\s\S])|(")/g, '\\$1$2') // Escape double quotes that are not already escaped
                  .replace(/\\([\s\S])|(')/g, '\\$1$2'); // Escape single quotes that are not already escaped
              } else {
                // Determine how many unescaped quotes there are within the string
                const doubleQuotePositions = [...stringItem.matchAll(/"/g)].map(
                  a => a.index
                );
                const singleQuotePositions = [...stringItem.matchAll(/'/g)].map(
                  a => a.index
                );

                if (doubleQuotePositions.length === 1) {
                  const pos = doubleQuotePositions[0];
                  const prevChar = pos > 0 ? stringItem[pos - 1] : null;
                  if (prevChar !== ESCAPE_CHAR) {
                    stringItem = [
                      stringItem.slice(0, pos),
                      ESCAPE_CHAR,
                      stringItem.slice(pos)
                    ].join('');
                  }
                } else if (doubleQuotePositions.length === 3) {
                  const pos = doubleQuotePositions[1];
                  const prevChar = pos > 0 ? stringItem[pos - 1] : null;
                  if (prevChar !== ESCAPE_CHAR) {
                    stringItem = [
                      stringItem.slice(0, pos),
                      ESCAPE_CHAR,
                      stringItem.slice(pos)
                    ].join('');
                  }
                }

                if (singleQuotePositions.length === 3) {
                  const pos = singleQuotePositions[1];
                  const prevChar = pos > 0 ? stringItem[pos - 1] : null;
                  if (prevChar !== ESCAPE_CHAR) {
                    stringItem = [
                      stringItem.slice(0, pos),
                      ESCAPE_CHAR,
                      stringItem.slice(pos)
                    ].join('');
                  }
                }
              }

              return `"${stringItem.replace(/\\(r|n)/g, '\\$1')}"`
                .replace(/^"(function[\s\S]*})"$/g, '$1') // Remove quotes around function definitions
                .replace(/^"(\/[\s\S]*\/[im]*)"$/g, '$1') // Remove quotes around regex patterns
                .replace(/^"{{([\s\S]*)}}"$/g, '$1') // Remove quotes and braces around tokens if token is only value
                .replace('{{', '" & ')
                .replace('}}', ' & "'); // Replace token braces with a quote and an & to represent concatenation for tokens within a string
            } else if (
              typeof item !== 'boolean' &&
              isNaN(parseInt(item as string, 10))
            ) {
              return JSON.stringify(item);
            }

            return item;
          })
          .join(', ')
      : '';

  let modifiers: Modifier[] = [modifier];
  if (modifier.additionalModifiers) {
    modifiers.push(...modifier.additionalModifiers);
  }

  modifiers.reverse();

  let returnString = '';
  modifiers.forEach(m => {
    if (m.modifier) {
      returnString += m.applyMap ? `$map(` : `${m.modifier}(`;
    }
  });
  if (modifier.value) {
    // We need to wrap values that cannot be rendered
    returnString += modifier.value;
  }

  modifiers.reverse();

  modifiers.forEach(m => {
    if (m.modifier) {
      if (m.applyMap) {
        returnString += `, function($v) { ${m.modifier}($v`;
      }
      if (m?.params?.length) {
        if (modifier.value) {
          returnString += `, `;
        }
        returnString += `${getParams(m.params)}`;
      }
      if (m.applyMap) {
        returnString += ') }';
      }
      returnString += ')';
    }
  });

  return `{{${returnString}}}`;
};
