import React, {
  FC,
  Suspense,
  lazy,
  useCallback,
  useRef,
  useState
} from 'react';
import { createRoot } from 'react-dom/client';
import 'core/tracking';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Notifications } from 'shared/layers/Notification';
import {
  Route,
  Routes,
  unstable_HistoryRouter as Router,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { IdleTimerProvider } from 'react-idle-timer';
import { Auth, GuardedRoute, CALLBACK_PATH } from 'core/Auth';
import { oktaAuth } from 'core/Auth/authClient';
import { Offline, Online } from 'react-detect-offline';
import { Theme } from 'shared/utils/Theme';
import { NotFound } from 'App/NotFound';
import { Security, LoginCallback } from '@okta/okta-react';
import { ErrorBoundary } from 'shared/utils/ErrorBoundary';
import { Loader } from 'shared/elements/Loader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { toRelativeUrl } from '@okta/okta-auth-js';
import './index.css';
import { ReactQueryProvider } from 'core/ReactQuery';
import Token from 'core/utils/token';
import { IdleDialog } from 'shared/utils/IdleDialog';
import { history } from 'core/utils/history';

const UniversalLogin = lazy(() => import('./App/Login/UniversalLogin'));
const Logout = lazy(() => import('./App/Logout'));
const App = lazy(() => import('./App'));

const Root: FC = () => {
  const [showIdleDialog, setShowIdleDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const isIdle = useRef<boolean>(false);
  const { pathname } = useLocation();

  const onLogout = useCallback(() => {
    Token.del('X-FP-Authorization');
    Token.del('X-FP-IdToken');
    navigate('/logout');
  }, [navigate]);

  const checkAuth = useCallback(() => {
    const xFpAuth = Token.cke('X-FP-Authorization');
    const xFpId = Token.cke('X-FP-IdToken');

    if ((!xFpAuth || !xFpId) && pathname !== '/login') {
      if (isIdle.current) {
        onLogout();
      }
    }

    isIdle.current = false;
  }, [onLogout, pathname]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Flashpoint Automate"
        defaultTitle="Flashpoint Automate"
      />
      <Theme>
        <Notifications>
          <Online polling={false}>
            <Security
              oktaAuth={oktaAuth}
              onAuthRequired={() => navigate('/login')}
              restoreOriginalUri={async (_oktaAuth, originalUri) => {
                navigate(toRelativeUrl(originalUri, window.location.origin), {
                  replace: true
                });
              }}
            >
              <ReactQueryProvider>
                <IdleTimerProvider
                  timeout={1000 * 60 * 30}
                  promptTimeout={1}
                  onIdle={() => {
                    isIdle.current = true;
                  }}
                  onActive={checkAuth}
                  onPrompt={() => {
                    if (pathname !== '/login') {
                      setShowIdleDialog(true);
                    }
                  }}
                >
                  <Auth>
                    <ErrorBoundary>
                      <IdleDialog
                        open={showIdleDialog}
                        onClose={() => setShowIdleDialog(false)}
                        onLogout={onLogout}
                      />
                      <Suspense fallback={<Loader />}>
                        <Routes>
                          <Route
                            path={CALLBACK_PATH}
                            element={<LoginCallback />}
                          />
                          <Route path="/logout" element={<Logout />} />
                          <Route path="/login" element={<UniversalLogin />} />
                          <Route
                            path="/*"
                            element={
                              <GuardedRoute>
                                <App />
                              </GuardedRoute>
                            }
                          />
                          {/* <GuardedRoute path="/" component={App} /> */}
                        </Routes>
                      </Suspense>
                    </ErrorBoundary>
                  </Auth>
                </IdleTimerProvider>
              </ReactQueryProvider>
            </Security>
          </Online>
          <Offline polling={false}>
            <NotFound />
          </Offline>
        </Notifications>
      </Theme>
    </HelmetProvider>
  );
};

const WrappedRoot = withLDProvider({
  clientSideID: process.env.REACT_APP_DARKLY_KEY
})(Root);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <WrappedRoot />
    </Router>
  </React.StrictMode>
);

// Turn on SW for production deploys
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
