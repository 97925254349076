import React, { FC, RefObject, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';
import { ThemeProvider, ThemeType } from './themeContext';
import { isIframe } from '../Module';

const isRefObject = ref =>
  ref !== null && typeof ref === 'object' && ref.hasOwnProperty('current');

type ThemeProps = {
  reference?: RefObject<any> | HTMLElement;
  theme?: ThemeType;
  children?: any;
};

export const Theme: FC<ThemeProps> = ({
  children,
  reference = document.body,
  theme
}) => {
  const [userTheme, setUserTheme] = useLocalStorage<ThemeType>(
    'theme',
    theme || 'light'
  );

  const activeTheme = useMemo(
    // if isIframe is true we will force light theme in iframes
    () =>
      !isIframe && (theme || userTheme) === 'dark' ? darkTheme : lightTheme,
    [theme, userTheme]
  );

  useEffect(() => {
    const element = isRefObject(reference)
      ? (reference as RefObject<any>).current
      : reference;

    for (const prop in activeTheme.variables) {
      element.style.setProperty(prop, activeTheme.variables[prop]);
    }

    for (const prop in activeTheme.colors) {
      for (const k in activeTheme.colors[prop]) {
        element.style.setProperty(
          `--color-global-colors-${prop}-${k}`,
          activeTheme.colors[prop][k]
        );
      }
    }

    for (const prop in activeTheme.components) {
      for (const k in activeTheme.components[prop]) {
        element.style.setProperty(
          `--${prop}-${k}`,
          activeTheme.components[prop][k]
        );
      }
    }
  }, [activeTheme, reference]);

  const values: any = useMemo(
    () => ({
      ...activeTheme,
      setTheme: setUserTheme
    }),
    [activeTheme, setUserTheme]
  );

  return <ThemeProvider value={values}>{children}</ThemeProvider>;
};
