import React, {
  ReactNode,
  FC,
  useState,
  useCallback,
  useMemo,
  useRef
} from 'react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import remove from 'lodash/remove';
import { Tree, TreeProps } from '../Tree';
import { parseJson, stringifyTree, getType } from './utils';
import { NodeEditor } from './NodeEditor';
import { Dialog } from 'shared/layers/Dialog';
import { schemaGenerator } from '@getcrft/jsonizer';
import { useNotification } from 'shared/layers/Notification';
import { useHotkeys } from 'reakeys';
import { JsonTreeNode, JsonTreeNodeData } from './JsonTreeNode';
import css from './JsonTree.module.css';
import { NodeImporter } from './NodeImporter';
import { TokenOption } from 'shared/form/TokenEditor';

export type JsonTreeProps = TreeProps & {
  data: { [key: string]: any };
  type?: string;
  tokens?: TokenOption[];
  stores?: any[];
  flows?: any[];
  allowCopy?: boolean;
  allowTokens?: boolean;
  editable?: boolean;
  showEmpty: boolean;
  showType: 'text' | 'icons' | boolean;
  showExtendedTypes: boolean;
  maskSensitive?: boolean;
  showCount: boolean;
  showAll?: boolean;
  showAllLimit?: number;
  showAllThreshold?: number;
  expandDepth?: number;
  ellipsisText?: boolean;
  ellipsisTextLength?: number;
  pathPrefix?: string;
  nodeRenderer?: (node: ReactNode, editing?: boolean) => ReactNode;
  onUpdateData: (data: { [key: string]: any }) => void;
};

export type Operation = 'add' | 'delete' | 'edit' | 'replace' | 'merge';

export const JsonTree: FC<Partial<JsonTreeProps>> = ({
  editable = false,
  showAll = false,
  showAllLimit = 11,
  showAllThreshold = 5,
  showCount = true,
  showEmpty = true,
  showType = 'icons',
  allowCopy = true,
  allowTokens = false,
  showExtendedTypes = true,
  maskSensitive = false,
  ellipsisText = true,
  ellipsisTextLength = 150,
  pathPrefix,
  expandDepth,
  data,
  tokens = [],
  stores = [],
  flows = [],
  type = 'object',
  className,
  nodeRenderer,
  onUpdateData,
  ...rest
}) => {
  const treeRef = useRef<HTMLDivElement | null>(null);
  const { addSuccess } = useNotification();
  const [currentNode, setCurrentNode] = useState<JsonTreeNodeData>();
  const [importNode, setImportNode] = useState<JsonTreeNodeData>();
  const [showAllAtNode, setShowAllAtNode] = useState<string[]>([]);
  const [internalExpandDepth, setInternalExpandDepth] = useState<number | null>(
    expandDepth
  );
  const [isEditingNode, setIsEditingNode] = useState<boolean>(false);
  const treeData: JsonTreeNodeData[] = useMemo(
    () => [
      parseJson(
        data,
        '',
        {
          showEmpty,
          showExtendedTypes,
          maskSensitive,
          ellipsisText,
          ellipsisTextLength
        },
        undefined,
        undefined
      )
    ],
    [
      data,
      showEmpty,
      showExtendedTypes,
      maskSensitive,
      ellipsisText,
      ellipsisTextLength
    ]
  );

  const copySchema = useCallback(() => {
    const output = schemaGenerator(data);
    copy(JSON.stringify(output, null, 2));
    addSuccess(`Copied the schema to your clipboard`);
  }, [addSuccess, data]);

  useHotkeys([
    {
      name: 'Expand Tree',
      description: 'Expand all nodes in the focused JSON Tree',
      category: 'Data',
      keys: 'mod+e',
      ref: treeRef,
      callback: event => {
        event.preventDefault();
        setInternalExpandDepth(9999);
      }
    },
    {
      name: 'Collapse Tree',
      description: 'Collapse all nodes in the focused JSON Tree',
      category: 'Data',
      keys: 'mod+shift+c',
      ref: treeRef,
      callback: event => {
        event.preventDefault();
        setInternalExpandDepth(0);
      }
    },
    {
      name: 'Copy Schema',
      keys: 'mod+shift+q',
      category: 'Data',
      description: 'Copy the schema of the data object',
      ref: treeRef,
      callback: event => {
        event.preventDefault();
        copySchema();
      }
    }
  ]);

  const updateNode = useCallback(
    (parsedValue: JsonTreeNodeData, op: Operation, parentId?: string) => {
      let clonedTree = [...treeData];
      const updateTree = arr =>
        // eslint-disable-next-line
        arr.reduce((a, cur) => {
          if (a) {
            return a;
          }
          if (parentId) {
            if (cur.id === parentId) {
              if (op === 'delete') {
                cur.data = remove(
                  cur.data,
                  (item: any) => item.id !== parsedValue.id
                );
              } else if (op === 'edit') {
                const curIndex = cur.data.findIndex(
                  item => item.id === (currentNode || parsedValue).id
                );
                // data type may have changed, so double check
                // only check if existing type is not array or object
                const newType = !['array', 'object'].includes(parsedValue.type);
                const updatedValue = {
                  ...parsedValue,
                  type: newType ? getType(parsedValue.data) : parsedValue.type
                };
                cur.data.splice(curIndex, 1, updatedValue);
              }
              return cur;
            }
          } else if (
            cur.id === (currentNode || importNode || parsedValue).id ||
            (!currentNode && !importNode && cur.id === 'root')
          ) {
            if (op === 'add') {
              if (
                cur.type === 'array' &&
                parsedValue.type === 'array' &&
                parsedValue.data.length > 0
              ) {
                cur.data.push(...parsedValue.data);
              } else {
                cur.data.push(parsedValue);
              }
            } else if (op === 'edit') {
              // data type may have changed, so double check
              // only check if existing type is not array or object
              const newType = !['array', 'object'].includes(parsedValue.type);
              cur.data = parsedValue.data;
              cur.type = newType ? getType(parsedValue.data) : parsedValue.type;
            }
            return cur;
          }
          if (Array.isArray(cur.data)) {
            return updateTree(cur.data);
          }
        }, null);

      updateTree(clonedTree);
      const stringifiedCloned = stringifyTree(clonedTree);
      onUpdateData(JSON.parse(stringifiedCloned[0]));
      setCurrentNode(undefined);
      setImportNode(undefined);
    },
    [currentNode, importNode, onUpdateData, treeData]
  );

  const addEditNode = useCallback(
    (newNode: JsonTreeNodeData, curNode: JsonTreeNodeData, op: Operation) => {
      if (op === 'add') {
        updateNode(newNode, op);
      } else if (op === 'edit') {
        updateNode(newNode, op, curNode.parentId);
      }
    },
    [updateNode]
  );

  const deleteNode = useCallback(
    (node: JsonTreeNodeData) => {
      const { parentId } = node;
      if (parentId) {
        updateNode(node, 'delete', parentId);
      } else {
        // TODO: determine how to fix issue where if you delete the root value of a string type
        // and the previous value is displayed until you click in to edit the value. has to do with
        // TokenEditor editorState not updating when value changes (can't use useEffect)
        let defaultValue: any = '';
        if (type === 'array') {
          defaultValue = [];
        } else if (type === 'object') {
          defaultValue = {};
        }
        onUpdateData(defaultValue);
      }
    },
    [onUpdateData, type, updateNode]
  );

  return (
    <div ref={treeRef} tabIndex={-1} className={css.noOutlines}>
      <Tree className={classNames(css.tree, className)} {...rest}>
        {treeData.map((child, index) => (
          <JsonTreeNode
            key={`node-${child.id}`}
            child={child}
            pathPrefix={pathPrefix}
            depth={1}
            nodeRenderer={nodeRenderer}
            showAll={showAll}
            index={index}
            count={treeData.length}
            editable={editable}
            showType={showType}
            showCount={showCount}
            allowCopy={allowCopy}
            allowTokens={allowTokens}
            tokens={tokens}
            stores={stores}
            flows={flows}
            expandDepth={internalExpandDepth}
            showAllAtNode={showAllAtNode}
            showAllLimit={showAllLimit}
            showAllThreshold={showAllThreshold}
            isEditingNode={isEditingNode}
            onEditingNode={setIsEditingNode}
            onAddNode={setCurrentNode}
            onAddToken={(node, token) => {
              const { value } = token;
              const parsedValue = parseJson(
                {
                  '': `{{${value}}}`
                },
                '',
                showEmpty
              );
              addEditNode(parsedValue.data[0], node, 'add');
            }}
            onImportNodes={setImportNode}
            onEditNode={node => {
              updateNode(node, 'edit', node.parentId);
            }}
            onCopySchema={copySchema}
            onExpandDepth={setInternalExpandDepth}
            onDeleteNode={deleteNode}
            onUpdateData={onUpdateData}
            onShowAllAtNode={setShowAllAtNode}
          />
        ))}
      </Tree>
      {onUpdateData && (
        <Dialog
          size="650px"
          open={!!currentNode || !!importNode}
          header={!!currentNode ? 'Add Value' : 'Import Values'}
          onClose={() => {
            setCurrentNode(undefined);
            setImportNode(undefined);
          }}
        >
          {() =>
            !!currentNode ? (
              <NodeEditor
                currentNode={currentNode}
                showEmpty={showEmpty}
                allowTokens={allowTokens}
                tokens={tokens}
                addEditNode={addEditNode}
                onCancel={() => {
                  setCurrentNode(undefined);
                  setImportNode(undefined);
                }}
              />
            ) : (
              <NodeImporter
                currentNode={importNode}
                showEmpty={showEmpty}
                addEditNode={addEditNode}
                onCancel={() => {
                  setCurrentNode(undefined);
                  setImportNode(undefined);
                }}
              />
            )
          }
        </Dialog>
      )}
    </div>
  );
};
