import { axios } from 'core/Axios';
import {
  DataListEventGroupsParams,
  DataListEventGroupsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_EVENT_GROUPS;
export const buildQueryKey = (params?: DataListEventGroupsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListEventGroupsParams
): Promise<DataListEventGroupsResponse> => {
  return axios
    .get<DataListEventGroupsResponse>(Route.LIST_EVENT_GROUPS, { params })
    .then(data => data.data);
};

export const useListEventGroups = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListEventGroupsResponse,
  DataListEventGroupsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
