import React, { FC } from 'react';
import { Breadcrumbs } from 'shared/elements/Breadcrumbs';
import { Button } from 'shared/elements/Button';
import { CascaderItem } from './types';
import { Ellipsis } from 'shared/data/Ellipsis';

type CascaderBreadcrumbsProps = {
  selections: CascaderItem[];
  isSearching?: boolean;
  onHomeClick?: () => void;
  onSelection: (selection: CascaderItem) => void;
};

export const CascaderBreadcrumbs: FC<CascaderBreadcrumbsProps> = ({
  selections,
  isSearching,
  onHomeClick,
  onSelection
}) => {
  const isOverflow = selections.length > 3;
  const crumbs = isOverflow
    ? selections.slice(selections.length - 3, selections.length)
    : selections;

  return (
    <Breadcrumbs>
      <Button variant="text" size="small" disableMargins onClick={onHomeClick}>
        {isOverflow ? '...' : 'Home'}
      </Button>
      {isSearching && (
        <Button variant="text" size="small" disableMargins disabled>
          Search
        </Button>
      )}
      {crumbs.map(token => (
        <Button
          key={token.path}
          variant="text"
          size="small"
          disableMargins
          onClick={() => onSelection(token)}
        >
          <Ellipsis limit={25} expandable={false} text={token.text} />
        </Button>
      ))}
    </Breadcrumbs>
  );
};
