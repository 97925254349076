import { helpers } from 'shared/form/Modifiers/helpers';

const SORT_TEXT = {
  FIELD: '0',
  FUNCTION: '1'
};

export interface Suggestion {
  label?: string;
  detail?: string;
  documentation?: string;
  value: string;
}

const buildField = (suggestion: Suggestion, wrapSuggestion: boolean) => ({
  label: suggestion.label || suggestion.value,
  kind: 3,
  // TODO: The monaco editor import causes issues
  // kind: languages.CompletionItemKind.Field,
  detail: suggestion.detail,
  documentation: suggestion.documentation,
  insertText: !wrapSuggestion ? suggestion.value : `{{ ${suggestion.value} }}`,
  sortText: SORT_TEXT.FIELD
});

const functions = helpers.reduce((acc, cur) => {
  for (const helper of cur.options) {
    if (!helper.invariant) {
      acc.push({
        label: helper.label,
        kind: 1,
        // TODO: The monaco editor import causes issues
        // kind: languages.CompletionItemKind.Function,
        detail: helper.name,
        documentation: helper.description,
        insertText: `${helper.name}()`,
        sortText: SORT_TEXT.FUNCTION
      });
    }
  }

  return acc;
}, []);

export const jsonataHoverProvider = (
  _model,
  _position,
  suggestions: Suggestion[] = []
) => {
  const word = _model.getWordAtPosition(_position);
  let contents = [];
  if (!word) {
    return {
      contents
    };
  }
  const suggestion = suggestions.find(s => s.value === word.word);
  const helper = functions.find(h => h.detail === `$${word.word}`);
  let option;
  if (suggestion && suggestion.documentation) {
    option = suggestion;
  }
  if (helper && helper.documentation) {
    option = helper;
  }
  if (!option) {
    return {
      contents
    };
  }
  return {
    contents: [
      { value: `**${option.label}**` },
      { value: option.documentation }
    ]
  };
};

export const jsonataSuggestionsProvider = (
  _model,
  _position,
  suggestions: Suggestion[] = [],
  addFunctions: boolean = true
) => {
  // Want to wrap suggestions in {{}} if the current cursor position is not between {{}} already
  const lineContent = _model.getLineContent(_position.lineNumber);
  const hasBrackets = lineContent && lineContent.indexOf('{{') !== -1;
  let wrapSuggestion = !hasBrackets;
  if (hasBrackets) {
    // If there are {{ on the current line, need to check if cursor position is between
    // _position.column is indexed at 1 for whatever reason
    const cursorPosition = _position.column - 1;
    const openingBracketPositions = [];
    const closingBracketPositions = [];
    let previousChar = '';
    for (let i = 0; i < lineContent.length; i += 1) {
      if (lineContent[i] === '{' && previousChar === '{') {
        openingBracketPositions.push(i);
      } else if (lineContent[i] === '}' && previousChar === '}') {
        closingBracketPositions.push(i);
      }
      previousChar = lineContent[i];
    }
    let bracketPassedCursor = false;
    // If there are multiple {{ }} on the current line, need to determine the
    // position of the cursor to determine if it is currently between braces
    for (let i = 0; i < openingBracketPositions.length; i += 1) {
      if (wrapSuggestion || bracketPassedCursor) {
        break;
      }
      const openingPosition = openingBracketPositions[i];
      const nextOpeningPosition =
        openingBracketPositions.length > i + 1
          ? openingBracketPositions[i + 1]
          : Infinity;
      const closingPosition = closingBracketPositions[i] || Infinity;
      wrapSuggestion =
        cursorPosition < openingPosition ||
        (cursorPosition > closingPosition &&
          cursorPosition < nextOpeningPosition);
      if (closingPosition >= cursorPosition) {
        bracketPassedCursor = true;
      }
    }
  }
  return {
    suggestions: [
      ...suggestions.map(s => buildField(s, wrapSuggestion)),
      ...(addFunctions
        ? wrapSuggestion
          ? functions.map(f => ({ ...f, insertText: `{{ ${f.insertText} }}` }))
          : functions
        : [])
    ]
  };
};
