import { createContext } from 'react';

export type AuthContextUser = {
  allowed_user: boolean;
  email: string;
  email_user: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  id: string;
  locale: string;
  name: string;
  preferred_username: string;
  seen_welcome: boolean;
  automate_first_login: string;
  sub: string;
  updated_at: number;
  zoneinfo: string;
  login: string;
  picture: string;
};

type AuthContextProps = {
  user: AuthContextUser;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  oktaAuth: any;
  isFp: boolean;
  hasAccess: boolean | null;
};

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  logout: () => undefined,
  isAuthenticated: false,
  isFp: false,
  oktaAuth: undefined,
  hasAccess: null
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;
