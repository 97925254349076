import { CorePackages, WebhookActions } from '../enums';

const webhookExample = `<pre>
<code style="white-space: break-spaces;">
curl --location --request POST '${process.env.REACT_APP_REST_API_URL_OLD}/flow/FLOW_ID/ACTION_ID' \\
--header 'x-api-key: YOUR_API_KEY_HERE' \\
--header 'Content-Type: application/json' \\
--data-raw '{
  "action":"search",
  "filter":"test"
}'
</code></pre>
`;

export const webhookPackage = {
  id: CorePackages.Webhook,
  name: 'Webhook',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `webhook.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: WebhookActions.Trigger,
        name: 'Webhook',
        description: 'Receive data from a webhook call.',
        type: 'trigger',
        input: {
          type: 'object',
          required: [],
          properties: {
            url: {
              type: 'string',
              title: 'URL',
              description:
                'The URL your function should invoke. PAY ATTENTION: add "x-api-key" to QUERY params or HEADERS.',
              examples: [webhookExample],
              default: `${process.env.REACT_APP_REST_API_URL_OLD}/flow/{{flow.id}}/{{action.id}}`,
              attributes: {
                'ui:component': 'template'
              }
            },
            enabled: {
              type: 'boolean',
              title: 'Enabled',
              description: 'Enable or disable the triggering.',
              default: true
            },
            input: {
              type: 'object',
              title: 'Inputs',
              description:
                'Pre-defined inputs for trigger. Useful for defining senders/etc.',
              attributes: {
                'ui:component': 'key-value'
              }
            },
            headers: {
              title: 'Response Headers',
              type: 'object',
              examples: ['x-amz-sns-message-type: true'],
              attributes: {
                'ui:component': 'key-value'
              }
            },
            response: {
              type: 'string',
              title: 'Response Body',
              description: 'The response to return to the invoker.',
              examples: ['ok', `{ "status": 200 }`],
              default: `{ "status": 200 }`,
              attributes: {
                'ui:component': 'code:text'
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of webhook call.'
            },
            data: {
              title: 'Data',
              type: 'object',
              required: ['headers', 'body'],
              properties: {
                headers: {
                  title: 'Request headers',
                  description: 'The headers of the request.',
                  type: 'object',
                  attributes: {
                    'ui:component': 'key-value'
                  }
                },
                body: {
                  title: 'Request Body',
                  description: 'The body of the request.',
                  type: 'string',
                  attributes: {
                    'ui:component': 'json'
                  }
                },
                params: {
                  title: 'Request Parameters',
                  description: 'The HTTP params of the invocation',
                  type: 'object',
                  attributes: {
                    'ui:component': 'key-value'
                  }
                },
                url: {
                  title: 'Request URL',
                  description: 'The base HTTP URL of invoked webhook.',
                  type: 'string'
                },
                uploads: {
                  type: 'array',
                  title: 'Uploads ID List',
                  description: 'List of attachments',
                  items: {
                    type: 'string',
                    title: 'Upload ID',
                    examples: ['a1jc866lwn6x9ta']
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
};
