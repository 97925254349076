import React, {
  forwardRef,
  Ref,
  RefObject,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import { ReactComponent as VisibleIcon } from 'assets/svg/eye-visible.svg';
import { ReactComponent as HiddenIcon } from 'assets/svg/eye-hidden.svg';
import AutosizeInput from 'react-input-autosize';
import css from './Input.module.css';

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'suffix' | 'prefix'
> & {
  fullWidth?: boolean;
  selectOnFocus?: boolean;
  autosize?: boolean;
  error?: boolean;
  containerClassname?: string;
  prefix?: React.ReactNode | string;
  suffix?: React.ReactNode | string;
};

export type InputRef = {
  inputRef: RefObject<HTMLInputElement>;
  containerRef: RefObject<HTMLDivElement>;
  blur?: () => void;
  focus?: () => void;
};

export const Input = forwardRef(
  (
    {
      className,
      containerClassname,
      error,
      type,
      autosize = false,
      fullWidth,
      selectOnFocus,
      prefix,
      suffix,
      onFocus,
      ...rest
    }: InputProps,
    ref: Ref<InputRef>
  ) => {
    const [masked, setMasked] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({
      inputRef,
      containerRef,
      blur: () => inputRef.current?.blur(),
      focus: () => inputRef.current?.focus()
    }));

    const isPasswordField = type === 'password';
    const inputType = type
      ? isPasswordField && !masked
        ? 'text'
        : type
      : 'text';

    const cssClasses = classNames(className, css.input, {
      [css.passwordInput]: isPasswordField,
      'fs-mask': isPasswordField
    });

    return (
      <div
        className={classNames(css.container, containerClassname, {
          [css.passwordField]: isPasswordField,
          [css.fullWidth]: fullWidth,
          [css.error]: error,
          [css.autosize]: autosize
        })}
        ref={containerRef}
      >
        {prefix && <div className={css.prefix}>{prefix}</div>}
        {!autosize && (
          <input
            {...rest}
            ref={inputRef}
            type={inputType}
            className={cssClasses}
            onFocus={event => {
              if (selectOnFocus) {
                event.target.select();
              }
              onFocus?.(event);
            }}
          />
        )}
        {autosize && (
          <AutosizeInput
            {...rest}
            ref={inputRef}
            type={inputType}
            style={{ fontSize: 13 }}
            inputClassName={cssClasses}
            onFocus={event => {
              if (selectOnFocus) {
                event.target.select();
              }
              onFocus?.(event);
            }}
          />
        )}
        {isPasswordField && !masked && (
          <VisibleIcon
            className={css.maskIcon}
            onClick={() => setMasked(!masked)}
            title="Mask text"
          />
        )}
        {isPasswordField && masked && (
          <HiddenIcon
            className={css.maskIcon}
            onClick={() => setMasked(!masked)}
            title="Show text"
          />
        )}
        {suffix && <div className={css.suffix}>{suffix}</div>}
      </div>
    );
  }
);
