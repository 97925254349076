import React, {
  FC,
  useState,
  useRef,
  useMemo,
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  Suspense,
  ReactElement
} from 'react';
import {
  ContentState,
  EditorState,
  EditorProps,
  SelectionState,
  getDefaultKeyBinding,
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  Modifier
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import classNames from 'classnames';
import { useMount } from 'react-use';
import {
  createTokenPlugin,
  addToken,
  TokenPluginConfig,
  convertToText,
  getSelection,
  copyListener,
  TokenClickProps,
  addHelper,
  getTokenSelection,
  processEditorState,
  getInsertRange
} from './plugin';
import { Drawer } from 'shared/layers/Drawer';
import { TokenOption } from './types';
import {
  CloneElement,
  ConnectedOverlay,
  ConnectedOverlayContentRef
} from 'rdk';
import { TokenMenu } from './TokenMenu';
import { useTokenGroups } from './utils/useTokenGroups';
import { Datastore, Flow } from 'core/types/API';
import { useScrollTo } from './utils/useScrollTo';
import { Button, ButtonProps } from 'shared/elements/Button';
import 'draft-js/dist/Draft.css';
import { TokenCascader } from './TokenCascader';
import css from './TokenEditor.module.css';
import { useKeyboardNav } from 'shared/utils/KeyboardNav';

const Modifiers = React.lazy(() => import('../Modifiers'));

export type TokenEditorProps = {
  value: string;
  type?: 'list' | 'cascade';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  autoFocus?: boolean;
  allowModifiers?: boolean;
  allowFlowTrigger?: boolean;
  allowExpressions?: boolean;
  allowGrouping?: boolean;
  shouldTrim?: boolean;
  singleSelection?: boolean;
  buttonSelection?: boolean;
  selectorButton?: ReactElement<ButtonProps, typeof Button>;
  stores?: Datastore[];
  flows?: Flow[];
  menuClassName?: string;
  blurOnEnter?: boolean;
  onBlur?: (event) => void;
  onError?: (errors: TokenOption[]) => void;
  onAddModifier?: (value: string) => void;
  onChange?: (value: string) => void;
  onTokenClick?: (token: TokenClickProps) => void;
} & Partial<Omit<EditorProps, 'onChange'>> &
  Partial<TokenPluginConfig>;

export const TokenEditor: FC<TokenEditorProps> = ({
  value,
  type = 'cascade',
  tokens = [],
  allowAnyToken = true,
  allowFlowTrigger = true,
  allowExpressions = true,
  className,
  autoFocus,
  hasError = false,
  spellCheck = false,
  allowModifiers = true,
  allowGrouping = true,
  shouldTrim = true,
  singleSelection = false,
  buttonSelection = false,
  selectorButton,
  disabled = false,
  stores = [],
  flows = [],
  menuClassName,
  blurOnEnter = false,
  onChange = () => undefined,
  onBlur = () => undefined,
  onError = () => undefined,
  onAddModifier = () => undefined,
  onTokenClick = () => undefined,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<Editor | null>(null);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [activeToken, setActiveToken] = useState<TokenClickProps | null>(null);
  const [modifiersVisible, setModifiersVisible] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number | null>(null);
  const prevValue = useRef<string | null>(null);
  const overlayRef = useRef<ConnectedOverlayContentRef | null>(null);
  const cascaderPanelRef = useRef<HTMLDivElement | null>(null);
  const selectOnClick = singleSelection || buttonSelection;

  const [currentSelection, setCurrentSelection] =
    useState<SelectionState | null>(null);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromText(unescape(value || ''))
    )
  );
  const editorStateRef = useRef<EditorState>(editorState);

  const {
    offset,
    setOffset,
    groups,
    hasExpression,
    searchResult,
    keyword,
    maxOffset,
    search,
    hasGroups,
    expandedStates,
    tokensOffsetMap,
    resetSearch,
    conditionalOffset,
    groupsOffsetMap,
    setExpandedStates
  } = useTokenGroups({ tokens, allowAnyToken });

  useKeyboardNav({
    ref: containerRef,
    items: [],
    onKeyDown: event => {
      if (
        menuVisible &&
        cascaderPanelRef.current &&
        (event.key === 'ArrowUp' || event.key === 'ArrowDown')
      ) {
        (cascaderPanelRef.current.nextSibling as HTMLElement).focus();
      }
    }
  });

  const { scrollToItem } = useScrollTo({
    isSearch: !!keyword,
    tokenRef: tokensOffsetMap?.get(conditionalOffset)?.ref,
    groupRef: groupsOffsetMap?.get(conditionalOffset)?.ref,
    searchTokenRef: searchResult[offset]?.ref
  });

  useEffect(() => {
    const onCopy = event =>
      copyListener(editorStateRef.current, event, shouldTrim);

    const onResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setInputWidth(Math.max(width, 350));
      }
    };

    const editor = editorRef.current;
    if (editor) {
      // Note: Types are not resolving correctly here
      const editorHtml = (editor.getEditorRef() as any).editor;
      editorHtml.addEventListener('copy', onCopy);
      editorHtml.addEventListener('cut', onCopy);
      window.addEventListener('resize', onResize);
    }

    onResize();

    return () => {
      if (editor) {
        // Note: Types are not resolving correctly here
        const editorHtml = (editor.getEditorRef() as any)?.editor;
        if (editorHtml) {
          editorHtml.removeEventListener('copy', onCopy);
          editorHtml.removeEventListener('cut', onCopy);
        }

        window.removeEventListener('resize', onResize);
      }
    };
  }, [shouldTrim]);

  useMount(() => {
    if (editorRef.current && autoFocus) {
      setTimeout(() => {
        editorRef.current.focus();
      }, 200);
    }
  });

  const tokenClicked = useCallback(
    (token: TokenClickProps) => {
      if (allowModifiers) {
        setActiveToken(token);
        setModifiersVisible(true);
      }

      onTokenClick(token);
    },
    [allowModifiers, onTokenClick]
  );

  const onEditorChange = useCallback(
    (updatedState: EditorState) => {
      if (menuVisible) {
        const selectedText = getSelection(updatedState);
        if (selectedText) {
          search(selectedText.trim());
        } else {
          setOffset(-1);
          resetSearch();
        }
      }

      const { result, invalidTokens } = convertToText(updatedState, shouldTrim);
      // Check to see if there is excess whitespace within a token
      const needsCleaning =
        result.match(/({{)\s+(\S*)\s*(}})/gm) ||
        result.match(/({{)\s*(\S*)\s+(}})/gm);

      // Replace any whitespaces surrounding tokens
      const cleanResult = result.replaceAll(/({{)\s*(\S*)\s*(}})/gm, '$1$2$3');

      if (needsCleaning && prevValue.current !== cleanResult) {
        const processedEditorState = handleCleanState(cleanResult);
        setEditorState(processedEditorState);
        editorStateRef.current = processedEditorState;
      } else {
        setEditorState(updatedState);
        editorStateRef.current = updatedState;
      }

      // Only trigger updates on actual changes to the text
      if (prevValue.current !== cleanResult) {
        // The first time around, its null so don't bubble it
        if (prevValue.current !== null) {
          onChange(cleanResult);
        }

        const isInvalid = invalidTokens.length > 0 && !allowAnyToken;
        if (isInvalid) {
          onError(invalidTokens);
        }

        setIsInvalid(isInvalid);
        prevValue.current = cleanResult;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      menuVisible,
      prevValue,
      search,
      setOffset,
      resetSearch,
      allowAnyToken,
      shouldTrim,
      onChange,
      onError
    ]
  );

  const plugins = useMemo(
    () => [
      createTokenPlugin({
        tokens,
        allowAnyToken,
        disabled,
        getEditorState: () => editorStateRef.current,
        setEditorState: onEditorChange,
        onTokenClick: tokenClicked
      })
    ],
    [tokens, allowAnyToken, disabled, onEditorChange, tokenClicked]
  );

  const onTriggerSuggestions = useCallback(
    event => {
      event.stopPropagation();
      setMenuVisible(true);
      setCurrentSelection(editorState.getSelection());
      editorRef.current?.focus();
    },
    [editorState]
  );

  const onResetDropdown = useCallback(() => {
    setOffset(-1);
    setMenuVisible(false);
    resetSearch();
  }, [resetSearch, setOffset]);

  const onSelectToken = useCallback(
    (token: TokenOption) => {
      if (currentSelection) {
        setMenuVisible(false);
        const updatedState = addToken(editorState, token);
        setEditorState(updatedState);
        editorStateRef.current = updatedState;
        const { result } = convertToText(updatedState, shouldTrim);
        onChange(result);
        prevValue.current = result;
      }
    },
    [shouldTrim, currentSelection, editorState, onChange]
  );

  const onInsertExpression = useCallback(() => {
    setCurrentSelection(editorState.getSelection());
    setActiveToken(null);
    setMenuVisible(false);
    setModifiersVisible(true);
  }, [editorState]);

  const onEnterHandler = useCallback(
    ({ onInsertExpression, onResetDropdown, onSelectToken }) => {
      if (offset <= -1) {
        return null;
      }

      if (hasExpression && offset === 0) {
        onInsertExpression();
        onResetDropdown();
      } else if (!keyword && groupsOffsetMap.has(conditionalOffset)) {
        const order = groupsOffsetMap.get(conditionalOffset).order;
        setExpandedStates(expanded => ({
          ...expanded,
          [order]: !expanded[order]
        }));
      } else if (!keyword && tokensOffsetMap.has(conditionalOffset)) {
        onSelectToken(tokensOffsetMap.get(conditionalOffset));
        onResetDropdown();
      } else if (keyword) {
        onSelectToken(searchResult[offset]);
        onResetDropdown();
      } else {
        onResetDropdown();
      }

      return null;
    },
    [
      conditionalOffset,
      groupsOffsetMap,
      hasExpression,
      keyword,
      offset,
      searchResult,
      setExpandedStates,
      tokensOffsetMap
    ]
  );

  const onKeyBinding = useCallback(
    event => {
      if (blurOnEnter && event.key === 'Enter') {
        setFocused(false);
        onBlur(event);
        return null;
      }

      if (tokens.length) {
        if (event.key === ' ' && event.ctrlKey) {
          event.preventDefault();
          onTriggerSuggestions(event);
          return null;
        } else if (menuVisible) {
          if (event.key === 'ArrowUp') {
            event.preventDefault();
            setOffset(Math.max(offset - 1, 0));
            scrollToItem();
            return null;
          } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            setOffset(offset => Math.min(offset + 1, maxOffset));
            scrollToItem();
            return null;
          } else if (event.key === 'Enter') {
            event.preventDefault();
            return onEnterHandler({
              onInsertExpression,
              onResetDropdown,
              onSelectToken
            });
          } else if (event.key === 'Escape') {
            event.preventDefault();
            onResetDropdown();
            return null;
          }
        }
      }

      return getDefaultKeyBinding(event);
    },
    [
      menuVisible,
      offset,
      maxOffset,
      blurOnEnter,
      onBlur,
      onEnterHandler,
      onInsertExpression,
      onResetDropdown,
      onSelectToken,
      onTriggerSuggestions,
      scrollToItem,
      setOffset,
      tokens.length
    ]
  );

  const handleCleanState = (val: string) => {
    // Using a text val that has had any whitespace stripped from between token {{}}
    // create a new state with the new result, removing the excess whitespace
    const decorator = new CompositeDecorator(plugins[0].decorators);

    // Create a new editor state using the clean text
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromText(val)
    );
    // Run it through processing to apply token entities within the state
    const processedEditorState = processEditorState(newEditorState, tokens);
    const processedState = convertToRaw(
      processedEditorState.getCurrentContent()
    );

    return EditorState.createWithContent(
      convertFromRaw(processedState),
      decorator
    );
  };

  const addModifier = useCallback(
    (serializedHelper: string) => {
      const selection = !activeToken
        ? currentSelection
        : getTokenSelection(
            activeToken.entityKey,
            activeToken.block,
            activeToken.blockKey
          );

      const newEditorState = addHelper(
        editorState,
        selection,
        tokens,
        serializedHelper
      );

      setModifiersVisible(false);
      setActiveToken(null);

      setEditorState(newEditorState);
      editorStateRef.current = newEditorState;

      const { result } = convertToText(newEditorState, shouldTrim);
      onChange(result);
      onAddModifier(result);
    },
    [
      activeToken,
      tokens,
      editorState,
      currentSelection,
      shouldTrim,
      onChange,
      onAddModifier
    ]
  );

  const onInsertModifier = useCallback(
    (token: TokenOption) => {
      // Add the token to the editor and then fake clicking on it to open modifiers drawer
      onSelectToken(token);

      const curEditorState = editorStateRef.current;
      const curContentState = curEditorState.getCurrentContent();

      // onSelectToken adds the token to the editor, so get the last created entity
      const entityKey = curContentState.getLastCreatedEntityKey();
      const entity = curContentState.getEntity(entityKey);

      // Get block details for the modifier drawer
      const block = curContentState.getLastBlock();
      tokenClicked({
        ...entity.getData(),
        entityKey,
        block,
        blockKey: block.getKey()
      });
    },
    [onSelectToken, tokenClicked]
  );

  const onTokenCascaderHome = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const { start, end, currentContentBlock } = getInsertRange(editorState);
    const selection = new SelectionState({
      anchorKey: currentContentBlock.getKey(),
      anchorOffset: start,
      focusKey: currentContentBlock.getKey(),
      focusOffset: end
    });
    const textWithoutRange = Modifier.removeRange(
      contentState,
      selection,
      'forward'
    );

    const updatedState = EditorState.push(
      editorState,
      textWithoutRange,
      'remove-range'
    );
    onEditorChange(updatedState);
    requestAnimationFrame(() => {
      editorRef.current?.focus();
    });
  }, [editorState, onEditorChange]);

  useLayoutEffect(() => {
    // Expanding when flipped could require a position update
    requestAnimationFrame(() => {
      overlayRef.current?.updatePosition();
    });
  }, [expandedStates, inputWidth]);

  useEffect(() => {
    const { result } = convertToText(editorState, shouldTrim);
    const processedEditorState = handleCleanState(result);
    setEditorState(processedEditorState);
    editorStateRef.current = processedEditorState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    const { result } = convertToText(editorState, shouldTrim);
    if (value !== result && (result === '' || value === '')) {
      const processedEditorState = handleCleanState(`${value}`);
      setEditorState(processedEditorState);
      editorStateRef.current = processedEditorState;
      onEditorChange(processedEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={classNames(css.editor, className, 'mousetrap', {
        [css.focused]: focused,
        [css.disabled]: disabled,
        [css.error]: isInvalid || hasError,
        [css.buttonSelection]: buttonSelection
      })}
      ref={containerRef}
    >
      <div
        className={css.draftEditor}
        onClick={e => {
          if (selectOnClick && !value) {
            onTriggerSuggestions(e);
          }
        }}
      >
        <Editor
          {...rest}
          readOnly={disabled || selectOnClick}
          spellCheck={spellCheck}
          ref={editorRef}
          editorState={editorState}
          onFocus={() => setFocused(true)}
          onBlur={event => {
            setFocused(false);
            onBlur(event);
          }}
          stripPastedStyles={true}
          keyBindingFn={onKeyBinding}
          onChange={onEditorChange}
          plugins={plugins}
        />
      </div>
      {(tokens?.length > 0 || allowFlowTrigger || allowExpressions) &&
        !disabled &&
        (!value || (value && !selectOnClick)) && (
          <Fragment>
            <div className={css.menuButtonContainer}>
              {selectorButton ? (
                <CloneElement<ButtonProps>
                  element={selectorButton}
                  children={selectorButton.props.children}
                  onClick={onTriggerSuggestions}
                />
              ) : (
                <Button
                  color="primary"
                  disableMargins
                  disablePadding
                  size="small"
                  title="Insert token"
                  className={css.showMenu}
                  onClick={onTriggerSuggestions}
                >
                  <PlusIcon className={css.addIcon} />
                </Button>
              )}
            </div>
            <ConnectedOverlay
              placement={type === 'list' ? 'bottom-start' : 'bottom'}
              open={menuVisible}
              reference={containerRef}
              ref={overlayRef}
              closeOnEscape={true}
              content={() =>
                type === 'list' ? (
                  <TokenMenu
                    style={{ width: `${inputWidth}px` }}
                    allowGrouping={allowGrouping}
                    tokens={searchResult}
                    offset={offset}
                    keyword={keyword}
                    groups={groups}
                    hasGroups={hasGroups}
                    expandedGroups={expandedStates}
                    onExpandGroup={setExpandedStates}
                    allowModifiers={allowModifiers}
                    allowExpressions={allowExpressions}
                    onSelectToken={onSelectToken}
                    onInsertExpression={onInsertExpression}
                  />
                ) : (
                  <TokenCascader
                    tokens={searchResult}
                    keyword={keyword}
                    className={menuClassName}
                    onHome={onTokenCascaderHome}
                    style={{
                      minWidth: `${inputWidth}px`,
                      width: inputWidth > 400 ? `${inputWidth}px` : `450px`,
                      height: '250px'
                    }}
                    allowModifiers={allowModifiers}
                    allowFlowTrigger={allowFlowTrigger}
                    allowExpressions={allowExpressions}
                    supplementalData={{
                      stores,
                      flows
                    }}
                    panelRef={cascaderPanelRef}
                    onSelectToken={onSelectToken}
                    onInsertExpression={onInsertExpression}
                    onInsertModifier={onInsertModifier}
                  />
                )
              }
              onClose={onResetDropdown}
            />
          </Fragment>
        )}
      {allowModifiers && (
        <Drawer
          size="900px"
          open={modifiersVisible}
          header="Modifiers"
          onClose={() => setModifiersVisible(false)}
        >
          {() => (
            <Suspense fallback={null}>
              <Modifiers
                token={activeToken}
                tokens={tokens}
                onClose={() => setModifiersVisible(false)}
                onAddModifier={addModifier}
              />
            </Suspense>
          )}
        </Drawer>
      )}
    </div>
  );
};
