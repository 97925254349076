import { FC, ReactNode } from 'react';

export interface SelectOptionProps {
  value: any;
  children?: ReactNode | string;
  inputLabel?: any;
  group?: string;
  menuLabel?: any;
  inputPrefix?: any;
  selected?: boolean;
  disabled?: boolean;
}

export const SelectOption: FC<SelectOptionProps> = ({ children }) =>
  children as any;
