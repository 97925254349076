import classNames from 'classnames';
import React, { FC } from 'react';
import ReactAvatar, { ReactAvatarProps, Cache } from 'react-avatar';
import css from './Avatar.module.css';

export type AvatarProps = ReactAvatarProps & {
  user?: any;
};

export const avatarCache = new Cache({
  sourceTTL: 1 * 24 * 3600 * 1000,
  sourceSize: 50
});

export const Avatar: FC<AvatarProps> = ({
  user,
  className,
  round = true,
  size = '20px',
  maxInitials = 2,
  textSizeRatio = 2,
  ...rest
}) => {
  if (user) {
    if (user.picture) {
      rest.src = user.picture;
    } else if (user.image) {
      rest.src = user.image;
    } else {
      rest.name = user.name || user.email;
    }
  }

  return (
    <ReactAvatar
      {...rest}
      className={classNames(css.avatar, className)}
      textSizeRatio={textSizeRatio}
      size={size}
      round={round}
      maxInitials={maxInitials}
    />
  );
};
