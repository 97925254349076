import { axios } from 'core/Axios';
import {
  DataUpdateUserBody,
  DataUpdateUserResponse
} from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_USER;

export const req = async (
  data: DataUpdateUserBody
): Promise<DataUpdateUserResponse> => {
  return axios
    .patch<DataUpdateUserResponse>(Route.UPDATE_USER, data)
    .then(data => data.data);
};

export const useUpdateUser = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataUpdateUserResponse, DataUpdateUserBody> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
