import { Helper } from '.';

export const geoHelpers: Helper[] = [
  {
    name: '$isLatLong',
    label: 'Valid Lat Long',
    description: 'Checks if the passed value is valid latitude and longitude.',
    examples: [`$isLatLong("41.977226, -87.836723") => true`],
    valueType: 'string',
    returnType: 'boolean'
  },
  {
    name: '$latLongDistance',
    invariant: true,
    label: 'Lat Long Distance',
    description: 'Gets the distance between two points in various formats.',
    examples: [
      `$latLongDistance("41.977226, -87.836723", "40.730610, -73.935242", "mi") => 3829598.9810101157`
    ],
    returnType: 'number',
    params: {
      type: 'object',
      required: ['from', 'to', 'format'],
      properties: {
        from: {
          type: 'string',
          title: 'From',
          description: 'Lat long of the from location.',
          examples: ['41.977226, -87.836723'],
          attributes: {
            'ui:order': 0
          }
        },
        to: {
          type: 'string',
          title: 'To',
          description: 'Lat long of the to location.',
          examples: ['40.730610, -73.935242'],
          attributes: {
            'ui:order': 1
          }
        },
        format: {
          type: 'array',
          title: 'Unit of Time',
          default: 'miles',
          items: {
            type: 'string',
            enum: [
              'kilometers',
              'meters',
              'centimeters',
              'miles',
              'feet',
              'inches',
              'yards'
            ]
          },
          attributes: {
            'ui:order': 2
          }
        }
      }
    }
  }
];
