import moment from 'moment';

export type IntervalTypes =
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';

export type CronObject = {
  interval?: IntervalTypes;
  daysOfWeek?: number[];
  dayOfMonth?: number;
  timeOfDay?: any;
  month?: number;
  minute?: number;
  hour?: number;
};

/**
 * Given a CRON expression, build a CRON object.
 */
export const buildCronObject = (value: string, local: boolean) => {
  const result: CronObject = {};

  if (value) {
    const [minute, hour, day, month, dayOfTheWeek] = value.split(' ');
    const hasHour = hour !== '*' && !hour.startsWith('0/');
    const hasHourInterval = hour.startsWith('0/');
    const hasMinute = minute !== '*';
    const hasMonth = month !== '*';
    const hasDayOfTheMonth = day !== '*';
    const hasDayOfTheWeek = dayOfTheWeek !== '?' && dayOfTheWeek !== '';
    // Some timezones include minute differences
    const localMinute =
      local && hasMinute && !minute.includes(',')
        ? moment().utc().set('minute', Number(minute)).local().get('minute')
        : '';
    const localHour =
      local && hasHour && !hour.includes(',')
        ? moment().utc().set('hour', Number(hour)).local().get('hour')
        : '';
    // Need to allow for comma separated minutes and hours
    // Moment's get function returns a number, meaning we need to manually add leading zeros
    const timeOfDayHour =
      localHour !== '' ? (localHour < 10 ? `0${localHour}` : localHour) : hour;
    const timeOfDayMinutes =
      localMinute !== ''
        ? localMinute < 10
          ? `0${localMinute}`
          : localMinute
        : Number(minute) < 10
        ? `0${minute}`
        : minute;
    const timeOfDay = `${timeOfDayHour}:${timeOfDayMinutes}`;

    if (hasHour && hasMinute && hasDayOfTheMonth && hasMonth) {
      result.interval = 'year';
      result.month = parseInt(month);
      result.timeOfDay = timeOfDay;
      result.dayOfMonth = parseInt(day);
    } else if (hasHour && hasMinute && hasDayOfTheMonth && !hasDayOfTheWeek) {
      result.interval = 'month';
      result.timeOfDay = timeOfDay;
      result.dayOfMonth = parseInt(day);
    } else if (hasHour && hasMinute && hasDayOfTheWeek) {
      result.interval = 'day';
      result.timeOfDay = timeOfDay;
      result.daysOfWeek = dayOfTheWeek.split(',').map(d => parseInt(d));
    } else if (hasHour && hasMinute) {
      result.interval = 'day';
      result.timeOfDay = timeOfDay;
    } else if (hasHourInterval && hasMinute) {
      result.interval = 'hour';
      const [, hourInterval] = value.split('/');
      result.hour = parseInt(hourInterval);
      result.minute = parseInt(minute);
    } else if (minute) {
      result.interval = 'minute';
      const [, minuteInterval] = value.split('/');
      result.minute = parseInt(minuteInterval);
    }
  }

  return result;
};

/**
 * Given a CRON object, build a expression.
 */
export const buildCronExpression = (
  {
    interval,
    dayOfMonth,
    daysOfWeek,
    hour,
    minute,
    month,
    timeOfDay
  }: CronObject,
  local: boolean
) => {
  let formattedTimeOfDay;
  if (timeOfDay) {
    // Allow for comma separated minutes and hours
    const [hourOfDay, minuteOfHour] = timeOfDay.split(':');
    // Some timezones include minute differences
    let formattedMinute;
    let formattedHour;
    if (!minuteOfHour.includes(',')) {
      formattedMinute = local
        ? moment().set('minute', Number(minuteOfHour)).utc().get('minute')
        : Number(minuteOfHour);
    } else {
      formattedMinute = minuteOfHour;
    }
    if (!hourOfDay.includes(',')) {
      formattedHour = local
        ? moment().set('hour', Number(hourOfDay)).utc().get('hour')
        : Number(hourOfDay);
    } else {
      formattedHour = hourOfDay;
    }
    formattedTimeOfDay = `${formattedMinute} ${formattedHour}`;
  }
  if (interval === 'minute') {
    if (minute) {
      return `0/${minute} * * * ? *`;
    }
  } else if (interval === 'hour') {
    if (hour) {
      return `${minute} 0/${hour} * * ? *`;
    }
  } else if (interval === 'day') {
    if (timeOfDay) {
      // "You cannot use * in both the Day-of-month and Day-of-week fields. If you use it in one, you must use ? in the other."
      const daysOfTheMonth = daysOfWeek?.length ? '?' : '*';
      const daysOfTheWeek = daysOfWeek?.length
        ? daysOfWeek.map(d => d.toString()).join(',')
        : '?';
      return `${formattedTimeOfDay} ${daysOfTheMonth} * ${daysOfTheWeek} *`;
    }
  } else if (interval === 'month') {
    if (timeOfDay && dayOfMonth) {
      return `${formattedTimeOfDay} ${dayOfMonth} * ? *`;
    }
  } else if (interval === 'year') {
    if (timeOfDay && dayOfMonth && month) {
      return `${formattedTimeOfDay} ${dayOfMonth} ${month} ? *`;
    }
  }
};
