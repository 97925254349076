import React, { forwardRef, Ref, InputHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import css from './List.module.css';

type ListProps = InputHTMLAttributes<HTMLDivElement> & {
  ref?: Ref<HTMLDivElement>;
};

export const List: FC<ListProps> = forwardRef(
  ({ className, children, ...rest }, ref: Ref<HTMLDivElement>) => (
    <div
      {...rest}
      ref={ref}
      role="list"
      className={classNames(className, css.list)}
    >
      {children}
    </div>
  )
);
