import React, { FC } from 'react';
import { pluralize } from './utils';

export const Pluralize: FC<any> = ({
  className,
  style,
  count = 1,
  showCount = true,
  zero = null,
  ...rest
}) => (
  <span className={className} style={style}>
    {pluralize({
      count,
      showCount,
      zero,
      ...rest
    })}
  </span>
);
