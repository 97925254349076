import React, {
  FC,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react';
import clone from 'lodash/clone';
import get from 'lodash/get';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import pullAllBy from 'lodash/pullAllBy';
// import {
//   validateForm,
//   convertOutputsToTokens,
//   getInitialValues,
//   getIsRequired,
//   getUIAttribute,
//   initDependenciesContext
// } from './utils';
import isEqual from 'react-fast-compare';
import { useFormik } from 'formik';
import { JsonSchemaFormInput } from './JsonSchemaFormInput';
import { useMount } from 'react-use';
import { TokenOption } from 'shared/form/TokenEditor';
import {
  getInitialValues,
  getIsRequired,
  getUIAttribute,
  initDependenciesContext
} from './utils/form';
import { convertOutputsToTokens } from './utils/token';
import { validateForm } from './utils/validator';

export type CustomFieldComponents = {
  id: string;
  type: string;
};

export type JsonSchemaFormProps<T> = {
  schema: any;
  flowId?: string;
  options?: any[];
  tokenOptions?: TokenOption[];
  disabled?: boolean;
  allowOverride?: boolean;
  allowModifiers?: boolean;
  allowFlowTrigger?: boolean;
  allowExpressions?: boolean;
  shouldTrim?: boolean;
  validateOnMount?: boolean;
  preferNative?: boolean;
  value?: any;
  components?: CustomFieldComponents[];
  context?: any;

  // TODO: These need to be decoupled from this component
  stores?: any[];
  flows?: any[];
  addFlowDetails?: boolean;
  addEventDetails?: boolean;
  addActionDetails?: boolean;
  parent?: string | string[];
  onPortUpdate?: (conditionId: string, toAdd: boolean) => void;

  onChange?: (data) => void;
  onComponentToggle?: (components: T) => void;
  onValidate?: (hasError: boolean) => void;
};

export const JsonSchemaForm: FC<
  JsonSchemaFormProps<CustomFieldComponents[]>
> = ({
  schema,
  context,
  value,
  parent,
  components = [],
  options = [],
  tokenOptions,
  stores = [],
  disabled = false,
  preferNative = false,
  validateOnMount = false,
  addFlowDetails = false,
  addEventDetails = false,
  addActionDetails = false,
  onPortUpdate = () => undefined,
  onValidate = () => undefined,
  onChange = () => undefined,
  onComponentToggle = () => undefined,
  ...rest
}) => {
  const shown = useRef<boolean>(false);
  const [mountData] = useState(value);

  const keys = useMemo(
    () =>
      Object.keys(schema?.properties || {}).sort((a, b) => {
        // Custom order overrides
        const aOrder = schema.properties[a].attributes?.['ui:order'];
        const bOrder = schema.properties[b].attributes?.['ui:order'];
        if (aOrder !== undefined && bOrder !== undefined) {
          return aOrder < bOrder ? -1 : 1;
        }

        // Otherwise sort by required
        return getIsRequired(schema, a) ? -1 : 1;
      }),
    [schema]
  );

  const initialValues = useMemo(
    () => getInitialValues({ value, schema, context }),
    [value, schema, context]
  );

  const tokens = useMemo(
    () =>
      !options.length && tokenOptions
        ? tokenOptions
        : convertOutputsToTokens(
            options,
            stores,
            addFlowDetails,
            addEventDetails,
            addActionDetails,
            parent
          ),
    [
      addEventDetails,
      addFlowDetails,
      addActionDetails,
      options,
      tokenOptions,
      stores,
      parent
    ]
  );

  useMount(() => {
    // If this is the first time it is loaded, let's load our initial values
    // TODO: This should be moved to the action drop method but thats gonna
    // be a lot of work - should also move connnection there too...
    if (!value) {
      // No judging - race cases are a thing - let it settle
      setTimeout(() => onChange(initialValues), 10);
    }
  });

  const { handleSubmit, values, resetForm, ...formikRest } = useFormik({
    initialValues,
    validateOnMount,
    enableReinitialize: validateOnMount,
    validate: nextValues => {
      const { errors, hasError } = validateForm(schema, nextValues);

      // Kinda hacky but need a way to trigger
      // a change event when something changes
      if (shown.current) {
        onChange(nextValues);
      }

      onValidate(hasError);

      return errors;
    },
    onSubmit: () => {}
  });

  const [internalComponents, setInternalComponents] = useState<
    CustomFieldComponents[]
  >(components || []);
  const [fieldDependencies, setFieldDependencies] = useState<any>(
    initDependenciesContext(schema)
  );

  useEffect(() => {
    const properties = schema?.properties;
    const fields = (properties && Object.keys(properties)) || [];

    fields.forEach((fieldName: string) => {
      const requiredFields = getUIAttribute(
        properties[fieldName].attributes,
        'ui:requires',
        []
      );

      const updates = {
        ...fieldDependencies
      };

      let changed = false;

      requiredFields.forEach(key => {
        const prevValue = get(fieldDependencies, [fieldName, key]);
        const value = values[key];

        if (prevValue !== value) {
          changed = true;

          updates[fieldName] = clone(updates[fieldName]);

          set(updates, [fieldName, key], values[key]);
        }
      });

      if (changed) {
        setFieldDependencies(updates);
      }

      // Only run onChange after first shown
      shown.current = true;
    });
  }, [fieldDependencies, values, schema]);

  useEffect(() => {
    // When the Editor clicks revert, make sure to reset the form if node is selected
    if (isEqual(value, mountData)) {
      resetForm();
    }
  }, [value, mountData, resetForm]);

  const setComponentToggle = useCallback(
    ({ id, type }: CustomFieldComponents) => {
      const customFields = clone(internalComponents);
      const setCustomField = (id, type) => {
        pullAllBy(customFields, [{ id }], 'id');

        if (type) {
          customFields.push({ id, type });
        }
      };

      setCustomField(id, type);

      forEach(fieldDependencies, (dependency, field) => {
        if (has(dependency, id)) {
          setCustomField(field, type);
        }
      });

      setInternalComponents(customFields);
      onComponentToggle(customFields);
    },
    [onComponentToggle, fieldDependencies, internalComponents]
  );

  return (
    <form onSubmit={handleSubmit}>
      {keys.map(p => (
        <JsonSchemaFormInput
          key={`input-${p}`}
          {...formikRest}
          {...rest}
          preferNative={preferNative}
          disabled={disabled}
          values={values}
          components={internalComponents}
          fieldDependencies={fieldDependencies}
          tokens={tokens}
          schema={schema}
          property={p}
          stores={stores}
          context={context}
          onPortUpdate={onPortUpdate}
          onComponentToggle={setComponentToggle}
        />
      ))}
    </form>
  );
};
