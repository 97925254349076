import { axios } from 'core/Axios';
import {
  DataUpdateEventBody,
  DataUpdateEventResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.UPDATE_EVENT;

export const req = async (
  data: DataUpdateEventBody
): Promise<DataUpdateEventResponse> => {
  return axios
    .patch<DataUpdateEventResponse>(Route.UPDATE_EVENT, data)
    .then(data => data.data);
};

export const useUpdateEvent = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataUpdateEventResponse, DataUpdateEventBody> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_EVENTS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
