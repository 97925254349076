import React, { FC, forwardRef, Ref } from 'react';
import classNames from 'classnames';
import css from './ButtonGroup.module.css';

type ButtonGroupProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const ButtonGroup: FC<ButtonGroupProps & { ref?: Ref<HTMLDivElement> }> =
  forwardRef(
    (
      { children, className, ...rest }: ButtonGroupProps,
      ref: Ref<HTMLDivElement>
    ) => (
      <div {...rest} ref={ref} className={classNames(className, css.group)}>
        {children}
      </div>
    )
  );
