import React, { FC } from 'react';
import classnames from 'classnames';
import css from './Toggle.module.css';

type ToggleProps = {
  checked: boolean;
  disabled?: boolean;
  className?: any;
  onChange?: (value: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
};

export const Toggle: FC<ToggleProps> = ({
  checked,
  disabled,
  onChange,
  onBlur = () => undefined,
  className
}) => (
  <div
    tabIndex={0}
    className={classnames(css.switch, className, {
      [css.on]: checked,
      [css.off]: !checked,
      [css.disabled]: disabled
    })}
    onClick={() => {
      if (!disabled && onChange) {
        onChange(!checked);
      }
    }}
    onBlur={onBlur}
    onKeyDown={event => {
      if (!disabled && onChange && event.key === 'Space') {
        onChange(!checked);
      }
    }}
  />
);
