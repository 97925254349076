export const colors = {
  // Primary actions: CTAs
  blurple: {
    '50': '#F6F7FD',
    '100': '#E5E7FA',
    '200': '#C3C8F4',
    '300': '#A1A9ED',
    '400': '#7E89E7',
    '500': '#5C6AE0',
    '600': '#3547D9',
    '700': '#2434BC',
    '800': '#1C2996',
    '900': '#151F6F'
  },
  // Typography
  charcoal: {
    '50': '#EEEDF9',
    '100': '#B6B8CC',
    '200': '#999CB5',
    '300': '#8487A2',
    '400': '#606580',
    '500': '#4F5269',
    '600': '#3E4054',
    '700': '#2A2C3C',
    '800': '#1D1F2A',
    '900': '#0D0D12'
  },
  // Borders, dividers, etc
  slate: {
    '50': '#EFF0FB',
    '100': '#D5DBEA',
    '200': '#BCC2D6',
    '300': '#A2A9C2',
    '400': '#8E96B1',
    '500': '#7A84A1',
    '600': '#6B7590',
    '700': '#596178',
    '800': '#484E62',
    '900': '#343949'
  },
  neutral: {
    '0': '#FFFFFF',
    '50': '#F7F7F8',
    '100': '#F7F7F8',
    '200': '#F1F1F2',
    '300': '#E5E5E6',
    '400': '#C2C2C3',
    '500': '#A4A4A5',
    '600': '#7A7A7B',
    '700': '#666667',
    '800': '#474748',
    '900': '#252526'
  },
  success: {
    '50': '#E1F4EE',
    '100': '#B6E3D3',
    '200': '#89D2B8',
    '300': '#5BBF9C',
    '400': '#3BB189',
    '500': '#25A277',
    '600': '#21946B',
    '700': '#1B845C',
    '800': '#12744E',
    '900': '#035834'
  },
  danger: {
    '50': '#FEE8ED',
    '100': '#FDC5CE',
    '200': '#EC8B93',
    '300': '#E05C69',
    '400': '#E92A46',
    '500': '#EB002B',
    '600': '#DD002B',
    '700': '#CB0025',
    '800': '#BE001E',
    '900': '#AF0012'
  },
  warning: {
    '50': '#FFF3E9',
    '100': '#FEE7D3',
    '200': '#FEDBBC',
    '300': '#FDC390',
    '400': '#FC9F4D',
    '500': '#FB8721',
    '600': '#E27A1E',
    '700': '#C96C1A',
    '800': '#B05F17',
    '900': '#975114'
  },
  coral: {
    25: '#FEF3F1',
    50: '#FEE7E2',
    100: '#FDDBD4',
    200: '#FCC3B8',
    300: '#FBB6A9',
    400: '#F99E8D',
    500: '#F88670',
    600: '#DF7965',
    700: '#C66B5A',
    800: '#AE5E4E',
    900: '#955043',
    1000: '#7C4338'
  },
  mint: {
    50: '#D8FAF6',
    100: '#C5F7F1',
    200: '#B1F4EC',
    300: '#8BEFE3',
    400: '#61E9D9',
    500: '#3DE4D0',
    600: '#1ED5BF',
    700: '#18AD9B',
    800: '#128577',
    900: '#0D5D53'
  },
  blue: {
    50: '#E1F0F9',
    100: '#B7DAF1',
    200: '#8AC3E9',
    300: '#5CABE0',
    400: '#379ADB',
    500: '#008BD8',
    600: '#007DCC',
    700: '#006CBA',
    800: '#005CA9',
    900: '#00408C'
  },
  purple: {
    50: '#F8E4F9',
    100: '#ECBCF1',
    200: '#E08EE9',
    300: '#D35CE0',
    400: '#C82AD9',
    500: '#B900CD',
    600: '#A900C8',
    700: '#9300C1',
    800: '#8000BA',
    900: '#5900AE'
  }
};
