import shortid from '@getcrft/shortid';
import { ClientFlow } from 'core/types/Flow';
import { Flow, Map, Step, Trigger } from 'core/types/API';
import { EdgeData, NodeData } from 'reaflow';
import { filterValidConditions } from '@getcrft/jsonata-ext';
import isObject from 'lodash/isObject';
import { DatastoreActions, LogicActions } from './enums';

/**
 * Transform the Flow to a backend model.
 */
export function flowSerializer(flow: ClientFlow): Flow {
  const nodes = [];
  const triggers = [];

  if (flow.nodes) {
    const results = transformNodes({
      flow,
      preNodes: flow.nodes,
      preEdges: flow.edges
    });
    nodes.push(...results.nodes);
    triggers.push(...results.triggers);
  }

  const serverFlow = convertFlow(flow, nodes, triggers);

  return serverFlow;
}

function convertFlow(
  flow: ClientFlow,
  nodes: Step[],
  triggers: Trigger[]
): Flow {
  const serverFlow: Flow = { ...(flow as any) };
  serverFlow.name = flow.name?.trim();
  serverFlow.description = flow.description?.trim();
  serverFlow.instructions = flow.instructions?.trim();
  serverFlow.dataScrubbing = flow.dataScrubbing;
  serverFlow.trackRevisions = flow.trackRevisions;
  serverFlow.requireRevisionComments = flow.requireRevisionComments;
  serverFlow.tags = flow.tags?.length > 0 ? flow.tags : null;
  serverFlow.failureAlert = flow.failureAlert;
  serverFlow.failureAlertEmails = flow.failureAlertEmails?.length
    ? flow.failureAlertEmails
    : null;

  serverFlow.template = flow.template
    ? { ...flow.template, author: null }
    : null;
  serverFlow.nodes = nodes;
  serverFlow.triggers = triggers;
  serverFlow.group = flow.group ? flow.group.name : null;
  serverFlow.owner = flow.owner
    ? typeof flow.owner === 'string'
      ? flow.owner
      : flow.owner.id
    : null;
  serverFlow.forkCount = flow.forkCount || 0;
  serverFlow.version = flow.version;

  // @ts-ignore
  delete serverFlow.edges;

  // UI can't set this...
  delete serverFlow.usage;

  // UI can't set this...
  delete serverFlow.schema;

  // UI can't set this...
  delete serverFlow.revisions;

  return serverFlow;
}

type TransformNodesInput = {
  flow: ClientFlow;
  preNodes: NodeData[];
  preEdges: EdgeData[];
  hasParent?: boolean;
};

function transformNodes({
  flow,
  preNodes,
  preEdges,
  hasParent = false
}: TransformNodesInput) {
  const nodes = [];
  const triggers = [];

  for (const node of preNodes) {
    if (!hasParent && node.parent) {
      continue;
    }

    const edgesForNode = preEdges.filter(e => e.from === node.id);
    const next = edgesForNode.map(e => e.to);
    const nodeData = node.data;

    // visual nodes might not have a data obj, ignore them
    if (nodeData?.action) {
      if (nodeData.action.type === 'trigger') {
        triggers.push(buildTrigger(node, next));
      } else if (nodeData.action.type === 'map') {
        // Get any nested loops and include any nodes whose parent is that loop
        const nestedLoops = flow.nodes.filter(
          n =>
            n.data?.action?.type === 'map' &&
            n.parent &&
            n.parent === node.parent
        );
        const nestedLoopIds = nestedLoops.map(l => l.id);
        const nestedNodes = flow.nodes.filter(n =>
          nestedLoopIds.includes(n.parent)
        );

        nodes.push(
          buildMap({
            flow,
            allNodes: [...preNodes, ...nestedNodes],
            allEdges: preEdges,
            node,
            next
          })
        );
      } else if (['choice', 'branch'].includes(nodeData.action.type)) {
        nodes.push(buildChoice(node, edgesForNode));
      } else if (nodeData.action.type === 'approval') {
        nodes.push(buildApproval(node, edgesForNode));
      } else {
        nodes.push(buildTask(node, next));
      }
    }
  }

  return {
    nodes,
    triggers
  };
}

function transformInput(node: NodeData) {
  const nodeData = node.data;
  let input = nodeData.input;

  if (
    nodeData.action.id === LogicActions.ListFilter ||
    nodeData.action.id === DatastoreActions.FilterList
  ) {
    const { condition, ...rest } = input;
    input = {
      ...rest,
      condition: {
        ...filterValidConditions(condition[0])
      }
    };
  }

  return typeof input !== 'string' ? JSON.stringify(input) : input;
}

function buildTask(node: NodeData, next: string[]) {
  const nodeData = node.data;

  return {
    key: node.id,
    disabled: nodeData.disabled,
    task: {
      name: nodeData.label,
      schema: {
        inputComponents: nodeData.schema?.inputComponents,
        outputComponent: nodeData.schema?.outputComponent || null
      },
      retry: nodeData.retry,
      actionId: nodeData.action.id,
      timeout: nodeData.timeout,
      connectionId: nodeData.connectionId,
      input: transformInput(node),
      next
    }
  };
}

function buildApproval(node: NodeData, edgesForNode: EdgeData[]) {
  const nodeData = node.data;
  const caseId = shortid();

  return {
    key: node.id,
    disabled: nodeData.disabled,
    approval: {
      name: nodeData.label,
      actionId: nodeData.action.id,
      connectionId: nodeData.connectionId,
      input:
        typeof nodeData.input !== 'string'
          ? JSON.stringify(nodeData.input)
          : nodeData.input,
      choice: {
        key: caseId,
        declineNext: extractDefaultNext(edgesForNode),
        approveNext: extractConditionalNext(edgesForNode)
      },
      next: [caseId],
      timeout: nodeData.timeout,
      schema: {
        inputComponents: nodeData.schema?.inputComponents,
        outputComponent: nodeData.schema?.outputComponent || null
      }
    }
  };
}

type BuildMapInput = {
  flow: ClientFlow;
  allNodes: NodeData[];
  allEdges: EdgeData[];
  node: NodeData;
  next: string[];
};

function buildMap({
  flow,
  allNodes,
  allEdges,
  node,
  next
}: BuildMapInput): Map {
  const processedChildNodes = [];

  // Temp fix, nested loop edges are being removed
  // so for now just keep sending all edges to be filtered (may slow down serialization)
  const childEdges = allEdges;
  // const childEdges = allEdges.filter(e => e.parent === node.id);
  const childNodes = allNodes.filter(n => n.parent === node.id);

  if (childNodes.length) {
    const result = transformNodes({
      flow,
      preNodes: childNodes,
      preEdges: childEdges,
      hasParent: true
    });
    processedChildNodes.push(...result.nodes);
  }

  const nodeData = node.data;
  const itemsExpression = nodeData.input?.itemsExpression;
  const isAsync = nodeData.input?.isAsync || false;

  const obj: any = {
    key: node.id,
    disabled: nodeData.disabled,
    map: {
      name: nodeData.label,
      // maxConcurrency: node.input.maxConcurrency,
      itemsExpression: itemsExpression || null,
      isAsync,
      nodes: processedChildNodes,
      schema: {
        inputComponents: nodeData.schema?.inputComponents,
        outputComponent: nodeData.schema?.outputComponent || null
      },
      next
    }
  };

  if (nodeData.input?.maxConcurrency) {
    obj.map.maxConcurrency = parseInt(nodeData.input.maxConcurrency, 10);
  }

  return obj;
}

function buildChoice(node: NodeData, edgesForNode: EdgeData[]) {
  const choices: any[] = [];
  const nodeData = node.data;

  if (nodeData.input && nodeData.input.conditions) {
    for (const condition of nodeData.input?.conditions) {
      if (condition.view && isObject(condition.view)) {
        condition.view =
          typeof condition.view !== 'string'
            ? JSON.stringify(condition.view)
            : condition.view;
      }

      if (condition.or) {
        const ors = condition.or.map(c => ({
          ...c,
          view:
            c.view && isObject(c.view)
              ? typeof c.view !== 'string'
                ? JSON.stringify(c.view)
                : c.view
              : c.view
        }));
        condition.or = ors;
      }

      if (condition.and) {
        const ands = condition.and.map(c => ({
          ...c,
          view:
            c.view && isObject(c.view)
              ? typeof c.view !== 'string'
                ? JSON.stringify(c.view)
                : c.view
              : c.view
        }));
        condition.and = ands;
      }

      if (condition.or) {
        const ors = condition.or.map(c => ({
          ...c,
          view:
            c.view && isObject(c.view)
              ? typeof c.view !== 'string'
                ? JSON.stringify(c.view)
                : c.view
              : c.view
        }));
        condition.or = ors;
      }

      if (condition.and) {
        const ands = condition.and.map(c => ({
          ...c,
          view:
            c.view && isObject(c.view)
              ? typeof c.view !== 'string'
                ? JSON.stringify(c.view)
                : c.view
              : c.view
        }));
        condition.and = ands;
      }
      const positiveChoices = extractConditionalNext(
        edgesForNode,
        condition.id
      );
      choices.push({
        ...filterValidConditions(condition),
        // Needed for CORE_ARRAY_FILTER
        ...(condition.expression ? { expression: condition.expression } : {}),
        next: positiveChoices
      });
    }
  } else if (nodeData.input) {
    const positiveChoices = extractConditionalNext(edgesForNode);
    choices.push({
      // Needed for CORE_ARRAY_FILTER
      ...(nodeData.input.expression
        ? { expression: nodeData.input.expression }
        : {}),
      next: positiveChoices
    });
  }

  const obj: any = {
    key: node.id,
    disabled: nodeData.disabled,
    choice: {
      actionId: nodeData.action.id,
      name: nodeData.label,
      choices
    }
  };

  const defaultChoices = extractDefaultNext(edgesForNode);
  if (defaultChoices.length) {
    obj.choice.default = defaultChoices;
  }

  return obj;
}

function buildTrigger(node: NodeData, next: string[]) {
  const nodeData = node.data;

  return {
    actionId: nodeData.action.id,
    key: node.id,
    name: nodeData.label,
    connectionId: nodeData.connectionId,
    input:
      typeof nodeData.input !== 'string'
        ? JSON.stringify(nodeData.input)
        : nodeData.input,
    next,
    schema: {
      inputComponents: nodeData.schema?.inputComponents,
      outputComponent: nodeData.schema?.outputComponent || null
    }
  };
}

function extractConditionalNext(
  edgesForNode: EdgeData[],
  conditionId?: string
): string[] {
  return edgesForNode
    .filter(e =>
      e.fromPort.includes(!conditionId ? 'positive' : `${conditionId}-positive`)
    )
    .map(e => e.to);
}

function extractDefaultNext(edgesForNode: EdgeData[]): string[] {
  return edgesForNode
    .filter(e => e.fromPort.includes('negative'))
    .map(e => e.to);
}
