import { parseTemplate } from '@getcrft/jsonata-ext';

const getValueLabel = (
  data: { [key: string]: string },
  keyValue: string,
  keyLabel: string
): { label: string | string[]; value: string | string[] } => {
  if (!keyValue.includes('{{')) {
    keyValue = `{{${keyValue}}}`;
  }

  if (!keyLabel.includes('{{')) {
    keyLabel = `{{${keyLabel}}}`;
  }

  return {
    value: parseTemplate(keyValue, data),
    label: parseTemplate(keyLabel, data)
  };
};

export const getNestedOptions = (
  data,
  keyValue,
  keyLabel
): { label: string; value: string }[] => {
  data = data || [];
  if (!Array.isArray(data)) {
    const options = getValueLabel(data, keyValue, keyLabel);
    if (Array.isArray(options.value)) {
      // parseTemplate will parse all values in an array if accessing an array
      return options.value.map((v, i) => ({
        value: v,
        label: options.label[i]
      }));
    }

    return [
      {
        value: options.value,
        label: options.label as string
      }
    ];
  }

  return data?.map(item => {
    const option = getValueLabel(item, keyValue, keyLabel);

    return {
      value: option.value as string,
      label: option.label as string
    };
  });
};
