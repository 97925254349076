export const typography = {
  // font-family
  '--font-family': `Hind, "Open Sans", sans-serif`,
  '--mono-font-family': `Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace`,

  // line-height
  '--line-height-100': '16px',
  '--line-height-200': '18px',
  '--line-height-300': '20px',
  '--line-height-400': '24px',
  '--line-height-500': '28px',
  '--line-height-600': '32px',
  '--line-height-700': '40px',
  '--line-height-800': '48px',

  // font-weight
  '--weight-light': 'Light',
  '--weight-regular': 'Regular',
  '--weight-medium': 'SemiBold',
  '--weight-bold': 'Bold',

  // letter-spacing
  '--letter-spacing-100': '0.1px',
  '--letter-spacing-200': '0.15px',
  '--letter-spacing-300': '0.25px',
  '--letter-spacing-400': '0.5px',
  '--letter-spacing-500': '1px',
  '--letter-spacing-600': '1.25px',
  '--letter-spacing-700': '1.5px',

  // font-size
  '--font-xxs': '12px',
  '--font-xs': '14px',
  '--font-sm': '16px',
  '--font-md': '20px',
  '--font-lg': '24px',
  '--font-xl': '32px',
  '--font-xxl': '40px',

  // padding/margin
  '--paragraph-spacing-0': '0',
  '--spacing-0': '0',
  '--spacing-100': '0.25rem',
  '--spacing-200': '0.5rem',
  '--spacing-300': '0.75rem',
  '--spacing-400': '1rem',
  '--spacing-500': '1.25rem',
  '--spacing-600': '1.5rem',
  '--spacing-700': '2rem',
  '--spacing-800': '2.5rem',
  '--spacing-900': '3rem',
  '--spacing-1000': '3.5rem',

  // text-transform
  '--text-case-none': 'none',

  // text-decoration
  '--text-decoration-none': 'none'
};
