import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { useIdleTimerContext } from 'react-idle-timer';
import { AuthProvider } from './authContext';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import Token from 'core/utils/token';
import { CLIENT_ID, ISSUER, SCOPES } from './config';
import { CustomUserClaim } from '@okta/okta-auth-js';

export const Auth: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const fullPath = pathname + search;
  const ldClient = useLDClient();
  const [isFp, setIsFp] = useState<boolean>(false);
  const [hasAccess, setHasAccess] = useState<boolean>(null);
  const isTokenRefreshing = useRef<boolean>(false);

  const idleTimer = useIdleTimerContext();

  const setUserOnLogin = useCallback(
    async (curUser, curAuthState) => {
      const fpUser =
        curAuthState?.accessToken?.claims?.sf_id === '001o000000yPXXuAAO';

      // Rediect non-fp users to automate when trying to access autodemo
      if (!fpUser && window.location.host.includes('autodemo')) {
        return window.location.replace('https://automate.fp.tools');
      }

      const permissions =
        (curAuthState?.accessToken?.claims?.prm as CustomUserClaim[]) || [];

      setUser(curUser);
      setIsFp(fpUser);
      // Only want to set hasAccess once so if user manually changes their access token in localStorage,
      // that does not affect a user's access
      if (hasAccess == null) {
        setHasAccess(
          fpUser ||
            permissions.some(
              p =>
                (p as string).includes('crft') || (p as string).includes('flow')
            )
        );
      }

      if (curUser) {
        if (!process.env.REACT_APP_EMBEDDED) {
          // NOTE: In beta, all customers are required to run fullstory
          FullStory.identify(curUser.id, {
            displayName: curUser.name,
            email: curUser.email
          });
        }

        if (process.env.NODE_ENV !== 'development') {
          datadogRum.setUser({
            id: curUser.id,
            name: curUser.name,
            email: curUser.email
          });

          Sentry.configureScope(scope =>
            scope.setUser({
              username: curUser.id,
              name: curUser.name,
              email: curUser.email
            })
          );

          // Parent handles these in embedded mode
          if (
            !process.env.REACT_APP_EMBEDDED &&
            ['automate', 'autodemo'].includes(process.env.REACT_APP_ENV)
          ) {
            const Intercom = (window as any).Intercom;
            if (Intercom && fpUser) {
              Intercom('boot', {
                app_id: 'h96kfhua',
                email: curUser.email,
                name: curUser.name,
                user_id: curUser.id,
                ...(curUser.picture
                  ? { avatar: { type: 'avatar', image_url: curUser.picture } }
                  : {}),
                Audience: 'Automate'
              });
            }

            if (!curUser.do_not_track) {
              const uid = Token.get('fp_uid');
              const sid = Token.get('sf_id');
              pendo.initialize({
                visitor: { id: uid || curUser.id },
                account: { id: sid || curUser.sub }
              });
            }
          }
        }
      } else {
        Token.del('X-FP-Authorization');
        Token.del('X-FP-IdToken');
      }
    },
    [hasAccess]
  );

  const refreshTokens = useCallback(async () => {
    if (isTokenRefreshing.current) {
      return;
    }

    try {
      isTokenRefreshing.current = true;
      const response = await oktaAuth?.token?.getWithoutPrompt();
      if (response) {
        oktaAuth.tokenManager?.setTokens(response.tokens);

        const accessExpiration = response.tokens?.accessToken?.expiresAt
          ? // Convert Okta JWT expiration from s to ms
            new Date(response.tokens.accessToken.expiresAt * 1000)
          : null;
        Token.set(
          'X-FP-Authorization',
          response.tokens?.accessToken?.accessToken,
          {
            expires: accessExpiration
          }
        );

        const idExpiration = response.tokens?.idToken?.expiresAt
          ? // Convert Okta JWT expiration from s to ms
            new Date(response.tokens.idToken.expiresAt * 1000)
          : null;
        Token.set('X-FP-IdToken', response.tokens?.idToken?.idToken, {
          expires: idExpiration
        });
      }
      isTokenRefreshing.current = false;
    } catch {
      isTokenRefreshing.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      ldClient?.identify({
        key: user.id,
        name: user.name,
        email: user.email,
        avatar: user.picture,
        custom: {
          embedded: process.env.REACT_APP_EMBEDDED
        }
      });
    }
  }, [ldClient, user]);

  const setPrevRoute = useCallback(() => {
    if (pathname !== '/logout') {
      window.localStorage.setItem('PREV_ROUTE', fullPath);
    }
  }, [fullPath, pathname]);

  const logout = useCallback(async () => {
    Token.del('X-FP-Authorization');
    Token.del('X-FP-IdToken');
    setPrevRoute();
    await oktaAuth.signOut();
    setUser(null);
  }, [oktaAuth, setPrevRoute]);

  useEffect(() => {
    if (pathname === '/logout') {
      return;
    }

    const xFpAuth = Token.cke('X-FP-Authorization');
    const xFpId = Token.cke('X-FP-IdToken');

    // We want to allow a user logging into one of our .fp.tools domains to only log in once
    // and be able to automatically be logged into our subdomains. We pass the okta access token
    // and id token through cookies to allow cross domain access. We need to recreate the access
    // and id token using the passed cookies below.
    if (!xFpAuth || !xFpId) {
      Token.del('X-FP-Authorization');
      Token.del('X-FP-IdToken');

      if (authState?.isAuthenticated) {
        // If authstate is saying user is authenticated, but their cookies have expired, that means the user's
        // session expired when they were not on the page, so need to log them out if hadn't been active for 30 minutes
        if (idleTimer.isIdle() || idleTimer.isPrompted()) {
          navigate('/logout');
        } else {
          refreshTokens();
        }
      }
    } else {
      const tokens = window.localStorage.getItem('okta-token-storage');
      if (!tokens) {
        const accessClaims = Token.jwt(xFpAuth);
        const accessToken = {
          value: xFpAuth,
          accessToken: xFpAuth,
          claims: accessClaims,
          // @ts-ignore
          expiresAt: accessClaims.exp,
          tokenType: 'Bearer',
          scopes: SCOPES,
          authorizeUrl: `${ISSUER}/v1/authorize`,
          userinfoUrl: `${ISSUER}/v1/userinfo`
        };

        const idClaims = Token.jwt(xFpId);
        const idToken = {
          value: xFpId,
          idToken: xFpId,
          claims: idClaims,
          // @ts-ignore
          expiresAt: idClaims.exp,
          scopes: SCOPES,
          authorizeUrl: `${ISSUER}/v1/authorize`,
          issuer: ISSUER,
          clientId: CLIENT_ID
        };

        // @ts-ignore
        oktaAuth.tokenManager.setTokens({ accessToken, idToken });
      }
    }

    if (!user) {
      if (!authState?.isAuthenticated) {
        setUser(null);
      } else {
        oktaAuth.getUser().then(async user => {
          if (user) {
            setUserOnLogin(user, authState);
            const permissions = authState?.accessToken?.claims
              ?.prm as CustomUserClaim[];
            const fp =
              authState?.accessToken?.claims?.sf_id === '001o000000yPXXuAAO';
            if (
              (!fp &&
                !permissions.some(
                  p =>
                    (p as string).includes('crft') ||
                    (p as string).includes('flow')
                )) ||
              !user.allowed_user
            ) {
              navigate('/denied', { replace: true });
              return;
            }

            if (!process.env.REACT_APP_EMBEDDED) {
              if (hasAccess === false || !user.allowed_user) {
                navigate('/denied', { replace: true });
              } else if (
                (!user.seen_welcome || !user.automate_first_login) &&
                sessionStorage.getItem('seen_welcome') !== 'true' &&
                pathname !== '/denied'
              ) {
                navigate('/welcome', { replace: true });
              }
            }
          } else if (!process.env.REACT_APP_EMBEDDED) {
            setPrevRoute();
            navigate('/logout');
          }
        });
      }
    }
    // removed authState because it was causing infinite errors in the iframe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState?.isAuthenticated,
    oktaAuth,
    user,
    setUserOnLogin,
    navigate,
    logout,
    setPrevRoute,
    refreshTokens,
    hasAccess,
    isFp,
    idleTimer,
    pathname
  ]);

  const values = useMemo(
    () => ({
      user,
      hasAccess,
      logout,
      isAuthenticated: authState?.isAuthenticated,
      isFp,
      oktaAuth
    }),
    [user, hasAccess, logout, authState?.isAuthenticated, oktaAuth, isFp]
  );

  return <AuthProvider value={values}>{children}</AuthProvider>;
};
