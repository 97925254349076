import { createRef, Ref, useMemo, useState, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import { TokenOption } from '../types';
import { useFuzzy } from '@reaviz/react-use-fuzzy';

export type GroupOption = {
  offset: number;
  order: number;
  items: TokenOption[];
  name: string;
  ref: Ref<HTMLLIElement>;
};

export const useTokenGroups = ({ tokens, allowAnyToken }) => {
  const [offset, setOffset] = useState<number>(-1);

  const { result, keyword, search, resetSearch } = useFuzzy<TokenOption>(
    tokens,
    {
      keys: ['text', 'subtext', 'value', 'description', 'group']
    }
  );
  const hasExpression = !keyword && allowAnyToken;
  const [expandedStates, setExpandedStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [groupsOffsetMap, setGroupsOffsetMap] = useState<
    Map<number, GroupOption>
  >(new Map());
  const [tokensOffsetMap, setItemsOffsetMap] = useState<
    Map<number, TokenOption>
  >(new Map());
  const [maxOffset, setMaxOffset] = useState<number>(0);

  const { groups, hasGroups, groupsMap, tokensMap, index } = useMemo(
    () => buildGroups(tokens, expandedStates),
    [expandedStates, tokens]
  );

  useEffect(() => {
    tokens.forEach(token => (token.ref = createRef()));
  }, [tokens]);

  useEffect(() => {
    setGroupsOffsetMap(groupsMap);
    setItemsOffsetMap(tokensMap);
    setMaxOffset(
      keyword ? result.length - 1 : hasExpression ? index : index - 1
    );
  }, [groupsMap, hasExpression, index, keyword, result.length, tokensMap]);

  const conditionalOffset = hasExpression ? offset - 1 : offset;

  return {
    groups,
    hasExpression,
    conditionalOffset,
    maxOffset,
    tokensOffsetMap,
    groupsOffsetMap,
    searchResult: result,
    keyword,
    search,
    resetSearch,
    offset,
    setOffset,
    hasGroups,
    expandedStates,
    setExpandedStates
  };
};

function buildGroups(
  tokens: TokenOption[],
  expandedStates: { [key: string]: boolean }
) {
  if (tokens.length === 0) {
    return {
      groups: [],
      hasGroups: false
    };
  }

  let index = 0;
  const groupsMap = new Map();
  const tokensMap = new Map();
  const results = groupBy(tokens, t => t.group);

  // If we found no actual groups defined
  const groupNames = Object.keys(results);
  if (groupNames.length === 1 && groupNames[0] === 'undefined') {
    return {
      groups: [],
      hasGroups: false
    };
  }

  const groups = Object.entries(results).map(([key, value], order) => ({
    offset: 0,
    order,
    items: value,
    name: key,
    ref: createRef() as Ref<HTMLLIElement>
  }));

  // Refresh offset for every visible item
  groups.forEach(group => {
    group.offset = index++;
    groupsMap.set(group.offset, group);
    if (expandedStates[group.order]) {
      group.items.forEach(item => {
        item.offset = index++;
        tokensMap.set(item.offset, item);
      });
    }
  });

  return {
    groupsMap,
    tokensMap,
    index,
    groups,
    hasGroups: groups?.length !== 0
  };
}
