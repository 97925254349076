import React, { FC, useRef, useCallback } from 'react';
import classNames from 'classnames';
import Editor, { EditorProps, Monaco } from '@monaco-editor/react';
import { setupEditor } from './instance';
import { useUnmount } from 'react-use';
import {
  jsonataHoverProvider,
  jsonataSuggestionsProvider,
  Suggestion
} from './jsonata';
import { editor } from 'monaco-editor';
import css from './CodeEditor.module.css';
import { useTheme } from 'shared/utils/Theme';

export type IDisposable = {
  dispose(): void;
};

export type CodeEditorProps = EditorProps & {
  allowFullscreen?: boolean;
  error?: boolean;
  disabled?: boolean;
  minHeight?: number;
  maxHeight?: number;

  // TODO: Abstract these types
  suggestions?: Suggestion[];
  jsonataSuggestions?: boolean;

  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
};

export const CodeEditor: FC<CodeEditorProps> = ({
  value,
  options,
  error,
  disabled,
  suggestions,
  minHeight = 175,
  maxHeight = 400,
  height = '100%',
  width = '100%',
  language,
  allowFullscreen = true,
  jsonataSuggestions = true,
  onBlur = () => undefined,
  onFocus = () => undefined,
  ...rest
}) => {
  const elmRef = useRef<HTMLDivElement | null>(null);
  const disposables = useRef<IDisposable[]>([]);
  const contentHeightRef = useRef<number>(0);
  const { name } = useTheme();

  useUnmount(() => disposables.current.forEach(d => d.dispose()));

  const handleEditorWillMount = useCallback(
    (instance: Monaco) => {
      setupEditor(instance, name);

      if (language === 'jsonata') {
        const newDisposables = [];

        if (suggestions?.length > 0) {
          const completionProvider =
            instance.languages.registerCompletionItemProvider('jsonata', {
              // TODO: Come back and make it smart enough if you type in {{}}
              triggerCharacters: ['.'],
              provideCompletionItems: (model, position) =>
                jsonataSuggestionsProvider(
                  model,
                  position,
                  suggestions,
                  jsonataSuggestions
                )
            });

          newDisposables.push(completionProvider);

          const hoverProvider = instance.languages.registerHoverProvider(
            'jsonata',
            {
              provideHover: (model, position) =>
                jsonataHoverProvider(model, position, suggestions)
            }
          );

          newDisposables.push(hoverProvider);
        }

        disposables.current = newDisposables;
      }
    },
    [jsonataSuggestions, language, name, suggestions]
  );

  const calculateHeight = useCallback(
    (editor: editor.IStandaloneCodeEditor, height?: number) => {
      if (typeof height === 'undefined') {
        // Retrieve content height directly from the editor if no height provided as param
        height = editor.getContentHeight();
      }

      height = Math.max(minHeight, height);
      height = Math.min(maxHeight, height);

      // Ref: https://github.com/nteract/nteract/pull/5587/files
      if (elmRef.current && contentHeightRef.current !== height) {
        elmRef.current.style.height = `${height}px`;

        editor.layout({
          width: editor.getLayoutInfo().width,
          height
        });

        contentHeightRef.current = height;
      }
    },
    [minHeight, maxHeight]
  );

  const onEditorMount = useCallback(
    (editor: editor.IStandaloneCodeEditor) => {
      if (
        language === 'javascript' ||
        language === 'json' ||
        language === 'jsonata' ||
        language === 'markdown' ||
        language === 'text'
      ) {
        editor.updateOptions({
          tabSize: 2
        });
      }

      calculateHeight(editor);
      editor.onDidContentSizeChange(info => {
        if (info.contentHeightChanged) {
          calculateHeight(editor, info.contentHeight);
        }
      });

      if (allowFullscreen) {
        editor.addAction({
          id: 'fullScreen',
          label: 'Full Screen',
          contextMenuGroupId: 'navigation',
          keybindings: [2048 | 69],
          contextMenuOrder: 1.5,
          run: () => {
            const elm = elmRef.current as any;
            if (elm.requestFullscreen) {
              elm.requestFullscreen();
            } else if (elm.webkitRequestFullscreen) {
              elm.webkitRequestFullscreen();
            } else if (elm.msRequestFullscreen) {
              elm.msRequestFullscreen();
            } else if (elm.mozRequestFullScreen) {
              elm.mozRequestFullScreen();
            }
          }
        });
      }
    },
    [allowFullscreen, calculateHeight, language]
  );

  return (
    <div
      ref={elmRef}
      className={classNames(css.container, 'mousetrap', { [css.error]: error })}
      tabIndex={-1}
      style={{ height, width }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Editor
        {...rest}
        language={language}
        value={value}
        height="100%"
        options={{
          readOnly: disabled,
          fontSize: 12,
          // NOTE: this is required, otherwise the editor will continue to
          // change its size on layout if set to true in options overrides
          scrollBeyondLastLine: false,
          automaticLayout: true,
          glyphMargin: false,
          folding: false,
          lineNumbersMinChars: 3,
          ...(options || {}),
          suggest: {
            showWords: false,
            ...(options && options.suggest ? options.suggest : {})
          },
          minimap: {
            enabled: false,
            ...(options && options.minimap ? options.minimap : {})
          }
        }}
        theme="flashpoint"
        beforeMount={handleEditorWillMount}
        onMount={onEditorMount}
      />
    </div>
  );
};
