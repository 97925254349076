import React, { FC } from 'react';
import { Drawer } from 'shared/layers/Drawer';
import {
  NewEditStoreContainer,
  NewEditStoreContainerProps
} from './NewEditStoreContainer';
import { Datastore } from 'core/types/API';

export type ExtendedDataStore = Omit<Datastore, 'owner' | 'group' | 'value'> & {
  owner?: any;
  group?: any;
  value?: any;
};

export type DatastoreDrawerProps = NewEditStoreContainerProps & {
  open: boolean;
  size?: string;
  className?: string;
};

export const DatastoreDrawer: FC<DatastoreDrawerProps> = ({
  open,
  size = '650px',
  store,
  className,
  onClose,
  ...rest
}) => (
  <Drawer
    size={size}
    open={open}
    className={className}
    header={store ? `Editing Datastore - ${store.name}` : 'New Datastore'}
    onClose={onClose}
  >
    {() => <NewEditStoreContainer {...rest} store={store} onClose={onClose} />}
  </Drawer>
);
