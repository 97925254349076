import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Block } from 'shared/layout/Block';
import { Select, SelectOption } from 'shared/form/Select';
import * as Yup from 'yup';
import { Button } from 'shared/elements/Button';
import { parseJson } from './utils';
import { JsonTreeNodeData } from './JsonTreeNode';
import { Operation } from './JsonTree';
import css from './NodeEditor.module.css';
import { TokenEditor, TokenOption } from 'shared/form/TokenEditor';

type NodeEditorProps = {
  currentNode?: any;
  showEmpty?: boolean;
  allowTokens?: boolean;
  tokens?: TokenOption[];
  addEditNode?: (
    newNode: JsonTreeNodeData,
    curNode: JsonTreeNodeData,
    op: Operation
  ) => void;
  onCancel: () => void;
};

export const NodeEditor: FC<NodeEditorProps> = ({
  currentNode,
  showEmpty,
  allowTokens,
  tokens = [],
  addEditNode,
  onCancel
}) => {
  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        key: '',
        value: '',
        type: 'string'
      },
      validationSchema: Yup.object().shape({
        key:
          currentNode && currentNode.type !== 'array'
            ? Yup.string().required('Required')
            : Yup.string(),
        type: Yup.string().required('Required'),
        value: Yup.string().when('type', {
          is: t => t === 'boolean',
          then: Yup.string().required('Required')
        })
      }),
      onSubmit: values => {
        const { key, value, type } = values;
        const nodeValue = type === 'boolean' && value === 'true';
        const parsedValue = parseJson(
          {
            [key]:
              type === 'object'
                ? {}
                : type === 'array'
                ? []
                : type === 'boolean'
                ? nodeValue
                : value
          },
          '',
          showEmpty
        );
        addEditNode(parsedValue.data[0], currentNode, 'add');
      }
    });

  const isArray = currentNode && currentNode.type === 'array';

  return (
    <form onSubmit={handleSubmit}>
      {!isArray && (
        <Block label="Key">
          <TokenEditor
            className={css.tokenEditor}
            value={values.key}
            tokens={allowTokens ? tokens : []}
            allowFlowTrigger={false}
            allowExpressions={false}
            onChange={val => setFieldValue('key', val)}
          />
        </Block>
      )}
      <Block label="Type">
        <Select
          name="type"
          value={values.type}
          onBlur={handleBlur}
          onChange={v => {
            setFieldValue('type', v);
            setFieldValue('value', '');
          }}
        >
          <SelectOption value="string">String</SelectOption>
          <SelectOption value="number">Number</SelectOption>
          <SelectOption value="boolean">Boolean</SelectOption>
          <SelectOption value="object">Object</SelectOption>
          <SelectOption value="array">Array</SelectOption>
        </Select>
      </Block>
      {['string', 'number'].includes(values.type) && (
        <Block label="Value">
          <TokenEditor
            className={css.tokenEditor}
            value={values.value}
            tokens={allowTokens ? tokens : []}
            allowFlowTrigger={allowTokens}
            allowExpressions={allowTokens}
            onChange={val => setFieldValue('value', val)}
          />
        </Block>
      )}
      {values.type === 'boolean' && (
        <Block label="Value">
          <div
            className={!!touched.value && !!errors.value ? css.hasError : ''}
          >
            <span className={css.trueFalseInput}>
              <input
                type="radio"
                name="value"
                id="radio-true"
                value="true"
                checked={values.value === 'true'}
                onChange={() => setFieldValue('value', 'true')}
              />
              {` `}
              True
            </span>
            <span className={css.trueFalseInput}>
              <input
                type="radio"
                name="value"
                id="radio-false"
                value="false"
                checked={values.value === 'false'}
                onChange={() => setFieldValue('value', 'false')}
              />
              {` `}
              False
            </span>
          </div>
        </Block>
      )}
      <div className={css.actions}>
        <Button type="submit" color="primary">
          Add
        </Button>
        <Button variant="outline" disableMargins onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
