import { axios } from 'core/Axios';
import {
  DataListActionsParams,
  DataListActionsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_ACTIONS;
export const buildQueryKey = (params?: DataListActionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListActionsParams
): Promise<DataListActionsResponse> => {
  return axios
    .get<DataListActionsResponse>(Route.LIST_ACTIONS, { params })
    .then(data => data.data);
};

export const useListActions = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<DataListActionsResponse, DataListActionsParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
