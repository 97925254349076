/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: any;
};

export type Action = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  discriminator: Scalars['String'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  optionalConnection?: Maybe<Scalars['Boolean']>;
  output?: Maybe<Scalars['AWSJSON']>;
  package: Package;
  packageConnectionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<ActionType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  actionPackageId?: Maybe<Scalars['String']>;
};

export enum ActionType {
  Approval = 'approval',
  Choice = 'choice',
  Map = 'map',
  Task = 'task',
  Trigger = 'trigger'
}

export type ApiKey = {
  created?: Maybe<Scalars['AWSDateTime']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  lastUsed?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type AuthorizeConnectionInput = {
  connectionId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  packageConnectionId: Scalars['ID'];
};

export type AuthorizeConnectionOutput = {
  output?: Maybe<Scalars['AWSJSON']>;
  success: Scalars['Boolean'];
};

export type Billing = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lifetimeActionInvocations?: Maybe<Scalars['Int']>;
  lifetimeFlowInvocations?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['ID']>;
  plan?: Maybe<BillingPlan>;
  totalDataProcessed?: Maybe<Scalars['Float']>;
  totalFilesSizeUploaded?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  usage?: Maybe<ModelBillingUsageConnection>;
};

export type BillingUsageArgs = {
  date?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelBillingUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type BillingPlan = {
  actionInvocations?: Maybe<Scalars['Int']>;
  dataSize?: Maybe<Scalars['Float']>;
  expiration?: Maybe<Scalars['AWSDateTime']>;
  flowCount?: Maybe<Scalars['Int']>;
  flowInvocations?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type BillingPlanInput = {
  actionInvocations?: InputMaybe<Scalars['Int']>;
  dataSize?: InputMaybe<Scalars['Float']>;
  expiration?: InputMaybe<Scalars['AWSDateTime']>;
  flowCount?: InputMaybe<Scalars['Int']>;
  flowInvocations?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type BillingUsage = {
  actionsCount?: Maybe<Scalars['Int']>;
  billingId: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  dataSize?: Maybe<Scalars['Float']>;
  date: Scalars['AWSDateTime'];
  filesSize?: Maybe<Scalars['Float']>;
  flowsCount?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isActionLimitExceeded: Scalars['Boolean'];
  isActionLimitNotified: Scalars['Boolean'];
  isFlowLimitExceeded: Scalars['Boolean'];
  isFlowLimitNotified: Scalars['Boolean'];
  owner?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type Catch = {
  expression?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CatchInput = {
  expression?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Choice = {
  actionId?: Maybe<Scalars['ID']>;
  choices: Array<ChoiceRule>;
  default?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type ChoiceInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  choices: Array<ChoiceRuleInput>;
  default?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ChoiceRule = {
  and?: Maybe<Array<Maybe<InnerChoiceRule>>>;
  comparator?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  or?: Maybe<Array<Maybe<InnerChoiceRule>>>;
  value?: Maybe<Scalars['String']>;
  variable?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['AWSJSON']>;
};

export type ChoiceRuleInput = {
  and?: InputMaybe<Array<InputMaybe<InnerChoiceRuleInput>>>;
  comparator?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  or?: InputMaybe<Array<InputMaybe<InnerChoiceRuleInput>>>;
  value?: InputMaybe<Scalars['String']>;
  variable?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['AWSJSON']>;
};

export type CommunityFlowResult = {
  items: Array<Flow>;
};

export type Component = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type ComponentInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Connection = {
  created?: Maybe<CreatedUpdated>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  lastPing?: Maybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  output?: Maybe<Scalars['AWSJSON']>;
  owner?: Maybe<Scalars['ID']>;
  packageConnection?: Maybe<PackageConnection>;
  packageConnectionId: Scalars['ID'];
  revisions?: Maybe<ModelConnectionRevisionConnection>;
  status?: Maybe<ConnectionStatus>;
  updated?: Maybe<CreatedUpdated>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type ConnectionRevisionsArgs = {
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ConnectionRevision = {
  connection: Scalars['AWSJSON'];
  connectionId: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  editedAt: Scalars['AWSDateTime'];
  editedBy: Scalars['ID'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  packageConnection?: Maybe<PackageConnection>;
  packageConnectionId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  version: Scalars['Int'];
};

export enum ConnectionStatus {
  Active = 'Active',
  Error = 'Error',
  Unknown = 'Unknown'
}

export type CreateActionInput = {
  actionPackageId: Scalars['ID'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discriminator: Scalars['String'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  optionalConnection?: InputMaybe<Scalars['Boolean']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
  packageConnectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<ActionType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateApiKeyInput = {
  created?: InputMaybe<Scalars['AWSDateTime']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  lastUsed?: InputMaybe<Scalars['AWSDateTime']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateApiTokenInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateApiTokenResult = {
  created?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<Scalars['ID']>;
};

export type CreateBillingInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  lifetimeActionInvocations?: InputMaybe<Scalars['Int']>;
  lifetimeFlowInvocations?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['ID']>;
  plan?: InputMaybe<BillingPlanInput>;
  totalDataProcessed?: InputMaybe<Scalars['Float']>;
  totalFilesSizeUploaded?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateBillingUsageInput = {
  actionsCount?: InputMaybe<Scalars['Int']>;
  billingId: Scalars['ID'];
  dataSize?: InputMaybe<Scalars['Float']>;
  date: Scalars['AWSDateTime'];
  filesSize?: InputMaybe<Scalars['Float']>;
  flowsCount?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isActionLimitExceeded: Scalars['Boolean'];
  isActionLimitNotified: Scalars['Boolean'];
  isFlowLimitExceeded: Scalars['Boolean'];
  isFlowLimitNotified: Scalars['Boolean'];
  owner?: InputMaybe<Scalars['ID']>;
};

export type CreateConnectionInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  lastPing?: InputMaybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  output?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['ID']>;
  packageConnectionId: Scalars['ID'];
  status?: InputMaybe<ConnectionStatus>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type CreateConnectionRevisionInput = {
  connection: Scalars['AWSJSON'];
  connectionId: Scalars['ID'];
  editedAt: Scalars['AWSDateTime'];
  editedBy: Scalars['ID'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['ID']>;
  packageConnectionId: Scalars['ID'];
  version: Scalars['Int'];
};

export type CreateDatastoreInput = {
  created?: InputMaybe<StoreValueCreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  type: StoreValueType;
  updated?: InputMaybe<StoreValueCreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateEventGroupInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  end?: InputMaybe<Scalars['AWSDateTime']>;
  error?: InputMaybe<Scalars['String']>;
  flowId: Scalars['ID'];
  flowVersion?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['ID']>;
  start: Scalars['AWSDateTime'];
  status?: InputMaybe<EventStatus>;
  triggeredBy?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateEventInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  approvalStatus?: InputMaybe<EventApprovalStatus>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  end?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  index?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  mapKey?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['String']>;
  runId: Scalars['ID'];
  start: Scalars['AWSDateTime'];
  taskKey?: InputMaybe<Scalars['String']>;
  taskToken?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateFlowInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  dataScrubbing?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  failureAlert?: InputMaybe<Scalars['Boolean']>;
  failureAlertEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  forkCount?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  instructions?: InputMaybe<Scalars['String']>;
  lastRun?: InputMaybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  nodes: Array<StepInput>;
  owner?: InputMaybe<Scalars['ID']>;
  requireRevisionComments?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  template?: InputMaybe<TemplateDetailsInput>;
  trackRevisions?: InputMaybe<Scalars['Boolean']>;
  triggers: Array<TriggerInput>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type CreateFlowRevisionInput = {
  comment?: InputMaybe<Scalars['String']>;
  editedAt: Scalars['AWSDateTime'];
  editedBy: Scalars['ID'];
  flow: Scalars['AWSJSON'];
  flowId: Scalars['ID'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['ID']>;
  version: Scalars['Int'];
};

export type CreateFlowSchemaInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  items?: InputMaybe<Array<InputMaybe<FlowSchemaItemInput>>>;
  owner?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateFlowUsageInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  date: Scalars['AWSDateTime'];
  errorCount: Scalars['Int'];
  flowId: Scalars['ID'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['ID']>;
  successCount: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  inviteUsers?: InputMaybe<Array<InputMaybe<CreateUserInput>>>;
  name: Scalars['String'];
};

export type CreateNotificationInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  date: Scalars['AWSDateTime'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<NotificationLevel>;
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreatePackageConnectionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discriminator?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  packageConnectionPackageId: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreatePackageInput = {
  author?: InputMaybe<PackageAuthorDetailsInput>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  created?: InputMaybe<Scalars['AWSDateTime']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  lambdaARN?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<PackageDetailsInput>;
  repository: Scalars['String'];
  runtime?: InputMaybe<RuntimeType>;
  schema?: InputMaybe<Scalars['String']>;
  status: PackageStatus;
  updated?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  usageCount?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<PackageDetailsInput>;
  version?: InputMaybe<Scalars['String']>;
};

export type CreateUploadInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventId?: InputMaybe<Scalars['ID']>;
  fileName: Scalars['String'];
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  size: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CreateUserInput = {
  do_not_track?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  integrations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  job_tasks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  seen_welcome?: InputMaybe<Scalars['Boolean']>;
};

export type CreatedUpdated = {
  date?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type CreatedUpdatedInput = {
  date?: InputMaybe<Scalars['AWSDateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
};

export type Datastore = {
  created?: Maybe<StoreValueCreatedUpdated>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  flow?: Maybe<Flow>;
  flowId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner?: Maybe<Scalars['ID']>;
  type: StoreValueType;
  updated?: Maybe<StoreValueCreatedUpdated>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type DatastoreResult = {
  items?: Maybe<Array<Maybe<Datastore>>>;
};

export type DeleteActionInput = {
  id: Scalars['ID'];
};

export type DeleteApiKeyInput = {
  id: Scalars['ID'];
};

export type DeleteBillingInput = {
  id: Scalars['ID'];
};

export type DeleteBillingUsageInput = {
  id: Scalars['ID'];
};

export type DeleteConnectionInput = {
  id: Scalars['ID'];
};

export type DeleteConnectionRevisionInput = {
  id: Scalars['ID'];
};

export type DeleteDatastoreInput = {
  id: Scalars['ID'];
};

export type DeleteEventGroupInput = {
  id: Scalars['ID'];
};

export type DeleteEventInput = {
  id: Scalars['ID'];
};

export type DeleteFlowInput = {
  id: Scalars['ID'];
};

export type DeleteFlowRevisionInput = {
  id: Scalars['ID'];
};

export type DeleteFlowSchemaInput = {
  id: Scalars['ID'];
};

export type DeleteFlowUsageInput = {
  id: Scalars['ID'];
};

export type DeleteNotificationInput = {
  date: Scalars['AWSDateTime'];
  id: Scalars['ID'];
};

export type DeletePackageConnectionInput = {
  id: Scalars['ID'];
};

export type DeletePackageInput = {
  id: Scalars['ID'];
};

export type DeleteUploadInput = {
  id: Scalars['ID'];
};

export type DeployPackageInput = {
  group?: InputMaybe<Scalars['ID']>;
  repository: Scalars['String'];
};

export type Event = {
  actionId?: Maybe<Scalars['ID']>;
  approvalStatus?: Maybe<EventApprovalStatus>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  end?: Maybe<Scalars['AWSDateTime']>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['AWSJSON']>;
  mapKey?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['AWSJSON']>;
  owner?: Maybe<Scalars['String']>;
  runId: Scalars['ID'];
  start: Scalars['AWSDateTime'];
  taskKey?: Maybe<Scalars['String']>;
  taskToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export enum EventApprovalStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Pending = 'Pending'
}

export type EventGroup = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  end?: Maybe<Scalars['AWSDateTime']>;
  error?: Maybe<Scalars['String']>;
  events?: Maybe<ModelEventConnection>;
  flowId: Scalars['ID'];
  flowVersion?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  start: Scalars['AWSDateTime'];
  status?: Maybe<EventStatus>;
  triggeredBy?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type EventGroupEventsArgs = {
  filter?: InputMaybe<ModelEventFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  start?: InputMaybe<ModelStringKeyConditionInput>;
};

export enum EventStatus {
  Errored = 'Errored',
  Started = 'Started',
  Succeeded = 'Succeeded'
}

export type Flow = {
  created?: Maybe<CreatedUpdated>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  dataScrubbing?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  failureAlert?: Maybe<Scalars['Boolean']>;
  failureAlertEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  forkCount?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  lastRun?: Maybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  nodes: Array<Step>;
  owner?: Maybe<Scalars['ID']>;
  requireRevisionComments?: Maybe<Scalars['Boolean']>;
  revisions?: Maybe<ModelFlowRevisionConnection>;
  schema?: Maybe<FlowSchema>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  template?: Maybe<TemplateDetails>;
  trackRevisions?: Maybe<Scalars['Boolean']>;
  triggers: Array<Trigger>;
  updated?: Maybe<CreatedUpdated>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  usage?: Maybe<ModelFlowUsageConnection>;
  version?: Maybe<Scalars['Int']>;
};

export type FlowRevisionsArgs = {
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type FlowUsageArgs = {
  date?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelFlowUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type FlowInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  dataScrubbing?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  failureAlert?: InputMaybe<Scalars['Boolean']>;
  failureAlertEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  forkCount?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  instructions?: InputMaybe<Scalars['String']>;
  lastRun?: InputMaybe<Scalars['AWSDateTime']>;
  name?: InputMaybe<Scalars['String']>;
  nodes: Array<StepInput>;
  owner?: InputMaybe<Scalars['ID']>;
  requireRevisionComments?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  template?: InputMaybe<TemplateDetailsInput>;
  trackRevisions?: InputMaybe<Scalars['Boolean']>;
  triggers: Array<TriggerInput>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type FlowRevision = {
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  editedAt: Scalars['AWSDateTime'];
  editedBy: Scalars['ID'];
  flow: Scalars['AWSJSON'];
  flowId: Scalars['ID'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['AWSDateTime'];
  version: Scalars['Int'];
};

export type FlowSchema = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<FlowSchemaItem>>>;
  owner?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type FlowSchemaItem = {
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  output?: Maybe<Scalars['AWSJSON']>;
};

export type FlowSchemaItemInput = {
  id: Scalars['ID'];
  input?: InputMaybe<Scalars['AWSJSON']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
};

export type FlowUsage = {
  totalRuntimeMs: Scalars['Int'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  date: Scalars['AWSDateTime'];
  errorCount: Scalars['Int'];
  flowId: Scalars['ID'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  successCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type GetActionRunDataResult = {
  dataScrubbing?: Maybe<Scalars['Boolean']>;
  endedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  output?: Maybe<Scalars['AWSJSON']>;
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GetFlowRunDataResult = {
  endedAt?: Maybe<Scalars['AWSDateTime']>;
  events?: Maybe<Array<GetRunDataEvent>>;
  flow?: Maybe<Flow>;
  id: Scalars['ID'];
  ownerName?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
  triggeredBy?: Maybe<Scalars['ID']>;
};

export type GetRunDataEvent = {
  endedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  output?: Maybe<Scalars['AWSJSON']>;
  startedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type Group = {
  created?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  lastMembershipUpdated?: Maybe<Scalars['AWSDateTime']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<UsersResult>;
};

export type GroupResult = {
  items?: Maybe<Array<Maybe<Group>>>;
};

export type InnerChoiceRule = {
  and?: Maybe<Array<Maybe<InnerChoiceRule>>>;
  comparator?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  or?: Maybe<Array<Maybe<InnerChoiceRule>>>;
  value?: Maybe<Scalars['String']>;
  variable?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['AWSJSON']>;
};

export type InnerChoiceRuleInput = {
  and?: InputMaybe<Array<InputMaybe<InnerChoiceRuleInput>>>;
  comparator?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<InnerChoiceRuleInput>>>;
  value?: InputMaybe<Scalars['String']>;
  variable?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['AWSJSON']>;
};

export type ListAllFlowsResult = {
  count?: Maybe<Scalars['Int']>;
  items: Array<Flow>;
};

export type ManualApproval = {
  actionId: Scalars['ID'];
  choice: ManualApprovalChoice;
  connectionId?: Maybe<Scalars['ID']>;
  input?: Maybe<Scalars['AWSJSON']>;
  name?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  schema?: Maybe<TaskSchema>;
  timeout?: Maybe<Scalars['Int']>;
};

export type ManualApprovalChoice = {
  approveNext?: Maybe<Array<Maybe<Scalars['String']>>>;
  declineNext?: Maybe<Array<Maybe<Scalars['String']>>>;
  key: Scalars['String'];
};

export type ManualApprovalChoiceInput = {
  approveNext?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  declineNext?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key: Scalars['String'];
};

export type ManualApprovalInput = {
  actionId: Scalars['ID'];
  choice: ManualApprovalChoiceInput;
  connectionId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schema?: InputMaybe<TaskSchemaInput>;
  timeout?: InputMaybe<Scalars['Int']>;
};

export enum ManualApprovalStatus {
  Approve = 'Approve',
  Decline = 'Decline'
}

export type ManualApprovalTaskInput = {
  runId?: InputMaybe<Scalars['ID']>;
  status: ManualApprovalStatus;
  taskToken: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type Map = {
  itemsExpression?: Maybe<Scalars['String']>;
  itemsPath?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  maxConcurrency?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  nodes: Array<Step>;
  schema?: Maybe<TaskSchema>;
};

export type MapInput = {
  itemsExpression?: InputMaybe<Scalars['String']>;
  itemsPath?: InputMaybe<Scalars['String']>;
  maxConcurrency?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nodes: Array<StepInput>;
  schema?: InputMaybe<TaskSchemaInput>;
};

export type ModelActionConnection = {
  items?: Maybe<Array<Maybe<Action>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelActionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelActionFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  discriminator?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  input?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelActionFilterInput>;
  optionalConnection?: InputMaybe<ModelBooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelActionFilterInput>>>;
  output?: InputMaybe<ModelStringFilterInput>;
  packageConnectionIds?: InputMaybe<ModelIdFilterInput>;
  type?: InputMaybe<ModelActionTypeFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelActionTypeFilterInput = {
  eq?: InputMaybe<ActionType>;
  ne?: InputMaybe<ActionType>;
};

export type ModelApiKeyConnection = {
  items?: Maybe<Array<Maybe<ApiKey>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelApiKeyFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelApiKeyFilterInput>>>;
  created?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  key?: InputMaybe<ModelStringFilterInput>;
  lastUsed?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelApiKeyFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelApiKeyFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelBillingConnection = {
  items?: Maybe<Array<Maybe<Billing>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelBillingFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelBillingFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  lifetimeActionInvocations?: InputMaybe<ModelIntFilterInput>;
  lifetimeFlowInvocations?: InputMaybe<ModelIntFilterInput>;
  not?: InputMaybe<ModelBillingFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelBillingFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  totalDataProcessed?: InputMaybe<ModelFloatFilterInput>;
  totalFilesSizeUploaded?: InputMaybe<ModelFloatFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelBillingUsageConnection = {
  items?: Maybe<Array<Maybe<BillingUsage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelBillingUsageFilterInput = {
  actionsCount?: InputMaybe<ModelIntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ModelBillingUsageFilterInput>>>;
  billingId?: InputMaybe<ModelIdFilterInput>;
  dataSize?: InputMaybe<ModelFloatFilterInput>;
  date?: InputMaybe<ModelStringFilterInput>;
  filesSize?: InputMaybe<ModelFloatFilterInput>;
  flowsCount?: InputMaybe<ModelIntFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  isActionLimitExceeded?: InputMaybe<ModelBooleanFilterInput>;
  isActionLimitNotified?: InputMaybe<ModelBooleanFilterInput>;
  isFlowLimitExceeded?: InputMaybe<ModelBooleanFilterInput>;
  isFlowLimitNotified?: InputMaybe<ModelBooleanFilterInput>;
  not?: InputMaybe<ModelBillingUsageFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelBillingUsageFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
};

export type ModelBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelConnectionConnection = {
  items?: Maybe<Array<Maybe<Connection>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  disabled?: InputMaybe<ModelBooleanFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  input?: InputMaybe<ModelStringFilterInput>;
  lastPing?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelConnectionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelConnectionFilterInput>>>;
  output?: InputMaybe<ModelStringFilterInput>;
  owner?: InputMaybe<ModelIdFilterInput>;
  packageConnectionId?: InputMaybe<ModelIdFilterInput>;
  status?: InputMaybe<ModelConnectionStatusFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  version?: InputMaybe<ModelIntFilterInput>;
};

export type ModelConnectionRevisionConnection = {
  items?: Maybe<Array<Maybe<ConnectionRevision>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelConnectionRevisionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelConnectionRevisionFilterInput>>>;
  connection?: InputMaybe<ModelStringFilterInput>;
  connectionId?: InputMaybe<ModelIdFilterInput>;
  editedAt?: InputMaybe<ModelStringFilterInput>;
  editedBy?: InputMaybe<ModelIdFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  not?: InputMaybe<ModelConnectionRevisionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelConnectionRevisionFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  packageConnectionId?: InputMaybe<ModelIdFilterInput>;
  version?: InputMaybe<ModelIntFilterInput>;
};

export type ModelConnectionStatusFilterInput = {
  eq?: InputMaybe<ConnectionStatus>;
  ne?: InputMaybe<ConnectionStatus>;
};

export type ModelDatastoreConnection = {
  items?: Maybe<Array<Maybe<Datastore>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDatastoreFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDatastoreFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  flowId?: InputMaybe<ModelIdFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelDatastoreFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDatastoreFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  type?: InputMaybe<ModelStoreValueTypeFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  value?: InputMaybe<ModelStringFilterInput>;
};

export type ModelEventApprovalStatusFilterInput = {
  eq?: InputMaybe<EventApprovalStatus>;
  ne?: InputMaybe<EventApprovalStatus>;
};

export type ModelEventConnection = {
  items?: Maybe<Array<Maybe<Event>>>;
  nextToken?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
};

export type ModelEventFilterInput = {
  actionId?: InputMaybe<ModelIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ModelEventFilterInput>>>;
  approvalStatus?: InputMaybe<ModelEventApprovalStatusFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  end?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelStringFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  index?: InputMaybe<ModelIntFilterInput>;
  input?: InputMaybe<ModelStringFilterInput>;
  mapKey?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelEventFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEventFilterInput>>>;
  output?: InputMaybe<ModelStringFilterInput>;
  owner?: InputMaybe<ModelStringFilterInput>;
  runId?: InputMaybe<ModelIdFilterInput>;
  start?: InputMaybe<ModelStringFilterInput>;
  taskKey?: InputMaybe<ModelStringFilterInput>;
  taskToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelEventGroupConnection = {
  items?: Maybe<Array<Maybe<EventGroup>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelEventGroupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelEventGroupFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  end?: InputMaybe<ModelStringFilterInput>;
  error?: InputMaybe<ModelStringFilterInput>;
  flowId?: InputMaybe<ModelIdFilterInput>;
  flowVersion?: InputMaybe<ModelIntFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  not?: InputMaybe<ModelEventGroupFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEventGroupFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  start?: InputMaybe<ModelStringFilterInput>;
  status?: InputMaybe<ModelEventStatusFilterInput>;
  triggeredBy?: InputMaybe<ModelIdFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelEventStatusFilterInput = {
  eq?: InputMaybe<EventStatus>;
  ne?: InputMaybe<EventStatus>;
};

export type ModelFloatFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ModelFlowConnection = {
  items?: Maybe<Array<Maybe<Flow>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFlowFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelFlowFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  dataScrubbing?: InputMaybe<ModelBooleanFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  disabled?: InputMaybe<ModelBooleanFilterInput>;
  failureAlert?: InputMaybe<ModelBooleanFilterInput>;
  failureAlertEmails?: InputMaybe<ModelStringFilterInput>;
  forkCount?: InputMaybe<ModelIntFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  instructions?: InputMaybe<ModelStringFilterInput>;
  lastRun?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelFlowFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFlowFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  requireRevisionComments?: InputMaybe<ModelBooleanFilterInput>;
  tags?: InputMaybe<ModelStringFilterInput>;
  trackRevisions?: InputMaybe<ModelBooleanFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  version?: InputMaybe<ModelIntFilterInput>;
};

export type ModelFlowRevisionConnection = {
  items?: Maybe<Array<Maybe<FlowRevision>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFlowRevisionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelFlowRevisionFilterInput>>>;
  comment?: InputMaybe<ModelStringFilterInput>;
  editedAt?: InputMaybe<ModelStringFilterInput>;
  editedBy?: InputMaybe<ModelIdFilterInput>;
  flow?: InputMaybe<ModelStringFilterInput>;
  flowId?: InputMaybe<ModelIdFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  not?: InputMaybe<ModelFlowRevisionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFlowRevisionFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  version?: InputMaybe<ModelIntFilterInput>;
};

export type ModelFlowSchemaConnection = {
  items?: Maybe<Array<Maybe<FlowSchema>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFlowSchemaFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelFlowSchemaFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  not?: InputMaybe<ModelFlowSchemaFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFlowSchemaFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelFlowUsageConnection = {
  totalRuntimeMs: Scalars['Int'];
  items?: Maybe<Array<Maybe<FlowUsage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelFlowUsageFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelFlowUsageFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  date?: InputMaybe<ModelStringFilterInput>;
  errorCount?: InputMaybe<ModelIntFilterInput>;
  flowId?: InputMaybe<ModelIdFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  not?: InputMaybe<ModelFlowUsageFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFlowUsageFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  successCount?: InputMaybe<ModelIntFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelIdFilterInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
};

export type ModelIntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ModelNotificationConnection = {
  items?: Maybe<Array<Maybe<Notification>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelNotificationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  date?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  level?: InputMaybe<ModelNotificationLevelFilterInput>;
  link?: InputMaybe<ModelStringFilterInput>;
  message?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelNotificationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  referenceId?: InputMaybe<ModelIdFilterInput>;
  title?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelNotificationLevelFilterInput = {
  eq?: InputMaybe<NotificationLevel>;
  ne?: InputMaybe<NotificationLevel>;
};

export type ModelPackageConnection = {
  items?: Maybe<Array<Maybe<Package>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPackageConnectionConnection = {
  items?: Maybe<Array<Maybe<PackageConnection>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelPackageConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPackageConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  discriminator?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  input?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelPackageConnectionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPackageConnectionFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type ModelPackageFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelPackageFilterInput>>>;
  categories?: InputMaybe<ModelStringFilterInput>;
  created?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  lambdaARN?: InputMaybe<ModelStringFilterInput>;
  license?: InputMaybe<ModelStringFilterInput>;
  markdown?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelPackageFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelPackageFilterInput>>>;
  repository?: InputMaybe<ModelStringFilterInput>;
  runtime?: InputMaybe<ModelRuntimeTypeFilterInput>;
  schema?: InputMaybe<ModelStringFilterInput>;
  status?: InputMaybe<ModelPackageStatusFilterInput>;
  updated?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  usageCount?: InputMaybe<ModelIntFilterInput>;
  version?: InputMaybe<ModelStringFilterInput>;
};

export type ModelPackageStatusFilterInput = {
  eq?: InputMaybe<PackageStatus>;
  ne?: InputMaybe<PackageStatus>;
};

export type ModelRuntimeTypeFilterInput = {
  eq?: InputMaybe<RuntimeType>;
  ne?: InputMaybe<RuntimeType>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStoreValueTypeFilterInput = {
  eq?: InputMaybe<StoreValueType>;
  ne?: InputMaybe<StoreValueType>;
};

export type ModelStringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
};

export type ModelUploadConnection = {
  items?: Maybe<Array<Maybe<Upload>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUploadFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUploadFilterInput>>>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  eventId?: InputMaybe<ModelIdFilterInput>;
  fileName?: InputMaybe<ModelStringFilterInput>;
  flowId?: InputMaybe<ModelIdFilterInput>;
  group?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  mimeType?: InputMaybe<ModelStringFilterInput>;
  not?: InputMaybe<ModelUploadFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUploadFilterInput>>>;
  owner?: InputMaybe<ModelIdFilterInput>;
  size?: InputMaybe<ModelIntFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
};

export type Mutation = {
  addUserToGroup?: Maybe<Group>;
  authorizeConnection?: Maybe<AuthorizeConnectionOutput>;
  createAction?: Maybe<Action>;
  createApiKey?: Maybe<ApiKey>;
  createApiToken?: Maybe<CreateApiTokenResult>;
  createBilling?: Maybe<Billing>;
  createBillingUsage?: Maybe<BillingUsage>;
  createConnection?: Maybe<Connection>;
  createConnectionRevision?: Maybe<ConnectionRevision>;
  createDatastore?: Maybe<Datastore>;
  createEvent?: Maybe<Event>;
  createEventGroup?: Maybe<EventGroup>;
  createFlow?: Maybe<Flow>;
  createFlowRevision?: Maybe<FlowRevision>;
  createFlowSchema?: Maybe<FlowSchema>;
  createFlowUsage?: Maybe<FlowUsage>;
  createGroup?: Maybe<Group>;
  createNotification?: Maybe<Notification>;
  createPackage?: Maybe<Package>;
  createPackageConnection?: Maybe<PackageConnection>;
  createUpload?: Maybe<Upload>;
  createUser?: Maybe<Scalars['Boolean']>;
  deleteAction?: Maybe<Action>;
  deleteApiKey?: Maybe<ApiKey>;
  deleteBilling?: Maybe<Billing>;
  deleteBillingUsage?: Maybe<BillingUsage>;
  deleteConnection?: Maybe<Connection>;
  deleteConnectionRevision?: Maybe<ConnectionRevision>;
  deleteDatastore?: Maybe<Datastore>;
  deleteEvent?: Maybe<Event>;
  deleteEventGroup?: Maybe<EventGroup>;
  deleteFlow?: Maybe<Flow>;
  deleteFlowRevision?: Maybe<FlowRevision>;
  deleteFlowSchema?: Maybe<FlowSchema>;
  deleteFlowUsage?: Maybe<FlowUsage>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Notification>;
  deletePackage?: Maybe<Package>;
  deletePackageConnection?: Maybe<PackageConnection>;
  deleteUpload?: Maybe<Upload>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  manualApprovalTask?: Maybe<Scalars['Boolean']>;
  removeUserFromGroup?: Maybe<Scalars['Boolean']>;
  runAction?: Maybe<RunActionOutput>;
  runFlow?: Maybe<RunFlowOutput>;
  saveConnection?: Maybe<Connection>;
  saveDatastore?: Maybe<Datastore>;
  saveFlow?: Maybe<Flow>;
  shareConnection?: Maybe<Connection>;
  shareFlow?: Maybe<Flow>;
  testAllConnections?: Maybe<Scalars['Boolean']>;
  testConnection?: Maybe<TestConnectionOutput>;
  updateAction?: Maybe<Action>;
  updateApiKey?: Maybe<ApiKey>;
  updateBilling?: Maybe<Billing>;
  updateBillingUsage?: Maybe<BillingUsage>;
  updateConnection?: Maybe<Connection>;
  updateConnectionRevision?: Maybe<ConnectionRevision>;
  updateDatastore?: Maybe<Datastore>;
  updateEvent?: Maybe<Event>;
  updateEventGroup?: Maybe<EventGroup>;
  updateFlow?: Maybe<Flow>;
  updateFlowRevision?: Maybe<FlowRevision>;
  updateFlowSchema?: Maybe<FlowSchema>;
  updateFlowUsage?: Maybe<FlowUsage>;
  updateGroup?: Maybe<Group>;
  updateNotification?: Maybe<Notification>;
  updatePackage?: Maybe<Package>;
  updatePackageConnection?: Maybe<PackageConnection>;
  updateUpload?: Maybe<Upload>;
  updateUser?: Maybe<User>;
};

export type MutationAddUserToGroupArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAuthorizeConnectionArgs = {
  input: AuthorizeConnectionInput;
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationCreateApiTokenArgs = {
  input?: InputMaybe<CreateApiTokenInput>;
};

export type MutationCreateBillingArgs = {
  input: CreateBillingInput;
};

export type MutationCreateBillingUsageArgs = {
  input: CreateBillingUsageInput;
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationCreateConnectionRevisionArgs = {
  input: CreateConnectionRevisionInput;
};

export type MutationCreateDatastoreArgs = {
  input: CreateDatastoreInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateEventGroupArgs = {
  input: CreateEventGroupInput;
};

export type MutationCreateFlowArgs = {
  input: CreateFlowInput;
};

export type MutationCreateFlowRevisionArgs = {
  input: CreateFlowRevisionInput;
};

export type MutationCreateFlowSchemaArgs = {
  input: CreateFlowSchemaInput;
};

export type MutationCreateFlowUsageArgs = {
  input: CreateFlowUsageInput;
};

export type MutationCreateGroupArgs = {
  groupInput: CreateGroupInput;
  userId: Scalars['ID'];
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};

export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};

export type MutationCreatePackageConnectionArgs = {
  input: CreatePackageConnectionInput;
};

export type MutationCreateUploadArgs = {
  input: CreateUploadInput;
};

export type MutationCreateUserArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  user: CreateUserInput;
};

export type MutationDeleteActionArgs = {
  input: DeleteActionInput;
};

export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};

export type MutationDeleteBillingArgs = {
  input: DeleteBillingInput;
};

export type MutationDeleteBillingUsageArgs = {
  input: DeleteBillingUsageInput;
};

export type MutationDeleteConnectionArgs = {
  input: DeleteConnectionInput;
};

export type MutationDeleteConnectionRevisionArgs = {
  input: DeleteConnectionRevisionInput;
};

export type MutationDeleteDatastoreArgs = {
  input: DeleteDatastoreInput;
};

export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};

export type MutationDeleteEventGroupArgs = {
  input: DeleteEventGroupInput;
};

export type MutationDeleteFlowArgs = {
  input: DeleteFlowInput;
};

export type MutationDeleteFlowRevisionArgs = {
  input: DeleteFlowRevisionInput;
};

export type MutationDeleteFlowSchemaArgs = {
  input: DeleteFlowSchemaInput;
};

export type MutationDeleteFlowUsageArgs = {
  input: DeleteFlowUsageInput;
};

export type MutationDeleteGroupArgs = {
  groupId: Scalars['ID'];
};

export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
};

export type MutationDeletePackageArgs = {
  input: DeletePackageInput;
};

export type MutationDeletePackageConnectionArgs = {
  input: DeletePackageConnectionInput;
};

export type MutationDeleteUploadArgs = {
  input: DeleteUploadInput;
};

export type MutationManualApprovalTaskArgs = {
  input: ManualApprovalTaskInput;
};

export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRunActionArgs = {
  input: RunActionInput;
};

export type MutationRunFlowArgs = {
  input: RunFlowInput;
};

export type MutationSaveConnectionArgs = {
  input: SaveConnectionInput;
};

export type MutationSaveDatastoreArgs = {
  input: SaveDatastoreInput;
};

export type MutationSaveFlowArgs = {
  input: SaveFlowInput;
};

export type MutationShareConnectionArgs = {
  input: ShareConnectionInput;
};

export type MutationShareFlowArgs = {
  input: ShareFlowInput;
};

export type MutationTestConnectionArgs = {
  input: TestConnectionInput;
};

export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};

export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};

export type MutationUpdateBillingArgs = {
  input: UpdateBillingInput;
};

export type MutationUpdateBillingUsageArgs = {
  input: UpdateBillingUsageInput;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateConnectionRevisionArgs = {
  input: UpdateConnectionRevisionInput;
};

export type MutationUpdateDatastoreArgs = {
  input: UpdateDatastoreInput;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type MutationUpdateEventGroupArgs = {
  input: UpdateEventGroupInput;
};

export type MutationUpdateFlowArgs = {
  input: UpdateFlowInput;
};

export type MutationUpdateFlowRevisionArgs = {
  input: UpdateFlowRevisionInput;
};

export type MutationUpdateFlowSchemaArgs = {
  input: UpdateFlowSchemaInput;
};

export type MutationUpdateFlowUsageArgs = {
  input: UpdateFlowUsageInput;
};

export type MutationUpdateGroupArgs = {
  groupInput: UpdateGroupInput;
};

export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};

export type MutationUpdatePackageArgs = {
  input: UpdatePackageInput;
};

export type MutationUpdatePackageConnectionArgs = {
  input: UpdatePackageConnectionInput;
};

export type MutationUpdateUploadArgs = {
  input: UpdateUploadInput;
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type Notification = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  date: Scalars['AWSDateTime'];
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  level?: Maybe<NotificationLevel>;
  link?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  referenceId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export enum NotificationLevel {
  Announcement = 'Announcement',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning'
}

export type Package = {
  actions?: Maybe<ModelActionConnection>;
  author?: Maybe<PackageAuthorDetails>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<ModelPackageConnectionConnection>;
  created?: Maybe<Scalars['AWSDateTime']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lambdaARN?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<PackageDetails>;
  repository: Scalars['String'];
  runtime?: Maybe<RuntimeType>;
  schema?: Maybe<Scalars['String']>;
  status: PackageStatus;
  updated?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  usageCount?: Maybe<Scalars['Int']>;
  vendor?: Maybe<PackageDetails>;
  version?: Maybe<Scalars['String']>;
};

export type PackageActionsArgs = {
  filter?: InputMaybe<ModelActionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type PackageConnectionsArgs = {
  filter?: InputMaybe<ModelPackageConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type PackageAuthorDetails = {
  email: Scalars['String'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PackageAuthorDetailsInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  picture?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PackageConnection = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  discriminator?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  package: Package;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  packageConnectionPackageId?: Maybe<Scalars['String']>;
};

export type PackageDetails = {
  logo?: Maybe<Scalars['String']>;
  logoBw?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PackageDetailsInput = {
  logo?: InputMaybe<Scalars['String']>;
  logoBw?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum PackageStatus {
  Approved = 'Approved',
  Deployed = 'Deployed',
  Deprecated = 'Deprecated',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type Query = {
  eventGroupsByFlowStart?: Maybe<ModelEventGroupConnection>;
  flowUsageByDate?: Maybe<ModelFlowUsageConnection>;
  getAction?: Maybe<Action>;
  getActionEvent?: Maybe<GetActionRunDataResult>;
  getApiKey?: Maybe<ApiKey>;
  getBilling?: Maybe<Billing>;
  getBillingUsage?: Maybe<BillingUsage>;
  getConnection?: Maybe<Connection>;
  getConnectionRevision?: Maybe<ConnectionRevision>;
  getDatastore?: Maybe<Datastore>;
  getDatastoreAndValues?: Maybe<DatastoreResult>;
  getEvent?: Maybe<Event>;
  getEventGroup?: Maybe<EventGroup>;
  getFlow?: Maybe<Flow>;
  getFlowEvent?: Maybe<GetFlowRunDataResult>;
  getFlowRevision?: Maybe<FlowRevision>;
  getFlowSchema?: Maybe<FlowSchema>;
  getFlowUsage?: Maybe<FlowUsage>;
  getGroup?: Maybe<Group>;
  getNotification?: Maybe<Notification>;
  getPackage?: Maybe<Package>;
  getPackageConnection?: Maybe<PackageConnection>;
  getUpload?: Maybe<Upload>;
  getUser?: Maybe<User>;
  listActions?: Maybe<ModelActionConnection>;
  listAllFlows?: Maybe<ListAllFlowsResult>;
  listApiKeys?: Maybe<ModelApiKeyConnection>;
  listBillingUsages?: Maybe<ModelBillingUsageConnection>;
  listBillings?: Maybe<ModelBillingConnection>;
  listCommunityFlows?: Maybe<CommunityFlowResult>;
  listConnectionRevisions?: Maybe<ModelConnectionRevisionConnection>;
  listConnections?: Maybe<ModelConnectionConnection>;
  listDatastores?: Maybe<ModelDatastoreConnection>;
  listDatastoresAndValues?: Maybe<DatastoreResult>;
  listEventGroups?: Maybe<ModelEventGroupConnection>;
  listEvents?: Maybe<ModelEventConnection>;
  listFlowRevisions?: Maybe<ModelFlowRevisionConnection>;
  listFlowSchemas?: Maybe<ModelFlowSchemaConnection>;
  listFlowUsages?: Maybe<ModelFlowUsageConnection>;
  listFlows?: Maybe<ModelFlowConnection>;
  listGroups?: Maybe<GroupResult>;
  listNotifications?: Maybe<ModelNotificationConnection>;
  listPackageConnections?: Maybe<ModelPackageConnectionConnection>;
  listPackages?: Maybe<ModelPackageConnection>;
  listUploads?: Maybe<ModelUploadConnection>;
  listUserGroups?: Maybe<GroupResult>;
  listUsers?: Maybe<UsersResult>;
  listUsersInGroup?: Maybe<UsersResult>;
  revisionsByConnectionEditedAt?: Maybe<ModelConnectionRevisionConnection>;
  revisionsByConnectionEditedByEditedAt?: Maybe<ModelConnectionRevisionConnection>;
  revisionsByEditedByEditedAt?: Maybe<ModelFlowRevisionConnection>;
  revisionsByFlowEditedAt?: Maybe<ModelFlowRevisionConnection>;
};

export type QueryEventGroupsByFlowStartArgs = {
  filter?: InputMaybe<ModelEventGroupFilterInput>;
  flowId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  start?: InputMaybe<ModelStringKeyConditionInput>;
};

export type QueryFlowUsageByDateArgs = {
  date?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelFlowUsageFilterInput>;
  flowId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryGetActionArgs = {
  id: Scalars['ID'];
};

export type QueryGetActionEventArgs = {
  runId: Scalars['ID'];
};

export type QueryGetApiKeyArgs = {
  id: Scalars['ID'];
};

export type QueryGetBillingArgs = {
  id: Scalars['ID'];
};

export type QueryGetBillingUsageArgs = {
  id: Scalars['ID'];
};

export type QueryGetConnectionArgs = {
  id: Scalars['ID'];
};

export type QueryGetConnectionRevisionArgs = {
  id: Scalars['ID'];
};

export type QueryGetDatastoreArgs = {
  id: Scalars['ID'];
};

export type QueryGetDatastoreAndValuesArgs = {
  id: Scalars['ID'];
};

export type QueryGetEventArgs = {
  id: Scalars['ID'];
};

export type QueryGetEventGroupArgs = {
  id: Scalars['ID'];
};

export type QueryGetFlowArgs = {
  id: Scalars['ID'];
};

export type QueryGetFlowEventArgs = {
  flowId: Scalars['ID'];
  runId: Scalars['ID'];
};

export type QueryGetFlowRevisionArgs = {
  id: Scalars['ID'];
};

export type QueryGetFlowSchemaArgs = {
  id: Scalars['ID'];
};

export type QueryGetFlowUsageArgs = {
  id: Scalars['ID'];
};

export type QueryGetGroupArgs = {
  groupId: Scalars['ID'];
};

export type QueryGetNotificationArgs = {
  date: Scalars['AWSDateTime'];
  id: Scalars['ID'];
};

export type QueryGetPackageArgs = {
  id: Scalars['ID'];
};

export type QueryGetPackageConnectionArgs = {
  id: Scalars['ID'];
};

export type QueryGetUploadArgs = {
  id: Scalars['ID'];
};

export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};

export type QueryListActionsArgs = {
  filter?: InputMaybe<ModelActionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListApiKeysArgs = {
  filter?: InputMaybe<ModelApiKeyFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListBillingUsagesArgs = {
  filter?: InputMaybe<ModelBillingUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListBillingsArgs = {
  filter?: InputMaybe<ModelBillingFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListConnectionRevisionsArgs = {
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListConnectionsArgs = {
  filter?: InputMaybe<ModelConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListDatastoresArgs = {
  filter?: InputMaybe<ModelDatastoreFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListEventGroupsArgs = {
  filter?: InputMaybe<ModelEventGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListEventsArgs = {
  filter?: InputMaybe<ModelEventFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListFlowRevisionsArgs = {
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListFlowSchemasArgs = {
  filter?: InputMaybe<ModelFlowSchemaFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListFlowUsagesArgs = {
  filter?: InputMaybe<ModelFlowUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListFlowsArgs = {
  filter?: InputMaybe<ModelFlowFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListGroupsArgs = {
  filter?: InputMaybe<Scalars['String']>;
};

export type QueryListNotificationsArgs = {
  date?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelNotificationFilterInput>;
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryListPackageConnectionsArgs = {
  filter?: InputMaybe<ModelPackageConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListPackagesArgs = {
  filter?: InputMaybe<ModelPackageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListUploadsArgs = {
  filter?: InputMaybe<ModelUploadFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListUserGroupsArgs = {
  includeGroupUsers?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type QueryListUsersArgs = {
  filter?: InputMaybe<Scalars['String']>;
};

export type QueryListUsersInGroupArgs = {
  groupId: Scalars['ID'];
};

export type QueryRevisionsByConnectionEditedAtArgs = {
  connectionId?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryRevisionsByConnectionEditedByEditedAtArgs = {
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  editedBy?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryRevisionsByEditedByEditedAtArgs = {
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  editedBy?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryRevisionsByFlowEditedAtArgs = {
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  flowId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Retry = {
  expression?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Int']>;
  maxTimeout?: Maybe<Scalars['Int']>;
  minTimeout?: Maybe<Scalars['Int']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  retries?: Maybe<Scalars['Int']>;
};

export type RetryInput = {
  expression?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  maxTimeout?: InputMaybe<Scalars['Int']>;
  minTimeout?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retries?: InputMaybe<Scalars['Int']>;
};

export type RunActionAsyncOutput = {
  runId: Scalars['ID'];
};

export type RunActionInput = {
  async?: InputMaybe<Scalars['Boolean']>;
  connectionId?: InputMaybe<Scalars['ID']>;
  dataScrubbing?: InputMaybe<Scalars['Boolean']>;
  flowId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: InputMaybe<Scalars['AWSJSON']>;
  log?: InputMaybe<Scalars['Boolean']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['AWSJSON']>;
};

export type RunActionOutput = {
  id: Scalars['ID'];
  output?: Maybe<Scalars['AWSJSON']>;
};

export type RunFlowInput = {
  dataScrubbing?: InputMaybe<Scalars['Boolean']>;
  flow?: InputMaybe<FlowInput>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['AWSJSON']>;
  trigger?: InputMaybe<RunFlowTriggerInput>;
};

export type RunFlowOutput = {
  runId: Scalars['ID'];
};

export type RunFlowTriggerInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  key: Scalars['String'];
  uploadedFiles?: InputMaybe<Array<UploadFileData>>;
};

export enum RuntimeType {
  Nodejs_12X = 'NODEJS_12_X',
  Nodejs_14X = 'NODEJS_14_X'
}

export type SaveConnectionInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  lastPing?: InputMaybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
  output?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['ID']>;
  packageConnectionId: Scalars['ID'];
  status?: InputMaybe<ConnectionStatus>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type SaveDatastoreInput = {
  created?: InputMaybe<StoreValueCreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  type: StoreValueType;
  updated?: InputMaybe<StoreValueCreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type SaveFlowInput = {
  comment?: InputMaybe<Scalars['String']>;
  flow: FlowInput;
};

export type ShareConnectionInput = {
  connectionId: Scalars['ID'];
  group?: InputMaybe<Scalars['ID']>;
};

export type ShareFlowInput = {
  comment?: InputMaybe<Scalars['String']>;
  flowId: Scalars['ID'];
  group?: InputMaybe<Scalars['ID']>;
};

export type Step = {
  approval?: Maybe<ManualApproval>;
  choice?: Maybe<Choice>;
  disabled?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  map?: Maybe<Map>;
  task?: Maybe<Task>;
};

export type StepInput = {
  approval?: InputMaybe<ManualApprovalInput>;
  choice?: InputMaybe<ChoiceInput>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  map?: InputMaybe<MapInput>;
  task?: InputMaybe<TaskInput>;
};

export type StoreValueCreatedUpdated = {
  date?: Maybe<Scalars['AWSDateTime']>;
  flowId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type StoreValueCreatedUpdatedInput = {
  date?: InputMaybe<Scalars['AWSDateTime']>;
  flowId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
};

export enum StoreValueType {
  List = 'LIST',
  Single = 'SINGLE'
}

export type Subscription = {
  onCreateAction?: Maybe<Action>;
  onCreateApiKey?: Maybe<ApiKey>;
  onCreateBilling?: Maybe<Billing>;
  onCreateBillingUsage?: Maybe<BillingUsage>;
  onCreateConnection?: Maybe<Connection>;
  onCreateConnectionRevision?: Maybe<ConnectionRevision>;
  onCreateDatastore?: Maybe<Datastore>;
  onCreateEvent?: Maybe<Event>;
  onCreateEventGroup?: Maybe<EventGroup>;
  onCreateFlow?: Maybe<Flow>;
  onCreateFlowRevision?: Maybe<FlowRevision>;
  onCreateFlowSchema?: Maybe<FlowSchema>;
  onCreateFlowUsage?: Maybe<FlowUsage>;
  onCreateNotification?: Maybe<Notification>;
  onCreatePackage?: Maybe<Package>;
  onCreatePackageConnection?: Maybe<PackageConnection>;
  onCreateUpload?: Maybe<Upload>;
  onDeleteAction?: Maybe<Action>;
  onDeleteApiKey?: Maybe<ApiKey>;
  onDeleteBilling?: Maybe<Billing>;
  onDeleteBillingUsage?: Maybe<BillingUsage>;
  onDeleteConnection?: Maybe<Connection>;
  onDeleteConnectionRevision?: Maybe<ConnectionRevision>;
  onDeleteDatastore?: Maybe<Datastore>;
  onDeleteEvent?: Maybe<Event>;
  onDeleteEventGroup?: Maybe<EventGroup>;
  onDeleteFlow?: Maybe<Flow>;
  onDeleteFlowRevision?: Maybe<FlowRevision>;
  onDeleteFlowSchema?: Maybe<FlowSchema>;
  onDeleteFlowUsage?: Maybe<FlowUsage>;
  onDeleteNotification?: Maybe<Notification>;
  onDeletePackage?: Maybe<Package>;
  onDeletePackageConnection?: Maybe<PackageConnection>;
  onDeleteUpload?: Maybe<Upload>;
  onUpdateAction?: Maybe<Action>;
  onUpdateApiKey?: Maybe<ApiKey>;
  onUpdateBilling?: Maybe<Billing>;
  onUpdateBillingUsage?: Maybe<BillingUsage>;
  onUpdateConnection?: Maybe<Connection>;
  onUpdateConnectionRevision?: Maybe<ConnectionRevision>;
  onUpdateDatastore?: Maybe<Datastore>;
  onUpdateEvent?: Maybe<Event>;
  onUpdateEventGroup?: Maybe<EventGroup>;
  onUpdateFlow?: Maybe<Flow>;
  onUpdateFlowRevision?: Maybe<FlowRevision>;
  onUpdateFlowSchema?: Maybe<FlowSchema>;
  onUpdateFlowUsage?: Maybe<FlowUsage>;
  onUpdateNotification?: Maybe<Notification>;
  onUpdatePackage?: Maybe<Package>;
  onUpdatePackageConnection?: Maybe<PackageConnection>;
  onUpdateUpload?: Maybe<Upload>;
  subscribeToConnectionChanges?: Maybe<Connection>;
  subscribeToDatastoreChanges?: Maybe<Datastore>;
  subscribeToEvent?: Maybe<Event>;
  subscribeToEventGroup?: Maybe<EventGroup>;
  subscribeToFlowChanges?: Maybe<Flow>;
  subscribeToFlowEventGroup?: Maybe<EventGroup>;
  subscribeToHistoryChanges?: Maybe<FlowRevision>;
  subscribeToPackageChanges?: Maybe<Package>;
};

export type SubscriptionOnCreateApiKeyArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateBillingArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateBillingUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateConnectionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateConnectionRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateDatastoreArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateEventArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateEventGroupArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateFlowArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateFlowRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateFlowSchemaArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateFlowUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateNotificationArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnCreateUploadArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteApiKeyArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteBillingArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteBillingUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteConnectionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteConnectionRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteDatastoreArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteEventArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteEventGroupArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteFlowArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteFlowRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteFlowSchemaArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteFlowUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteNotificationArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnDeleteUploadArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateApiKeyArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateBillingArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateBillingUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateConnectionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateConnectionRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateDatastoreArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateEventArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateEventGroupArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateFlowArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateFlowRevisionArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateFlowSchemaArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateFlowUsageArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateNotificationArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionOnUpdateUploadArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type SubscriptionSubscribeToConnectionChangesArgs = {
  id: Scalars['ID'];
};

export type SubscriptionSubscribeToDatastoreChangesArgs = {
  id: Scalars['ID'];
};

export type SubscriptionSubscribeToEventArgs = {
  runId: Scalars['ID'];
};

export type SubscriptionSubscribeToEventGroupArgs = {
  id: Scalars['ID'];
};

export type SubscriptionSubscribeToFlowChangesArgs = {
  id: Scalars['ID'];
};

export type SubscriptionSubscribeToFlowEventGroupArgs = {
  flowId: Scalars['ID'];
};

export type SubscriptionSubscribeToHistoryChangesArgs = {
  flowId: Scalars['ID'];
};

export type SubscriptionSubscribeToPackageChangesArgs = {
  id: Scalars['ID'];
};

export type Task = {
  actionId: Scalars['ID'];
  catch?: Maybe<Array<Maybe<Catch>>>;
  connectionId?: Maybe<Scalars['ID']>;
  input?: Maybe<Scalars['AWSJSON']>;
  name?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  retry?: Maybe<Retry>;
  schema?: Maybe<TaskSchema>;
  timeout?: Maybe<Scalars['Int']>;
};

export type TaskInput = {
  actionId: Scalars['ID'];
  catch?: InputMaybe<Array<InputMaybe<CatchInput>>>;
  connectionId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retry?: InputMaybe<RetryInput>;
  schema?: InputMaybe<TaskSchemaInput>;
  timeout?: InputMaybe<Scalars['Int']>;
};

export type TaskSchema = {
  inputComponents?: Maybe<Array<Maybe<Component>>>;
  outputComponent?: Maybe<Scalars['String']>;
};

export type TaskSchemaInput = {
  inputComponents?: InputMaybe<Array<InputMaybe<ComponentInput>>>;
  outputComponent?: InputMaybe<Scalars['String']>;
};

export type TemplateDetails = {
  author?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TemplateDetailsInput = {
  author?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type TestConnectionInput = {
  connectionId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  packageConnectionId: Scalars['ID'];
};

export type TestConnectionOutput = {
  output?: Maybe<Scalars['AWSJSON']>;
  success: Scalars['Boolean'];
  errors?: any[];
};

export type Trigger = {
  actionId?: Maybe<Scalars['ID']>;
  connectionId?: Maybe<Scalars['ID']>;
  disabled?: Maybe<Scalars['Boolean']>;
  input?: Maybe<Scalars['AWSJSON']>;
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  next?: Maybe<Array<Maybe<Scalars['String']>>>;
  schema?: Maybe<TaskSchema>;
};

export type TriggerInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  connectionId?: InputMaybe<Scalars['ID']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  next?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  schema?: InputMaybe<TaskSchemaInput>;
};

export type UpdateActionInput = {
  actionPackageId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discriminator?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  optionalConnection?: InputMaybe<Scalars['Boolean']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
  packageConnectionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<ActionType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateApiKeyInput = {
  created?: InputMaybe<Scalars['AWSDateTime']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: InputMaybe<Scalars['String']>;
  lastUsed?: InputMaybe<Scalars['AWSDateTime']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateBillingInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  lifetimeActionInvocations?: InputMaybe<Scalars['Int']>;
  lifetimeFlowInvocations?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['ID']>;
  plan?: InputMaybe<BillingPlanInput>;
  totalDataProcessed?: InputMaybe<Scalars['Float']>;
  totalFilesSizeUploaded?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateBillingUsageInput = {
  actionsCount?: InputMaybe<Scalars['Int']>;
  billingId?: InputMaybe<Scalars['ID']>;
  dataSize?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['AWSDateTime']>;
  filesSize?: InputMaybe<Scalars['Float']>;
  flowsCount?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  isActionLimitExceeded?: InputMaybe<Scalars['Boolean']>;
  isActionLimitNotified?: InputMaybe<Scalars['Boolean']>;
  isFlowLimitExceeded?: InputMaybe<Scalars['Boolean']>;
  isFlowLimitNotified?: InputMaybe<Scalars['Boolean']>;
  owner?: InputMaybe<Scalars['ID']>;
};

export type UpdateConnectionInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: InputMaybe<Scalars['AWSJSON']>;
  lastPing?: InputMaybe<Scalars['AWSDateTime']>;
  name?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['ID']>;
  packageConnectionId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ConnectionStatus>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateConnectionRevisionInput = {
  connection?: InputMaybe<Scalars['AWSJSON']>;
  connectionId?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<Scalars['AWSDateTime']>;
  editedBy?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: InputMaybe<Scalars['ID']>;
  packageConnectionId?: InputMaybe<Scalars['ID']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateDatastoreInput = {
  created?: InputMaybe<StoreValueCreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<StoreValueType>;
  updated?: InputMaybe<StoreValueCreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateEventGroupInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  end?: InputMaybe<Scalars['AWSDateTime']>;
  error?: InputMaybe<Scalars['String']>;
  flowId?: InputMaybe<Scalars['ID']>;
  flowVersion?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['AWSDateTime']>;
  status?: InputMaybe<EventStatus>;
  triggeredBy?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateEventInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  approvalStatus?: InputMaybe<EventApprovalStatus>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  end?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<Scalars['AWSJSON']>;
  mapKey?: InputMaybe<Scalars['String']>;
  output?: InputMaybe<Scalars['AWSJSON']>;
  owner?: InputMaybe<Scalars['String']>;
  runId?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['AWSDateTime']>;
  taskKey?: InputMaybe<Scalars['String']>;
  taskToken?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateFlowInput = {
  created?: InputMaybe<CreatedUpdatedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  dataScrubbing?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  failureAlert?: InputMaybe<Scalars['Boolean']>;
  failureAlertEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  forkCount?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  lastRun?: InputMaybe<Scalars['AWSDateTime']>;
  name?: InputMaybe<Scalars['String']>;
  nodes?: InputMaybe<Array<StepInput>>;
  owner?: InputMaybe<Scalars['ID']>;
  requireRevisionComments?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  template?: InputMaybe<TemplateDetailsInput>;
  trackRevisions?: InputMaybe<Scalars['Boolean']>;
  triggers?: InputMaybe<Array<TriggerInput>>;
  updated?: InputMaybe<CreatedUpdatedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateFlowRevisionInput = {
  comment?: InputMaybe<Scalars['String']>;
  editedAt?: InputMaybe<Scalars['AWSDateTime']>;
  editedBy?: InputMaybe<Scalars['ID']>;
  flow?: InputMaybe<Scalars['AWSJSON']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: InputMaybe<Scalars['ID']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateFlowSchemaInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  items?: InputMaybe<Array<InputMaybe<FlowSchemaItemInput>>>;
  owner?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateFlowUsageInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  date?: InputMaybe<Scalars['AWSDateTime']>;
  errorCount?: InputMaybe<Scalars['Int']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  owner?: InputMaybe<Scalars['ID']>;
  successCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateGroupInput = {
  created?: InputMaybe<Scalars['AWSDateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['String']>;
  lastMembershipUpdated?: InputMaybe<Scalars['AWSDateTime']>;
  lastUpdated?: InputMaybe<Scalars['AWSDateTime']>;
  name: Scalars['String'];
};

export type UpdateNotificationInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  date: Scalars['AWSDateTime'];
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  level?: InputMaybe<NotificationLevel>;
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdatePackageConnectionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  description?: InputMaybe<Scalars['String']>;
  discriminator?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  input?: InputMaybe<Scalars['AWSJSON']>;
  name?: InputMaybe<Scalars['String']>;
  packageConnectionPackageId?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdatePackageInput = {
  author?: InputMaybe<PackageAuthorDetailsInput>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  created?: InputMaybe<Scalars['AWSDateTime']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  lambdaARN?: InputMaybe<Scalars['String']>;
  license?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<PackageDetailsInput>;
  repository?: InputMaybe<Scalars['String']>;
  runtime?: InputMaybe<RuntimeType>;
  schema?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PackageStatus>;
  updated?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  usageCount?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<PackageDetailsInput>;
  version?: InputMaybe<Scalars['String']>;
};

export type UpdateUploadInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  eventId?: InputMaybe<Scalars['ID']>;
  fileName?: InputMaybe<Scalars['String']>;
  flowId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  mimeType?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type UpdateUserInput = {
  do_not_track?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  integrations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  job_tasks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seen_welcome?: InputMaybe<Scalars['Boolean']>;
};

export type Upload = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  eventId?: Maybe<Scalars['ID']>;
  fileName: Scalars['String'];
  flowId?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  owner?: Maybe<Scalars['ID']>;
  size: Scalars['Int'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type UploadFileData = {
  id: Scalars['ID'];
  size?: InputMaybe<Scalars['Int']>;
};

export type User = {
  created?: Maybe<Scalars['AWSDateTime']>;
  do_not_track?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrations?: Maybe<Array<Maybe<Scalars['String']>>>;
  job_tasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastLogin?: Maybe<Scalars['AWSDateTime']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']>;
  mobilePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  seen_welcome?: Maybe<Scalars['Boolean']>;
  sf_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UsersResult = {
  items?: Maybe<Array<Maybe<User>>>;
};

export type RunFlowMutationVariables = Exact<{
  input: RunFlowInput;
}>;

export type RunFlowMutation = { runFlow?: { runId: string } | null };

export type SaveFlowMutationVariables = Exact<{
  input: SaveFlowInput;
}>;

export type SaveFlowMutation = {
  saveFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SaveConnectionMutationVariables = Exact<{
  input: SaveConnectionInput;
}>;

export type SaveConnectionMutation = {
  saveConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type SaveDatastoreMutationVariables = Exact<{
  input: SaveDatastoreInput;
}>;

export type SaveDatastoreMutation = {
  saveDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ShareFlowMutationVariables = Exact<{
  input: ShareFlowInput;
}>;

export type ShareFlowMutation = {
  shareFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ShareConnectionMutationVariables = Exact<{
  input: ShareConnectionInput;
}>;

export type ShareConnectionMutation = {
  shareConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type TestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;

export type TestConnectionMutation = {
  testConnection?: { success: boolean; output?: any | null } | null;
};

export type TestAllConnectionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type TestAllConnectionsMutation = {
  testAllConnections?: boolean | null;
};

export type AuthorizeConnectionMutationVariables = Exact<{
  input: AuthorizeConnectionInput;
}>;

export type AuthorizeConnectionMutation = {
  authorizeConnection?: { success: boolean; output?: any | null } | null;
};

export type RunActionMutationVariables = Exact<{
  input: RunActionInput;
}>;

export type RunActionMutation = {
  runAction?: { id: string; output?: any | null } | null;
};

export type AddUserToGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type AddUserToGroupMutation = {
  addUserToGroup?: {
    id?: string | null;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastMembershipUpdated?: any | null;
    image?: string | null;
    users?: {
      items?: Array<{
        id: string;
        name?: string | null;
        status?: string | null;
        created?: any | null;
        lastUpdated?: any | null;
        lastLogin?: any | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        sf_id?: string | null;
        mobilePhone?: string | null;
        picture?: string | null;
        seen_welcome?: boolean | null;
        do_not_track?: boolean | null;
        job_tasks?: Array<string | null> | null;
        integrations?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type DeleteGroupMutation = { deleteGroup?: boolean | null };

export type RemoveUserFromGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type RemoveUserFromGroupMutation = {
  removeUserFromGroup?: boolean | null;
};

export type CreateGroupMutationVariables = Exact<{
  groupInput: CreateGroupInput;
  userId: Scalars['ID'];
}>;

export type CreateGroupMutation = {
  createGroup?: {
    id?: string | null;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastMembershipUpdated?: any | null;
    image?: string | null;
    users?: {
      items?: Array<{
        id: string;
        name?: string | null;
        status?: string | null;
        created?: any | null;
        lastUpdated?: any | null;
        lastLogin?: any | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        sf_id?: string | null;
        mobilePhone?: string | null;
        picture?: string | null;
        seen_welcome?: boolean | null;
        do_not_track?: boolean | null;
        job_tasks?: Array<string | null> | null;
        integrations?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateGroupMutationVariables = Exact<{
  groupInput: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  updateGroup?: {
    id?: string | null;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastMembershipUpdated?: any | null;
    image?: string | null;
    users?: {
      items?: Array<{
        id: string;
        name?: string | null;
        status?: string | null;
        created?: any | null;
        lastUpdated?: any | null;
        lastLogin?: any | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        sf_id?: string | null;
        mobilePhone?: string | null;
        picture?: string | null;
        seen_welcome?: boolean | null;
        do_not_track?: boolean | null;
        job_tasks?: Array<string | null> | null;
        integrations?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ManualApprovalTaskMutationVariables = Exact<{
  input: ManualApprovalTaskInput;
}>;

export type ManualApprovalTaskMutation = {
  manualApprovalTask?: boolean | null;
};

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
  groupId?: InputMaybe<Scalars['String']>;
}>;

export type CreateUserMutation = { createUser?: boolean | null };

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  updateUser?: {
    id: string;
    name?: string | null;
    status?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastLogin?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    sf_id?: string | null;
    mobilePhone?: string | null;
    picture?: string | null;
    seen_welcome?: boolean | null;
    do_not_track?: boolean | null;
    job_tasks?: Array<string | null> | null;
    integrations?: Array<string | null> | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserMutation = { deleteUser?: boolean | null };

export type CreateApiTokenMutationVariables = Exact<{
  input?: InputMaybe<CreateApiTokenInput>;
}>;

export type CreateApiTokenMutation = {
  createApiToken?: {
    id: string;
    name: string;
    description?: string | null;
    key?: string | null;
    owner?: string | null;
    created?: any | null;
  } | null;
};

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;

export type CreateApiKeyMutation = {
  createApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type UpdateApiKeyMutationVariables = Exact<{
  input: UpdateApiKeyInput;
}>;

export type UpdateApiKeyMutation = {
  updateApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type DeleteApiKeyMutationVariables = Exact<{
  input: DeleteApiKeyInput;
}>;

export type DeleteApiKeyMutation = {
  deleteApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type CreateBillingMutationVariables = Exact<{
  input: CreateBillingInput;
}>;

export type CreateBillingMutation = {
  createBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateBillingMutationVariables = Exact<{
  input: UpdateBillingInput;
}>;

export type UpdateBillingMutation = {
  updateBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteBillingMutationVariables = Exact<{
  input: DeleteBillingInput;
}>;

export type DeleteBillingMutation = {
  deleteBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateBillingUsageMutationVariables = Exact<{
  input: CreateBillingUsageInput;
}>;

export type CreateBillingUsageMutation = {
  createBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type UpdateBillingUsageMutationVariables = Exact<{
  input: UpdateBillingUsageInput;
}>;

export type UpdateBillingUsageMutation = {
  updateBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type DeleteBillingUsageMutationVariables = Exact<{
  input: DeleteBillingUsageInput;
}>;

export type DeleteBillingUsageMutation = {
  deleteBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CreateConnectionRevisionMutationVariables = Exact<{
  input: CreateConnectionRevisionInput;
}>;

export type CreateConnectionRevisionMutation = {
  createConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type UpdateConnectionRevisionMutationVariables = Exact<{
  input: UpdateConnectionRevisionInput;
}>;

export type UpdateConnectionRevisionMutation = {
  updateConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type DeleteConnectionRevisionMutationVariables = Exact<{
  input: DeleteConnectionRevisionInput;
}>;

export type DeleteConnectionRevisionMutation = {
  deleteConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type CreateConnectionMutationVariables = Exact<{
  input: CreateConnectionInput;
}>;

export type CreateConnectionMutation = {
  createConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type UpdateConnectionMutationVariables = Exact<{
  input: UpdateConnectionInput;
}>;

export type UpdateConnectionMutation = {
  updateConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type DeleteConnectionMutationVariables = Exact<{
  input: DeleteConnectionInput;
}>;

export type DeleteConnectionMutation = {
  deleteConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type CreateDatastoreMutationVariables = Exact<{
  input: CreateDatastoreInput;
}>;

export type CreateDatastoreMutation = {
  createDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateDatastoreMutationVariables = Exact<{
  input: UpdateDatastoreInput;
}>;

export type UpdateDatastoreMutation = {
  updateDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteDatastoreMutationVariables = Exact<{
  input: DeleteDatastoreInput;
}>;

export type DeleteDatastoreMutation = {
  deleteDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = {
  createEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;

export type UpdateEventMutation = {
  updateEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;

export type DeleteEventMutation = {
  deleteEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type CreateEventGroupMutationVariables = Exact<{
  input: CreateEventGroupInput;
}>;

export type CreateEventGroupMutation = {
  createEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateEventGroupMutationVariables = Exact<{
  input: UpdateEventGroupInput;
}>;

export type UpdateEventGroupMutation = {
  updateEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteEventGroupMutationVariables = Exact<{
  input: DeleteEventGroupInput;
}>;

export type DeleteEventGroupMutation = {
  deleteEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateFlowRevisionMutationVariables = Exact<{
  input: CreateFlowRevisionInput;
}>;

export type CreateFlowRevisionMutation = {
  createFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type UpdateFlowRevisionMutationVariables = Exact<{
  input: UpdateFlowRevisionInput;
}>;

export type UpdateFlowRevisionMutation = {
  updateFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type DeleteFlowRevisionMutationVariables = Exact<{
  input: DeleteFlowRevisionInput;
}>;

export type DeleteFlowRevisionMutation = {
  deleteFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type CreateFlowSchemaMutationVariables = Exact<{
  input: CreateFlowSchemaInput;
}>;

export type CreateFlowSchemaMutation = {
  createFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type UpdateFlowSchemaMutationVariables = Exact<{
  input: UpdateFlowSchemaInput;
}>;

export type UpdateFlowSchemaMutation = {
  updateFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type DeleteFlowSchemaMutationVariables = Exact<{
  input: DeleteFlowSchemaInput;
}>;

export type DeleteFlowSchemaMutation = {
  deleteFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type CreateFlowUsageMutationVariables = Exact<{
  input: CreateFlowUsageInput;
}>;

export type CreateFlowUsageMutation = {
  createFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type UpdateFlowUsageMutationVariables = Exact<{
  input: UpdateFlowUsageInput;
}>;

export type UpdateFlowUsageMutation = {
  updateFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type DeleteFlowUsageMutationVariables = Exact<{
  input: DeleteFlowUsageInput;
}>;

export type DeleteFlowUsageMutation = {
  deleteFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type CreateFlowMutationVariables = Exact<{
  input: CreateFlowInput;
}>;

export type CreateFlowMutation = {
  createFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateFlowMutationVariables = Exact<{
  input: UpdateFlowInput;
}>;

export type UpdateFlowMutation = {
  updateFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteFlowMutationVariables = Exact<{
  input: DeleteFlowInput;
}>;

export type DeleteFlowMutation = {
  deleteFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
  createNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  updateNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type DeleteNotificationMutationVariables = Exact<{
  input: DeleteNotificationInput;
}>;

export type DeleteNotificationMutation = {
  deleteNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type CreatePackageMutationVariables = Exact<{
  input: CreatePackageInput;
}>;

export type CreatePackageMutation = {
  createPackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type UpdatePackageMutationVariables = Exact<{
  input: UpdatePackageInput;
}>;

export type UpdatePackageMutation = {
  updatePackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type DeletePackageMutationVariables = Exact<{
  input: DeletePackageInput;
}>;

export type DeletePackageMutation = {
  deletePackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type CreatePackageConnectionMutationVariables = Exact<{
  input: CreatePackageConnectionInput;
}>;

export type CreatePackageConnectionMutation = {
  createPackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type UpdatePackageConnectionMutationVariables = Exact<{
  input: UpdatePackageConnectionInput;
}>;

export type UpdatePackageConnectionMutation = {
  updatePackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type DeletePackageConnectionMutationVariables = Exact<{
  input: DeletePackageConnectionInput;
}>;

export type DeletePackageConnectionMutation = {
  deletePackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type CreateActionMutationVariables = Exact<{
  input: CreateActionInput;
}>;

export type CreateActionMutation = {
  createAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type UpdateActionMutationVariables = Exact<{
  input: UpdateActionInput;
}>;

export type UpdateActionMutation = {
  updateAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type DeleteActionMutationVariables = Exact<{
  input: DeleteActionInput;
}>;

export type DeleteActionMutation = {
  deleteAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type CreateUploadMutationVariables = Exact<{
  input: CreateUploadInput;
}>;

export type CreateUploadMutation = {
  createUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type UpdateUploadMutationVariables = Exact<{
  input: UpdateUploadInput;
}>;

export type UpdateUploadMutation = {
  updateUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type DeleteUploadMutationVariables = Exact<{
  input: DeleteUploadInput;
}>;

export type DeleteUploadMutation = {
  deleteUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type ListCommunityFlowsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCommunityFlowsQuery = {
  listCommunityFlows?: {
    items: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type ListAllFlowsQueryVariables = Exact<{ [key: string]: never }>;

export type ListAllFlowsQuery = {
  listAllFlows?: {
    count?: number | null;
    items: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type GetActionEventQueryVariables = Exact<{
  runId: Scalars['ID'];
}>;

export type GetActionEventQuery = {
  getActionEvent?: {
    id: string;
    userId?: string | null;
    startedAt?: any | null;
    endedAt?: any | null;
    input?: any | null;
    output?: any | null;
    dataScrubbing?: boolean | null;
  } | null;
};

export type GetFlowEventQueryVariables = Exact<{
  flowId: Scalars['ID'];
  runId: Scalars['ID'];
}>;

export type GetFlowEventQuery = {
  getFlowEvent?: {
    id: string;
    startedAt?: any | null;
    endedAt?: any | null;
    ownerName?: string | null;
    status?: string | null;
    triggeredBy?: string | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
    events?: Array<{
      id: string;
      status?: string | null;
      startedAt?: any | null;
      endedAt?: any | null;
      input?: any | null;
      output?: any | null;
    }> | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUserQuery = {
  getUser?: {
    id: string;
    name?: string | null;
    status?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastLogin?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    sf_id?: string | null;
    mobilePhone?: string | null;
    picture?: string | null;
    seen_welcome?: boolean | null;
    do_not_track?: boolean | null;
    job_tasks?: Array<string | null> | null;
    integrations?: Array<string | null> | null;
  } | null;
};

export type GetGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type GetGroupQuery = {
  getGroup?: {
    id?: string | null;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUpdated?: any | null;
    lastMembershipUpdated?: any | null;
    image?: string | null;
    users?: {
      items?: Array<{
        id: string;
        name?: string | null;
        status?: string | null;
        created?: any | null;
        lastUpdated?: any | null;
        lastLogin?: any | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        sf_id?: string | null;
        mobilePhone?: string | null;
        picture?: string | null;
        seen_welcome?: boolean | null;
        do_not_track?: boolean | null;
        job_tasks?: Array<string | null> | null;
        integrations?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListUsersInGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type ListUsersInGroupQuery = {
  listUsersInGroup?: {
    items?: Array<{
      id: string;
      name?: string | null;
      status?: string | null;
      created?: any | null;
      lastUpdated?: any | null;
      lastLogin?: any | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      sf_id?: string | null;
      mobilePhone?: string | null;
      picture?: string | null;
      seen_welcome?: boolean | null;
      do_not_track?: boolean | null;
      job_tasks?: Array<string | null> | null;
      integrations?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ListUserGroupsQueryVariables = Exact<{
  userId: Scalars['ID'];
  includeGroupUsers?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListUserGroupsQuery = {
  listUserGroups?: {
    items?: Array<{
      id?: string | null;
      name?: string | null;
      description?: string | null;
      created?: any | null;
      lastUpdated?: any | null;
      lastMembershipUpdated?: any | null;
      image?: string | null;
      users?: {
        items?: Array<{
          id: string;
          name?: string | null;
          status?: string | null;
          created?: any | null;
          lastUpdated?: any | null;
          lastLogin?: any | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          sf_id?: string | null;
          mobilePhone?: string | null;
          picture?: string | null;
          seen_welcome?: boolean | null;
          do_not_track?: boolean | null;
          job_tasks?: Array<string | null> | null;
          integrations?: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListUsersQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
}>;

export type ListUsersQuery = {
  listUsers?: {
    items?: Array<{
      id: string;
      name?: string | null;
      status?: string | null;
      created?: any | null;
      lastUpdated?: any | null;
      lastLogin?: any | null;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      sf_id?: string | null;
      mobilePhone?: string | null;
      picture?: string | null;
      seen_welcome?: boolean | null;
      do_not_track?: boolean | null;
      job_tasks?: Array<string | null> | null;
      integrations?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ListGroupsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars['String']>;
}>;

export type ListGroupsQuery = {
  listGroups?: {
    items?: Array<{
      id?: string | null;
      name?: string | null;
      description?: string | null;
      created?: any | null;
      lastUpdated?: any | null;
      lastMembershipUpdated?: any | null;
      image?: string | null;
      users?: {
        items?: Array<{
          id: string;
          name?: string | null;
          status?: string | null;
          created?: any | null;
          lastUpdated?: any | null;
          lastLogin?: any | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          sf_id?: string | null;
          mobilePhone?: string | null;
          picture?: string | null;
          seen_welcome?: boolean | null;
          do_not_track?: boolean | null;
          job_tasks?: Array<string | null> | null;
          integrations?: Array<string | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListDatastoresAndValuesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListDatastoresAndValuesQuery = {
  listDatastoresAndValues?: {
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      type: StoreValueType;
      flowId?: string | null;
      name: string;
      value?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      created?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      flow?: {
        owner?: string | null;
        group?: string | null;
        id: string;
        name: string;
        description?: string | null;
        instructions?: string | null;
        disabled: boolean;
        tags?: Array<string | null> | null;
        version?: number | null;
        lastRun?: any | null;
        forkCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        dataScrubbing?: boolean | null;
        trackRevisions?: boolean | null;
        requireRevisionComments?: boolean | null;
        failureAlert?: boolean | null;
        failureAlertEmails?: Array<string | null> | null;
        template?: {
          id: string;
          name?: string | null;
          date?: any | null;
          author?: string | null;
        } | null;
        triggers: Array<{
          key: string;
          name?: string | null;
          disabled?: boolean | null;
          actionId?: string | null;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        }>;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
        created?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        updated?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        revisions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            version: number;
            owner?: string | null;
            group?: string | null;
            flow: any;
            flowId: string;
            editedBy: string;
            editedAt: any;
            comment?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null> | null;
        } | null;
        schema?: {
          id: string;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          items?: Array<{
            id: string;
            input?: any | null;
            output?: any | null;
          } | null> | null;
        } | null;
        usage?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            flowId: string;
            successCount: number;
            errorCount: number;
            date: any;
            owner?: string | null;
            group?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetDatastoreAndValuesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDatastoreAndValuesQuery = {
  getDatastoreAndValues?: {
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      type: StoreValueType;
      flowId?: string | null;
      name: string;
      value?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      created?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      flow?: {
        owner?: string | null;
        group?: string | null;
        id: string;
        name: string;
        description?: string | null;
        instructions?: string | null;
        disabled: boolean;
        tags?: Array<string | null> | null;
        version?: number | null;
        lastRun?: any | null;
        forkCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        dataScrubbing?: boolean | null;
        trackRevisions?: boolean | null;
        requireRevisionComments?: boolean | null;
        failureAlert?: boolean | null;
        failureAlertEmails?: Array<string | null> | null;
        template?: {
          id: string;
          name?: string | null;
          date?: any | null;
          author?: string | null;
        } | null;
        triggers: Array<{
          key: string;
          name?: string | null;
          disabled?: boolean | null;
          actionId?: string | null;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        }>;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
        created?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        updated?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        revisions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            version: number;
            owner?: string | null;
            group?: string | null;
            flow: any;
            flowId: string;
            editedBy: string;
            editedAt: any;
            comment?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null> | null;
        } | null;
        schema?: {
          id: string;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          items?: Array<{
            id: string;
            input?: any | null;
            output?: any | null;
          } | null> | null;
        } | null;
        usage?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            flowId: string;
            successCount: number;
            errorCount: number;
            date: any;
            owner?: string | null;
            group?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetApiKeyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetApiKeyQuery = {
  getApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type ListApiKeysQueryVariables = Exact<{
  filter?: InputMaybe<ModelApiKeyFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListApiKeysQuery = {
  listApiKeys?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      name?: string | null;
      description?: string | null;
      created?: any | null;
      lastUsed?: any | null;
      owner?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      key?: string | null;
    } | null> | null;
  } | null;
};

export type GetBillingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBillingQuery = {
  getBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type ListBillingsQueryVariables = Exact<{
  filter?: InputMaybe<ModelBillingFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListBillingsQuery = {
  listBillings?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      owner?: string | null;
      group?: string | null;
      lifetimeFlowInvocations?: number | null;
      lifetimeActionInvocations?: number | null;
      totalDataProcessed?: number | null;
      totalFilesSizeUploaded?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      plan?: {
        name: string;
        flowInvocations?: number | null;
        actionInvocations?: number | null;
        dataSize?: number | null;
        flowCount?: number | null;
        expiration?: any | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          date: any;
          owner?: string | null;
          group?: string | null;
          dataSize?: number | null;
          filesSize?: number | null;
          actionsCount?: number | null;
          flowsCount?: number | null;
          billingId: string;
          isFlowLimitNotified: boolean;
          isActionLimitNotified: boolean;
          isFlowLimitExceeded: boolean;
          isActionLimitExceeded: boolean;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetBillingUsageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBillingUsageQuery = {
  getBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type ListBillingUsagesQueryVariables = Exact<{
  filter?: InputMaybe<ModelBillingUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListBillingUsagesQuery = {
  listBillingUsages?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      date: any;
      owner?: string | null;
      group?: string | null;
      dataSize?: number | null;
      filesSize?: number | null;
      actionsCount?: number | null;
      flowsCount?: number | null;
      billingId: string;
      isFlowLimitNotified: boolean;
      isActionLimitNotified: boolean;
      isFlowLimitExceeded: boolean;
      isActionLimitExceeded: boolean;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null;
};

export type GetConnectionRevisionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetConnectionRevisionQuery = {
  getConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type ListConnectionRevisionsQueryVariables = Exact<{
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListConnectionRevisionsQuery = {
  listConnectionRevisions?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      connection: any;
      connectionId: string;
      packageConnectionId: string;
      editedBy: string;
      editedAt: any;
      createdAt: any;
      updatedAt: any;
      packageConnection?: {
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null;
    } | null> | null;
  } | null;
};

export type RevisionsByConnectionEditedAtQueryVariables = Exact<{
  connectionId?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type RevisionsByConnectionEditedAtQuery = {
  revisionsByConnectionEditedAt?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      connection: any;
      connectionId: string;
      packageConnectionId: string;
      editedBy: string;
      editedAt: any;
      createdAt: any;
      updatedAt: any;
      packageConnection?: {
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null;
    } | null> | null;
  } | null;
};

export type RevisionsByConnectionEditedByEditedAtQueryVariables = Exact<{
  editedBy?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelConnectionRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type RevisionsByConnectionEditedByEditedAtQuery = {
  revisionsByConnectionEditedByEditedAt?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      connection: any;
      connectionId: string;
      packageConnectionId: string;
      editedBy: string;
      editedAt: any;
      createdAt: any;
      updatedAt: any;
      packageConnection?: {
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null;
    } | null> | null;
  } | null;
};

export type GetConnectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetConnectionQuery = {
  getConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type ListConnectionsQueryVariables = Exact<{
  filter?: InputMaybe<ModelConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListConnectionsQuery = {
  listConnections?: {
    nextToken?: string | null;
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      packageConnectionId: string;
      name: string;
      description?: string | null;
      status?: ConnectionStatus | null;
      lastPing?: any | null;
      disabled: boolean;
      output?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      version?: number | null;
      input?: any | null;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          connection: any;
          connectionId: string;
          packageConnectionId: string;
          editedBy: string;
          editedAt: any;
          createdAt: any;
          updatedAt: any;
          packageConnection?: {
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null;
        } | null> | null;
      } | null;
      packageConnection?: {
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null;
    } | null> | null;
  } | null;
};

export type GetDatastoreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDatastoreQuery = {
  getDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ListDatastoresQueryVariables = Exact<{
  filter?: InputMaybe<ModelDatastoreFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListDatastoresQuery = {
  listDatastores?: {
    nextToken?: string | null;
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      type: StoreValueType;
      flowId?: string | null;
      name: string;
      value?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      created?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        flowId?: string | null;
        id?: string | null;
        name?: string | null;
        picture?: string | null;
        date?: any | null;
      } | null;
      flow?: {
        owner?: string | null;
        group?: string | null;
        id: string;
        name: string;
        description?: string | null;
        instructions?: string | null;
        disabled: boolean;
        tags?: Array<string | null> | null;
        version?: number | null;
        lastRun?: any | null;
        forkCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        dataScrubbing?: boolean | null;
        trackRevisions?: boolean | null;
        requireRevisionComments?: boolean | null;
        failureAlert?: boolean | null;
        failureAlertEmails?: Array<string | null> | null;
        template?: {
          id: string;
          name?: string | null;
          date?: any | null;
          author?: string | null;
        } | null;
        triggers: Array<{
          key: string;
          name?: string | null;
          disabled?: boolean | null;
          actionId?: string | null;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        }>;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
        created?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        updated?: {
          id?: string | null;
          picture?: string | null;
          name?: string | null;
          date?: any | null;
        } | null;
        revisions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            version: number;
            owner?: string | null;
            group?: string | null;
            flow: any;
            flowId: string;
            editedBy: string;
            editedAt: any;
            comment?: string | null;
            createdAt: any;
            updatedAt: any;
          } | null> | null;
        } | null;
        schema?: {
          id: string;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          items?: Array<{
            id: string;
            input?: any | null;
            output?: any | null;
          } | null> | null;
        } | null;
        usage?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            flowId: string;
            successCount: number;
            errorCount: number;
            date: any;
            owner?: string | null;
            group?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventQuery = {
  getEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type ListEventsQueryVariables = Exact<{
  filter?: InputMaybe<ModelEventFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListEventsQuery = {
  listEvents?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      runId: string;
      actionId?: string | null;
      input?: any | null;
      output?: any | null;
      start: any;
      end?: any | null;
      group?: string | null;
      owner?: string | null;
      taskKey?: string | null;
      taskToken?: string | null;
      approvalStatus?: EventApprovalStatus | null;
      mapKey?: string | null;
      index?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null> | null;
  } | null;
};

export type GetEventGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventGroupQuery = {
  getEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListEventGroupsQueryVariables = Exact<{
  filter?: InputMaybe<ModelEventGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListEventGroupsQuery = {
  listEventGroups?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      flowVersion?: number | null;
      status?: EventStatus | null;
      triggeredBy?: string | null;
      error?: string | null;
      flowId: string;
      start: any;
      end?: any | null;
      group?: string | null;
      owner?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      events?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          runId: string;
          actionId?: string | null;
          input?: any | null;
          output?: any | null;
          start: any;
          end?: any | null;
          group?: string | null;
          owner?: string | null;
          taskKey?: string | null;
          taskToken?: string | null;
          approvalStatus?: EventApprovalStatus | null;
          mapKey?: string | null;
          index?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type EventGroupsByFlowStartQueryVariables = Exact<{
  flowId?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelEventGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type EventGroupsByFlowStartQuery = {
  eventGroupsByFlowStart?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      flowVersion?: number | null;
      status?: EventStatus | null;
      triggeredBy?: string | null;
      error?: string | null;
      flowId: string;
      start: any;
      end?: any | null;
      group?: string | null;
      owner?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      events?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          runId: string;
          actionId?: string | null;
          input?: any | null;
          output?: any | null;
          start: any;
          end?: any | null;
          group?: string | null;
          owner?: string | null;
          taskKey?: string | null;
          taskToken?: string | null;
          approvalStatus?: EventApprovalStatus | null;
          mapKey?: string | null;
          index?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetFlowRevisionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFlowRevisionQuery = {
  getFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type ListFlowRevisionsQueryVariables = Exact<{
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListFlowRevisionsQuery = {
  listFlowRevisions?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      flow: any;
      flowId: string;
      editedBy: string;
      editedAt: any;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null;
};

export type RevisionsByFlowEditedAtQueryVariables = Exact<{
  flowId?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type RevisionsByFlowEditedAtQuery = {
  revisionsByFlowEditedAt?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      flow: any;
      flowId: string;
      editedBy: string;
      editedAt: any;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null;
};

export type RevisionsByEditedByEditedAtQueryVariables = Exact<{
  editedBy?: InputMaybe<Scalars['ID']>;
  editedAt?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelFlowRevisionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type RevisionsByEditedByEditedAtQuery = {
  revisionsByEditedByEditedAt?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      version: number;
      owner?: string | null;
      group?: string | null;
      flow: any;
      flowId: string;
      editedBy: string;
      editedAt: any;
      comment?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null;
};

export type GetFlowSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFlowSchemaQuery = {
  getFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type ListFlowSchemasQueryVariables = Exact<{
  filter?: InputMaybe<ModelFlowSchemaFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListFlowSchemasQuery = {
  listFlowSchemas?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetFlowUsageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFlowUsageQuery = {
  getFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type ListFlowUsagesQueryVariables = Exact<{
  filter?: InputMaybe<ModelFlowUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListFlowUsagesQuery = {
  listFlowUsages?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      flowId: string;
      successCount: number;
      errorCount: number;
      date: any;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null> | null;
  } | null;
};

export type FlowUsageByDateQueryVariables = Exact<{
  flowId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  filter?: InputMaybe<ModelFlowUsageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type FlowUsageByDateQuery = {
  flowUsageByDate?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      flowId: string;
      successCount: number;
      errorCount: number;
      date: any;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null> | null;
  } | null;
};

export type GetFlowQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFlowQuery = {
  getFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListFlowsQueryVariables = Exact<{
  filter?: InputMaybe<ModelFlowFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListFlowsQuery = {
  listFlows?: {
    nextToken?: string | null;
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetNotificationQueryVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['AWSDateTime'];
}>;

export type GetNotificationQuery = {
  getNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type ListNotificationsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelNotificationFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
}>;

export type ListNotificationsQuery = {
  listNotifications?: {
    nextToken?: string | null;
    items?: Array<{
      owner?: string | null;
      group?: string | null;
      id: string;
      title?: string | null;
      message?: string | null;
      link?: string | null;
      referenceId?: string | null;
      date: any;
      level?: NotificationLevel | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null> | null;
  } | null;
};

export type GetPackageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPackageQuery = {
  getPackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type ListPackagesQueryVariables = Exact<{
  filter?: InputMaybe<ModelPackageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListPackagesQuery = {
  listPackages?: {
    nextToken?: string | null;
    items?: Array<{
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPackageConnectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPackageConnectionQuery = {
  getPackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type ListPackageConnectionsQueryVariables = Exact<{
  filter?: InputMaybe<ModelPackageConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListPackageConnectionsQuery = {
  listPackageConnections?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export type GetActionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetActionQuery = {
  getAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type ListActionsQueryVariables = Exact<{
  filter?: InputMaybe<ModelActionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListActionsQuery = {
  listActions?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      group?: string | null;
      name: string;
      type?: ActionType | null;
      description?: string | null;
      discriminator: string;
      packageConnectionIds?: Array<string | null> | null;
      optionalConnection?: boolean | null;
      input?: any | null;
      output?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export type GetUploadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUploadQuery = {
  getUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type ListUploadsQueryVariables = Exact<{
  filter?: InputMaybe<ModelUploadFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListUploadsQuery = {
  listUploads?: {
    nextToken?: string | null;
    items?: Array<{
      id: string;
      fileName: string;
      size: number;
      mimeType: string;
      group?: string | null;
      owner?: string | null;
      flowId?: string | null;
      eventId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
    } | null> | null;
  } | null;
};

export type SubscribeToEventGroupSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToEventGroupSubscription = {
  subscribeToEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SubscribeToEventSubscriptionVariables = Exact<{
  runId: Scalars['ID'];
}>;

export type SubscribeToEventSubscription = {
  subscribeToEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type SubscribeToFlowEventGroupSubscriptionVariables = Exact<{
  flowId: Scalars['ID'];
}>;

export type SubscribeToFlowEventGroupSubscription = {
  subscribeToFlowEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SubscribeToFlowChangesSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToFlowChangesSubscription = {
  subscribeToFlowChanges?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SubscribeToConnectionChangesSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToConnectionChangesSubscription = {
  subscribeToConnectionChanges?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type SubscribeToDatastoreChangesSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToDatastoreChangesSubscription = {
  subscribeToDatastoreChanges?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SubscribeToPackageChangesSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToPackageChangesSubscription = {
  subscribeToPackageChanges?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type SubscribeToHistoryChangesSubscriptionVariables = Exact<{
  flowId: Scalars['ID'];
}>;

export type SubscribeToHistoryChangesSubscription = {
  subscribeToHistoryChanges?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnCreateApiKeySubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateApiKeySubscription = {
  onCreateApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type OnUpdateApiKeySubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateApiKeySubscription = {
  onUpdateApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type OnDeleteApiKeySubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteApiKeySubscription = {
  onDeleteApiKey?: {
    id: string;
    name?: string | null;
    description?: string | null;
    created?: any | null;
    lastUsed?: any | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    key?: string | null;
  } | null;
};

export type OnCreateBillingSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateBillingSubscription = {
  onCreateBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type OnUpdateBillingSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateBillingSubscription = {
  onUpdateBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type OnDeleteBillingSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteBillingSubscription = {
  onDeleteBilling?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    lifetimeFlowInvocations?: number | null;
    lifetimeActionInvocations?: number | null;
    totalDataProcessed?: number | null;
    totalFilesSizeUploaded?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    plan?: {
      name: string;
      flowInvocations?: number | null;
      actionInvocations?: number | null;
      dataSize?: number | null;
      flowCount?: number | null;
      expiration?: any | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        date: any;
        owner?: string | null;
        group?: string | null;
        dataSize?: number | null;
        filesSize?: number | null;
        actionsCount?: number | null;
        flowsCount?: number | null;
        billingId: string;
        isFlowLimitNotified: boolean;
        isActionLimitNotified: boolean;
        isFlowLimitExceeded: boolean;
        isActionLimitExceeded: boolean;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
  } | null;
};

export type OnCreateBillingUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateBillingUsageSubscription = {
  onCreateBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnUpdateBillingUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateBillingUsageSubscription = {
  onUpdateBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnDeleteBillingUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteBillingUsageSubscription = {
  onDeleteBillingUsage?: {
    id: string;
    date: any;
    owner?: string | null;
    group?: string | null;
    dataSize?: number | null;
    filesSize?: number | null;
    actionsCount?: number | null;
    flowsCount?: number | null;
    billingId: string;
    isFlowLimitNotified: boolean;
    isActionLimitNotified: boolean;
    isFlowLimitExceeded: boolean;
    isActionLimitExceeded: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnCreateConnectionRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateConnectionRevisionSubscription = {
  onCreateConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnUpdateConnectionRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateConnectionRevisionSubscription = {
  onUpdateConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnDeleteConnectionRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteConnectionRevisionSubscription = {
  onDeleteConnectionRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    connection: any;
    connectionId: string;
    packageConnectionId: string;
    editedBy: string;
    editedAt: any;
    createdAt: any;
    updatedAt: any;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnCreateConnectionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateConnectionSubscription = {
  onCreateConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnUpdateConnectionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateConnectionSubscription = {
  onUpdateConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnDeleteConnectionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteConnectionSubscription = {
  onDeleteConnection?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    packageConnectionId: string;
    name: string;
    description?: string | null;
    status?: ConnectionStatus | null;
    lastPing?: any | null;
    disabled: boolean;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    version?: number | null;
    input?: any | null;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        connection: any;
        connectionId: string;
        packageConnectionId: string;
        editedBy: string;
        editedAt: any;
        createdAt: any;
        updatedAt: any;
        packageConnection?: {
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null;
      } | null> | null;
    } | null;
    packageConnection?: {
      id: string;
      group?: string | null;
      name: string;
      description?: string | null;
      discriminator?: string | null;
      input?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      package: {
        schema?: string | null;
        id: string;
        group?: string | null;
        name?: string | null;
        markdown?: string | null;
        status: PackageStatus;
        license?: string | null;
        categories?: Array<string | null> | null;
        version?: string | null;
        repository: string;
        runtime?: RuntimeType | null;
        lambdaARN?: string | null;
        created?: any | null;
        updated?: any | null;
        usageCount?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author?: {
          name: string;
          url?: string | null;
          email: string;
          picture?: string | null;
        } | null;
        vendor?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        product?: {
          name?: string | null;
          url?: string | null;
          logo?: string | null;
          logoBw?: string | null;
        } | null;
        connections?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            description?: string | null;
            discriminator?: string | null;
            input?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
        actions?: {
          nextToken?: string | null;
          items?: Array<{
            id: string;
            group?: string | null;
            name: string;
            type?: ActionType | null;
            description?: string | null;
            discriminator: string;
            packageConnectionIds?: Array<string | null> | null;
            optionalConnection?: boolean | null;
            input?: any | null;
            output?: any | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            package: {
              schema?: string | null;
              id: string;
              group?: string | null;
              name?: string | null;
              markdown?: string | null;
              status: PackageStatus;
              license?: string | null;
              categories?: Array<string | null> | null;
              version?: string | null;
              repository: string;
              runtime?: RuntimeType | null;
              lambdaARN?: string | null;
              created?: any | null;
              updated?: any | null;
              usageCount?: number | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              author?: {
                name: string;
                url?: string | null;
                email: string;
                picture?: string | null;
              } | null;
              vendor?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              product?: {
                name?: string | null;
                url?: string | null;
                logo?: string | null;
                logoBw?: string | null;
              } | null;
              connections?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  description?: string | null;
                  discriminator?: string | null;
                  input?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
              actions?: {
                nextToken?: string | null;
                items?: Array<{
                  id: string;
                  group?: string | null;
                  name: string;
                  type?: ActionType | null;
                  description?: string | null;
                  discriminator: string;
                  packageConnectionIds?: Array<string | null> | null;
                  optionalConnection?: boolean | null;
                  input?: any | null;
                  output?: any | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                } | null> | null;
              } | null;
            };
          } | null> | null;
        } | null;
      };
    } | null;
  } | null;
};

export type OnCreateDatastoreSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateDatastoreSubscription = {
  onCreateDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type OnUpdateDatastoreSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateDatastoreSubscription = {
  onUpdateDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type OnDeleteDatastoreSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteDatastoreSubscription = {
  onDeleteDatastore?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    type: StoreValueType;
    flowId?: string | null;
    name: string;
    value?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    created?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      flowId?: string | null;
      id?: string | null;
      name?: string | null;
      picture?: string | null;
      date?: any | null;
    } | null;
    flow?: {
      owner?: string | null;
      group?: string | null;
      id: string;
      name: string;
      description?: string | null;
      instructions?: string | null;
      disabled: boolean;
      tags?: Array<string | null> | null;
      version?: number | null;
      lastRun?: any | null;
      forkCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      dataScrubbing?: boolean | null;
      trackRevisions?: boolean | null;
      requireRevisionComments?: boolean | null;
      failureAlert?: boolean | null;
      failureAlertEmails?: Array<string | null> | null;
      template?: {
        id: string;
        name?: string | null;
        date?: any | null;
        author?: string | null;
      } | null;
      triggers: Array<{
        key: string;
        name?: string | null;
        disabled?: boolean | null;
        actionId?: string | null;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      }>;
      nodes: Array<{
        key: string;
        disabled?: boolean | null;
        task?: {
          name?: string | null;
          next?: Array<string | null> | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          timeout?: number | null;
          retry?: {
            retries?: number | null;
            minTimeout?: number | null;
            maxTimeout?: number | null;
            factor?: number | null;
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null;
          catch?: Array<{
            expression?: string | null;
            next?: Array<string | null> | null;
          } | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
        choice?: {
          name?: string | null;
          default?: Array<string | null> | null;
          actionId?: string | null;
          choices: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            next?: Array<string | null> | null;
            id?: string | null;
            label?: string | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          }>;
        } | null;
        map?: {
          name?: string | null;
          maxConcurrency?: number | null;
          itemsPath?: string | null;
          itemsExpression?: string | null;
          next?: Array<string | null> | null;
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
          nodes: Array<{
            key: string;
            disabled?: boolean | null;
            task?: {
              name?: string | null;
              next?: Array<string | null> | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              timeout?: number | null;
              retry?: {
                retries?: number | null;
                minTimeout?: number | null;
                maxTimeout?: number | null;
                factor?: number | null;
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null;
              catch?: Array<{
                expression?: string | null;
                next?: Array<string | null> | null;
              } | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
            choice?: {
              name?: string | null;
              default?: Array<string | null> | null;
              actionId?: string | null;
              choices: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                next?: Array<string | null> | null;
                id?: string | null;
                label?: string | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              }>;
            } | null;
            map?: {
              name?: string | null;
              maxConcurrency?: number | null;
              itemsPath?: string | null;
              itemsExpression?: string | null;
              next?: Array<string | null> | null;
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
              nodes: Array<{
                key: string;
                disabled?: boolean | null;
                task?: {
                  name?: string | null;
                  next?: Array<string | null> | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  timeout?: number | null;
                } | null;
                choice?: {
                  name?: string | null;
                  default?: Array<string | null> | null;
                  actionId?: string | null;
                } | null;
                map?: {
                  name?: string | null;
                  maxConcurrency?: number | null;
                  itemsPath?: string | null;
                  itemsExpression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                approval?: {
                  name?: string | null;
                  actionId: string;
                  connectionId?: string | null;
                  input?: any | null;
                  next?: Array<string | null> | null;
                  timeout?: number | null;
                } | null;
              }>;
            } | null;
            approval?: {
              name?: string | null;
              actionId: string;
              connectionId?: string | null;
              input?: any | null;
              next?: Array<string | null> | null;
              timeout?: number | null;
              choice: {
                key: string;
                declineNext?: Array<string | null> | null;
                approveNext?: Array<string | null> | null;
              };
              schema?: {
                outputComponent?: string | null;
                inputComponents?: Array<{
                  id: string;
                  type: string;
                } | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        approval?: {
          name?: string | null;
          actionId: string;
          connectionId?: string | null;
          input?: any | null;
          next?: Array<string | null> | null;
          timeout?: number | null;
          choice: {
            key: string;
            declineNext?: Array<string | null> | null;
            approveNext?: Array<string | null> | null;
          };
          schema?: {
            outputComponent?: string | null;
            inputComponents?: Array<{ id: string; type: string } | null> | null;
          } | null;
        } | null;
      }>;
      created?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      updated?: {
        id?: string | null;
        picture?: string | null;
        name?: string | null;
        date?: any | null;
      } | null;
      revisions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          version: number;
          owner?: string | null;
          group?: string | null;
          flow: any;
          flowId: string;
          editedBy: string;
          editedAt: any;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
      } | null;
      schema?: {
        id: string;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        items?: Array<{
          id: string;
          input?: any | null;
          output?: any | null;
        } | null> | null;
      } | null;
      usage?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          flowId: string;
          successCount: number;
          errorCount: number;
          date: any;
          owner?: string | null;
          group?: string | null;
          createdAt?: any | null;
          updatedAt?: any | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateEventSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateEventSubscription = {
  onCreateEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnUpdateEventSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateEventSubscription = {
  onUpdateEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnDeleteEventSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteEventSubscription = {
  onDeleteEvent?: {
    id: string;
    runId: string;
    actionId?: string | null;
    input?: any | null;
    output?: any | null;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    taskKey?: string | null;
    taskToken?: string | null;
    approvalStatus?: EventApprovalStatus | null;
    mapKey?: string | null;
    index?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnCreateEventGroupSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateEventGroupSubscription = {
  onCreateEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnUpdateEventGroupSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateEventGroupSubscription = {
  onUpdateEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnDeleteEventGroupSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteEventGroupSubscription = {
  onDeleteEventGroup?: {
    id: string;
    flowVersion?: number | null;
    status?: EventStatus | null;
    triggeredBy?: string | null;
    error?: string | null;
    flowId: string;
    start: any;
    end?: any | null;
    group?: string | null;
    owner?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    events?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        runId: string;
        actionId?: string | null;
        input?: any | null;
        output?: any | null;
        start: any;
        end?: any | null;
        group?: string | null;
        owner?: string | null;
        taskKey?: string | null;
        taskToken?: string | null;
        approvalStatus?: EventApprovalStatus | null;
        mapKey?: string | null;
        index?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnCreateFlowRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateFlowRevisionSubscription = {
  onCreateFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnUpdateFlowRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateFlowRevisionSubscription = {
  onUpdateFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnDeleteFlowRevisionSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteFlowRevisionSubscription = {
  onDeleteFlowRevision?: {
    id: string;
    version: number;
    owner?: string | null;
    group?: string | null;
    flow: any;
    flowId: string;
    editedBy: string;
    editedAt: any;
    comment?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type OnCreateFlowSchemaSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateFlowSchemaSubscription = {
  onCreateFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type OnUpdateFlowSchemaSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateFlowSchemaSubscription = {
  onUpdateFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type OnDeleteFlowSchemaSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteFlowSchemaSubscription = {
  onDeleteFlowSchema?: {
    id: string;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    items?: Array<{
      id: string;
      input?: any | null;
      output?: any | null;
    } | null> | null;
  } | null;
};

export type OnCreateFlowUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateFlowUsageSubscription = {
  onCreateFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnUpdateFlowUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateFlowUsageSubscription = {
  onUpdateFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnDeleteFlowUsageSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteFlowUsageSubscription = {
  onDeleteFlowUsage?: {
    id: string;
    flowId: string;
    successCount: number;
    errorCount: number;
    date: any;
    owner?: string | null;
    group?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnCreateFlowSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateFlowSubscription = {
  onCreateFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnUpdateFlowSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateFlowSubscription = {
  onUpdateFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnDeleteFlowSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteFlowSubscription = {
  onDeleteFlow?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    name: string;
    description?: string | null;
    instructions?: string | null;
    disabled: boolean;
    tags?: Array<string | null> | null;
    version?: number | null;
    lastRun?: any | null;
    forkCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    dataScrubbing?: boolean | null;
    trackRevisions?: boolean | null;
    requireRevisionComments?: boolean | null;
    failureAlert?: boolean | null;
    failureAlertEmails?: Array<string | null> | null;
    template?: {
      id: string;
      name?: string | null;
      date?: any | null;
      author?: string | null;
    } | null;
    triggers: Array<{
      key: string;
      name?: string | null;
      disabled?: boolean | null;
      actionId?: string | null;
      connectionId?: string | null;
      input?: any | null;
      next?: Array<string | null> | null;
      schema?: {
        outputComponent?: string | null;
        inputComponents?: Array<{ id: string; type: string } | null> | null;
      } | null;
    }>;
    nodes: Array<{
      key: string;
      disabled?: boolean | null;
      task?: {
        name?: string | null;
        next?: Array<string | null> | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        timeout?: number | null;
        retry?: {
          retries?: number | null;
          minTimeout?: number | null;
          maxTimeout?: number | null;
          factor?: number | null;
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null;
        catch?: Array<{
          expression?: string | null;
          next?: Array<string | null> | null;
        } | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
      choice?: {
        name?: string | null;
        default?: Array<string | null> | null;
        actionId?: string | null;
        choices: Array<{
          expression?: string | null;
          variable?: string | null;
          comparator?: string | null;
          value?: string | null;
          view?: any | null;
          next?: Array<string | null> | null;
          id?: string | null;
          label?: string | null;
          and?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
          or?: Array<{
            expression?: string | null;
            variable?: string | null;
            comparator?: string | null;
            value?: string | null;
            view?: any | null;
            and?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
            or?: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        }>;
      } | null;
      map?: {
        name?: string | null;
        maxConcurrency?: number | null;
        itemsPath?: string | null;
        itemsExpression?: string | null;
        next?: Array<string | null> | null;
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
        nodes: Array<{
          key: string;
          disabled?: boolean | null;
          task?: {
            name?: string | null;
            next?: Array<string | null> | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            timeout?: number | null;
            retry?: {
              retries?: number | null;
              minTimeout?: number | null;
              maxTimeout?: number | null;
              factor?: number | null;
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null;
            catch?: Array<{
              expression?: string | null;
              next?: Array<string | null> | null;
            } | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
          choice?: {
            name?: string | null;
            default?: Array<string | null> | null;
            actionId?: string | null;
            choices: Array<{
              expression?: string | null;
              variable?: string | null;
              comparator?: string | null;
              value?: string | null;
              view?: any | null;
              next?: Array<string | null> | null;
              id?: string | null;
              label?: string | null;
              and?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
              or?: Array<{
                expression?: string | null;
                variable?: string | null;
                comparator?: string | null;
                value?: string | null;
                view?: any | null;
                and?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
                or?: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                } | null> | null;
              } | null> | null;
            }>;
          } | null;
          map?: {
            name?: string | null;
            maxConcurrency?: number | null;
            itemsPath?: string | null;
            itemsExpression?: string | null;
            next?: Array<string | null> | null;
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
            nodes: Array<{
              key: string;
              disabled?: boolean | null;
              task?: {
                name?: string | null;
                next?: Array<string | null> | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                timeout?: number | null;
                retry?: {
                  retries?: number | null;
                  minTimeout?: number | null;
                  maxTimeout?: number | null;
                  factor?: number | null;
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null;
                catch?: Array<{
                  expression?: string | null;
                  next?: Array<string | null> | null;
                } | null> | null;
                schema?: { outputComponent?: string | null } | null;
              } | null;
              choice?: {
                name?: string | null;
                default?: Array<string | null> | null;
                actionId?: string | null;
                choices: Array<{
                  expression?: string | null;
                  variable?: string | null;
                  comparator?: string | null;
                  value?: string | null;
                  view?: any | null;
                  next?: Array<string | null> | null;
                  id?: string | null;
                  label?: string | null;
                }>;
              } | null;
              map?: {
                name?: string | null;
                maxConcurrency?: number | null;
                itemsPath?: string | null;
                itemsExpression?: string | null;
                next?: Array<string | null> | null;
                schema?: { outputComponent?: string | null } | null;
                nodes: Array<{ key: string; disabled?: boolean | null }>;
              } | null;
              approval?: {
                name?: string | null;
                actionId: string;
                connectionId?: string | null;
                input?: any | null;
                next?: Array<string | null> | null;
                timeout?: number | null;
                choice: {
                  key: string;
                  declineNext?: Array<string | null> | null;
                  approveNext?: Array<string | null> | null;
                };
                schema?: { outputComponent?: string | null } | null;
              } | null;
            }>;
          } | null;
          approval?: {
            name?: string | null;
            actionId: string;
            connectionId?: string | null;
            input?: any | null;
            next?: Array<string | null> | null;
            timeout?: number | null;
            choice: {
              key: string;
              declineNext?: Array<string | null> | null;
              approveNext?: Array<string | null> | null;
            };
            schema?: {
              outputComponent?: string | null;
              inputComponents?: Array<{
                id: string;
                type: string;
              } | null> | null;
            } | null;
          } | null;
        }>;
      } | null;
      approval?: {
        name?: string | null;
        actionId: string;
        connectionId?: string | null;
        input?: any | null;
        next?: Array<string | null> | null;
        timeout?: number | null;
        choice: {
          key: string;
          declineNext?: Array<string | null> | null;
          approveNext?: Array<string | null> | null;
        };
        schema?: {
          outputComponent?: string | null;
          inputComponents?: Array<{ id: string; type: string } | null> | null;
        } | null;
      } | null;
    }>;
    created?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    updated?: {
      id?: string | null;
      picture?: string | null;
      name?: string | null;
      date?: any | null;
    } | null;
    revisions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        version: number;
        owner?: string | null;
        group?: string | null;
        flow: any;
        flowId: string;
        editedBy: string;
        editedAt: any;
        comment?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    schema?: {
      id: string;
      owner?: string | null;
      group?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      items?: Array<{
        id: string;
        input?: any | null;
        output?: any | null;
      } | null> | null;
    } | null;
    usage?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        flowId: string;
        successCount: number;
        errorCount: number;
        date: any;
        owner?: string | null;
        group?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null> | null;
    } | null;
  } | null;
};

export type OnCreateNotificationSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateNotificationSubscription = {
  onCreateNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnUpdateNotificationSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnDeleteNotificationSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?: {
    owner?: string | null;
    group?: string | null;
    id: string;
    title?: string | null;
    message?: string | null;
    link?: string | null;
    referenceId?: string | null;
    date: any;
    level?: NotificationLevel | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnCreatePackageSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreatePackageSubscription = {
  onCreatePackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type OnUpdatePackageSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdatePackageSubscription = {
  onUpdatePackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type OnDeletePackageSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeletePackageSubscription = {
  onDeletePackage?: {
    schema?: string | null;
    id: string;
    group?: string | null;
    name?: string | null;
    markdown?: string | null;
    status: PackageStatus;
    license?: string | null;
    categories?: Array<string | null> | null;
    version?: string | null;
    repository: string;
    runtime?: RuntimeType | null;
    lambdaARN?: string | null;
    created?: any | null;
    updated?: any | null;
    usageCount?: number | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author?: {
      name: string;
      url?: string | null;
      email: string;
      picture?: string | null;
    } | null;
    vendor?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    product?: {
      name?: string | null;
      url?: string | null;
      logo?: string | null;
      logoBw?: string | null;
    } | null;
    connections?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        description?: string | null;
        discriminator?: string | null;
        input?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
    actions?: {
      nextToken?: string | null;
      items?: Array<{
        id: string;
        group?: string | null;
        name: string;
        type?: ActionType | null;
        description?: string | null;
        discriminator: string;
        packageConnectionIds?: Array<string | null> | null;
        optionalConnection?: boolean | null;
        input?: any | null;
        output?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        package: {
          schema?: string | null;
          id: string;
          group?: string | null;
          name?: string | null;
          markdown?: string | null;
          status: PackageStatus;
          license?: string | null;
          categories?: Array<string | null> | null;
          version?: string | null;
          repository: string;
          runtime?: RuntimeType | null;
          lambdaARN?: string | null;
          created?: any | null;
          updated?: any | null;
          usageCount?: number | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          author?: {
            name: string;
            url?: string | null;
            email: string;
            picture?: string | null;
          } | null;
          vendor?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          product?: {
            name?: string | null;
            url?: string | null;
            logo?: string | null;
            logoBw?: string | null;
          } | null;
          connections?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              description?: string | null;
              discriminator?: string | null;
              input?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
          actions?: {
            nextToken?: string | null;
            items?: Array<{
              id: string;
              group?: string | null;
              name: string;
              type?: ActionType | null;
              description?: string | null;
              discriminator: string;
              packageConnectionIds?: Array<string | null> | null;
              optionalConnection?: boolean | null;
              input?: any | null;
              output?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              package: {
                schema?: string | null;
                id: string;
                group?: string | null;
                name?: string | null;
                markdown?: string | null;
                status: PackageStatus;
                license?: string | null;
                categories?: Array<string | null> | null;
                version?: string | null;
                repository: string;
                runtime?: RuntimeType | null;
                lambdaARN?: string | null;
                created?: any | null;
                updated?: any | null;
                usageCount?: number | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                author?: {
                  name: string;
                  url?: string | null;
                  email: string;
                  picture?: string | null;
                } | null;
                vendor?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                product?: {
                  name?: string | null;
                  url?: string | null;
                  logo?: string | null;
                  logoBw?: string | null;
                } | null;
                connections?: { nextToken?: string | null } | null;
                actions?: { nextToken?: string | null } | null;
              };
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null;
  } | null;
};

export type OnCreatePackageConnectionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreatePackageConnectionSubscription = {
  onCreatePackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnUpdatePackageConnectionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdatePackageConnectionSubscription = {
  onUpdatePackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnDeletePackageConnectionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeletePackageConnectionSubscription = {
  onDeletePackageConnection?: {
    id: string;
    group?: string | null;
    name: string;
    description?: string | null;
    discriminator?: string | null;
    input?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnCreateActionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnCreateActionSubscription = {
  onCreateAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnUpdateActionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnUpdateActionSubscription = {
  onUpdateAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnDeleteActionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OnDeleteActionSubscription = {
  onDeleteAction?: {
    id: string;
    group?: string | null;
    name: string;
    type?: ActionType | null;
    description?: string | null;
    discriminator: string;
    packageConnectionIds?: Array<string | null> | null;
    optionalConnection?: boolean | null;
    input?: any | null;
    output?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    package: {
      schema?: string | null;
      id: string;
      group?: string | null;
      name?: string | null;
      markdown?: string | null;
      status: PackageStatus;
      license?: string | null;
      categories?: Array<string | null> | null;
      version?: string | null;
      repository: string;
      runtime?: RuntimeType | null;
      lambdaARN?: string | null;
      created?: any | null;
      updated?: any | null;
      usageCount?: number | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      author?: {
        name: string;
        url?: string | null;
        email: string;
        picture?: string | null;
      } | null;
      vendor?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      product?: {
        name?: string | null;
        url?: string | null;
        logo?: string | null;
        logoBw?: string | null;
      } | null;
      connections?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          description?: string | null;
          discriminator?: string | null;
          input?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
      actions?: {
        nextToken?: string | null;
        items?: Array<{
          id: string;
          group?: string | null;
          name: string;
          type?: ActionType | null;
          description?: string | null;
          discriminator: string;
          packageConnectionIds?: Array<string | null> | null;
          optionalConnection?: boolean | null;
          input?: any | null;
          output?: any | null;
          createdAt?: any | null;
          updatedAt?: any | null;
          package: {
            schema?: string | null;
            id: string;
            group?: string | null;
            name?: string | null;
            markdown?: string | null;
            status: PackageStatus;
            license?: string | null;
            categories?: Array<string | null> | null;
            version?: string | null;
            repository: string;
            runtime?: RuntimeType | null;
            lambdaARN?: string | null;
            created?: any | null;
            updated?: any | null;
            usageCount?: number | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            author?: {
              name: string;
              url?: string | null;
              email: string;
              picture?: string | null;
            } | null;
            vendor?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            product?: {
              name?: string | null;
              url?: string | null;
              logo?: string | null;
              logoBw?: string | null;
            } | null;
            connections?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                description?: string | null;
                discriminator?: string | null;
                input?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
            actions?: {
              nextToken?: string | null;
              items?: Array<{
                id: string;
                group?: string | null;
                name: string;
                type?: ActionType | null;
                description?: string | null;
                discriminator: string;
                packageConnectionIds?: Array<string | null> | null;
                optionalConnection?: boolean | null;
                input?: any | null;
                output?: any | null;
                createdAt?: any | null;
                updatedAt?: any | null;
                package: {
                  schema?: string | null;
                  id: string;
                  group?: string | null;
                  name?: string | null;
                  markdown?: string | null;
                  status: PackageStatus;
                  license?: string | null;
                  categories?: Array<string | null> | null;
                  version?: string | null;
                  repository: string;
                  runtime?: RuntimeType | null;
                  lambdaARN?: string | null;
                  created?: any | null;
                  updated?: any | null;
                  usageCount?: number | null;
                  createdAt?: any | null;
                  updatedAt?: any | null;
                };
              } | null> | null;
            } | null;
          };
        } | null> | null;
      } | null;
    };
  } | null;
};

export type OnCreateUploadSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnCreateUploadSubscription = {
  onCreateUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnUpdateUploadSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnUpdateUploadSubscription = {
  onUpdateUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};

export type OnDeleteUploadSubscriptionVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type OnDeleteUploadSubscription = {
  onDeleteUpload?: {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    group?: string | null;
    owner?: string | null;
    flowId?: string | null;
    eventId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};
