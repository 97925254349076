import { axios } from 'core/Axios';
import {
  DataListEventsParams,
  DataListEventsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_EVENTS;
export const buildQueryKey = (params?: DataListEventsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListEventsParams
): Promise<DataListEventsResponse> => {
  return axios
    .get<DataListEventsResponse>(Route.LIST_EVENTS, { params })
    .then(data => data.data);
};

export const useListEvents = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<DataListEventsResponse, DataListEventsParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
