import { createContext } from 'react';

export interface NotificationOptions {
  title?: string;
  body?: string | JSX.Element | JSX.Element[];
  timeout?: number;
  showClose?: boolean;
  style?: 'default' | 'error' | 'success' | 'warning';
  className?: any;
}

export interface NotificationsContextValue {
  /**
   * Add a default error message.
   */
  add(title: string, options?: NotificationOptions): void;

  /**
   * Add a error message. Shortcut for passing options type.
   */
  addError(title: string, options?: NotificationOptions): void;

  /**
   * Add a warning message. Shortcut for passing options type.
   */
  addWarning(title: string, options?: NotificationOptions): void;

  /**
   * Add a success message. Shortcut for passing options type.
   */
  addSuccess(title: string, options?: NotificationOptions): void;

  /**
   * Clear a specific notification.
   */
  clear(id: number): void;

  /**
   * Clear all notifications.
   */
  clearAll(): void;
}

export const NotificationsContext = createContext<NotificationsContextValue>({
  add: () => undefined,
  addError: () => undefined,
  addWarning: () => undefined,
  addSuccess: () => undefined,
  clear: () => undefined,
  clearAll: () => undefined
});
