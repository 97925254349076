import { corePackages } from './Actions';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

const coreActions = [
  'CORE_DATA',
  'CORE_DATASTORE',
  'CORE_EMAIL',
  'CORE_LOGIC',
  'CORE_MANUAL_APPROVALS',
  'CORE_SMS',
  'web-tools',
  'CORE_FLOW'
];

/**
 * Mash Actions into categories and optionally filter.
 */
export const actionBuilder = (
  packages: any[] = [],
  connections: any[] = []
) => {
  const triggers = {
    name: 'Workflow Triggers',
    packages: [],
    tooltip: 'Trigger the workflow to run using these actions'
  };
  const core = {
    name: 'Core Actions',
    packages: [],
    tooltip: 'Common actions'
  };
  const configured = {
    name: 'Configured Actions',
    packages: [],
    tooltip: 'Actions that require a connection that has been created'
  };
  const available = {
    name: 'Additional Actions',
    packages: [],
    tooltip: 'Actions that do not require a connection'
  };
  const unconfigured = {
    name: 'Unconfigured Actions',
    packages: [],
    tooltip: 'Actions that require a connection that has not been created'
  };

  const connectionPackageIds = connections.map(
    c => c.packageConnection?.packageConnectionPackageId
  );
  const mappedPackages = packages.map(p => {
    return {
      ...p,
      actions: {
        items: sortBy(p.actions?.items, ['type', d => d.name.toLowerCase()])
      },
      categories: p.categories || ['Uncategorized']
    };
  });

  mappedPackages.forEach(p => {
    const actions = p.actions?.items;
    if (actions && actions.length) {
      if (p.status == null) {
        // This is an internal package
        const triggerActions = actions.filter(a => a.type === 'trigger');
        triggers.packages.push(...triggerActions);

        if (coreActions.includes(p.id)) {
          core.packages.push({
            ...p,
            actions: {
              items: actions.filter(a => a.type !== 'trigger')
            }
          });
        }
      } else if (p.status === 'Approved') {
        // This is a package in its own repo
        if (coreActions.includes(p.id)) {
          core.packages.push({
            ...p,
            actions: {
              items: actions.filter(a => a.type !== 'trigger')
            }
          });
        } else if (connectionPackageIds.includes(p.id)) {
          configured.packages.push(p);
        } else if (p.connections && p.connections?.length) {
          unconfigured.packages.push(p);
        } else {
          available.packages.push(p);
        }
      }
    }
  });

  return [
    {
      ...triggers,
      packages: sortBy(triggers.packages, [d => d.name.toLowerCase()])
    },
    { ...core, packages: sortBy(core.packages, [d => d.name.toLowerCase()]) },
    {
      ...configured,
      packages: sortBy(configured.packages, [d => d.name.toLowerCase()])
    },
    {
      ...available,
      packages: sortBy(available.packages, [d => d.name.toLowerCase()])
    },
    {
      ...unconfigured,
      packages: sortBy(unconfigured.packages, [d => d.name.toLowerCase()])
    }
  ].filter(c => c.packages.length);
};

/**
 * Given a vendor object, get the image.
 */
export const getImageForVendor = ({
  pkg,
  type = 'product',
  variant = 'bw',
  action
}: {
  pkg?;
  type?: 'product' | 'vendor';
  variant?: 'color' | 'bw';
  action?;
}) => {
  if ((!pkg || isEmpty(pkg)) && (!action || !action.logo)) {
    return `${process.env.REACT_APP_LOGO_URL}/placeholder.svg`;
  }

  const getLogoForVariant = o => {
    if (o) {
      switch (variant) {
        // eslint-disable-next-line
        case 'bw':
          if (o.logoBw) {
            return o.logoBw;
          }
        // eslint-disable-next-line
        case 'color':
          if (o.logo) {
            return o.logo;
          }
      }
    }
  };

  let logo: string;
  if (action) {
    logo = getLogoForVariant(action);

    if (logo) {
      return `${process.env.REACT_APP_LOGO_URL}/${logo}`;
    }
  }

  const typeObj = pkg[type];
  const otherTypeObj = type === 'product' ? pkg.vendor : pkg.product;

  logo = getLogoForVariant(typeObj);
  if (!logo) {
    if (otherTypeObj) {
      logo = getLogoForVariant(otherTypeObj);
      if (!logo) {
        logo = 'placeholder.svg';
      }
    } else {
      logo = 'placeholder.svg';
    }
  }

  logo = `${process.env.REACT_APP_LOGO_URL}/${logo}`;

  return logo;
};

/**
 * Flatten vendor actions to a single list.
 */
export const flattenActions = (packages: any[]) =>
  [...packages, ...corePackages].reduce((cur, item) => {
    if (!item) {
      return cur;
    }

    cur.push(
      ...item.actions.items.map(a => ({
        ...a,
        // TODO: Normalize vendor/pkg/package variables
        vendor: item,
        package: item
      }))
    );

    return cur;
  }, []);

/**
 * Get a package/action for a given action id.
 */
export const getActionForId = (packages: any[], actionId: string) => {
  for (const pack of packages) {
    if (
      (!pack.actions?.items || !pack.actions.items.length) &&
      actionId?.replace(/\/.*$/, '') === pack.id
    ) {
      return {
        pkg: {
          ...pack,
          actions: { items: [] }
        },
        action: {}
      };
    }
    for (const act of pack.actions?.items || []) {
      if (actionId === act.id) {
        const pkg = {
          ...pack,
          actions: {
            items: [...(pack.action?.items || [])]
          }
        };

        const action = {
          ...act,
          connections: {
            items: []
          }
        };

        // Add Connections but this is kinda hacky for now...
        // TODO: Come back and remove this
        if (
          action.packageConnectionIds &&
          action.packageConnectionIds.length &&
          !action.optionalConnection
        ) {
          const connections = pkg.connections;
          if (connections && connections.length) {
            action.connections.items = connections.filter(c =>
              action.packageConnectionIds.includes(c.id)
            );
          }
        }

        pkg.actions.items = [
          ...pkg.actions.items.filter(a => a.id !== action.id),
          action
        ];

        return {
          action,
          pkg
        };
      }
    }
  }
  return { action: null, pkg: null };
};
