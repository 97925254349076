import React, { FC, useCallback } from 'react';
import { NewEditStore } from './NewEditStore';
import { SaveDatastoreInput, UpdateDatastoreInput } from 'core/types/API';
import { useAuth } from 'core/Auth';
import shortid from '@getcrft/shortid';
import { ExtendedDataStore } from './DatastoreDrawer';
import { useNotification } from 'shared/layers/Notification';
import {
  useDataDelDatastore,
  useDataPutDatastore,
  useDataUpdateDatastore
} from 'shared/hooks/api';

export type NewEditStoreContainerProps = {
  flowId?: string;
  groups?: any[];
  storeGroups: any[];
  flows: any[];
  connectedFlows?: {
    id: string;
    name: string;
  }[];
  store?: ExtendedDataStore;
  onCreate?: (key?: string) => void;
  onClose: () => void;
};

export const NewEditStoreContainer: FC<NewEditStoreContainerProps> = ({
  flowId,
  flows,
  onClose,
  onCreate = () => undefined,
  ...rest
}) => {
  const { user } = useAuth();
  const { addSuccess, addError } = useNotification();

  const { mutate: saveDatastore } = useDataPutDatastore({
    options: {
      onError({ message }, input) {
        addError(`Failed to create ${input.name} Datastore`, {
          body: message as string
        });
      },
      onSuccess(data) {
        onCreate(data.id);
        addSuccess(`Datastore ${data.name} was created.`);
      }
    }
  });

  const { mutate: updateDatastore } = useDataUpdateDatastore({
    options: {
      onError({ message }, input) {
        addError(`Failed to update ${input.name} Datastore`, {
          body: message as string
        });
      },
      onSuccess(data) {
        addSuccess(`Datastore ${data.name} was updated.`);
      }
    }
  });

  const { mutate: deleteDatastore } = useDataDelDatastore({
    options: {
      onError({ message }) {
        addError(`Failed to delete Datastore`, {
          body: message as string
        });
      },
      onSuccess() {
        addSuccess('Datastore deleted');
      }
    }
  });

  const createStore = useCallback(
    async (input: SaveDatastoreInput) => {
      let owner = user.id;
      let group = input.group ? input.group : '';

      if (input.flowId === '') {
        input.flowId = '';
      } else {
        // TODO: Come back this will need to happen on backend more than likely
        // Automatically associate permissions on the Flow
        const flow = flows.find(f => f.id === input.flowId);
        if (flow) {
          group = flow.group?.name;
        }
      }

      saveDatastore({
        ...input,
        id: shortid(),
        owner,
        group
      });
    },
    [flows, saveDatastore, user.id]
  );

  const updateStore = useCallback(
    async (input: UpdateDatastoreInput) => {
      let { group } = input;

      if (!input.flowId) {
        input.flowId = '';
      } else {
        // TODO: Come back this will need to happen on backend more than likely
        // Automatically associate permissions on the Flow
        const flow = flows.find(f => f.id === input.flowId);
        if (flow) {
          // @ts-ignore
          group = flow.group?.name || flow.group || '';
        }
      }

      updateDatastore({
        ...input,
        group: group ? group : ''
      });
    },
    [flows, updateDatastore]
  );

  const deleteStore = useCallback(
    async (id: string) => {
      deleteDatastore({
        id
      });
    },
    [deleteDatastore]
  );

  return (
    <NewEditStore
      {...rest}
      onCreate={createStore}
      onUpdate={updateStore}
      onDelete={deleteStore}
      onClose={onClose}
      flowId={flowId}
    />
  );
};
