import { Helper } from '.';

export const emailHelpers: Helper[] = [
  {
    name: '$emailName',
    label: 'Email Prefix',
    description: 'Return everything before the "@" symbol.',
    examples: [`$emailName("baz@bar.com") => "baz"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$emailDomain',
    label: 'Email Domain',
    description: 'Return everything after the "@" symbol.',
    examples: [`$emailDomain("baz@bar.com") => "bar.com"`],
    valueType: 'string',
    returnType: 'string'
  }
];
