import React, { DetailedHTMLProps, FC, ReactNode } from 'react';
import classNames from 'classnames';
import css from './Tag.module.css';

type TagProps = {
  icon?: ReactNode;
  className?: any;
  color?: 'default' | 'coral' | 'error';
  size?: 'small' | 'medium' | 'large';
  disableMargins?: boolean;
} & DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const Tag: FC<TagProps> = ({
  color,
  children,
  size,
  icon,
  className,
  disableMargins = false,
  onClick,
  ...rest
}) => (
  <span
    {...rest}
    className={classNames(css.tag, className, {
      [css.default]: !color || color === 'default',
      [css.coral]: color === 'coral',
      [css.error]: color === 'error',
      [css.small]: size === 'small',
      [css.medium]: !size || size === 'medium',
      [css.large]: size === 'large',
      [css.clickable]: onClick !== undefined,
      [css.disableMargins]: disableMargins
    })}
    onClick={onClick}
  >
    <span>{children}</span>
    {icon && <span className={css.tagIcon}>{icon}</span>}
  </span>
);
