import { axios } from 'core/Axios';
import {
  DataListNotificationsParams,
  DataListNotificationsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_NOTIFICATIONS;
export const buildQueryKey = (params?: DataListNotificationsParams) => {
  return [baseQueryKey, params];
};

export const req = async (): Promise<DataListNotificationsResponse> => {
  return axios
    .get<DataListNotificationsResponse>(Route.LIST_NOTIFICATIONS)
    .then(data => data.data);
};

export const useListNotifications = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListNotificationsResponse,
  DataListNotificationsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req()),
    options
  );
};
