import React, { FC, useState } from 'react';
import ellipsize from 'ellipsize';
import css from './Ellipsis.module.css';

type EllipsisProps = {
  text: string;
  expandable?: boolean;
  limit?: number;
  className?: string;
};

export const Ellipsis: FC<EllipsisProps> = ({
  text,
  className,
  expandable = true,
  limit = 256
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const substr = ellipsize(text, limit);

  return (
    <span className={className}>
      {expanded ? text : <span title={text}>{substr}</span>}
      {expandable && !expanded && text.length > limit && (
        <span
          title="Click to view rest of content"
          className={css.dots}
          tabIndex={-1}
          onClick={() => setExpanded(true)}
        >
          ...
        </span>
      )}
    </span>
  );
};
