import React, { FC } from 'react';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { TokenOption } from '../types';
import ellipsize from 'ellipsize';
import css from './TokenMenuItem.module.css';

const MAX_LENGTH = 175;
const MAX_DESC_LENGTH = 110;

export type TokenMenuItemProps = {
  keyword?: string;
  active?: boolean;
  token: TokenOption;
  className?: string;
  onSelectToken: (token: TokenOption) => void;
};

export const TokenMenuItem: FC<TokenMenuItemProps> = ({
  keyword,
  active,
  token,
  className,
  onSelectToken
}) => (
  <li
    ref={token.ref}
    className={classNames(css.listItem, className, {
      [css.active]: active
    })}
    key={token.value}
    onPointerDown={event => {
      if (event.button === 2) {
        return;
      }

      onSelectToken(token);
    }}
  >
    {token.icon && (
      <img src={token.icon} className={css.icon} alt={token.text} />
    )}
    <span className={css.textContainer}>
      <span className={css.title}>
        <Highlighter
          autoEscape={true}
          searchWords={[keyword]}
          textToHighlight={ellipsize(token.text, MAX_LENGTH)}
        />
      </span>
      {token.subtext && (
        <span className={css.subtext}>
          <Highlighter
            autoEscape={true}
            searchWords={[keyword]}
            textToHighlight={ellipsize(token.subtext, MAX_LENGTH)}
          />
        </span>
      )}
      {token.description && (
        <p className={css.description}>
          <Highlighter
            autoEscape={true}
            searchWords={[keyword]}
            textToHighlight={ellipsize(token.description, MAX_DESC_LENGTH)}
          />
        </p>
      )}
    </span>
  </li>
);
