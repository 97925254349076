import React, { ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import css from './Collapse.module.css';

interface CollapseProps {
  children: ReactNode | (() => any);
  expanded?: boolean;
  className?: any;
}

export const Collapse = ({ children, expanded, className }: CollapseProps) => (
  <AnimatePresence initial={false}>
    {expanded && (
      <motion.section
        className={classNames(className, css.section)}
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: 'auto'
          },
          collapsed: {
            opacity: 0,
            height: 0
          }
        }}
        transition={{
          duration: 0.5,
          ease: [0.04, 0.62, 0.23, 0.98],
          when: 'beforeChildren'
        }}
      >
        {isFunction(children) ? children() : children}
      </motion.section>
    )}
  </AnimatePresence>
);
