import { axios } from 'core/Axios';
import {
  DataListApiKeysParams,
  DataListApiKeysResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_API_KEYS;
export const buildQueryKey = (params?: DataListApiKeysParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListApiKeysParams
): Promise<DataListApiKeysResponse> => {
  return axios
    .get<DataListApiKeysResponse>(Route.LIST_API_KEYS, { params })
    .then(data => data.data);
};

export const useListApiKeys = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<DataListApiKeysResponse, DataListApiKeysParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
