import { EditorState } from 'draft-js';
import { convertToText } from './serializer';

/**
 * When a user copies our content, we need to serialize it. Inspired by below but didn't support
 * custom serialization.
 *
 * Reference: https://github.com/thibaudcolas/draftjs-conductor/blob/master/src/lib/api/copypaste.js#L70
 */
export const copyListener = (
  editorState: EditorState,
  event: Event & {
    clipboardData: DataTransfer;
  },
  shouldTrim?: boolean
) => {
  const selection = window.getSelection();

  if (!event.clipboardData || selection?.rangeCount === 0) {
    return false;
  }

  const { result } = convertToText(editorState, shouldTrim);
  event.clipboardData.setData('text/plain', result);
  event.preventDefault();
};
