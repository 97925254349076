import React, {
  FC,
  Fragment,
  useRef,
  useLayoutEffect,
  useCallback
} from 'react';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { Button } from 'shared/elements/Button';
import { Img } from 'shared/elements/Img';
import copy from 'copy-to-clipboard';
import { useNotification } from 'shared/layers/Notification';
import { ReactComponent as AddAppIcon } from 'assets/svg/add-app.svg';
import { ReactComponent as OpenIcon } from 'assets/svg/open.svg';
import { Block } from 'shared/layout/Block';
import Linkify from 'react-linkify-always-blank';
import { Pluralize } from 'shared/utils/Pluralize';
import { CascaderAdditionalActions, CascaderItem } from '../types';
import { Ellipsis } from 'shared/data/Ellipsis';
import { isActionLinkable } from 'core/Flows';
import isEmpty from 'lodash/isEmpty';
import { ButtonGroup } from 'shared/elements/ButtonGroup';
import css from './CascaderDetail.module.css';

type CascaderDetailProps = {
  selection?: CascaderItem;
  disabled?: boolean;
  hover?: CascaderItem;
  allowModifiers?: boolean;
  additionalDetail?: (item?: CascaderItem) => React.ReactNode;
  additionalActions?: CascaderAdditionalActions[];
  onSelection?: (item: CascaderItem) => void;
  onHover?: (item: CascaderItem) => void;
};

export const CascaderDetail: FC<CascaderDetailProps> = ({
  selection,
  hover,
  disabled,
  additionalDetail,
  additionalActions,
  onSelection,
  onHover
}) => {
  const { addSuccess } = useNotification();
  const panelRef = useRef<HTMLDivElement | null>(null);
  const toShowDetail = !!hover ? hover : selection;

  const scrollIntoView = useCallback(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = 0;
      panelRef.current.scrollIntoView?.({
        behavior: 'smooth',
        inline: 'end'
      });
    }
  }, []);

  useLayoutEffect(() => {
    requestAnimationFrame(() => scrollIntoView());
  }, [scrollIntoView, selection]);

  useMount(() => scrollIntoView());

  return (
    <div
      ref={panelRef}
      className={classNames(css.panel, css.details, {
        [css.emptyDetails]: !toShowDetail,
        [css.hoverDetails]: toShowDetail
      })}
      onMouseEnter={() => onHover(hover)}
      onMouseLeave={() => onHover(null)}
    >
      {toShowDetail && (
        <Fragment>
          <h4>
            <Img className={css.icon} src={toShowDetail.icon} alt="icon" />{' '}
            <Ellipsis expandable={false} text={toShowDetail.text} />
          </h4>
          {toShowDetail.description && (
            <Block>
              <Linkify properties={{ target: '_blank' }}>
                {toShowDetail.description}
              </Linkify>
            </Block>
          )}
          {toShowDetail?.items?.length > 0 && (
            <Block label="Count">
              {toShowDetail.items.length.toLocaleString()}
              {' child '}
              <Pluralize
                showCount={false}
                singular="field"
                count={toShowDetail.items.length}
              />
            </Block>
          )}
          {additionalDetail !== undefined && additionalDetail(toShowDetail)}
          {toShowDetail?.schema?.examples?.length > 0 &&
            !isEmpty(toShowDetail?.schema?.examples[0]) && (
              <Block
                className={css.examples}
                label={
                  <Pluralize
                    showCount={false}
                    singular="Example"
                    count={toShowDetail?.schema?.examples.length}
                  />
                }
              >
                {toShowDetail.schema.examples.length > 1 ? (
                  <ul>
                    {toShowDetail.schema.examples.map((e, i) => (
                      <li key={i}>{e}</li>
                    ))}
                  </ul>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: toShowDetail?.schema?.examples.join(', ')
                    }}
                  />
                )}
              </Block>
            )}
          {toShowDetail?.selectable && (
            <Fragment>
              {toShowDetail.valueType && (
                <Block label="Return Type" className={css.type}>
                  {toShowDetail.subType ? 'array' : toShowDetail.valueType}
                  {toShowDetail.subType ? `<${toShowDetail.subType}>` : ''}
                </Block>
              )}
              <Block label="Path">
                <span
                  className={css.path}
                  title="Click to copy to clipboard"
                  onClick={() => {
                    copy(`${toShowDetail.value}`);
                    addSuccess('Path copied to clipboard');
                  }}
                >
                  {toShowDetail.value}
                </span>
              </Block>
            </Fragment>
          )}
          {toShowDetail?.node?.pkg && (
            <Block label="Integration">
              {isActionLinkable(toShowDetail.node.pkg) ? (
                <Button
                  title="View Integration documentation"
                  variant="text"
                  disablePadding
                  disableMargins
                  className={css.packageBtn}
                  color="primary"
                  onClick={() =>
                    window.open(
                      `/marketplace/integrations/${toShowDetail.node.pkg.id}`,
                      '_blank'
                    )
                  }
                >
                  <OpenIcon className={css.openIcon} />
                  {toShowDetail.node.pkg.name} / {toShowDetail.node.action.name}
                </Button>
              ) : (
                <Fragment>
                  {toShowDetail.node.pkg.name} / {toShowDetail.node.action.name}
                </Fragment>
              )}
            </Block>
          )}
          {toShowDetail?.selectable && (
            <ButtonGroup className={css.actions}>
              <Button
                size="small"
                color="primary"
                className={css.selectButton}
                disabled={disabled}
                disableMargins
                variant="outline"
                onClick={() => onSelection?.(toShowDetail)}
              >
                Select Field
              </Button>
              {additionalActions?.length
                ? additionalActions.map(action => (
                    <Button
                      key={action.id}
                      className={classNames(action.className, css.addlButton)}
                      size="small"
                      disableMargins
                      disabled={action.disabled}
                      variant="outline"
                      onClick={() => action.onClick(selection)}
                    >
                      {action.text}
                    </Button>
                  ))
                : null}
            </ButtonGroup>
          )}
        </Fragment>
      )}
      {!toShowDetail && (
        <Fragment>
          <AddAppIcon className={css.appIcon} />
          <h4 className={css.emptyText}>
            Select an item on the left
            <br />
            or start typing to search.
          </h4>
        </Fragment>
      )}
    </div>
  );
};
