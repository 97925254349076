import React, { FC, useMemo, useCallback } from 'react';
import flow from 'lodash/flow';
import copy from 'copy-to-clipboard';
import { Block } from 'shared/layout/Block';
import { Card } from 'shared/layout/Card';
import { useNotification } from 'shared/layers/Notification';
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg';
import { Tooltip } from 'shared/layers/Tooltip';
import { getType } from 'shared/internal/Datastore/utils';
import css from './DatastorePreview.module.css';

const MAX_PREVIEW_DATA_LENGTH = 3;
const replaceFinalEllipse = prettyStringArr =>
  prettyStringArr.replace('"..."\n]', '...\n]');
const prettyStringify = myArr => JSON.stringify(myArr, null, 2);
const addEllipse = myArr => [...myArr, '...'];
const stringifyArrayWithEllipses = previewData => {
  return previewData.length > MAX_PREVIEW_DATA_LENGTH
    ? flow(
        addEllipse,
        prettyStringify,
        replaceFinalEllipse
      )(previewData.slice(0, MAX_PREVIEW_DATA_LENGTH))
    : prettyStringify(previewData);
};

type DatastorePreviewProps = {
  value: any;
  header?: boolean;
};

export const DatastorePreview: FC<DatastorePreviewProps> = ({
  value,
  header = true
}) => {
  const { addSuccess } = useNotification();
  const type = useMemo(() => getType(value), [value]);

  const onCopy = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      event.stopPropagation();
      const copyVal = ['array', 'object'].includes(type)
        ? JSON.stringify(value)
        : value;
      copy(copyVal);
      addSuccess('Copied to clipboard');
    },
    [addSuccess, type, value]
  );

  const renderArrayElement = useCallback(
    val => (
      <Block label={header ? 'Preview' : ''} className={css.preview}>
        <Card className={css.card}>
          <pre>{stringifyArrayWithEllipses(val)}</pre>
          <Tooltip content="Copy Datastore Value" placement="right">
            <CopyIcon className={css.copy} onClick={onCopy} />
          </Tooltip>
          <span className={css.count}>{val.length.toLocaleString()} Items</span>
        </Card>
      </Block>
    ),
    [header, onCopy]
  );

  const renderObjectElement = useCallback(
    val => (
      <Block label={header ? 'Preview' : ''} className={css.preview}>
        <Card className={css.card}>
          <pre>{JSON.stringify(val, null, 2)}</pre>
          <Tooltip content="Copy Datastore Value" placement="right">
            <CopyIcon className={css.copy} onClick={onCopy} />
          </Tooltip>
        </Card>
      </Block>
    ),
    [header, onCopy]
  );

  const renderPrimitiveElement = useCallback(
    val => (
      <Block label={header ? 'Preview' : ''} className={css.preview}>
        <Card className={css.card}>
          {val}
          <Tooltip content="Copy Datastore Value" placement="left">
            <CopyIcon className={css.copy} onClick={onCopy} />
          </Tooltip>
        </Card>
      </Block>
    ),
    [header, onCopy]
  );

  const element = useMemo(() => {
    if (type === 'array') {
      return renderArrayElement(value);
    } else if (type === 'object') {
      return renderObjectElement(value);
    }

    return renderPrimitiveElement(value);
  }, [
    renderArrayElement,
    renderObjectElement,
    renderPrimitiveElement,
    type,
    value
  ]);

  return element;
};
