import { axios } from 'core/Axios';
import {
  DataDeleteConnectionParams,
  DataDeleteConnectionResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DELETE_CONNECTION;

export const req = async (
  params: DataDeleteConnectionParams
): Promise<DataDeleteConnectionResponse> => {
  return axios
    .delete<DataDeleteConnectionResponse>(Route.DELETE_CONNECTION, { params })
    .then(data => data.data);
};

export const useDeleteConnection = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataDeleteConnectionResponse,
  DataDeleteConnectionParams
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_CONNECTIONS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
