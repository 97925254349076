import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Papa from 'papaparse';
import * as Yup from 'yup';
import { useEvent } from 'react-use';
import { Input } from 'shared/form/Input';
import { UploadInput } from 'shared/form/Upload';
import { Block } from 'shared/layout/Block';
import { parseJson } from './utils';
import { CodeEditor } from 'shared/form/CodeEditor';
import { useNotification } from 'shared/layers/Notification';
import { Button } from 'shared/elements/Button';
import classNames from 'classnames';
import { jsonrepair } from 'jsonrepair';
import { JsonTreeNodeData } from './JsonTreeNode';
import { Operation } from './JsonTree';
import css from './NodeImporter.module.css';
import { RadioGroup, RadioItem } from 'shared/form/RadioGroup';

type NodeImporterProps = {
  currentNode?: any;
  currentNodeType?: string;
  showEmpty?: boolean;
  replacement?: boolean;
  addEditNode?: (
    newNode: JsonTreeNodeData,
    curNode: JsonTreeNodeData,
    op: Operation
  ) => void;
  onCancel: () => void;
};

export const NodeImporter: FC<NodeImporterProps> = ({
  currentNode,
  currentNodeType,
  showEmpty,
  replacement,
  addEditNode,
  onCancel
}) => {
  const { addError } = useNotification();
  const [uploadData, setUploadData] = useState<string | null>(null);
  const [uploadDataType, setUploadDataType] = useState<'csv' | 'json' | null>(
    null
  );
  const [importValues, setImportValues] = useState<string | null>(null);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [mergeConflict, setMergeConflict] = useState<boolean>(false);

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      key: '',
      op: replacement ? 'replace' : 'add',
      value: ''
    },
    validationSchema: Yup.object().shape({
      key:
        currentNode && currentNode.type !== 'array' && !replacement
          ? Yup.string().required('Required')
          : Yup.string(),
      op: Yup.mixed<Operation>().oneOf([
        'add',
        'delete',
        'edit',
        'replace',
        'merge'
      ]),
      value: Yup.mixed().required()
    }),
    onSubmit: values => {
      const { key, op, value } = values;
      const parsedValue = parseJson(
        {
          [key]: value
        },
        '',
        showEmpty
      );
      addEditNode(parsedValue.data[0], currentNode, op as Operation);
    }
  });

  const isArray = currentNode && currentNode.type === 'array';

  useEvent('paste', event => {
    if (!importValues) {
      const text = (
        event.clipboardData || (window as any).clipboardData
      ).getData('text');
      let val = text.trim();

      try {
        const newValue = text.replace(/("|'|\w)\n/, '$1,\n');
        val = jsonrepair(newValue);
        JSON.parse(val);
        setUploadDataType('json');
      } catch {
        setUploadDataType('csv');
      }

      setUploadData(val);
    }
  });

  const onUpload = useCallback(result => {
    const [file] = result.successful;
    const reader = new FileReader();

    reader.onload = e => {
      const data = e.target.result as string;
      if (file.name.endsWith('.csv')) {
        setUploadDataType('csv');
      } else {
        setUploadDataType('json');
      }

      setUploadData(data);
    };

    reader.readAsText(file.data);
  }, []);

  useEffect(() => {
    if (uploadData) {
      if (uploadDataType === 'csv') {
        const csv = Papa.parse(uploadData.trim(), {
          header: false
        });

        const csvData = csv.data;
        if (csvData.length) {
          const [firstVal] = csvData as string[][];
          if (firstVal.length === 1) {
            // If first row does not have any separators, assume list of single values
            const importList = (csvData as string[][]).reduce(
              (acc, cur) => [...acc, cur[0].trim()],
              []
            );
            setImportValues(JSON.stringify(importList, null, 2));
            setIsInvalid(false);
            setFieldValue('value', importList).then(() => {
              setFieldTouched('value', true, true);
            });
          } else {
            // If first row has separators, assume list of objects keyed by first row
            const keys = firstVal;
            const values = csvData.slice(1, csvData.length);
            const importList = (values as string[][]).map(v =>
              keys.reduce(
                (obj, key, index) => ({
                  ...obj,
                  [key.trim()]: v[index].trim()
                }),
                {}
              )
            );
            setImportValues(JSON.stringify(importList, null, 2));
            setIsInvalid(false);
            setFieldValue('value', importList).then(() => {
              setFieldTouched('value', true, true);
            });
          }
        } else {
          setIsInvalid(true);
        }
      } else if (uploadDataType === 'json') {
        try {
          const data = JSON.parse(uploadData);
          setImportValues(JSON.stringify(data, null, 2));
          setFieldValue('value', data).then(() => {
            setFieldTouched('value', true, true);
          });
        } catch (e) {
          setIsInvalid(true);
          setImportValues(null);
          setFieldValue('value', '').then(() => {
            setFieldTouched('value', true, true);
          });
        }
      }
    }
  }, [setFieldTouched, setFieldValue, uploadData, uploadDataType]);

  useEffect(() => {
    if (
      currentNode &&
      importValues &&
      currentNodeType !== '' &&
      replacement &&
      values.op === 'merge'
    ) {
      const importType = parseJson(JSON.parse(importValues), '', {
        showEmpty
      }).type;

      // If the current value is an array, allow any imported value to be merge into the array as a new value.
      // If the current value is an object and the imported value is not an object, we cannot merge into an object
      if (currentNodeType !== 'array' && currentNodeType !== importType) {
        setMergeConflict(true);
      } else {
        setMergeConflict(false);
      }
    } else {
      setMergeConflict(false);
    }
  }, [
    currentNode,
    currentNodeType,
    importValues,
    replacement,
    showEmpty,
    values.op
  ]);

  const repairJson = (val: string) => {
    try {
      // jsonrepair doesn't handle certain cases we want to handle, so need to format the value into a way jsonrepair handles
      // Add comma before a newline when a quote or a letter is before it (no comma)
      const newValue = val.replace(/("|'|\w)\n/, '$1,\n');
      const repaired = jsonrepair(newValue);
      const parsed = JSON.parse(repaired);
      setImportValues(JSON.stringify(parsed, null, 2));
      setFieldValue('value', parsed).then(() => {
        setFieldTouched('value', true, true);
      });
      setIsInvalid(false);
    } catch (err) {
      addError('Unable to repair value. Please ensure valid JSON is used');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!isArray && !replacement && (
        <Block label="Key" required>
          <Input
            name="key"
            value={values.key}
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!touched.key && !!errors.key}
            fullWidth
          />
        </Block>
      )}
      {replacement && (
        <Block label="Import Operation" required>
          <RadioGroup
            onChange={val => setFieldValue('op', val)}
            orientation="horizontal"
          >
            <RadioItem value="replace" checked={values.op === 'replace'}>
              Replace value with import
            </RadioItem>
            <RadioItem value="merge" checked={values.op === 'merge'}>
              Merge value with import
            </RadioItem>
          </RadioGroup>
        </Block>
      )}
      <Block label="Value" required>
        <UploadInput
          className={classNames(css.import, {
            [css.value]: !!importValues
          })}
          type="local"
          height={!importValues ? '100px' : '42px'}
          options={{
            restrictions: {
              maxFileSize: 1000000,
              maxNumberOfFiles: 1,
              allowedFileTypes: ['application/json', 'text/csv']
            },
            autoProceed: true
          }}
          note={
            !importValues
              ? 'Upload a JSON or CSV file - or - paste from clipboard'
              : 'Import new file'
          }
          onComplete={onUpload}
          onRestrictionFailed={(_file, error) => addError(error.message)}
        />
        {importValues && (
          <Fragment>
            <CodeEditor
              value={importValues}
              language="json"
              maxHeight={200}
              error={isInvalid}
              onChange={val => {
                try {
                  const parsed = JSON.parse(val);
                  setImportValues(val);
                  setFieldValue('value', parsed);
                  setIsInvalid(false);
                } catch (e) {
                  setImportValues(val);
                  setFieldValue('value', '');
                  setIsInvalid(true);
                }
              }}
              onValidate={markers => {
                setIsInvalid(markers.length > 0);
              }}
            />
            {isInvalid && (
              <span className={css.error}>
                The value provided is not valid JSON.
                <Button
                  variant="text"
                  color="primary"
                  className={css.repair}
                  disableMargins
                  disablePadding
                  onClick={() => repairJson(importValues)}
                >
                  Repair JSON
                </Button>
              </span>
            )}
            {mergeConflict && (
              <span className={css.error}>
                Unable to merge imported value into existing datastore. Please
                reupload the value or choose to
                <Button
                  variant="text"
                  color="primary"
                  className={css.repair}
                  disableMargins
                  disablePadding
                  onClick={() => setFieldValue('op', 'replace')}
                >
                  replace the existing value
                </Button>
              </span>
            )}
          </Fragment>
        )}
      </Block>
      <div className={css.actions}>
        <Button
          type="submit"
          color="primary"
          disabled={isInvalid || !isValid || !importValues || mergeConflict}
        >
          Add
        </Button>
        <Button variant="outline" disableMargins onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
