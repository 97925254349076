import { CorePackages, DatastoreActions } from '../enums';

export const datastorePackage = {
  id: CorePackages.Datastore,
  name: 'Datastore',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `database.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: DatastoreActions.Set,
        discriminator: DatastoreActions.Set,
        name: 'Set Datastore Value',
        description: 'Set a value in the datastore',
        input: {
          type: 'object',
          required: ['store'],
          properties: {
            store: {
              type: 'string',
              title: 'Datastore',
              description: 'The datastore to operate on',
              attributes: {
                'ui:component': 'datastore',
                'ui:order': 0
              }
            },
            value: {
              type: 'string',
              title: 'Value',
              description: 'The value to set in the store',
              attributes: {
                'ui:order': 1
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of setting the value'
            },
            data: {
              type: 'string',
              title: 'Data',
              description: 'Value of the datastore'
            }
          }
        }
      },
      {
        id: DatastoreActions.Get,
        discriminator: DatastoreActions.Get,
        name: 'Get Datastore Value',
        description: 'Get a value from the datastore',
        input: {
          type: 'object',
          required: ['name'],
          properties: {
            name: {
              type: 'string',
              title: 'Datastore',
              description: 'The datastore to get the value from',
              attributes: {
                'ui:order': 0,
                'ui:tokenTypes': ['store'],
                'ui:fieldProps': {
                  singleSelection: true,
                  allowModifiers: false,
                  allowExpressions: false,
                  allowOverride: false
                }
              }
            },
            default: {
              type: 'string',
              title: 'Default',
              description: 'Default value if no value is present',
              attributes: {
                'ui:order': 1
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of getting the value'
            },
            data: {
              type: 'object',
              title: 'Data',
              description: 'Value of the datastore'
            }
          }
        }
      },
      {
        id: DatastoreActions.AddList,
        discriminator: DatastoreActions.AddList,
        name: 'Array - Add Item(s)',
        description: 'Add item(s) to a datastore list',
        input: {
          type: 'object',
          required: ['store', 'value'],
          properties: {
            store: {
              type: 'string',
              title: 'Datastore',
              description: 'The datastore to operate on',
              attributes: {
                'ui:component': 'datastore:list',
                'ui:order': 0
              }
            },
            value: {
              title: 'Item(s) To Add',
              type: 'datastore:value',
              attributes: {
                'ui:datastoreType': 'array',
                'ui:order': 1
              }
            },
            flatten: {
              title: 'Flatten Items',
              type: 'boolean',
              description:
                'If the items contain another array, convert into a "single" dimensional array',
              default: true,
              attributes: {
                'ui:order': 2
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of updating the datastore'
            },
            data: {
              type: 'array',
              title: 'Data',
              description: 'Updated value of the datastore'
            }
          }
        }
      },
      {
        id: DatastoreActions.FindInList,
        discriminator: DatastoreActions.FindInList,
        name: 'Array - Find Item',
        description:
          'Find an item matching certain criteria within a datastore list. Returns a list of matching item(s)',
        input: {
          type: 'object',
          required: ['filters'],
          properties: {
            filters: {
              title: false,
              type: 'array',
              description:
                'Conditions to return an item that first matches the conditions',
              attributes: {
                'ui:component': 'condition',
                'ui:condition-multiple': false,
                'ui:tokenTypes': ['store:array'],
                'ui:order': 0,
                'ui:fieldProps': {
                  allowExpressions: false,
                  allowModifiers: false
                }
              }
            },
            all: {
              title: 'Find All Matches',
              type: 'boolean',
              description:
                'Return an array of all items that match the criteria',
              default: false,
              attributes: {
                'ui:order': 1
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of finding a value'
            },
            data: {
              type: 'object',
              title: 'Data',
              description: 'The resulting data from the action',
              properties: {
                matches: {
                  title: 'Matches',
                  type: 'array',
                  description:
                    'A list of items matching the find conditions. [NOTE: Will be empty if no matches were found]'
                },
                found: {
                  title: 'Found Match',
                  type: 'boolean'
                },
                count: {
                  title: 'Found Count',
                  type: 'number'
                }
              }
            }
          }
        }
      },
      {
        id: DatastoreActions.RemoveList,
        discriminator: DatastoreActions.RemoveList,
        name: 'Array - Remove Item(s)',
        description:
          'Remove item(s) matching certain criteria within a datastore list',
        input: {
          type: 'object',
          required: ['filters'],
          properties: {
            filters: {
              title: false,
              type: 'array',
              description:
                'Conditions to remove item(s) that match the conditions',
              attributes: {
                'ui:component': 'condition',
                'ui:condition-multiple': false,
                'ui:tokenTypes': ['store:array'],
                'ui:order': 0
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of removing value'
            },
            data: {
              type: 'array',
              title: 'Data',
              description: 'Updated value of the datastore'
            }
          }
        }
      },
      {
        id: DatastoreActions.DedupeList,
        discriminator: DatastoreActions.DedupeList,
        name: 'Array - Deduplicate Items',
        description: 'Deduplicate items within a datastore list',
        input: {
          type: 'object',
          required: ['uniqueBy'],
          properties: {
            uniqueBy: {
              title: 'Deduplicate by Field',
              type: 'string',
              description:
                "The datastore field to deduplicate the list by. [NOTE: Selecting a datastore will deduplicate by the full item. Selecting a datastore item's field will deduplicate the list based on the value of each item's field value]",
              attributes: {
                'ui:order': 0,
                'ui:tokenTypes': ['store:array'],
                'ui:fieldProps': {
                  singleSelection: true,
                  allowExpressions: false,
                  allowModifiers: false,
                  allowOverride: false
                }
              }
            },
            save: {
              title: 'Save Deduplication',
              type: 'boolean',
              description:
                'If true, replace the datastore with the deduplicated list, otherwise return the deduplicated list',
              default: true,
              attributes: {
                'ui:order': 1
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['data', 'success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Result of deduplicating datastore'
            },
            data: {
              type: 'array',
              title: 'Data',
              description: 'Updated value of the datastore'
            }
          }
        }
      },
      {
        id: DatastoreActions.AddUpdateProperty,
        discriminator: DatastoreActions.AddUpdateProperty,
        name: 'Object - Add/Update Property',
        description:
          "Add to or update the value of a datastore object's properties",
        input: {
          type: 'object',
          required: ['store', 'value'],
          properties: {
            store: {
              type: 'string',
              title: 'Datastore',
              description: 'The Datastore item to operate on.',
              attributes: {
                'ui:component': 'datastore:object',
                'ui:order': 0
              }
            },
            value: {
              title: 'Values To Add/Update',
              type: 'datastore:value',
              attributes: {
                'ui:datastoreType': 'object',
                'ui:order': 1
              }
            },
            overwrite: {
              title: 'Overwrite Properties',
              description:
                'If a property specified in value exists on the datastore, overwrite the value',
              type: 'boolean',
              default: true,
              attributes: {
                'ui:order': 2
              }
            }
          }
        }
      },
      {
        id: DatastoreActions.DeleteProperty,
        discriminator: DatastoreActions.DeleteProperty,
        name: 'Object - Remove Property',
        description: 'Remove properties from a datastore object',
        input: {
          type: 'object',
          required: ['store', 'properties'],
          properties: {
            store: {
              type: 'string',
              title: 'Datastore',
              description: 'The Datastore item to operate on.',
              attributes: {
                'ui:component': 'datastore:object',
                'ui:order': 0
              }
            },
            properties: {
              title: 'Properties to Remove',
              description:
                'Enter properties to remove from the datastore. Press enter to confirm.',
              type: 'string',
              attributes: {
                'ui:component': 'array',
                'ui:order': 1
              }
            }
          }
        }
      }
      // Does not work for LIST datastores: see https://fpintel.slack.com/archives/C03B47N09NY/p1652800566867849
      // {
      //   id: DatastoreActions.Trigger,
      //   name: 'Datastore Value Changed',
      //   type: 'trigger',
      //   description: 'Trigger an event when the datastore value has changed.',
      //   input: {
      //     type: 'object',
      //     required: ['store'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The name of the datastore.',
      //         attributes: {
      //           'ui:component': 'datastore',
      //           'ui:order': 0
      //         }
      //       },
      //       enabled: {
      //         type: 'boolean',
      //         title: 'Enabled',
      //         description: 'Enable or disable the triggering.',
      //         default: true,
      //         attributes: {
      //           'ui:order': 1
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'If the trigger was successful.'
      //       },
      //       data: {
      //         title: 'Data',
      //         type: 'object',
      //         required: ['previous', 'update'],
      //         properties: {
      //           previous: {
      //             title: 'Previous Data',
      //             description: 'The previous data before the update.',
      //             type: 'object'
      //           },
      //           update: {
      //             title: 'Updated Data',
      //             description: 'The updated data.',
      //             type: 'object'
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.GetList,
      //   name: 'Get Datastore List',
      //   description: 'Get a list from the data store.',
      //   input: {
      //     type: 'object',
      //     required: ['store'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore item to operate on.',
      //         attributes: {
      //           'ui:component': 'datastore'
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['data', 'success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Result of setting the value'
      //       },
      //       data: {
      //         type: 'array',
      //         title: 'Data',
      //         description: 'The Datastore List',
      //         items: {
      //           type: 'object',
      //           properties: {
      //             required: ['key', 'value', 'createdAt'],
      //             key: {
      //               type: 'string',
      //               title: 'Key'
      //             },
      //             value: {
      //               type: 'string',
      //               title: 'Value'
      //             },
      //             createdAt: {
      //               type: 'string',
      //               title: 'Created At',
      //               description: 'The time of item creation.'
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.FindInList,
      //   name: 'Find in List',
      //   description:
      //     'Determine if the list contains a value or not and return the value.',
      //   input: {
      //     type: 'object',
      //     required: ['store', 'conditions'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore item to operate on.',
      //         attributes: {
      //           'ui:component': 'datastore:list',
      //           'ui:order': 0
      //         }
      //       },
      //       conditions: {
      //         title: false,
      //         type: 'condition',
      //         attributes: {
      //           'ui:component': 'condition'
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Whether the value was found in the list.'
      //       },
      //       data: {
      //         type: 'object',
      //         title: 'Data',
      //         description: 'The result of the list find operation.',
      //         properties: {
      //           key: {
      //             type: 'string',
      //             title: 'Key',
      //             description: 'The key of found item.'
      //           },
      //           value: {
      //             type: 'string',
      //             title: 'Value',
      //             description: 'The value of found item.'
      //           },
      //           createdAt: {
      //             type: 'string',
      //             title: 'Created At',
      //             description: 'The time of item creation.'
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.DedupeList,
      //   name: 'Deduplicate Datastore List',
      //   description:
      //     'Determine if the list contains a value or not and return the value.',
      //   input: {
      //     type: 'object',
      //     required: ['data'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore list to operate on.',
      //         examples: ['previousReports'],
      //         attributes: {
      //           'ui:component': 'datastore:list',
      //           'ui:order': 0
      //         }
      //       },
      //       data: {
      //         type: 'string',
      //         title: 'Data',
      //         examples: ['recentReports'],
      //         description:
      //           'The list of data to search to the datastore against.',
      //         attributes: {
      //           'ui:order': 1,
      //           'ui:component': 'select:field',
      //           'ui:field-types': ['array']
      //         }
      //       },
      //       key: {
      //         type: 'string',
      //         title: 'Key',
      //         description: 'The key to find the value by.',
      //         examples: ['id'],
      //         attributes: {
      //           'ui:order': 2
      //         }
      //       },
      //       value: {
      //         type: 'string',
      //         title: 'Value',
      //         description:
      //           'Optionally save a different value than the key. If the list contains strings, you can omit this.',
      //         examples: ['name'],
      //         attributes: {
      //           'ui:order': 3,
      //           'ui:component': 'string:text'
      //         }
      //       },
      //       update: {
      //         type: 'boolean',
      //         title: 'Update',
      //         default: true,
      //         description:
      //           'Automatically update the list with the new values. If the list contains strings, you can omit this.',
      //         attributes: {
      //           'ui:order': 4
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Whether the value was found in the list.'
      //       },
      //       data: {
      //         type: 'array',
      //         title: 'Data',
      //         description: 'The result of the list find operation.',
      //         items: [
      //           {
      //             type: 'object',
      //             properties: {
      //               key: {
      //                 type: 'string',
      //                 title: 'Key',
      //                 description: 'The key of found item.'
      //               },
      //               value: {
      //                 type: 'string',
      //                 title: 'Value',
      //                 description: 'The value of found item.'
      //               },
      //               createdAt: {
      //                 type: 'string',
      //                 title: 'Created At',
      //                 description: 'The time of item creation.'
      //               }
      //             }
      //           }
      //         ]
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.FilterList,
      //   name: 'Filter Datastore List',
      //   description: 'Filter values using an expression.',
      //   input: {
      //     type: 'object',
      //     required: ['store', 'condition'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore item to operate on.',
      //         attributes: {
      //           'ui:component': 'datastore:list',
      //           'ui:order': 0
      //         }
      //       },
      //       condition: {
      //         title: false,
      //         type: 'object',
      //         attributes: {
      //           'ui:component': 'condition',
      //           'ui:field-types': 'datastore:list',
      //           'ui:condition-type': 'simple',
      //           'ui:condition-multiple': false,
      //           'ui:order': 1
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Whether the value was found in the list.'
      //       },
      //       data: {
      //         type: 'array',
      //         title: 'Data',
      //         description: 'The result of the list filter operation.',
      //         items: [
      //           {
      //             type: 'object',
      //             properties: {
      //               key: {
      //                 type: 'string',
      //                 title: 'Key',
      //                 description: 'The key of found item.'
      //               },
      //               value: {
      //                 type: 'string',
      //                 title: 'Value',
      //                 description: 'The value of found item.'
      //               },
      //               createdAt: {
      //                 type: 'string',
      //                 title: 'Created At',
      //                 description: 'The time of item creation.'
      //               }
      //             }
      //           }
      //         ]
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.AddList,
      //   name: 'Add/Update List',
      //   description:
      //     'Add items to an existing list using a list of objects Data',
      //   input: {
      //     type: 'object',
      //     required: ['store', 'key', 'value', 'data', 'override'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore item to operate on.',
      //         attributes: {
      //           'ui:component': 'datastore:list',
      //           'ui:order': 0
      //         }
      //       },
      //       key: {
      //         type: 'string',
      //         title: 'Key',
      //         description:
      //           "The object property of a Data item used to set a datastore item's key field.",
      //         attributes: {
      //           'ui:order': 1
      //         }
      //       },
      //       value: {
      //         type: 'string',
      //         title: 'Value',
      //         description:
      //           "The object property of a Data item used to set a datastore item's value field.",
      //         attributes: {
      //           'ui:order': 2
      //         }
      //       },
      //       data: {
      //         type: 'string',
      //         title: 'Data',
      //         description:
      //           'The list of data to add to the datastore based on the key / value.',
      //         attributes: {
      //           'ui:order': 3
      //         }
      //       },
      //       override: {
      //         type: 'boolean',
      //         title: 'Override',
      //         description:
      //           'Whether to override the value if there is a value with same key already in the list.',
      //         default: true,
      //         attributes: {
      //           'ui:order': 4
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Whether the value was added or not to the list.'
      //       },
      //       data: {
      //         type: 'array',
      //         title: 'Data',
      //         description: 'The result of the list add operation.',
      //         items: {
      //           type: 'object',
      //           properties: {
      //             key: {
      //               type: 'string',
      //               title: 'Key',
      //               description: 'The key of item.'
      //             },
      //             value: {
      //               type: 'string',
      //               title: 'Value',
      //               description: 'The value of item.'
      //             },
      //             createdAt: {
      //               type: 'string',
      //               title: 'Created At',
      //               description: 'The time of item creation.'
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      // {
      //   id: DatastoreActions.RemoveList,
      //   name: 'Remove from List',
      //   description: 'Remove an item from the list.',
      //   input: {
      //     type: 'object',
      //     required: ['store', 'key'],
      //     properties: {
      //       store: {
      //         type: 'string',
      //         title: 'Datastore',
      //         description: 'The Datastore item to operate on.',
      //         attributes: {
      //           'ui:component': 'datastore:list',
      //           'ui:order': 0
      //         }
      //       },
      //       key: {
      //         type: 'string',
      //         title: 'Key',
      //         attributes: {
      //           'ui:order': 1
      //         }
      //       }
      //     }
      //   },
      //   output: {
      //     type: 'object',
      //     required: ['success'],
      //     properties: {
      //       success: {
      //         title: 'Success',
      //         type: 'boolean',
      //         description: 'Whether the value was found and removed.'
      //       },
      //       data: {
      //         type: 'array',
      //         title: 'Data',
      //         description: 'The result of the list remove operation.',
      //         items: {
      //           type: 'object',
      //           properties: {
      //             key: {
      //               type: 'string',
      //               title: 'Key',
      //               description: 'The key of item.'
      //             },
      //             value: {
      //               type: 'string',
      //               title: 'Value',
      //               description: 'The value of item.'
      //             },
      //             createdAt: {
      //               type: 'string',
      //               title: 'Created At',
      //               description: 'The time of item creation.'
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    ]
  }
};
