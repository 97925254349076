import { CorePackages, LogicActions } from '../enums';

export const logicPackage = {
  id: CorePackages.Logic,
  name: 'Logic',
  vendor: {
    name: 'Flashpoint',
    url: 'https://flashpoint-intel.com',
    logo: 'flashpoint.svg'
  },
  product: {
    name: 'Core Integrations',
    logo: `logic.svg`
  },
  categories: ['Core'],
  actions: {
    items: [
      {
        id: LogicActions.Condition,
        name: 'Condition',
        type: 'choice',
        description: 'Apply logic to determine a exit path.',
        input: {
          type: 'object',
          required: ['conditions'],
          properties: {
            conditions: {
              title: false,
              type: 'condition',
              attributes: {
                'ui:component': 'condition'
              }
            }
          }
        }
      },
      {
        id: LogicActions.Branch,
        name: 'Branch',
        type: 'branch',
        description: 'Apply multiple logic scenarios to determine a exit path.',
        logo: 'branch.svg',
        input: {
          type: 'object',
          required: ['conditions'],
          properties: {
            conditions: {
              title: false,
              type: 'condition',
              attributes: {
                'ui:component': 'branch'
              }
            }
          }
        }
      },
      {
        id: LogicActions.ListHasItems,
        name: 'List Has Items',
        description: 'Apply filtering logic to filter a list of data.',
        type: 'choice',
        input: {
          type: 'object',
          required: ['expression'],
          properties: {
            expression: {
              title: 'List',
              type: 'string',
              description: 'List to check for items',
              attributes: {
                'ui:component': 'select:field',
                'ui:field-types': ['array']
              }
            }
          }
        },
        output: {
          title: 'Output',
          type: 'object',
          description: 'The result of the List Has Items action.',
          properties: {
            data: {
              type: 'object',
              title: 'Data',
              description: 'Data returned from the action.',
              properties: {
                result: {
                  title: 'Result',
                  type: 'boolean',
                  description: 'Whether the list has items'
                }
              }
            }
          }
        }
      },
      {
        id: LogicActions.Loop,
        name: 'Loop',
        description: 'Loop over a list of data.',
        type: 'map',
        input: {
          type: 'object',
          required: ['itemsExpression'],
          properties: {
            itemsExpression: {
              title: 'Variable',
              type: 'string',
              description: 'Variable to loop over',
              attributes: {
                'ui:order': 0,
                'ui:component': 'select:field',
                'ui:field-types': ['array']
              }
            },
            isAsync: {
              title: 'Run asynchronously',
              tooltip:
                'This will cause all loop iterations to run at the same time and out of order',
              type: 'boolean',
              default: false,
              description:
                'BEWARE: Toggle on to make loop run asynchronously. Do not toggle on unless you understand what you are doing. This will cause all loop iterations to run at the same time and out of order, but will finish quicker',
              attributes: {
                'ui:order': 1,
                'ui:group': 'advanced'
              }
            }
            /*
            maxConcurrency: {
              title: 'Max Concurrency',
              type: 'integer',
              description:
                'Optionally specify the max number of concurrent iterations'
            }
            */
          }
        },
        output: {
          title: 'Output',
          type: 'object',
          description: 'The result of the Loop.',
          properties: {
            data: {
              type: 'array',
              title: 'Data',
              description: 'The iterations of the Loop.'
            }
          }
        }
      },
      {
        id: LogicActions.ListFilter,
        name: 'Filter List',
        description: 'Filter values using an expression.',
        input: {
          type: 'object',
          required: ['data', 'condition'],
          properties: {
            data: {
              type: 'string',
              title: 'Data',
              description: 'The data to operate on.',
              attributes: {
                'ui:order': 0,
                'ui:component': 'select:field',
                'ui:field-types': ['array']
              }
            },
            condition: {
              title: false,
              type: 'object',
              attributes: {
                'ui:order': 1,
                'ui:component': 'condition',
                'ui:condition-type': 'simple',
                'ui:condition-multiple': false
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Whether the value was filtered in the list.'
            },
            data: {
              type: 'array',
              title: 'Data',
              description: 'The result of the list filter operation.'
            }
          }
        }
      },
      {
        id: LogicActions.ListDifference,
        name: 'List Difference',
        description:
          'Get the difference between the first and the second list.',
        input: {
          type: 'object',
          required: ['listA', 'listB'],
          properties: {
            listA: {
              type: 'string',
              title: 'List A',
              description: 'The list to compare.',
              examples: ['employees'],
              attributes: {
                'ui:order': 0,
                'ui:component': 'select:field',
                'ui:field-types': ['array']
              }
            },
            listB: {
              type: 'string',
              title: 'List B',
              examples: ['newEmployees'],
              description: `The list to compare.`,
              attributes: {
                'ui:order': 1,
                'ui:component': 'select:field',
                'ui:field-types': ['array']
              }
            },
            key: {
              type: 'string',
              title: 'Key',
              description: `The key to compare by. If the list contains strings, you can omit this.`,
              examples: ['id'],
              attributes: {
                'ui:order': 2,
                'ui:component': 'string:text'
              }
            }
          }
        },
        output: {
          type: 'object',
          required: ['success'],
          properties: {
            success: {
              title: 'Success',
              type: 'boolean',
              description: 'Whether the value was filtered in the list.'
            },
            data: {
              type: 'array',
              title: 'Data',
              description: 'The result of the list filter operation.'
            }
          }
        }
      }
    ]
  }
};
