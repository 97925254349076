import { Helper } from '.';

export const artifactsHelpers: Helper[] = [
  {
    name: '$ip4',
    label: 'IPv4',
    description: 'Find all IPv4 addresses in a string.',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$ip6',
    label: 'IPv6',
    description: 'Find all IPv6 addresses in a string.',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$domain',
    label: 'Domain',
    description: 'Find all domains in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$onion',
    label: 'Onion',
    description: 'Find all onion URLs in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$email',
    label: 'Email',
    description: 'Find all email addresses in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$url',
    label: 'URL',
    description: 'Find all URLs in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$hash',
    label: 'Hash',
    description: 'Find all md5, SHA1, SHA256, and SHA512 hashes in a string.',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string',
    params: {
      type: 'object',
      required: ['hashType'],
      properties: {
        hashType: {
          type: 'array',
          title: 'Hash Types to Find',
          multiple: true,
          items: {
            type: 'string',
            enum: [
              { value: 'md5', label: 'md5' },
              { value: 'sha1', label: 'SHA1' },
              { value: 'sha256', label: 'SHA256' },
              { value: 'sha512', label: 'SHA512' }
            ]
          },
          default: 'md5,sha1,sha256,sha512',
          attributes: {
            'ui:order': 0
          }
        }
      }
    }
  },
  {
    name: '$mac',
    label: 'MAC Addresses',
    description: 'Find all MAC addresses in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$git',
    label: 'Git',
    description: 'Find all git urls in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$bitcoin',
    label: 'Bitcoin',
    description: 'Find all bitcoin addresses in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$ethereum',
    label: 'Ethereum',
    description: 'Find all ethereum addresses in a string',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$cards',
    label: 'Credit Cards',
    description: 'Returns a list of valid credit card numbers',
    examples: [
      '$cards("123456789123456 098765432109876") => ["123456789123456", "098765432109876"]'
    ],
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  }
];
