import { oktaAuth } from 'core/Auth/authClient';

export const getAuthToken = async () => {
  const token = oktaAuth.getAccessToken();

  if (token) {
    return token;
  }

  // For some reason, access token in local storage is undefined.
  // May happen if no network connectivity at the time the token would be renewed.
  // Solution: refetch token from source and update token manager
  // Ref: https://github.com/okta/okta-react/issues/15#issuecomment-758220763
  try {
    const response = await oktaAuth?.token?.getWithoutPrompt();
    if (response) {
      oktaAuth.tokenManager?.setTokens(response.tokens);
      const accessToken = response.tokens?.accessToken;

      if (accessToken) {
        return accessToken.accessToken;
      }
    }
  } catch {
    /* noop */
  }
};
