import { ReactComponent as ObjectIcon } from 'assets/svg/type-object.svg';
import { ReactComponent as ArrayIcon } from 'assets/svg/type-array.svg';
import { ReactComponent as NumberIcon } from 'assets/svg/type-number.svg';
import { ReactComponent as StringIcon } from 'assets/svg/type-string.svg';
import { ReactComponent as BooleanIcon } from 'assets/svg/type-boolean.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/type-email.svg';
import { ReactComponent as URLIcon } from 'assets/svg/type-url.svg';
import { ReactComponent as TimeIcon } from 'assets/svg/type-time.svg';
import { ReactComponent as HostnameIcon } from 'assets/svg/type-computer.svg';
import { ReactComponent as IpIcon } from 'assets/svg/type-ip.svg';
import { ReactComponent as KeyIcon } from 'assets/svg/type-key.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/phone.svg';
import { ReactComponent as BitcoinIcon } from 'assets/svg/bitcoin.svg';
import { ReactComponent as EthereumIcon } from 'assets/svg/ethereum.svg';
import { ReactComponent as GpsIcon } from 'assets/svg/gps.svg';
import { ReactComponent as GitIcon } from 'assets/svg/git.svg';
import { ReactComponent as HashIcon } from 'assets/svg/type-hash.svg';
import { ReactComponent as ImageIcon } from 'assets/svg/type-image.svg';
import { ReactComponent as UserIcon } from 'assets/svg/type-name.svg';
import { ReactComponent as GroupIcon } from 'assets/svg/group.svg';
import { ReactComponent as CompanyIcon } from 'assets/svg/company.svg';
import { ReactComponent as CreditCardIcon } from 'assets/svg/credit-card.svg';
import { ReactComponent as MagnetIcon } from 'assets/svg/magnet.svg';
import { ReactComponent as MustacheIcon } from 'assets/svg/mustache.svg';

export const typeIcons = {
  object: ObjectIcon,
  array: ArrayIcon,
  number: NumberIcon,
  float: NumberIcon,
  user: UserIcon,
  int: NumberIcon,
  integer: NumberIcon,
  string: StringIcon,
  boolean: BooleanIcon,
  email: EmailIcon,
  url: URLIcon,
  date: TimeIcon,
  company: CompanyIcon,
  group: GroupIcon,
  time: TimeIcon,
  datetime: TimeIcon,
  'date-time': TimeIcon,
  domain: HostnameIcon,
  mac: HostnameIcon,
  ip4: IpIcon,
  ip6: IpIcon,
  phone: PhoneIcon,
  id: KeyIcon,
  bitcoin: BitcoinIcon,
  onion: URLIcon,
  ethereum: EthereumIcon,
  latlong: GpsIcon,
  git: GitIcon,
  hash: HashIcon,
  image: ImageIcon,
  base64Image: ImageIcon,
  card: CreditCardIcon,
  magnet: MagnetIcon,
  token: MustacheIcon
};

export function getTypeIconComponent(type: string, subType?: string) {
  if (subType && typeIcons[subType]) {
    return typeIcons[subType];
  }

  return typeIcons[type];
}
