import React, { Children, ReactNode, FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'shared/elements/Button';
import { Collapse } from 'shared/layout/Collapse';
import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right.svg';
import { ReactComponent as ExpandIcon } from 'assets/svg/expand.svg';
import css from './TreeNode.module.css';

export type TreeNodeProps = {
  label: ReactNode;
  className?: any;
  expanded?: boolean;
  disabled?: boolean;
  contextBar?: boolean;
  children?: any;
  onExpand: () => void;
  onCollapse: () => void;
  onHover: (hovering: boolean) => void;
};

export const TreeNode: FC<Partial<TreeNodeProps>> = ({
  children,
  className,
  label,
  disabled,
  expanded: expandedProp,
  contextBar = false,
  onExpand = () => undefined,
  onCollapse = () => undefined,
  onHover = () => undefined
}) => {
  const [expanded, setExpanded] = useState<boolean>(expandedProp);
  // If a context bar should be shown, it is a child, but shouldn't be taken into account for if node has children
  let hasChildren = false;

  if (children && Array.isArray(children)) {
    const childArray = Children.toArray(children).filter(c => c);
    const count = childArray.length;
    hasChildren = contextBar ? count > 1 : count > 0;
  } else {
    hasChildren = children && Children.count(children) > 0;
  }

  const Icon = expanded ? ExpandIcon : ArrowRightIcon;

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  return (
    <li
      className={classNames(className, css.node, {
        [css.leaf]: !hasChildren,
        [css.disabled]: disabled,
        [css.contextBar]: contextBar
      })}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <div className={css.nodeBlock}>
        {hasChildren && (
          <Button
            size="small"
            disabled={disabled}
            variant="text"
            title={expanded ? 'Collapse children' : 'Expand children'}
            className={css.button}
            onClick={() => {
              const newState = !expanded;
              setExpanded(newState);
              if (newState) {
                onExpand();
              } else {
                onCollapse();
              }
            }}
          >
            <Icon className={css.icon} />
          </Button>
        )}
        <span className={css.label}>{label}</span>
      </div>
      {(hasChildren || contextBar) && (
        <Collapse expanded={expanded}>
          {() => (
            <ul
              className={classNames(css.container, {
                [css.expandedContainer]: expanded
              })}
            >
              {children}
            </ul>
          )}
        </Collapse>
      )}
    </li>
  );
};
