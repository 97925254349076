import { Helper } from '.';

export const arrayHelpers: Helper[] = [
  {
    name: '$count',
    label: 'Count',
    description: 'Returns the number of items in the array.',
    examples: [`$count([1,2,3,1]) => 4`, `$count("hello") => 1`],
    valueType: 'array',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/array-functions#count'
  },
  {
    name: '$distinct',
    label: 'Distinct',
    description:
      'Returns an array containing all the values from the array, but with any duplicates removed.',
    examples: [`$distinct([1,2,3,3,4,3,5]) => [1, 2, 3, 4, 5]`],
    valueType: 'array',
    returnType: 'array',
    reference: 'https://docs.jsonata.org/array-functions#distinct'
  }
];
