import { axios } from 'core/Axios';
import {
  DataDelEventParams,
  DataDelEventResponse,
  DataListEventsResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_EVENT;

export const req = async (
  params: DataDelEventParams
): Promise<DataDelEventResponse> => {
  return axios
    .delete<DataDelEventResponse>(Route.DEL_EVENT, { params })
    .then(data => data.data);
};

export const useDelEvent = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataDelEventResponse, DataDelEventParams> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_EVENTS);
      const previousData = client.getQueriesData(Route.LIST_EVENTS);
      client.setQueriesData(
        Route.LIST_EVENTS,
        (oldData: DataListEventsResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_EVENTS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
