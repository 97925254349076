import shortid from '@getcrft/shortid';
import { ClientFlow } from 'core/types/Flow';
import { User } from 'core/types/API';

export const flowDuplicator = (
  flow: ClientFlow,
  user?: User,
  suffix = ' [COPY]'
): ClientFlow => {
  const copy: ClientFlow = { ...flow };

  copy.id = shortid();
  copy.name = `${copy.name}${suffix}`;
  copy.disabled = true;
  copy.lastRun = undefined;
  copy.group = undefined;
  copy.version = 1;
  copy.updated = undefined;
  copy.dataScrubbing = true;
  copy.trackRevisions = true;
  copy.template = {
    id: flow.id,
    name: flow.name,
    date: flow.updated?.date || flow.created?.date
  };

  if (user?.id) {
    copy.owner = {
      id: user.id
    };

    copy.created = {
      id: user.id,
      picture: user.picture,
      name: user.name,
      date: new Date().toISOString()
    };
  }

  // Get us new unique IDs
  const uuids = [];
  copy.nodes?.forEach(n => uuids.push(n.id));

  // Replace all instances of the old UUIDs with new ones
  let stringCopy = JSON.stringify(copy);
  uuids.forEach(uuid => (stringCopy = stringCopy.split(uuid).join(shortid())));
  return JSON.parse(stringCopy);
};
