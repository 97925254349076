import { shortid, extractIds } from '@getcrft/shortid';
import { Flow, Upload } from 'core/types/API';
import { detectCircular, EdgeData, NodeData } from 'reaflow';
import { validateForm } from 'shared/form/JsonSchemaForm/utils/validator';
import isObject from 'lodash/isObject';
import {
  EmailActions,
  FlowActions,
  LogicActions,
  WebhookActions
} from './enums';

/**
 * Given a Flow, get the top/bottom nodes/edges for it.
 */
export const getPrunedGraph = (
  nodes: NodeData[],
  edges: EdgeData[],
  onlyTop = false,
  bottomNodeLimit?: number
) => {
  const filteredNodes = nodes.filter(n => n.id !== 'start');
  const filteredEdges = edges.filter(e => e.from !== 'start');

  const topNodes = filteredNodes.filter(
    n => filteredEdges.filter(e => e.to === n.id).length === 0 && !n.parent
  );

  const topNodeIds = topNodes.map(n => n.id);

  let bottomNodes = [];
  if (!onlyTop && filteredNodes.length > 1) {
    bottomNodes = filteredNodes.filter(
      n =>
        filteredEdges.filter(e => e.from === n.id).length === 0 &&
        !n.parent &&
        !topNodeIds.includes(n.id)
    );

    // handle the map last node scenario
    if (bottomNodes.length && bottomNodes.find(n => n.type === 'map')) {
      bottomNodes = filteredNodes.filter(
        n => filteredEdges.filter(e => e.from === n.id).length === 0
      );
    }

    if (bottomNodes.length && !!bottomNodeLimit) {
      bottomNodes = bottomNodes.slice(0, bottomNodeLimit);
    }
  }

  return {
    nodes: [...topNodes, ...bottomNodes],
    edges: bottomNodes.map(n => ({
      id: shortid(),
      from: topNodes[0].id,
      to: n.id
    }))
  };
};

export type FlowAlertMessage = {
  id?: string;
  prop: string;
  message: string;
  input?: boolean;
};

type CheckNodeWarningsProps = {
  node: NodeData;
  nodes: NodeData[];
  edges: EdgeData[];
  disabled: boolean;
};

/**
 * Given a node, check for warning on it.
 */
export const checkNodeWarnings = ({
  node,
  nodes,
  edges,
  disabled
}: CheckNodeWarningsProps) => {
  const result: FlowAlertMessage[] = [];
  const nodeData = node.data;

  // Warn if Trigger is disabled
  if (nodeData.type === 'trigger') {
    if (!nodeData.input?.enabled) {
      result.push({
        id: node.id,
        prop: nodeData.label,
        message: `is disabled which means this Workflow will not be triggered`
      });
    }

    if (disabled) {
      result.push({
        prop: 'Workflow',
        message: 'is disabled which means this Workflow will not be triggered'
      });
    }
  }

  // Build our map
  const nodeMap = nodes.reduce((m, o) => {
    m[o.id] = o;
    return m;
  }, {});

  // Check for possibly null outputs
  nodeData.dependsOn?.forEach(({ source, sourceValue }) => {
    if (nodeMap?.[source]?.action?.output) {
      const {
        action: {
          output: { required }
        }
      } = nodeMap[source];

      const contains = required?.includes(sourceValue);
      const doesntHaveWarning = result.findIndex(w => w.id === node.id) === -1;

      if (!contains && doesntHaveWarning) {
        result.push({
          id: node.id,
          prop: nodeData.label,
          message: `node depends on possibly null output`
        });
      }
    }
  });

  // Warn if condition node has any empty conditions
  if (
    [LogicActions.Condition, LogicActions.Branch].includes(nodeData.actionId)
  ) {
    const choiceEdges = edges.filter(
      e => e.from === node.id && e.fromPort.includes('positive')
    );
    if (
      nodeData.input?.conditions?.length > 1 &&
      nodeData.input?.conditions?.length !== choiceEdges.length
    ) {
      result.push({
        id: node.id,
        prop: nodeData.label,
        message: `node has conditions that don't exit`
      });
    }
  }

  return result;
};

/**
 * Given a node, check for errors on it.
 */
export const checkNodeErrors = (
  node: NodeData,
  nodes: NodeData[],
  group?,
  connections = [],
  datastores: any[] = [],
  flows: Flow[] = []
) => {
  const nodeData = node.data;
  const action = nodeData?.action;
  const result: FlowAlertMessage[] = [];

  if (!action) {
    return result;
  }

  if (nodeData.dependsOn) {
    const nodeIds = nodes.map(n => n.id);
    const storeIds = datastores.map(d => d.id);
    const flowIds = flows.map(f => f.id);
    let missingDependentNode = false;
    for (const { source } of nodeData.dependsOn) {
      missingDependentNode =
        !nodeIds.includes(source) &&
        !storeIds.includes(source) &&
        !flowIds.includes(source);
    }

    if (missingDependentNode) {
      result.push({
        id: node.id,
        prop: nodeData.label,
        message: `has a missing Action for a modifier, a missing Datastore Value, or a missing Workflow`
      });
    }
  }

  if (action.connections?.items?.length > 0 && !nodeData.connectionId) {
    result.push({
      id: node.id,
      prop: nodeData.label,
      message: `requires a Connection`,
      input: true
    });
  }

  if (nodeData.connectionId) {
    const nodeConnection = connections.find(
      c => c.id === nodeData.connectionId
    );

    if (
      (!nodeConnection || nodeConnection.status === 'Error') &&
      !action.optionalConnection
    ) {
      result.push({
        id: node.id,
        prop: nodeData.label,
        message: `has a Connection error`,
        input: true
      });
    }
  }

  // Warn if flow is shared and node uses a connection or datastore that is not shared with that group
  if (group) {
    // Check datastore references
    const storeIds = datastores.map(s => s.id);
    const storeReferencesUsedInNodes = getDatastoresFromNodeReferences(
      storeIds,
      [node]
    );
    const storeIdsUsedInNodes = Object.keys(storeReferencesUsedInNodes);

    if (storeIdsUsedInNodes.length > 0) {
      const nonSharedStores = datastores.filter(
        datastore =>
          !datastore.flowId &&
          storeIdsUsedInNodes.includes(datastore.id) &&
          datastore.group !== group &&
          // @ts-ignore-next-line
          datastore.group?.name !== group
      );

      for (const store of nonSharedStores) {
        result.push({
          id: node.id,
          prop: nodeData.label,
          message: `references Datastore "${store.name}" which has not been shared with "${group}". You are unable to save this workflow until it is shared or removed.`
        });
      }
    }

    // Check connection references
    if (node.data.connectionId) {
      const unsharedConnection = connections.find(
        connection =>
          node.data.connectionId === connection.id &&
          connection.group !== group &&
          // @ts-ignore-next-line
          connection.group?.name !== group
      );

      if (unsharedConnection) {
        result.push({
          id: node.id,
          prop: nodeData.label,
          message: `uses the Connection "${unsharedConnection.name}" which has not been shared with "${group}". You are unable to save this workflow until it is shared or removed.`
        });
      }
    }
  }

  const { hasError, errors } = validateForm(action.input, nodeData.input);
  if (hasError) {
    Object.keys(errors).forEach(k =>
      result.push({
        id: node.id,
        prop: nodeData.label,
        message: errors[k],
        input: true
      })
    );
  }

  return result;
};

/**
 * Given a Flow, check for any warnings before submitting.
 */
export const checkFlowWarnings = flow => {
  const result: FlowAlertMessage[] = [];

  for (const node of flow.nodes) {
    result.push(
      ...checkNodeWarnings({
        node,
        nodes: flow.nodes,
        edges: flow.edges,
        disabled: flow.disabled
      })
    );
  }

  return result;
};

/**
 * Given a Flow, check for any errors before submitting.
 */
export const checkFlowErrors = (
  flow,
  connections = [],
  datastores: any[] = [],
  flows: Flow[] = [],
  uploads: Upload[] = []
) => {
  const result: FlowAlertMessage[] = [];

  // Validate has a name
  if (!flow.name) {
    result.push({
      prop: 'Workflow',
      message: 'requires a name'
    });
  }

  // Cannot have multiple triggers
  const triggers = flow.nodes.filter(n => n.data.action?.type === 'trigger');
  if (triggers.length > 1) {
    result.push({
      prop: 'Workflow',
      message: 'cannot have multiple triggers'
    });
  }

  for (const node of flow.nodes) {
    let nodeValidationResult;
    const action = node.data.action;
    const noPkg = !node.data.pkg || Object.keys(node.data.pkg).length === 0;
    // TODO: Hack for Actions that dont get matched for whatever reason...
    if (!action || noPkg) {
      result.push({
        id: node.id,
        prop: node.data.label,
        message: `has been removed or upgraded into a different action and can no longer be used. Please delete this action`
      });
      continue;
    }

    switch (action.type) {
      case 'approval':
      case 'choice':
      case 'branch':
        nodeValidationResult = validateChoiceNode(node, flow);
        break;
      case 'map':
        nodeValidationResult = validateMapNode(node, flow);
        break;
    }

    if (nodeValidationResult) {
      result.push(nodeValidationResult);
    }

    const referenceValidation = validateNodeReferences({
      node,
      flows,
      uploads,
      nodes: flow.nodes,
      datastores
    });

    if (referenceValidation.length) {
      result.push(...referenceValidation);
    }

    result.push(
      ...checkNodeErrors(
        node,
        flow.nodes,
        flow.group?.name,
        connections,
        datastores,
        flows
      )
    );
  }

  return result;
};

/**
 * Validate choices has at least one exit.
 */
const validateChoiceNode = (node: NodeData, flow) => {
  const choiceEdge = flow.edges.filter(e => e.from === node.id);

  return choiceEdge.length
    ? null
    : {
        id: node.id,
        prop: node.data.label,
        message: `condition must have at least one child`
      };
};

/**
 * Validate map node has children.
 */
const validateMapNode = (node: NodeData, flow) => {
  const mapChildNode = flow.nodes.filter(e => e.parent === node.id);

  return mapChildNode.length
    ? null
    : {
        id: node.id,
        prop: node.data.label,
        message: `loop must have at least one child`
      };
};

type ValidateNodeReferencesInput = {
  node: NodeData;
  nodes?: NodeData[];
  datastores?: any[];
  flows?: Flow[];
  uploads?: Upload[];
};

export function validateNodeReferences({
  node,
  nodes = [],
  datastores = [],
  flows = [],
  uploads = []
}: ValidateNodeReferencesInput) {
  const errors = [];

  // Attempt to identify if node references a non existing entity - datastore, flow, or node
  const storeIds = datastores.map(s => s.id);
  const nodeIds = nodes.map(n => n.id);
  const flowIds = flows.map(n => n.id);
  const uploadIds = uploads.map(u => u.id);
  const triggerWorkflowNodeIds = nodes
    .filter(n => n.data?.actionId === FlowActions.Trigger)
    .map(n => n.id);

  const actionInputProperties = node.data?.action?.input?.properties;
  const input = node.data?.input;
  if (!actionInputProperties || !input) {
    return errors;
  }

  // Certain action produce inputs with ids that are used for triggering the flow, not referencing
  const ignoredTriggers = [WebhookActions.Trigger, EmailActions.Trigger];
  if (ignoredTriggers.includes(node.data?.actionId)) {
    return errors;
  }

  for (const prop in actionInputProperties) {
    // TODO: traverse down input val to determine if any part of input is invalid
    const inputVal = input[prop];
    const internalRefs = getInternalReferenceIds(inputVal);

    // If file input and it's not referencing a node do not check for node references
    if (prop === 'file' && !input?.file.includes('{{')) {
      return errors;
    }

    // Check for references in the input's value
    const references = getNodeInputReferences(inputVal);
    let missingRefs = [];

    for (const ref of references) {
      const hasKnownRef =
        nodeIds.includes(ref) ||
        storeIds.includes(ref) ||
        flowIds.includes(ref) ||
        uploadIds.includes(ref) ||
        internalRefs.includes(ref);

      if (!hasKnownRef) {
        missingRefs.push(ref);
      } else {
        // Check to see if the reference is a trigger workflow action and check to see if the reference
        // through this reference exists on the triggered workflow
        if (triggerWorkflowNodeIds.includes(ref)) {
          const paths = getInputPaths(inputVal);
          // Determine if any of the paths from the input contain any node references
          const triggerNodeReferences = paths
            .flat()
            .map(r => extractIds(r))
            .flat()
            .filter(r => !references.includes(r));
          const triggeringNode = nodes.find(n => n.id === ref);
          const triggeredFlowId = triggeringNode.data?.input?.flowId;
          const triggeredFlow = flows.find(f => f.id === triggeredFlowId);
          const triggeredFlowNodeIds = triggeredFlow?.nodes?.map(n => n.key);

          const triggeredMissingRefs = [];
          for (const triggerNodeRef of triggerNodeReferences) {
            if (!triggeredFlowNodeIds?.includes(triggerNodeRef)) {
              triggeredMissingRefs.push(triggerNodeRef);
            }
          }

          if (triggeredMissingRefs.length) {
            const verboseError = triggeredMissingRefs.join(', ');
            errors.push({
              id: node.id,
              prop: node.data.label,
              message: `is making references to actions which are not available within the
              triggered workflow "${triggeredFlow.name}". The following actions were likely
              deleted and will need to be updated: ${verboseError}`
            });
          }
        }
      }
    }

    if (missingRefs.length) {
      // if we've detected at least one invalid ref
      const verboseError = missingRefs.join(', ');
      errors.push({
        id: node.id,
        prop: node.data.label,
        message: `is missing a reference to ${verboseError} which is not available`
      });
    }
  }

  return errors;
}

/**
 * Get the reference paths from an input string to check if the path contains a reference to another action
 */
function getInputPaths(input: any) {
  const paths = [];

  if (typeof input === 'string') {
    const splitRef = input.replace('{{', '').replace('}}', '').split('.');
    // Remove the first portion of the ref string since it is the triggering node id
    const [, ...rest] = splitRef;
    paths.push(rest);
  } else if (Array.isArray(input)) {
    for (const item of input) {
      paths.push(...getInputPaths(item));
    }
  } else if (isObject(input)) {
    for (const key in input) {
      if (key !== 'next') {
        // Don't get choice/branch next inputs since those will always be a reference
        paths.push(...getInputPaths(input[key]));
      }
    }
  }

  return paths;
}

/**
 * Supports extraction of of a shortId (a string hash)
 * from either a string, array, or object input
 */
export function getNodeInputReferences(
  input: string | any[] | { key: string }
) {
  const references = [];

  // TODO: Update this when email domain changes
  if (
    typeof input === 'string' &&
    !input.match(/@mail(-.*)?\.crft\.app/im) &&
    !input.match(/@mail(-.*)?\.flashpoint-intel\.com/im)
  ) {
    references.push(...extractIds(input));
  } else if (Array.isArray(input)) {
    for (const item of input) {
      references.push(...getNodeInputReferences(item));
    }
  } else if (isObject(input)) {
    for (const key in input) {
      if (key !== 'next') {
        // Don't get choice/branch next inputs since those will always be a reference
        references.push(...getNodeInputReferences(input[key]));
      }
    }
  }

  return references;
}

interface shortIdContainer {
  id: string;
}

/**
 * Pulls out any internal node id(s) that can not be known by the outer scope.
 */
function getInternalReferenceIds(input: shortIdContainer | shortIdContainer[]) {
  let internalIds = [];
  if (Array.isArray(input)) {
    internalIds = input.map(i => i.id);
  } else if (isObject(input)) {
    internalIds = [input.id];
  }
  return internalIds;
}

/**
 * Given a target and source, determine if the edge can be added.
 */
export const canAddEdge = (
  nodes: NodeData[],
  edges: EdgeData[],
  source: NodeData,
  target: NodeData,
  context: 'node' | 'edge' = 'node'
) => {
  // Ensure we have everything and its not itself
  if (!source || !target || source.id === target.id) {
    return false;
  }

  // Can't have multiple start nodes
  const hasStartNode = edges.some(e => e.from === 'start');
  if (source.id === 'start' && hasStartNode) {
    if (context === 'node') {
      // check to see if target is a trigger and if there is no other trigger
      const hasTrigger = nodes.some(n => n.data?.type === 'trigger');
      if (
        target.data?.type !== 'trigger' ||
        (target.data?.type === 'trigger' && hasTrigger)
      ) {
        return false;
      }
    }
  }

  // Can't triple nest loops
  if (
    source.data?.type === 'map' &&
    source.parent &&
    target.data?.type === 'map'
  ) {
    return false;
  }

  // Can't add approvals into nested loops
  if (source.parent && target.data?.type === 'approval') {
    const sourceParent = nodes.find(n => n.id === source.parent);
    if (
      !sourceParent ||
      sourceParent.parent ||
      (sourceParent.data?.type === 'map' && source.data?.type === 'map')
    ) {
      return false;
    }
  }

  // Can't add loops to a node w/ a parent which has a parent
  if (target.data?.type === 'map' && source.parent) {
    const sourceParent = nodes.find(n => n.id === source.parent);
    if (!sourceParent || sourceParent.parent) {
      return false;
    }
  }

  // Can't add triggers inside maps
  if (target.data?.action?.type === 'trigger' && source.data?.type === 'map') {
    return false;
  }

  // Can't link things that are not in the same container
  if (source.parent !== target.parent && !target.data?.isNew) {
    return false;
  }

  // Ensure there is not already an edge to this
  if (edges.find(e => e.from === source.id && e.to === target.id)) {
    return false;
  }

  // You can't link to triggers
  if (target.data?.action?.type === 'trigger' && source.id !== 'start') {
    return false;
  }

  // You can't have multiple triggers
  if (target.data?.action?.type === 'trigger') {
    const flowTriggers = nodes.filter(n => n.data?.action?.type === 'trigger');
    if (flowTriggers.length > 1) {
      return false;
    }
  }

  // You can't do circulars; for now...
  if (detectCircular(nodes, edges, source, target)) {
    return false;
  }

  return true;
};

/**
 * Given a target and source, determine if the node can be repositioned.
 */
export const canRepositionNode = (
  nodes: NodeData[],
  edges: EdgeData[],
  source: NodeData,
  target: NodeData,
  context: 'node' | 'edge' = 'node'
) => {
  // Ensure we have everything and its not itself
  if (!source || !target || source.id === target.id) {
    return false;
  }

  // Can't have multiple start nodes
  const hasStartNode = edges.some(e => e.from === 'start');
  if (context === 'node' && source.id === 'start' && hasStartNode) {
    return false;
  }

  // Can't triple nest loops
  if (
    source.data?.type === 'map' &&
    source.parent &&
    target.data?.type === 'map'
  ) {
    return false;
  }

  // Can't add approvals into nested loops
  if (source.parent && target.data?.type === 'approval') {
    const sourceParent = nodes.find(n => n.id === source.parent);
    if (
      !sourceParent ||
      sourceParent.parent ||
      (sourceParent.data?.type === 'map' && source.data?.type === 'map')
    ) {
      return false;
    }
  }

  // Can't add loops to a node w/ a parent which has a parent
  if (target.data?.type === 'map' && source.parent) {
    const sourceParent = nodes.find(n => n.id === source.parent);
    if (!sourceParent || sourceParent.parent) {
      return false;
    }
  }

  // Can't add triggers inside maps
  if (target.data?.action?.type === 'trigger' && source.data?.type === 'map') {
    return false;
  }

  // Ensure there is not already an edge to this
  // If placing on a map though, the node can be placed inside the map
  if (
    source.data?.type !== 'map' &&
    source.id !== 'start' &&
    edges.find(e => e.from === source.id && e.to === target.id)
  ) {
    return false;
  }

  // You can't link to triggers
  if (target.data?.action?.type === 'trigger' && source.id !== 'start') {
    return false;
  }

  return true;
};

/**
 * Given a node and the full list of nodes and edges, return a list of its dependents to determine
 * whether to be viewed as disabled
 */
export function handleDisabledDependents(
  node: NodeData,
  nodes: NodeData[],
  edges: EdgeData[]
) {
  const disabledKey = node.data?.disabled
    ? { id: node.id, text: node.text || node.data?.label }
    : node.data?.disabledAncestor;
  const targetEdges = edges.filter(e => e.from === node.id);
  let updatedNodes: NodeData[] = [];

  updatedNodes = updatedNodes.concat(
    updateLoopDisabledDependents(node, nodes, edges)
  );

  for (const targetEdge of targetEdges) {
    const targetNode = nodes.find(n => n.id === targetEdge.to);
    if (
      targetNode &&
      targetNode.data?.disabledAncestor?.id !== disabledKey?.id
    ) {
      const updatedNode = {
        ...targetNode,
        data: {
          ...targetNode.data,
          disabledAncestor: disabledKey
        }
      };
      updatedNodes.push(updatedNode);
      updatedNodes = updatedNodes.concat(
        handleDisabledDependents(updatedNode, nodes, edges)
      );
    }
  }

  return updatedNodes;
}

function updateLoopDisabledDependents(
  node: NodeData,
  nodes: NodeData[],
  edges: EdgeData[]
) {
  let updatedNodes = [];
  const disabledKey = node.data?.disabled
    ? { id: node.id, text: node.text || node.data?.label }
    : node.data?.disabledAncestor;

  if (node.data?.action?.type === 'map') {
    const loopId = node.id;
    const loopStartNodes = nodes.filter(
      n => n.parent === loopId && edges.filter(e => e.to === n.id).length === 0
    );

    for (const startNode of loopStartNodes) {
      // Only update nodes for start nodes of the loop. Other nodes will be handled reecursively
      if (startNode.data?.disabledAncestor?.id !== disabledKey?.id) {
        const updatedNode = {
          ...startNode,
          data: {
            ...startNode.data,
            disabledAncestor: disabledKey
          }
        };

        updatedNodes.push(updatedNode);
        updatedNodes = updatedNodes.concat(
          handleDisabledDependents(updatedNode, nodes, edges)
        );
      }
    }
  }

  return updatedNodes;
}

export function getDatastoresFromNodeReferences(
  storeIds: string[],
  nodes: NodeData[]
) {
  if (!storeIds || storeIds.length === 0 || !nodes) {
    return [];
  }

  // Object of storeId: [nodeId] references
  const nodeReferences = {};
  // Get node input references and compare them to the list of datastores. If referenced, add to list
  for (const node of nodes) {
    const actionInputProperties = node.data?.action?.input?.properties;
    const input = node.data?.input;

    if (!actionInputProperties || !input) {
      continue;
    }

    for (const prop in actionInputProperties) {
      // TODO: traverse down input val to determine if any part of input references datastore
      const inputVal = input[prop];

      // Check for references in the input's value
      const references = getNodeInputReferences(inputVal);

      for (const ref of references) {
        if (storeIds.includes(ref)) {
          nodeReferences[ref] = nodeReferences[ref]
            ? [...nodeReferences[ref], node.id]
            : [node.id];
        }
      }
    }
  }

  return nodeReferences;
}
