import { axios } from 'core/Axios';
import { DataGetUserParams, DataGetUserResponse } from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_USER;
export const buildQueryKey = (params?: DataGetUserParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetUserParams
): Promise<DataGetUserResponse> => {
  return axios
    .get<DataGetUserResponse>(Route.GET_USER, { params })
    .then(data => data.data);
};

export const useGetUser = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetUserResponse, DataGetUserParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    {
      keepPreviousData: true,
      ...options
    }
  );
};
