import React, { FC, useState, Fragment } from 'react';
import ReactMoment, { MomentProps } from 'react-moment';
import classNames from 'classnames';
import css from './Moment.module.css';

// Perf increase
ReactMoment.startPooledTimer();

type CustomMomentProps = MomentProps & {
  allowToggle?: boolean;
  cacheKey?: string;
};

export const Moment: FC<CustomMomentProps> = ({
  allowToggle = true,
  cacheKey,
  format = 'MM/DD/YY hh:mm:ss A',
  fromNow,
  date,
  ...rest
}) => {
  let should = fromNow;
  if (cacheKey) {
    const cache = window.localStorage.getItem(`DATES_${cacheKey}`);
    if (cache) {
      should = cache === 'true';
    }
  }

  const [isRelative, setIsRelative] = useState<boolean>(should);

  if (!date) {
    return <Fragment>NA</Fragment>;
  }

  const dateObj = new Date(date.toString());

  return (
    <span
      title={
        allowToggle ? 'Click to toggle relative time' : dateObj.toLocaleString()
      }
      role="button"
      className={classNames({ [css.btn]: allowToggle })}
      onClick={event => {
        if (allowToggle) {
          event.preventDefault();
          event.stopPropagation();

          const next = !isRelative;
          setIsRelative(next);

          if (cacheKey) {
            // TODO: This won't work for 'global' shared keys -
            // will need to add observable or something later
            window.localStorage.setItem(`DATES_${cacheKey}`, `${next}`);
          }
        }
      }}
    >
      <ReactMoment
        {...rest}
        date={date}
        format={!isRelative ? format : undefined}
        fromNow={isRelative}
      />
    </span>
  );
};
