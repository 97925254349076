export const useScrollTo = ({
  isSearch,
  groupRef,
  tokenRef,
  searchTokenRef
}) => {
  const ref = !isSearch ? groupRef || tokenRef : searchTokenRef;

  const scrollToItem = () => {
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  return { scrollToItem };
};
