import React, { FC, Fragment } from 'react';
import { TokenOption } from '../types';
import { motion } from 'framer-motion';
import { TokenMenuItem } from './TokenMenuItem';
import variableIcon from 'assets/svg/variable.svg';
import collapseIcon from 'assets/svg/arrow-right.svg';
import expandIcon from 'assets/svg/expand.svg';
import { Collapse } from 'shared/layout/Collapse';
import { GroupOption } from '../utils/useTokenGroups';
import css from './TokenMenu.module.css';

export type TokenMenuProps = {
  tokens: TokenOption[];
  groups?: GroupOption[];
  hasGroups?: boolean;
  expandedGroups?: { [key: string]: boolean };
  offset?: number;
  keyword?: string;
  style?: React.CSSProperties;
  allowModifiers?: boolean;
  allowExpressions?: boolean;
  allowGrouping?: boolean;
  onExpandGroup?: (expandedGroups: { [key: string]: boolean }) => void;
  onSelectToken?: (token: TokenOption) => void;
  onInsertExpression?: () => void;
};

export const TokenMenu: FC<TokenMenuProps> = ({
  tokens,
  offset,
  keyword,
  allowGrouping = true,
  allowModifiers = true,
  allowExpressions = true,
  groups,
  hasGroups,
  expandedGroups,
  onExpandGroup = () => undefined,
  onSelectToken = () => undefined,
  onInsertExpression = () => undefined,
  ...rest
}) => {
  const showExpression = allowModifiers && !keyword;
  const offsetMod = showExpression && allowExpressions ? 1 : 0;

  return (
    <motion.div
      {...rest}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={css.menu}
    >
      <ul className={css.list}>
        {showExpression && allowExpressions && (
          <TokenMenuItem
            token={{
              text: 'Insert Dynamic Value',
              value: '',
              type: 'expression',
              icon: variableIcon
            }}
            active={offset === 0}
            onSelectToken={onInsertExpression}
          />
        )}
        {allowGrouping && !keyword && hasGroups
          ? groups.map((g, i) => (
              <Fragment key={i}>
                <TokenMenuItem
                  token={{
                    text: g.name,
                    value: '',
                    icon: expandedGroups[i] ? expandIcon : collapseIcon,
                    ref: g.ref
                  }}
                  className={css.expanderItem}
                  active={g.offset + offsetMod === offset}
                  onSelectToken={() =>
                    onExpandGroup({
                      ...expandedGroups,
                      [i]: !expandedGroups[i]
                    })
                  }
                />
                <Collapse expanded={expandedGroups[i]}>
                  {() =>
                    groups[i].items.map(token => (
                      <TokenMenuItem
                        key={`token-${token.value}-${token.offset}`}
                        token={token}
                        active={token.offset + offsetMod === offset}
                        keyword={keyword}
                        onSelectToken={onSelectToken}
                      />
                    ))
                  }
                </Collapse>
              </Fragment>
            ))
          : tokens.map((token, index) => (
              <TokenMenuItem
                key={`token-${token.value}-${index}`}
                token={token}
                active={index + offsetMod === offset}
                keyword={keyword}
                onSelectToken={onSelectToken}
              />
            ))}
        {tokens.length === 0 && keyword && (
          <li className={css.emptyItem}>No matching tokens...</li>
        )}
      </ul>
    </motion.div>
  );
};
