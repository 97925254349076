// @ts-nocheck
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

const Cookie = Cookies.withConverter<object>({
  write: v => v as string,
  read: v => v
});

const Domain =
  window.location.hostname === 'localhost'
    ? 'localhost'
    : `.${window.location.hostname.split('.').splice(-2).join('.')}`;

const Token = {
  flg: [],
  cke: (k = 'X-FP-Authorization') => Cookie.get(k) || '',
  del: (k = 'X-FP-Authorization', passDomain = true) =>
    Cookie.remove(k, passDomain ? { domain: Domain } : null),
  set: (k = 'X-FP-Authorization', v, opts) =>
    Cookie.set(k, v, { domain: Domain, ...opts }),
  jwt: (tok = '') => {
    const clm = (tok.split('.')[1] || '').replace('-', '+').replace('_', '/');
    const dat = clm
      ? (jwt_decode(clm, { header: true }) as object)
      : { prm: [], ffs: [] };

    return {
      ...dat
    };
  },
  get: (k = '', t = '') => {
    const tok = t || Token.cke();
    const jwt = Token.jwt(tok);
    return !k ? dat : jwt[String(k)] || '';
  }
};

export default Token;
