import React, { FC, useCallback, useState } from 'react';
import { useNotification } from 'shared/layers/Notification';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { UploadsListContainer } from '../UploadsList';
import css from './UploadsListInput.module.css';
import { UploadInput } from '../UploadInput';
import { useAuth } from 'core/Auth';
import { useDataListUploads } from 'shared/hooks/api';

export type UploadsListInputProps = {
  value: string[];
  onChange: (uploadIds: string[]) => any;
  isMultiple?: boolean;
  allowedTypes: string[];
  flowId?: string;
};

export const UploadsListInputContainer: FC<UploadsListInputProps> = ({
  value = [],
  isMultiple = true,
  allowedTypes,
  flowId,
  onChange
}) => {
  const { refetch: refetchUploads } = useDataListUploads({
    params: flowId ? { filter: { flowId: { eq: flowId } } } : {},
    options: { enabled: false }
  });
  const { oktaAuth } = useAuth();
  const token = oktaAuth.getAccessToken();

  const [ids, setIds] = useState<string[]>(value || []);
  const { addError } = useNotification();
  const uploadFile = (_file, response) => {
    if (response.body?.location) {
      const split = response.body.location.split('/');
      const id = split[split.length - 1];
      if (isMultiple) {
        setIds(ids => [...ids, id]);
      } else {
        setIds([id]);
      }

      // Refetch list uploads
      refetchUploads();
    }
  };

  const deleteFile = (id: string) => {
    // Refetch list uploads
    refetchUploads();

    setIds(ids => {
      const updatedList = ids.filter(item => item !== id);
      setTimeout(() => onChange(updatedList));

      return updatedList;
    });
  };

  const completeHandler = useCallback(
    result => {
      const uploadedIds = result.successful.reduce((map, res) => {
        const uploadUrl = res?.uploadURL;
        if (uploadUrl) {
          const split = uploadUrl.split('/');
          const id = split[split.length - 1];
          map.push(id);
        }

        return map;
      }, []);
      if (isMultiple) {
        onChange([...ids, ...uploadedIds]);
      } else {
        onChange(uploadedIds);
      }
    },
    [onChange, ids, isMultiple]
  );

  return (
    <div className={css.container}>
      {ids.length > 0 && (
        <UploadsListContainer ids={ids} onDelete={deleteFile} />
      )}
      <UploadInput
        type="url"
        flowId={flowId}
        options={{
          allowMultipleUploads: isMultiple,
          autoProceed: true,
          restrictions: {
            ...(isMultiple ? {} : { maxNumberOfFiles: 1 }),
            allowedFileTypes: allowedTypes
          }
        }}
        url={`${process.env.REACT_APP_REST_API_URL_OLD}/file`}
        token={token}
        note={
          allowedTypes &&
          allowedTypes.length &&
          `Allowed file extensions: ${allowedTypes.join(', ')}`
        }
        onUploadSuccess={uploadFile}
        onUploadError={file => addError(`File ${file.name} upload error.`)}
        onComplete={completeHandler}
      />
    </div>
  );
};
