import React, { Fragment, FC, ReactNode, useState, useCallback } from 'react';
import orderBy from 'lodash/orderBy';
import { Notification } from './Notification';
import {
  NotificationOptions,
  NotificationsContext
} from './NotificationsContext';
import { AnimatePresence, motion } from 'framer-motion';
import css from './Notifications.module.css';

type NotificationsProps = {
  limit?: number;
  timeout?: number;
  showClose?: boolean;
  preventFlooding?: boolean;
  children?: ReactNode;
};

let nextId = 0;

export const Notifications: FC<NotificationsProps> = ({
  children,
  limit = 10,
  timeout = 4000,
  showClose = true,
  preventFlooding = true
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);

  const clear = useCallback(
    (id: number) => setNotifications(notifications.filter(n => n.id !== id)),
    [notifications]
  );

  const clearAll = useCallback(() => setNotifications([]), []);

  const add = useCallback(
    (title: string, options: NotificationOptions = {}) => {
      // If we are flooded with the same message over and over,
      // dont add more of the same type. Mainly used for error use cases.
      if (preventFlooding) {
        const has = notifications.find(n => n.title === title);

        if (has) {
          return false;
        }
      }

      const obj = {
        title,
        id: nextId++,
        style: 'default',
        timeout,
        showClose,
        ...options
      };

      const sorted = orderBy([obj, ...notifications], ['date'], ['desc']);

      // Clear old notifications if we hit limit
      if (sorted.length >= limit) {
        sorted.pop();
      }

      // Update the container instance
      setNotifications(sorted);

      return obj.id;
    },
    [limit, notifications, preventFlooding, showClose, timeout]
  );

  const addError = useCallback(
    (title: string, options: NotificationOptions = {}) =>
      add(title, { ...options, style: 'error' }),
    [add]
  );

  const addWarning = useCallback(
    (title: string, options: NotificationOptions = {}) =>
      add(title, { ...options, style: 'warning' }),
    [add]
  );

  const addSuccess = useCallback(
    (title: string, options: NotificationOptions = {}) =>
      add(title, { ...options, style: 'success' }),
    [add]
  );

  return (
    <Fragment>
      <NotificationsContext.Provider
        value={{
          add,
          addError,
          addWarning,
          addSuccess,
          clear,
          clearAll
        }}
      >
        {children}
      </NotificationsContext.Provider>
      <div className={css.notificationContainer}>
        <div className={css.positions}>
          <AnimatePresence>
            {!!notifications.length && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {notifications.map(n => (
                  <Notification {...n} key={n.id} onClose={clear} />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Fragment>
  );
};
