import { axios } from 'core/Axios';
import {
  DataManualApproveActionBody,
  DataManualApproveActionResponse
} from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.MANUAL_APPROVE_ACTION;

export const req = async (
  data: DataManualApproveActionBody
): Promise<DataManualApproveActionResponse> => {
  return axios
    .post<DataManualApproveActionResponse>(Route.MANUAL_APPROVE_ACTION, data)
    .then(data => data.data);
};

export const useManualApproveAction = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataManualApproveActionResponse,
  DataManualApproveActionBody
> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
