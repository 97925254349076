import { axios } from 'core/Axios';
import { DataPutUploadBody, DataPutUploadResponse } from 'core/types/api/Data';
import { useMutation } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.PUT_UPLOAD;

export const req = async (
  data: DataPutUploadBody
): Promise<DataPutUploadResponse> => {
  return axios
    .put<DataPutUploadResponse>(Route.PUT_UPLOAD, data)
    .then(data => data.data);
};

export const usePutUpload = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataPutUploadResponse, DataPutUploadBody> = {}) => {
  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options
  });
};
