import { axios } from 'core/Axios';
import {
  UsageFlowWorkflowPageParams,
  UsageFlowWorkflowPageResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.FLOW_WORKFLOW_PAGE;
export const buildQueryKey = (params?: UsageFlowWorkflowPageParams) => {
  return [baseQueryKey, params];
};

export const req = async ({
  ids,
  from,
  to
}: UsageFlowWorkflowPageParams): Promise<UsageFlowWorkflowPageResponse> => {
  return axios
    .get<UsageFlowWorkflowPageResponse>(Route.FLOW_WORKFLOW_PAGE, {
      params: { from, to, ids: ids.join(',') }
    })
    .then(data => data.data);
};

export const useWorkflowPage = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  UsageFlowWorkflowPageResponse,
  UsageFlowWorkflowPageParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
