export const StringTypes = [
  'string',
  'user',
  'email',
  'url',
  'company',
  'group',
  'domain',
  'mac',
  'ip4',
  'ip6',
  'id',
  'bitcoin',
  'onion',
  'ethereum',
  'latlong',
  'git',
  'hash',
  'card',
  'magnet',
  'token'
];
export const NumericTypes = ['number', 'int', 'integer', 'float', 'phone'];
export const DateTypes = ['date', 'date-time', 'datetime', 'timestamp', 'time'];

export const valueTypeToBase = (valueType: string) => {
  if (StringTypes.includes(valueType)) {
    return 'string';
  } else if (NumericTypes.includes(valueType)) {
    return 'number';
  } else if (DateTypes.includes(valueType)) {
    return 'datetime';
  }

  return valueType;
};
