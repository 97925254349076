import { axios } from 'core/Axios';
import {
  DataDelUploadParams,
  DataDelUploadResponse,
  DataListUploadsResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_UPLOAD;

export const req = async (
  data: DataDelUploadParams
): Promise<DataDelUploadResponse> => {
  return axios
    .put<DataDelUploadResponse>(Route.DEL_UPLOAD, data)
    .then(data => data.data);
};

export const useDelUpload = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataDelUploadResponse, DataDelUploadParams> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_UPLOADS);
      const previousData = client.getQueriesData(Route.LIST_UPLOADS);
      client.setQueriesData(
        Route.LIST_UPLOADS,
        (oldData: DataListUploadsResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
