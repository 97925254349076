import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { TokenOption } from '../types';
import { modifierSerializer } from 'shared/form/Modifiers/utils';

export const convertToText = (
  editorState: EditorState,
  shouldTrim: boolean = true
) => {
  const invalidTokens: TokenOption[] = [];

  let result = convertToHTML({
    entityToHTML: (entity, originalText: string) => {
      if (entity.type === 'TOKEN') {
        // Capture not-found tokens...
        if (!entity.data.found) {
          invalidTokens.push(entity.data);
        }
        return modifierSerializer(entity.data);
      }
      return originalText;
    }
  })(editorState.getCurrentContent());

  // Remove the outside tags
  // Sigh https://github.com/HubSpot/draft-convert/blob/master/src/encodeBlock.js#L4
  result = result
    .replace(/<p>/g, '') // Note: Going from version 2.1.11 to 2.1.12 causes additional spaces between <p> and the token
    .replace(/<\/p>/g, '\n')
    .replace(/<code>/g, '')
    .replace(/<\/code>/g, '')
    .replace(/<em>/, '')
    .replace(/<\/em>/, '')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, `"`)
    .replace(/&#x27;/g, `'`)
    .replace(/&#x60;/g, '`');
  if (shouldTrim) {
    result = result
      .replace(/^([^\S\r\n])*/g, '') // Remove starting whitespace other than newlines
      .trimEnd(); // This is intentional, trim() removes line breaks
  } else {
    // still want to remove the last line break since it is added in the initial replace
    result = result.replace(/\n$/, '');
  }

  return {
    invalidTokens,
    result
  };
};
