import { axios } from 'core/Axios';
import {
  DataGetEventGroupParams,
  DataGetEventGroupResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_EVENT_GROUP;
export const buildQueryKey = (params?: DataGetEventGroupParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetEventGroupParams
): Promise<DataGetEventGroupResponse> => {
  return axios
    .get<DataGetEventGroupResponse>(Route.GET_EVENT_GROUP, { params })
    .then(data => data.data);
};

export const useGetEventGroup = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetEventGroupResponse,
  DataGetEventGroupParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
