import React, { FC, useMemo } from 'react';
import { ListItem } from 'shared/layout/List';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import ellipsize from 'ellipsize';
import { Img } from 'shared/elements/Img';
import { CascaderItem } from '../../types';
import css from './CascaderPanelItem.module.css';
import { useTheme } from 'shared/utils/Theme';

const MAX_LENGTH = 100;

type CascaderPanelItemProps = {
  disabled?: boolean;
  item: CascaderItem;
  selected?: boolean;
  active?: boolean;
  collapsed?: boolean;
  keyword?: string;
  onHover?: (item: CascaderItem | null) => void;
  onClick?: (item: CascaderItem) => void;
};

export const CascaderPanelItem: FC<CascaderPanelItemProps> = ({
  item,
  disabled,
  selected,
  active,
  collapsed,
  keyword,
  onHover,
  onClick
}) => {
  const text = useMemo(() => ellipsize(item.text, MAX_LENGTH), [item.text]);
  const { isDark } = useTheme();

  return (
    <ListItem
      size="small"
      disabled={disabled}
      onMouseEnter={() => {
        if (!disabled) {
          onHover?.(item);
        }
      }}
      onMouseLeave={() => {
        if (!disabled) {
          onHover?.(null);
        }
      }}
      onClick={() => {
        if (!disabled) {
          onClick?.(item);
        }
      }}
      className={classNames(css.item, {
        [css.selected]: selected,
        [css.active]: active,
        [css.collapsed]: collapsed,
        [css.disabled]: disabled
      })}
      icon={
        item.icon && (
          <Img
            className={classNames(css.icon, { [css.light]: !isDark })}
            src={item.icon}
            alt="icon"
          />
        )
      }
      suffix={item.items?.length ? `►` : ''}
    >
      {!collapsed && (
        <Highlighter
          autoEscape={true}
          searchWords={[keyword]}
          textToHighlight={text}
        />
      )}
    </ListItem>
  );
};
