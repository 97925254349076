import { Helper } from '.';

export const expressionHelpers: Helper[] = [
  {
    name: '$append',
    label: 'Append',
    description: 'Add items to the end of an array.',
    examples: [`$append([1,2,3], [4,5,6]) => [1,2,3,4,5,6]`],
    valueType: 'array',
    returnType: 'array',
    params: {
      type: 'object',
      required: ['value'],
      properties: {
        value: {
          type: 'tree',
          title: 'Values to Add',
          attributes: {
            'ui:order': 0,
            'ui:treeType': 'array'
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/array-functions#append'
  },
  {
    name: '$eval',
    label: 'Eval',
    description:
      'Parses and evaluates the string expr which contains literal JSON or a JSONata expression.',
    examples: [
      `$eval("[1,2,3]") => [1, 2, 3]`,
      `$eval('[1,$string(2),3]') => [1,"2",3]`
    ],
    valueType: 'string',
    returnType: 'any',
    reference: 'https://docs.jsonata.org/string-functions#eval'
  },
  {
    name: '$exists',
    label: 'Exists',
    description: 'Returns "true" if the arg expression evaluates to a value.',
    examples: [`$exists("value") => true`, `$exists(node.output.data) => true`],
    valueType: 'string',
    returnType: 'boolean',
    reference: 'https://docs.jsonata.org/boolean-functions#exists'
  },
  {
    name: '$filter',
    label: 'Filter',
    description:
      'Returns an array containing only the values in the array parameter that satisfy the function predicate. The function that is supplied as the second parameter must have the following signature: function(value [, index [, array]]). Visit the JSONata documentation to learn how to create a function - https://docs.jsonata.org/',
    examples: [
      `$filter(Order.Product, function($v, $i, $a) { $v.Price > $average($a.Price) })`
    ],
    valueType: 'array',
    returnType: 'array',
    params: {
      type: 'object',
      required: ['fnc'],
      properties: {
        fnc: {
          type: 'code:jsonata',
          title: 'Filter Function',
          default: 'function($v, $i, $a) { \n  $v\n}',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/higher-order-functions#filter'
  },
  {
    name: '$keys',
    label: 'Keys',
    description: 'Return an array containing the keys from an object.',
    examples: [
      `$keys({ id: "123", age: 28 }) => ["id", "age"]`,
      `$keys([{ id: "123", age: 28 }, { id: "124", age: 47, height: "5'8" }]) => ["id", "age", "height"]`
    ],
    valueType: 'object',
    returnType: 'array',
    returnSubType: 'string',
    reference: 'https://docs.jsonata.org/object-functions#keys'
  },
  {
    name: '$lookup',
    label: 'Lookup',
    description: 'Return the value of a specific key in an object.',
    examples: [
      `$lookup({ id: "123" }, "id") => "123"`,
      `$lookup([{ id: "123" }, { id: "124" }], "id") => ["123", "124"]`
    ],
    valueType: 'any',
    returnType: 'any',
    params: {
      type: 'object',
      required: ['key'],
      properties: {
        key: {
          type: 'string',
          title: 'Key',
          description:
            'The key key that will be used to determine the value within an object.',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/object-functions#lookup'
  },
  {
    name: '$map',
    label: 'Map',
    description:
      'Returns an array containing the results of applying the function parameter to each value in the array parameter. The function that is supplied as the second parameter must have the following signature: function(value [, index [, array]])',
    examples: [`$map([1..5], $string) => ["1", "2", "3", "4", "5"]`],
    valueType: 'array',
    returnType: 'array',
    params: {
      type: 'object',
      required: ['fnc'],
      properties: {
        fnc: {
          type: 'code:jsonata',
          title: 'Map Function',
          default: 'function($v, $i, $a) { \n  $v\n}',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/higher-order-functions#map'
  },
  {
    name: '$now',
    label: 'Now',
    description:
      'Generates a UTC timestamp in ISO 8601 compatible format and returns it as a string.',
    examples: [`$now => "2017-05-15T15:12:59.152Z"`],
    returnType: 'datetime',
    reference: 'https://docs.jsonata.org/date-time-functions#now'
  },
  {
    name: '$reverse',
    label: 'Reverse',
    description:
      'Returns an array containing all the values from the array parameter, but in reverse order.',
    examples: [
      `$reverse(["Hello", "World"]) => ["World", "Hello"]`,
      `$reverse([1, 2, 3]) => [3, 2, 1]`
    ],
    valueType: 'array',
    returnType: 'array',
    reference: 'https://docs.jsonata.org/array-functions#reverse'
  },
  {
    name: '$sort',
    label: 'Sort',
    description:
      'Returns an array containing all the values in the array parameter, but sorted into order. If no function parameter is supplied, then the array parameter must contain only numbers or only strings, and they will be sorted in order of increasing number, or increasing unicode codepoint respectively. If a comparator function is supplied, then is must be a function that takes two parameters: function(left, right).',
    examples: [
      `$sort(Order.Product, function($l, $r) { $l.Weight > $r.Weight })`
    ],
    valueType: 'array',
    returnType: 'array',
    params: {
      type: 'object',
      properties: {
        fnc: {
          type: 'code:jsonata',
          title: 'Sort Function',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/array-functions#sort'
  },
  {
    name: '$spread',
    label: 'Spread',
    description:
      'Split an object of key/value pairs into an array of objects, each of which has a single key/value pair.',
    examples: [
      `$spread({ key1: "value1", key2: "value2" }) => [{ key1: "value1 }, { key2: "value2" }]`
    ],
    valueType: 'object',
    returnType: 'array',
    reference: 'https://docs.jsonata.org/object-functions#spread'
  }
];
