import { axios } from 'core/Axios';
import {
  DataGetFlowRevisionParams,
  DataGetFlowRevisionResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_FLOW_REVISION;
export const buildQueryKey = (params?: DataGetFlowRevisionParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetFlowRevisionParams
): Promise<DataGetFlowRevisionResponse> => {
  return axios
    .get<DataGetFlowRevisionResponse>(Route.GET_FLOW_REVISION, { params })
    .then(data => data.data);
};

export const useGetFlowRevision = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetFlowRevisionResponse,
  DataGetFlowRevisionParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
