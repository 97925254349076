import { axios } from 'core/Axios';
import { DataGetGroupParams, DataGetGroupResponse } from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_GROUP;
export const buildQueryKey = (params?: DataGetGroupParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetGroupParams
): Promise<DataGetGroupResponse> => {
  return axios
    .get<DataGetGroupResponse>(Route.GET_GROUP, { params })
    .then(data => data.data);
};

export const useGetGroup = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetGroupResponse, DataGetGroupParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    {
      keepPreviousData: true,
      staleTime: 30 * 60 * 1000, // 30 min (1800000 ms),
      ...options
    }
  );
};
