import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useHoverIntent } from 'react-use-hoverintent';
import { ReactComponent as ClearIcon } from 'assets/svg/close.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import css from './SearchBar.module.css';

type SearchBarProps = {
  autoFocus?: boolean;
  hover?: boolean;
  className?: any;
  value: string;
  placeholder?: string;
  size?: 'small' | 'default';
  onChange?: (value: string) => void;
  onClear?: () => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
};

export const SearchBar: FC<SearchBarProps> = ({
  autoFocus,
  className,
  hover,
  placeholder,
  size = 'default',
  value,
  onChange,
  onClear,
  onBlur = () => null,
  onEnter = () => null
}) => {
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isHovering, hoverRef] = useHoverIntent({ timeout: 250 });

  const onKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;
      if (key === 'Enter' || key === 'Tab') {
        onEnter(value);
      } else if (key === 'Escape') {
        onChange?.('');
        setOpenSearch(false);
        inputRef.current?.blur();
      }
    },
    [onChange, onEnter, value]
  );

  useEffect(() => {
    if (value && !openSearch) {
      setOpenSearch(true);
    } else if (!value) {
      setOpenSearch(false);
    }
  }, [openSearch, value]);

  useEffect(() => {
    if (hover && isHovering && autoFocus) {
      inputRef.current?.focus();
    } else if (hover && !isHovering && !openSearch) {
      inputRef.current?.blur();
    }
  }, [autoFocus, hover, isHovering, openSearch]);

  // return <div/>

  // Hover width is based on minimum size of icon + 8px of padding
  return (
    <motion.div
      initial={
        !hover
          ? { width: '100%' }
          : { width: size === 'small' ? '23px' : '28px' }
      }
      animate={
        hover
          ? openSearch || isHovering
            ? { width: '100%' }
            : { width: size === 'small' ? '23px' : '28px' }
          : { width: '100%' }
      }
      transition={{ duration: 0.25 }}
    >
      <div
        className={classNames(css.inputContainer, className, {
          [css.open]: !hover || openSearch || isHovering
        })}
        ref={hoverRef as React.RefObject<HTMLDivElement>}
      >
        <SearchIcon
          className={classNames(css.searchIcon, {
            [css.small]: size === 'small'
          })}
        />
        <motion.div
          initial={
            !hover
              ? { opacity: 1, width: '100%' }
              : { opacity: 0, zIndex: -1, overflow: 'hidden' }
          }
          animate={
            hover
              ? openSearch || isHovering
                ? { opacity: 1, zIndex: 0, transition: { duration: 0.35 } }
                : {
                    opacity: 0,
                    zIndex: -1,
                    overflow: 'hidden',
                    transition: { duration: 0.15 }
                  }
              : { opacity: 1, zIndex: 0 }
          }
        >
          <input
            type="text"
            ref={inputRef}
            className={classNames(css.searchInput, {
              [css.small]: size === 'small'
            })}
            value={value}
            autoFocus={autoFocus && !hover}
            placeholder={placeholder}
            onChange={event => onChange?.(event.target.value)}
            onBlur={() => onBlur?.(value)}
            onKeyDown={onKeydown}
          />
          {value && (
            <ClearIcon
              className={classNames(css.clearIcon, {
                [css.small]: size === 'small'
              })}
              onClick={onClear}
            />
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};
