import { axios } from 'core/Axios';
import {
  UsageTopNActionsParams,
  UsageTopNActionsResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.ORG_TOP_N_ACTIONS;
export const buildQueryKey = (params?: UsageTopNActionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: UsageTopNActionsParams
): Promise<UsageTopNActionsResponse> => {
  return axios
    .get<UsageTopNActionsResponse>(Route.ORG_TOP_N_ACTIONS, { params })
    .then(data => data.data);
};

export const useTopNActions = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  UsageTopNActionsResponse,
  UsageTopNActionsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
