import React, { FC, useEffect, useState } from 'react';
import { ConditionRule } from '@getcrft/jsonata-ext';
import shortid from '@getcrft/shortid';
import { Button } from 'shared/elements/Button';
import classNames from 'classnames';
import { ConditionInputItemWrapper } from './ConditionInputItemWrapper';
import css from './ConditionInput.module.css';
import { OperatorGroup } from './ConditionInputItem';
import { Datastore, Flow } from 'core/types/API';

export type ExtendedConditionRule = ConditionRule & {
  id: string;
  label: string;
};

export type ConditionInputProps = {
  value?: ExtendedConditionRule[];
  disabled?: boolean;
  allowComplex?: boolean;
  allowMultiple?: boolean;
  allowTrigger?: boolean;
  allowExpressions?: boolean;
  allowModifiers?: boolean;
  fieldOptions?: any[];
  valueOptions?: any[];
  operatorOptions?: OperatorGroup[];
  inputType?: string;
  conditionRule?: 'and' | 'or';
  stores?: Datastore[];
  flows?: Flow[];
  onChange?: (value: ExtendedConditionRule[]) => void;
  onPortUpdate: (conditionId: string, toAdd: boolean) => void;
};

const emptyCondition = (index: number) => ({
  id: shortid(),
  label: `Condition ${index + 1}`,
  variable: null,
  comparator: null,
  value: ''
});

export const ConditionInput: FC<ConditionInputProps> = ({
  value,
  disabled,
  allowComplex = true,
  allowMultiple = true,
  allowTrigger = true,
  allowExpressions = true,
  allowModifiers = true,
  onChange,
  onPortUpdate,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<ExtendedConditionRule[]>(
    []
  );
  const hasMultiple = internalValue.length > 1;

  useEffect(() => {
    if (internalValue.length === 0) {
      if (value) {
        setInternalValue(value);
      } else {
        const condition = emptyCondition(0);
        const updatedValue = [condition];
        setInternalValue(updatedValue);
        // Needed to ensure single condition actions don't get an extra port (DATA_LIST_FILTER)
        if (allowMultiple) {
          onChange(updatedValue);
          onPortUpdate(condition.id, true);
        }
      }
    }
  }, [allowMultiple, value, internalValue, onChange, onPortUpdate]);

  return (
    <div className={css.container}>
      <div className={classNames(css.conditions)}>
        {internalValue.map((v, i) => (
          <ConditionInputItemWrapper
            {...rest}
            key={v.id}
            condition={v}
            type="condition"
            disabled={disabled}
            allowComplex={allowComplex}
            allowTrigger={allowTrigger}
            allowExpressions={allowExpressions}
            allowModifiers={allowModifiers}
            hasMultiple={hasMultiple}
            canDelete={hasMultiple}
            showLabel={allowMultiple}
            onDelete={() => {
              const updatedValue = [...internalValue];
              updatedValue.splice(i, 1);
              setInternalValue(updatedValue);
              onChange(updatedValue);
              onPortUpdate(v.id, false);
            }}
            onChange={updated => {
              const updatedValue = [...internalValue];
              updatedValue[i] = updated;
              setInternalValue(updatedValue);
              onChange(updatedValue);
            }}
          />
        ))}
      </div>
      {allowMultiple && (
        <Button
          className={css.addBtn}
          fullWidth={true}
          variant="outline"
          disableMargins={true}
          disabled={disabled}
          onClick={() => {
            const condition = emptyCondition(internalValue.length);
            const updatedValue = [...internalValue, condition];
            setInternalValue(updatedValue);
            onChange(updatedValue);
            onPortUpdate(condition.id, true);
          }}
        >
          Add Branch
        </Button>
      )}
    </div>
  );
};
