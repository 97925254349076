import React, { FC, useEffect, useRef } from 'react';
import Inputmask from 'inputmask';
import { InputProps, Input, InputRef } from './Input';

export const ValidatedInput: FC<InputProps & { validation: any }> = ({
  validation,
  ...rest
}) => {
  const maskRef = useRef<InputRef | null>(null);

  useEffect(() => {
    const input = maskRef.current?.inputRef?.current;
    if (input && validation) {
      const im = new Inputmask(validation);
      im.mask(input);
    }
  }, [validation]);

  return <Input {...rest} ref={maskRef} />;
};
