import { axios } from 'core/Axios';
import {
  DataGetActionEventParams,
  DataGetActionEventResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_ACTION_EVENT;
export const buildQueryKey = (params?: DataGetActionEventParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetActionEventParams
): Promise<DataGetActionEventResponse> => {
  return axios
    ?.get<DataGetActionEventResponse>(Route.GET_ACTION_EVENT, { params })
    .then(data => data.data);
};

export const useGetActionEvent = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetActionEventResponse,
  DataGetActionEventParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
