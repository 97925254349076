import React, { FC, Fragment, useMemo } from 'react';
import { Avatar } from 'shared/elements/Avatar';
import { Select, SelectOption, SelectProps } from 'shared/form/Select';
import css from './UserGroupSelect.module.css';

export const UserGroupSelect: FC<SelectProps & { groups: any[] }> = ({
  groups,
  value,
  ...rest
}) => {
  const options = useMemo(
    () =>
      groups.map(t => ({
        label: t.name,
        value: t.name,
        image: t.image
      })),
    [groups]
  );

  return (
    <Fragment>
      <Select value={value} {...rest}>
        {options.map(v => (
          <SelectOption
            value={v.value}
            key={v.value}
            inputLabel={
              <Fragment>
                <Avatar
                  className={css.avatar}
                  user={{ name: v.label, image: v.image }}
                />
                {v.label}
              </Fragment>
            }
            menuLabel={
              <Fragment>
                <Avatar
                  className={css.avatar}
                  user={{ name: v.label, image: v.image }}
                />
                {v.label}
              </Fragment>
            }
          >
            {v.label}
          </SelectOption>
        ))}
      </Select>
      <p className={css.description}>
        {value && `This is shared with the organization "${value}"`}
        {!value &&
          `This is not currently shared with any organization, select one to share it`}
      </p>
    </Fragment>
  );
};
