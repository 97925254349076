import React, { FC, useState } from 'react';
import { Button } from 'shared/elements/Button';
import { getType } from '../../utils';
import { JsonTree, Operation } from 'shared/data/Tree';
import { Select, SelectOption } from 'shared/form/Select';
import { Block } from 'shared/layout/Block';
import { Dialog } from 'shared/layers/Dialog';
import { NodeImporter } from 'shared/data/Tree/JsonTree/NodeImporter';
import { JsonTreeNodeData } from 'shared/data/Tree/JsonTree/JsonTreeNode';
import { stringifyTree } from 'shared/data/Tree/JsonTree/utils';
import merge from 'lodash/merge';
import css from './DatastoreJsonView.module.css';
import { RouteLeavingGuard } from 'shared/utils/RouteLeavingGuard';

type DatastoreJsonViewProps = {
  value: any;
  onSave: (val: any, type: string) => void;
  onViewChange: (view: 'general' | 'json') => void;
};

export const DatastoreJsonView: FC<DatastoreJsonViewProps> = ({
  value,
  onSave,
  onViewChange
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<any>(value || []);
  const [valueType, setValueType] = useState<string>(getType(internalValue));
  const [isValid] = useState<boolean>(true);
  const valueSelection = ['array', 'object'].includes(valueType)
    ? valueType
    : '';

  const transformValue = (value: any, newType: string, oldType: string) => {
    if (oldType === '') {
      if (newType === 'array') {
        setInternalValue([...value.toString().split(',')]);
      } else if (newType === 'object') {
        setInternalValue({ key: value });
      }
    } else if (oldType === 'array') {
      const isEmpty = value.length === 0;
      if (newType === '') {
        setInternalValue(isEmpty ? '' : JSON.stringify(value));
      } else if (newType === 'object') {
        setInternalValue(
          value.reduce(
            (acc, cur, index) => ({ ...acc, [`key${index + 1}`]: cur }),
            {}
          )
        );
      }
    } else if (oldType === 'object') {
      const isEmpty = Object.keys(value).length === 0;
      if (newType === '') {
        setInternalValue(isEmpty ? '' : JSON.stringify(value));
      } else if (newType === 'array') {
        setInternalValue(isEmpty ? [] : [value]);
      }
    }
  };

  const onImport = (newNode: JsonTreeNodeData, curVal: any, op: Operation) => {
    const stringifiedNewNode = stringifyTree([newNode]);
    const parsedNewNode = JSON.parse(stringifiedNewNode[0]);

    if (op === 'replace') {
      setInternalValue(parsedNewNode);
      setValueType(getType(parsedNewNode));
      setDirty(true);
    } else if (op === 'merge') {
      if (valueType === 'array') {
        // If current value is an array
        if (newNode.type === 'array') {
          setInternalValue([...curVal, ...parsedNewNode]);
        } else {
          setInternalValue([...curVal, parsedNewNode]);
        }
        setDirty(true);
      } else if (valueType === 'object' && newNode.type === 'object') {
        // Merge objects together
        setInternalValue(merge({ ...curVal }, parsedNewNode));
        setDirty(true);
      }
    }

    setOpenImport(false);
  };

  return (
    <div className={css.jsonView}>
      <Block label="Value Type">
        <Select
          value={valueSelection}
          onChange={v => {
            if (v !== valueSelection) {
              setDirty(true);
              transformValue(internalValue, v, valueSelection);
              setValueType(v);
            }
          }}
        >
          <SelectOption value="">Value</SelectOption>
          <SelectOption value="array">Array</SelectOption>
          <SelectOption value="object">Object</SelectOption>
        </Select>
      </Block>
      <div className={css.value}>
        <JsonTree
          className={css.tree}
          data={internalValue}
          type={valueSelection}
          editable
          showCount
          showEmpty
          showType="icons"
          onUpdateData={data => {
            setInternalValue(data);
            setValueType(getType(data));
            setDirty(true);
          }}
        />
      </div>
      <div className={css.buttonGroup}>
        <div>
          <Button
            variant="outline"
            color="default"
            onClick={() => setOpenImport(true)}
          >
            Import
          </Button>
        </div>
        <div>
          <Button
            variant="filled"
            color="primary"
            // seems weird to need the extra 'dirty' check, but isValid is true
            // initially which seems wrong to me
            disabled={!dirty || !isValid}
            onClick={() => onSave(internalValue, valueSelection)}
          >
            Ok
          </Button>
          <Button
            variant="outline"
            color="default"
            onClick={() => onViewChange('general')}
          >
            Cancel
          </Button>
        </div>
      </div>
      <Dialog
        size="650px"
        open={openImport}
        header="Import Value"
        onClose={() => {
          setOpenImport(false);
        }}
      >
        {() => (
          <NodeImporter
            currentNode={internalValue}
            currentNodeType={valueSelection}
            showEmpty
            replacement
            addEditNode={onImport}
            onCancel={() => {
              setOpenImport(false);
            }}
          />
        )}
      </Dialog>
      <RouteLeavingGuard
        when={dirty}
        title="Unsaved Changes"
        message="There are unsaved changes. Are you sure you want to leave?"
      />
    </div>
  );
};
