import React, { useEffect, useState, useCallback, FC } from 'react';
import { Location } from 'history';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'shared/layers/Dialog';
import { Prompt } from 'shared/utils/Prompt';

type RouteLeavingGuardProps = {
  when?: boolean;
  title: string;
  message: string;
  ignorePaths?: string[];
};

export const RouteLeavingGuard: FC<RouteLeavingGuardProps> = ({
  when,
  title,
  message,
  ignorePaths
}) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location) => {
      if (ignorePaths?.includes(nextLocation.pathname)) {
        return true;
      }

      if (!confirmedNavigation) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }

      return true;
    },
    [confirmedNavigation, ignorePaths]
  );

  const handleConfirmNavigationClick = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, []);

  const handleCancelNavigationClick = useCallback(() => {
    setModalVisible(false);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleCancelNavigationClick();
      }
    }
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [handleCancelNavigationClick]);

  return (
    <>
      {/* TODO: Get blocking navigation back in https://github.com/remix-run/react-router/pull/9709 https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743 */}
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmDialog
        open={modalVisible}
        title={title}
        text={message}
        onCancel={handleCancelNavigationClick}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};
