import React, { FC, useCallback, useState } from 'react';
import { ListItem } from 'shared/layout/List';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import classNames from 'classnames';
import { useUpdateEffect } from 'react-use';
import { TokenEditor, TokenOption } from 'shared/form/TokenEditor';
import { Select, SelectOption } from 'shared/form/Select';
import { Block } from 'shared/layout/Block';
import { Button } from 'shared/elements/Button';
import { Datastore, Flow } from 'core/types/API';
import css from './KeyValue.module.css';
import { Input } from '../Input';

export type KeyValueData = {
  key: string;
  value: any;
  createdAt?: Date;
  index: number;
  // id is tacked on for searching purposes
  id?: number;
};

export type KeyValueInputTypes = 'password' | 'text' | 'select';

export type KeyValueItemProps = {
  allowExpressions?: boolean;
  allowFlowTrigger?: boolean;
  showHeader?: boolean;
  keyOptions?: TokenOption[];
  valueOptions?: TokenOption[];
  disabled?: boolean;
  keyType?: KeyValueInputTypes;
  valueType?: KeyValueInputTypes;
};

type KeyValueProps = {
  data: KeyValueData;
  stores?: Datastore[];
  flows?: Flow[];
  commands?: (data: KeyValueData) => React.ReactNode;
  onChange?: (data: KeyValueData) => void;
  onDelete?: (data: KeyValueData) => void;
} & KeyValueItemProps;

export const KeyValue: FC<KeyValueProps> = ({
  data,
  disabled,
  keyType = 'text',
  valueType = 'text',
  commands,
  allowFlowTrigger,
  allowExpressions,
  showHeader = false,
  keyOptions,
  valueOptions,
  stores = [],
  flows = [],
  onDelete = () => undefined,
  onChange = () => undefined
}) => {
  const [key, setKey] = useState<string>(data.key);
  const [value, setValue] = useState<string>(data.value);

  const onBlur = useCallback(() => {
    if (data.key !== key || data.value !== value) {
      onChange({ ...data, key, value });
    }
  }, [data, key, value, onChange]);

  useUpdateEffect(() => {
    if (data.key) {
      setKey(data.key);
    }
    if (data.value) {
      setValue(data.value);
    }
  }, [data]);

  const renderFieldInput = ({
    inputType,
    placeholder,
    curValue,
    options,
    onFieldChange
  }) => {
    if (inputType === 'password') {
      return (
        <Input
          placeholder={placeholder}
          disabled={disabled}
          type="password"
          autoCorrect="false"
          autoCapitalize="false"
          autoComplete="false"
          value={curValue}
          onChange={event => onFieldChange(event.target.value)}
          onBlur={onBlur}
        />
      );
    } else if (inputType === 'select') {
      return (
        <Select
          placeholder={placeholder}
          value={curValue}
          disabled={disabled}
          onChange={onFieldChange}
          onBlur={onBlur}
        >
          {options?.map(v => (
            <SelectOption value={v.value} key={v.value}>
              {v.text}
            </SelectOption>
          ))}
        </Select>
      );
    } else if (inputType === 'text') {
      const allowTrigger = allowFlowTrigger && placeholder !== 'Key';

      return (
        <TokenEditor
          placeholder={placeholder}
          disabled={disabled}
          allowFlowTrigger={allowTrigger}
          allowExpressions={allowExpressions}
          value={curValue}
          tokens={options}
          stores={stores}
          flows={flows}
          onChange={onFieldChange}
          onBlur={onBlur}
        />
      );
    } else {
      throw new Error(`Input type not valid: ${inputType}`);
    }
  };

  return (
    <ListItem disablePadding={true} divider={false} disableBackground>
      <div
        className={classNames(css.blockRow, {
          [css.withHeader]: showHeader
        })}
      >
        <Block
          className={classNames(css.block, css.inputBlock)}
          label={showHeader && 'Key'}
        >
          {renderFieldInput({
            placeholder: 'Key',
            curValue: key,
            inputType: !keyOptions.length ? 'text' : keyType,
            options: keyOptions,
            onFieldChange: next => {
              if (key !== next) {
                setKey(next);
              }
            }
          })}
        </Block>
        <Block
          className={classNames(css.block, css.inputBlock)}
          label={showHeader && 'Value'}
        >
          {renderFieldInput({
            placeholder: 'Value',
            curValue: value,
            inputType: valueType,
            options: valueOptions,
            onFieldChange: next => {
              if (value !== next) {
                setValue(next);
              }
            }
          })}
        </Block>
        <Block className={classNames(css.block, css.actions)}>
          {commands && commands(data)}
          <Button
            variant="text"
            disablePadding
            disabled={disabled}
            onClick={() => onDelete(data)}
          >
            <TrashIcon className={css.deleteIcon} />
          </Button>
        </Block>
      </div>
    </ListItem>
  );
};
