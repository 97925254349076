import React, { FC, useCallback, useEffect } from 'react';
import { ConditionValueRule } from '@getcrft/jsonata-ext';
import { Select, SelectOption } from 'shared/form/Select';
import css from './ConditionInputBoolean.module.css';

export type ConditionBooleanView = {
  type?: 'specific' | 'field';
};

export type ConditionInputBooleanProps = {
  value: ConditionValueRule;
  disabled?: boolean;
  valueOptions?: any[];
  onChange?: (value: ConditionValueRule) => void;
};

export const ConditionInputBoolean: FC<ConditionInputBooleanProps> = ({
  value,
  disabled,
  onChange
}) => {
  const viewVal = value?.view as ConditionBooleanView;
  const selectedView =
    viewVal?.type === 'field' ? 'field' : value?.value?.toString();

  const onFieldChange = useCallback(
    (nextValue: ConditionValueRule) => {
      const newViewVal = nextValue.view as ConditionBooleanView;
      if (newViewVal.type === 'field' && viewVal?.type === 'specific') {
        nextValue.value = undefined;
      }

      onChange(nextValue);
    },
    [viewVal, onChange]
  );

  useEffect(() => {
    if (!value.view) {
      onChange({
        ...value,
        value: true,
        view: {
          type: 'specific'
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={css.boolInput}>
      <Select
        clearable={false}
        placeholder="Value"
        disabled={disabled}
        value={selectedView}
        onChange={v => {
          if (v === 'field') {
            onFieldChange({
              ...value,
              view: {
                type: v
              }
            });
          } else if (v) {
            const val = v === 'true';
            onFieldChange({
              ...value,
              value: val,
              view: {
                type: 'specific'
              }
            });
          }
        }}
      >
        <SelectOption value="true">True</SelectOption>
        <SelectOption value="false">False</SelectOption>
        <SelectOption value="field">Field</SelectOption>
      </Select>
    </div>
  );
};
