import {
  QueryKey as ReactQueryKey,
  UseQueryOptions,
  QueryFunction,
  MutateFunction,
  UseMutationOptions,
  UseInfiniteQueryOptions
} from 'react-query';

export type QueryApiHookProps<
  TResponseType = unknown,
  TParamsType = unknown,
  TBodyType = unknown
> = {
  queryKey?: ReactQueryKey;
  queryFn?: QueryFunction<TResponseType>;
  options?: UseQueryOptions<TResponseType, Error, TResponseType>;
  params?: TParamsType;
  body?: TBodyType;
};

export type InfiniteQueryApiHookProps<
  TResponseType = unknown,
  TParamsType = unknown,
  TBodyType = unknown
> = {
  queryKey?: ReactQueryKey;
  queryFn?: QueryFunction<TResponseType>;
  options?: UseInfiniteQueryOptions<TResponseType, Error, TResponseType>;
  params?: TParamsType;
  body?: TBodyType;
};

export type MutationApiHookProps<
  TResponseType = unknown,
  TVariablesType = unknown
> = {
  mutationFn?: MutateFunction<TResponseType, unknown, TVariablesType>;
  options?: UseMutationOptions<TResponseType, Error, TVariablesType>;
};

export enum Route {
  /** Action **/
  GET_ACTION = '/data/getAction',
  GET_ACTION_EVENT = '/data/getActionEvent',
  LIST_ACTIONS = '/data/listActions',
  RUN_ACTION = '/data/runAction',
  RUN_ACTION_SYNC_UI = '/data/runActionSyncUI',
  MANUAL_APPROVE_ACTION = '/data/manualApproveAction',

  /** ApiKey **/
  DEL_API_KEY = '/data/delApiKey',
  GET_API_KEY = '/data/getApiKey',
  LIST_API_KEYS = '/data/listApiKeys',
  PUT_API_KEY = '/data/putApiKey',
  UPDATE_API_KEY = '/data/updateApiKey',

  /** Connection **/
  DELETE_CONNECTION = '/data/deleteConnection',
  GET_CONNECTION = '/data/getConnection',
  LIST_CONNECTIONS = '/data/listConnections',
  PUT_CONNECTION = '/data/putConnection',
  TEST_CONNECTION = '/data/testConnection',
  LIST_CONNECTION_REVISIONS = '/data/listConnectionRevisions',
  UPDATE_CONNECTION = '/data/updateConnection',

  /** Datastore **/
  DEL_DATASTORE = '/data/delDatastore',
  GET_DATASTORE = '/data/getDatastore',
  LIST_DATASTORES = '/data/listDatastores',
  PUT_DATASTORE = '/data/putDatastore',
  UPDATE_DATASTORE = '/data/updateDatastore',

  /** Event **/
  DEL_EVENT = '/data/delEvent',
  GET_EVENT = '/data/getEvent',
  GET_FLOW_EVENT_SIGNED_QUERY = '/file/:flowId/:runId/download',
  LIST_EVENTS = '/data/listEvents',
  PUT_EVENT = '/data/putEvent',
  UPDATE_EVENT = '/data/updateEvent',

  /** EventGroup **/
  DEL_EVENT_GROUP = '/data/delEventGroup',
  GET_EVENT_GROUP = '/data/getEventGroup',
  LIST_EVENT_GROUPS = '/data/listEventGroups',
  PUT_EVENT_GROUP = '/data/putEventGroup',
  UPDATE_EVENT_GROUP = '/data/updateEventGroup',

  /** Flow **/
  DEL_FLOW = '/data/delFlow',
  GET_FLOW = '/data/getFlow',
  LIST_FLOWS = '/data/listFlows',
  PUT_FLOW = '/data/putFlow',
  RUN_FLOW = '/data/runFlow',
  UPDATE_FLOW = '/data/updateFlow',

  /** FlowRevision **/
  GET_FLOW_REVISION = '/data/getFlowRevision',
  LIST_FLOW_REVISIONS = '/data/listFlowRevisions',
  PUT_FLOW_REVISION = '/data/putFlowRevision',

  /** FlowSchema **/
  GET_FLOW_SCHEMA = '/data/getFlowSchema',
  LIST_FLOW_SCHEMAS = '/data/listFlowSchemas',

  /** FlowUsage **/
  DEL_FLOW_USAGE = '/data/delFlowUsage',
  GET_FLOW_USAGE = '/data/getFlowUsage',
  LIST_FLOW_USAGES = '/data/listFlowUsages',
  PUT_FLOW_USAGE = '/data/putFlowUsage',
  UPDATE_FLOW_USAGE = '/data/updateFlowUsage',

  /** Notification **/
  DEL_NOTIFICATION = '/data/delNotification',
  LIST_NOTIFICATIONS = '/data/listNotifications',

  /** Package **/
  GET_PACKAGE = '/data/getPackage',
  LIST_PACKAGES = '/data/listPackages',

  /** PackageConnection **/
  GET_PACKAGE_CONNECTION = '/data/getPackageConnection',
  LIST_PACKAGE_CONNECTIONS = '/data/listPackageConnections',

  /** User **/
  GET_GROUP = '/data/getGroup',
  GET_USER = '/data/getUser',
  LIST_USERS = '/data/listUsers',
  UPDATE_USER = '/data/updateUser',

  /** UserGroups **/
  LIST_USER_GROUPS = '/data/listUserGroups',

  /** Usage Flow **/
  FLOW_AVERAGE_ACTION_COUNT = '/usage/get-usage-by-flow/',
  FLOW_WORKFLOW_PAGE = '/usage/flow/workflow-page',

  /** Usage Org **/
  ORG_ALL_ACTIONS = '/usage/get-usage-my-org/all-actions',
  ORG_FLOWS_FAILED = '/usage/get-usage-my-org/flows-failed',
  ORG_FLOWS_RUN = '/usage/get-usage-my-org/flows-run',
  ORG_TOP_N_ACTIONS = '/usage/get-usage-my-org/top-n-actions',
  ORG_USAGE_PAGE_INFO = '/usage/get-usage-my-org/usage-page-info',

  /** Usage User **/
  USER_ALL_ACTIONS = '/usage/get-usage-mine/all-actions',
  USER_FLOWS_FAILED = '/usage/get-usage-mine/flows-failed',
  USER_FLOWS_RUN = '/usage/get-usage-mine/flows-run',
  USER_TOP_N_ACTIONS = '/usage/get-usage-mine/top-n-actions',
  USER_USAGE_PAGE_INFO = '/usage/get-usage-mine/usage-page-info',

  /** Upload **/
  DEL_UPLOAD = '/data/delUpload',
  GET_UPLOAD = '/data/getUpload',
  LIST_UPLOADS = '/data/listUploads',
  PUT_UPLOAD = '/data/putUpload',
  UPDATE_UPLOAD = '/data/updateUpload'
}
