import React, { FC, Fragment, useCallback, useRef, useState } from 'react';
import { Button } from 'shared/elements/Button';
import { List, ListItem } from 'shared/layout/List';
import { Menu } from 'shared/layers/Menu';
import { ReactComponent as DropdownIcon } from 'assets/svg/expand.svg';
import { useLocalStorage } from 'react-use';
import css from './Pager.module.css';

export type ResultsPerPageProps = {
  limit: number;
  limitKey?: string;
  limitOptions?: number[];
  onLimitChange?: (perPage: number) => void;
};

export const ResultsPerPage: FC<ResultsPerPageProps> = ({
  limit,
  limitKey,
  limitOptions,
  onLimitChange
}) => {
  const [limitSelector, setLimitSelector] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [initialLimit, setInitialLimit] = useState<string>(limit.toString());
  const [perPagelimit, setPerPageLimit] = useLocalStorage<string>(
    limitKey ? limitKey : 'perPageLimit',
    limit.toString()
  );
  const onChange = useCallback(
    (results: number) => {
      setPerPageLimit(results.toString());
      onLimitChange(results);
      setLimitSelector(false);
      setInitialLimit(null);
    },
    [onLimitChange, setPerPageLimit]
  );

  return (
    <Fragment>
      <span className={css.limitLabel}>Per Page</span>
      <Button
        size="small"
        variant="outline"
        color="secondary"
        ref={buttonRef}
        onClick={() => setLimitSelector(!limitSelector)}
      >
        {initialLimit || perPagelimit}
        <DropdownIcon className={css.dropdownIcon} />
      </Button>
      <Menu
        visible={limitSelector}
        onClose={() => setLimitSelector(false)}
        reference={buttonRef}
      >
        <List>
          {limitOptions.map(option => (
            <ListItem
              key={`per-page-${option}`}
              onClick={() => onChange(option)}
            >
              {option}
            </ListItem>
          ))}
        </List>
      </Menu>
    </Fragment>
  );
};
