import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Pluralize } from 'shared/utils/Pluralize';
import { getTypeIconComponent } from 'shared/internal/TypeIcons';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { useNotification } from 'shared/layers/Notification';
import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg';
import { ReactComponent as ConfirmIcon } from 'assets/svg/ok.svg';
import { ReactComponent as CopyIcon } from 'assets/svg/copy.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';
import { ReactComponent as ExpandIcon } from 'assets/svg/expand-arrows.svg';
import { ReactComponent as CollapseIcon } from 'assets/svg/collapse-arrows.svg';
import { ReactComponent as FileIcon } from 'assets/svg/document.svg';
import { ReactComponent as AddIcon } from 'assets/svg/plus.svg';
import { ReactComponent as ImportIcon } from 'assets/svg/import.svg';
import { TreeNode } from '../TreeNode';
import { List, ListItem } from 'shared/layout/List';
import { MODIFIER_KEY } from 'shared/utils/Hotkeys';
import { ContextMenu } from 'shared/layers/ContextMenu';
import css from './JsonTreeNode.module.css';
import { EditBar } from './EditBar';
import { Button } from 'shared/elements/Button';
import { TokenEditor, TokenOption } from 'shared/form/TokenEditor';

export type JsonTreeNodeData = {
  id: string;
  index?: number;
  parentId?: string;
  obfuscated?: boolean;
  label: string;
  type: 'object' | 'array' | 'string' | 'number' | 'boolean' | 'date' | 'image';
  count?: number;
  data: any;
  raw: any;
  subtype?: string;
  ellipsis?: string;
};

const PRIMATIVE_TYPES = ['string', 'number', 'boolean', 'image'];

export type JsonTreeNodeProps = {
  child: JsonTreeNodeData;
  showType: 'text' | 'icons' | boolean;
  showCount: boolean;
  allowCopy: boolean;
  allowTokens: boolean;
  tokens: TokenOption[];
  stores: any[];
  flows: any[];
  index: number;
  depth: number;
  editable?: boolean;
  showAll?: boolean;
  showAllLimit?: number;
  showAllThreshold?: number;
  expandDepth: number;
  count: number;
  showAllAtNode: string[];
  pathPrefix?: string;
  isEditingNode: boolean;
  onEditingNode: (isEditing: boolean) => void;
  onExpandDepth: (depth: number) => void;
  onChildHover?: (isHovering: boolean, childId: string) => void;
  onCopySchema: () => void;
  onAddNode: (node: JsonTreeNodeData) => void;
  onAddToken?: (node: JsonTreeNodeData, token: TokenOption) => void;
  onEditNode: (node: JsonTreeNodeData) => void;
  onDeleteNode: (node: JsonTreeNodeData) => void;
  onImportNodes: (node: JsonTreeNodeData) => void;
  nodeRenderer?: (node: ReactNode, editing?: boolean) => ReactNode;
  onShowAllAtNode: (ids: string[]) => void;
  onUpdateData: (data: { [key: string]: any }) => void;
};

export const JsonTreeNode: FC<JsonTreeNodeProps> = ({
  child,
  index,
  depth,
  editable,
  showAll,
  nodeRenderer,
  showType,
  showCount,
  allowCopy,
  allowTokens,
  tokens,
  stores,
  flows,
  isEditingNode,
  onEditingNode,
  onUpdateData,
  showAllLimit,
  onExpandDepth,
  count,
  onCopySchema,
  showAllThreshold,
  pathPrefix,
  onShowAllAtNode,
  expandDepth,
  onDeleteNode,
  onEditNode,
  onAddNode,
  onAddToken,
  onImportNodes,
  onChildHover,
  showAllAtNode
}) => {
  const { addSuccess } = useNotification();
  const [internalNode, setInternalNode] = useState<JsonTreeNodeData>(child);
  const [editingKey, setEditingKey] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [, setChildHovering] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const hoveringChildren = useRef<string[]>([]);
  const type = internalNode.subtype || internalNode.type;
  const isImage = internalNode.type === 'image';
  const TypeIcon = getTypeIconComponent(
    internalNode.type,
    internalNode.subtype
  );
  const { parentId } = internalNode;
  const showAllLink =
    !showAll &&
    count > showAllLimit + showAllThreshold &&
    !showAllAtNode.includes(parentId);

  const keyError =
    !internalNode.label &&
    internalNode.index == null &&
    internalNode.id !== 'root';
  const hasContext =
    editable &&
    hovering &&
    hoveringChildren.current?.length === 0 &&
    !isEditingNode;
  const canAdd =
    editable &&
    (internalNode.type === 'array' || internalNode.type === 'object') &&
    (hasContext || !internalNode.count);

  const editingKeyInput = useMemo(
    () => (
      <TokenEditor
        className={css.tokenEditor}
        value={internalNode.label}
        placeholder="key"
        autoFocus
        tokens={allowTokens ? tokens : []}
        allowFlowTrigger={false}
        allowExpressions={false}
        allowModifiers={false}
        blurOnEnter
        onChange={val => {
          setInternalNode({
            ...internalNode,
            label: val
          });
          setDirty(true);
        }}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          const targetClass = e.relatedTarget?.getAttribute('class');

          if (
            !targetClass ||
            (!targetClass.includes('showMenu') &&
              !targetClass.includes('Cascader') &&
              !targetClass.includes('SearchBar'))
          ) {
            setEditingKey(false);
            onEditingNode(false);
            if (!keyError && dirty) {
              onEditNode(internalNode);
            }
          }
        }}
      />
    ),
    [
      allowTokens,
      dirty,
      internalNode,
      keyError,
      onEditNode,
      onEditingNode,
      tokens
    ]
  );

  function getNodeText() {
    if (nodeRenderer) {
      return nodeRenderer(internalNode.data, editingValue);
    }

    if (editingValue) {
      return (
        <TokenEditor
          className={css.tokenEditor}
          value={internalNode.raw}
          placeholder="value"
          autoFocus
          tokens={allowTokens ? tokens : []}
          stores={stores}
          flows={flows}
          allowFlowTrigger={allowTokens}
          allowExpressions={allowTokens}
          blurOnEnter
          onChange={val => {
            setInternalNode({
              ...internalNode,
              data: val,
              raw: val
            });
            setDirty(true);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const targetClass = e.relatedTarget?.getAttribute('class');

            if (
              !targetClass ||
              (!targetClass.includes('showMenu') &&
                !targetClass.includes('Cascader') &&
                !targetClass.includes('SearchBar'))
            ) {
              setEditingValue(false);
              onEditingNode(false);
              if (!keyError && dirty) {
                onEditNode(internalNode);
              }
            }
          }}
        />
      );
    }

    if (internalNode.ellipsis) {
      return (
        <span
          className={css.ellpsisText}
          role="button"
          title={
            showAllAtNode.includes(internalNode.id)
              ? 'Click to collapse text'
              : 'Click to show all text'
          }
          tabIndex={-1}
          onClick={() => {
            if (showAllAtNode.includes(internalNode.id)) {
              onShowAllAtNode([
                ...showAllAtNode.filter(n => n !== internalNode.id)
              ]);
            } else {
              onShowAllAtNode([...showAllAtNode, internalNode.id]);
            }
          }}
        >
          {showAllAtNode.includes(internalNode.id) && (
            <span>
              {internalNode.data.toString()}&nbsp;
              <span className={css.ellipsis}>&#8672;</span>
            </span>
          )}
          {!showAllAtNode.includes(internalNode.id) && (
            <span>
              {internalNode.ellipsis}&nbsp;
              <span className={css.ellipsis}>...</span>
            </span>
          )}
        </span>
      );
    }

    return (
      <span>
        <TokenEditor
          className={css.editorValue}
          value={internalNode.data}
          tokens={tokens}
          stores={stores}
          flows={flows}
          disabled
        />
      </span>
    );
  }

  function renderImage() {
    return (
      <span
        tabIndex={-1}
        className={css.clicker}
        title={internalNode.raw}
        onClick={() => {
          const image = new Image();
          image.src = internalNode.raw;
          const w = window.open('');
          w.document.write(image.outerHTML);
        }}
      >
        <img height="100%" width="100%" src={internalNode.raw} alt="output" />
      </span>
    );
  }

  function renderExpandableNode() {
    let expandableLabel: string = '';
    if (internalNode.index !== undefined) {
      expandableLabel += internalNode.index.toLocaleString();
    } else if (internalNode.label !== '') {
      expandableLabel += internalNode.label;
    }

    return (
      <div
        className={classNames(css.nodeContainer, {
          [css.editable]: editable
        })}
        onClick={() => {
          if (
            editable &&
            internalNode.index == null &&
            internalNode.id !== 'root'
          ) {
            setEditingKey(true);
            onEditingNode(true);
          }
        }}
      >
        <span className={css.nodeLabel}>
          <ContextMenu
            disabled={
              !(
                allowCopy ||
                editable ||
                onUpdateData ||
                internalNode.id === 'root'
              )
            }
            content={
              <List>
                {allowCopy && (
                  <Fragment>
                    <ListItem
                      icon={<CopyIcon />}
                      onClick={() => {
                        copy(JSON.stringify(internalNode.raw, null, 2));
                        addSuccess(
                          `Copied "${
                            internalNode.label || internalNode.index
                          }" value to your clipboard`
                        );
                      }}
                    >
                      Copy Value
                    </ListItem>
                    <ListItem
                      icon={<LinkIcon />}
                      divider={false}
                      onClick={() => {
                        const path = internalNode.id.replace(
                          'root.',
                          pathPrefix || ''
                        );
                        copy(path);
                        addSuccess(`Copied "${path}" value to your clipboard`);
                      }}
                    >
                      Copy JSON Path
                    </ListItem>
                  </Fragment>
                )}
                {allowCopy && onUpdateData && <hr />}
                {editable && onUpdateData && (
                  <Fragment>
                    <ListItem
                      icon={<AddIcon />}
                      onClick={() => onAddNode(internalNode)}
                    >
                      Add Value
                    </ListItem>
                    <ListItem
                      icon={<ImportIcon />}
                      onClick={() => onImportNodes(internalNode)}
                    >
                      Import Values
                    </ListItem>
                    <ListItem
                      divider={internalNode.id !== 'root'}
                      icon={<DeleteIcon />}
                      onClick={() => onDeleteNode(internalNode)}
                    >
                      Delete Item
                    </ListItem>
                  </Fragment>
                )}
                {internalNode.id === 'root' && (
                  <ListItem
                    divider={false}
                    suffix={`${MODIFIER_KEY}+shift+q`}
                    icon={<FileIcon />}
                    onClick={() => onCopySchema()}
                  >
                    Copy JSON Schema
                  </ListItem>
                )}
                {(allowCopy || onUpdateData) && internalNode.id === 'root' && (
                  <hr />
                )}
                {internalNode.id === 'root' && (
                  <Fragment>
                    <ListItem
                      icon={<ExpandIcon />}
                      suffix={`${MODIFIER_KEY}+e`}
                      onClick={() => onExpandDepth(9999)}
                    >
                      Expand All Nodes
                    </ListItem>
                    <ListItem
                      icon={<CollapseIcon />}
                      suffix={`${MODIFIER_KEY}+shift+c`}
                      onClick={() => onExpandDepth(0)}
                    >
                      Collapse All Nodes
                    </ListItem>
                  </Fragment>
                )}
              </List>
            }
          >
            <Fragment>
              <code
                className={classNames(css.key, css.expandable, {
                  [css.error]: keyError && !editingKey,
                  [css.hoverable]: editable && internalNode.index == null
                })}
              >
                {!editingKey && <span>{expandableLabel}</span>}
                {editingKey && editingKeyInput}
              </code>
              {(showType === 'text' || !TypeIcon) && (
                <code className={classNames(css.type, css.expandableType)}>
                  {type}
                </code>
              )}
              {showType === 'icons' && TypeIcon && (
                <TypeIcon className={css.typeIcon} title={type} />
              )}
            </Fragment>
          </ContextMenu>
        </span>
        {showCount && (
          <Fragment>
            <span className={css.delimiter}>:</span>
            <span className={css.items}>
              {internalNode.count.toLocaleString()}{' '}
              <Pluralize
                singular={'item'}
                count={internalNode.count}
                showCount={false}
              />
            </span>
          </Fragment>
        )}
        {hasContext && !deleteConfirm && (
          <Button
            variant="text"
            className={css.deleteBtn}
            disableMargins
            onClick={e => {
              e.stopPropagation();
              setDeleteConfirm(true);
              setTimeout(() => setDeleteConfirm(false), 3000);
            }}
          >
            <DeleteIcon />
          </Button>
        )}
        {hasContext && deleteConfirm && (
          <Button
            variant="text"
            className={css.confirmBtn}
            disableMargins
            onClick={e => {
              e.stopPropagation();
              onDeleteNode(internalNode);
              setDeleteConfirm(false);
            }}
          >
            <ConfirmIcon />
          </Button>
        )}
      </div>
    );
  }

  function renderPrimativeNode() {
    let primitiveLabel = '';
    if (internalNode.index != null) {
      primitiveLabel = internalNode.index.toLocaleString();
    } else if (internalNode.label) {
      primitiveLabel = internalNode.label;
    } else {
      // primitiveLabel = 'Required';
    }

    return (
      <div
        className={classNames(css.nodeContainer, {
          [css.editable]: editable
        })}
      >
        <span
          className={css.nodeLabel}
          onClick={() => {
            if (
              editable &&
              internalNode.index == null &&
              internalNode.id !== 'root'
            ) {
              setEditingKey(true);
              onEditingNode(true);
            }
          }}
        >
          <ContextMenu
            disabled={!(allowCopy || onUpdateData)}
            content={
              <List>
                {allowCopy && (
                  <Fragment>
                    <ListItem
                      icon={<CopyIcon />}
                      onClick={() => {
                        copy(internalNode.raw);
                        addSuccess(
                          `Copied "${
                            internalNode.label || internalNode.index
                          }" value to your clipboard`
                        );
                      }}
                    >
                      Copy Value
                    </ListItem>
                    <ListItem
                      icon={<LinkIcon />}
                      divider={false}
                      onClick={() => {
                        const path = internalNode.id.replace(
                          'root.',
                          pathPrefix || ''
                        );
                        copy(path);
                        addSuccess(`Copied "${path}" value to your clipboard`);
                      }}
                    >
                      Copy JSON Path
                    </ListItem>
                  </Fragment>
                )}
                {allowCopy && onUpdateData && <hr />}
                {editable && onUpdateData && (
                  <Fragment>
                    <ListItem
                      divider={false}
                      icon={<DeleteIcon />}
                      onClick={() => onDeleteNode(internalNode)}
                    >
                      Delete Node
                    </ListItem>
                  </Fragment>
                )}
              </List>
            }
          >
            <Fragment>
              <code
                className={classNames(css.key, {
                  [css.error]: keyError && !editingKey,
                  [css.hoverable]: editable && internalNode.index == null
                })}
              >
                {!editingKey && (
                  <span>
                    <TokenEditor
                      className={css.editorKey}
                      value={primitiveLabel}
                      disabled
                    />
                  </span>
                )}
                {editingKey && editingKeyInput}
              </code>
              {(showType === 'text' || !TypeIcon) && (
                <code className={css.type}>{type}</code>
              )}
              {showType === 'icons' && TypeIcon && (
                <TypeIcon className={css.typeIcon} title={type} />
              )}
            </Fragment>
          </ContextMenu>
        </span>
        <span className={css.delimiter}>:</span>
        <code
          className={classNames(css.value, {
            [css.hoverable]: editable && !isImage
          })}
          onClick={() => {
            if (editable && !isImage) {
              setEditingValue(true);
              onEditingNode(true);
            }
          }}
        >
          {!isImage && getNodeText()}
          {isImage && renderImage()}
          {hasContext && !deleteConfirm && (
            <Button
              variant="text"
              className={css.deleteBtn}
              disableMargins
              onClick={e => {
                e.stopPropagation();
                setDeleteConfirm(true);
                setTimeout(() => setDeleteConfirm(false), 3000);
              }}
            >
              <DeleteIcon />
            </Button>
          )}
          {hasContext && deleteConfirm && (
            <Button
              variant="text"
              className={css.confirmBtn}
              disableMargins
              onClick={e => {
                e.stopPropagation();
                onDeleteNode(internalNode);
                setDeleteConfirm(false);
              }}
            >
              <ConfirmIcon />
            </Button>
          )}
        </code>
      </div>
    );
  }

  useEffect(() => {
    if (onChildHover) {
      onChildHover(hovering, internalNode.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovering, internalNode.id]);

  useEffect(() => {
    setInternalNode(child);
    setDirty(false);
  }, [child]);

  if (depth > 1 && showAllLink && index === showAllLimit) {
    return (
      <TreeNode
        key={`show-all-${internalNode.id}`}
        label={
          <span
            className={css.showAllLink}
            onClick={() => onShowAllAtNode([...showAllAtNode, parentId])}
          >
            Show All ({count - showAllLimit} more)
          </span>
        }
      />
    );
  } else if (depth > 1 && showAllLink && index > showAllLimit) {
    return null;
  }

  return (
    <TreeNode
      expanded={expandDepth !== undefined ? depth <= expandDepth : true}
      label={
        <span>
          {(internalNode.type === 'array' || internalNode.type === 'object') &&
            renderExpandableNode()}
          {PRIMATIVE_TYPES.includes(internalNode.type) && renderPrimativeNode()}
        </span>
      }
      onHover={setHovering}
      contextBar={canAdd}
    >
      {canAdd && (
        <EditBar
          allowTokens={allowTokens}
          tokens={tokens}
          stores={stores}
          flows={flows}
          onAddToken={token => onAddToken(internalNode, token)}
          onAdd={() => onAddNode(internalNode)}
          onImport={() => onImportNodes(internalNode)}
        />
      )}
      {internalNode.count! > 0 && (
        <Fragment>
          {internalNode.data.map((c: JsonTreeNodeData, i: number) => (
            <JsonTreeNode
              key={`node-${c.id}`}
              child={c}
              depth={depth + 1}
              editable={editable}
              showType={showType}
              pathPrefix={pathPrefix}
              showCount={showCount}
              allowCopy={allowCopy}
              allowTokens={allowTokens}
              tokens={tokens}
              stores={stores}
              flows={flows}
              showAll={showAll}
              nodeRenderer={nodeRenderer}
              showAllLimit={showAllLimit}
              showAllThreshold={showAllThreshold}
              count={internalNode.data.length}
              index={i}
              expandDepth={expandDepth}
              isEditingNode={isEditingNode}
              onEditingNode={onEditingNode}
              onChildHover={(isHovering, childId) => {
                if (
                  isHovering &&
                  !hoveringChildren.current?.includes(childId)
                ) {
                  hoveringChildren.current = [
                    ...hoveringChildren.current,
                    childId
                  ];
                } else if (!isHovering && hoveringChildren.current) {
                  hoveringChildren.current = hoveringChildren.current.filter(
                    h => h !== childId
                  );
                }

                setChildHovering(isHovering);
              }}
              onUpdateData={data => {
                onUpdateData(data);
                hoveringChildren.current = [];
              }}
              onCopySchema={onCopySchema}
              onDeleteNode={node => {
                onDeleteNode(node);
                setHovering(false);
                setChildHovering(false);
                hoveringChildren.current = hoveringChildren.current.filter(
                  h => h !== node.id
                );
              }}
              onAddNode={onAddNode}
              onAddToken={onAddToken}
              onImportNodes={onImportNodes}
              onShowAllAtNode={onShowAllAtNode}
              onExpandDepth={onExpandDepth}
              showAllAtNode={showAllAtNode}
              onEditNode={onEditNode}
            />
          ))}
        </Fragment>
      )}
    </TreeNode>
  );
};
