import { artifactsHelpers } from './artifactsHelpers';
import { arrayHelpers } from './arrayHelpers';
import { dateHelpers } from './dateHelpers';
import { stringHelpers } from './stringHelpers';
import { urlHelpers } from './urlHelpers';
import { numberHelpers } from './numberHelpers';
import { mathHelpers } from './mathHelpers';
import { logicHelpers } from './logicHelpers';
import { emailHelpers } from './emailHelpers';
import { domainHelpers } from './domainHelpers';
import { expressionHelpers } from './expressionHelpers';
import { geoHelpers } from './geoHelpers';

export interface Helpers {
  label: string;
  options: Helper[];
}

export interface Helper {
  name: string;
  label: string;
  description?: string;
  valueType?: string;
  returnType?: string;
  returnSubType?: string;
  params?: any;
  options?: any;
  invariant?: boolean;
  examples?: string[];
  reference?: string;
}

export const helpers: Helpers[] = [
  {
    label: 'Array',
    options: arrayHelpers
  },
  {
    label: 'Artifacts',
    options: artifactsHelpers
  },
  {
    label: 'Date',
    options: dateHelpers
  },
  {
    label: 'Domain',
    options: domainHelpers
  },
  {
    label: 'Email',
    options: emailHelpers
  },
  {
    label: 'Expression',
    options: expressionHelpers
  },
  {
    label: 'Geo',
    options: geoHelpers
  },
  {
    label: 'Logic',
    options: logicHelpers
  },
  {
    label: 'Math',
    options: mathHelpers
  },
  {
    label: 'Number',
    options: numberHelpers
  },
  {
    label: 'String',
    options: stringHelpers
  },
  {
    label: 'URL',
    options: urlHelpers
  }
];
