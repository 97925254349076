import { axios } from 'core/Axios';
import {
  UsageAllActionsParams,
  UsageAllActionsResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.ORG_ALL_ACTIONS;
export const buildQueryKey = (params?: UsageAllActionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: UsageAllActionsParams
): Promise<UsageAllActionsResponse> => {
  return axios
    .get<UsageAllActionsResponse>(Route.ORG_ALL_ACTIONS, { params })
    .then(data => data.data);
};

export const useAllActions = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<UsageAllActionsResponse, UsageAllActionsParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
