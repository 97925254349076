import isString from 'lodash/isString';

export const getConnectionsForAction = (pkg, action, connections: any[]) => {
  const packageConnections = pkg?.connections || [];

  let connectionMetas = [];
  if (action?.packageConnectionIds?.length && packageConnections?.length) {
    connectionMetas = packageConnections
      ?.filter(c => action.packageConnectionIds.includes(c.id))
      .map(c => ({
        ...c,
        input: isString(c.input) ? JSON.parse(c.input) : c.input,
        optionalConnection: action?.optionalConnection
      }));
  }

  const requiresConnection = connectionMetas.length > 0;

  const availableConnections = requiresConnection
    ? connections.filter(
        connection =>
          connectionMetas.find(
            nodeConnection =>
              nodeConnection.id === connection.packageConnectionId
          ) !== undefined
      )
    : [];

  return {
    requiresConnection,
    connectionMetas,
    availableConnections
  };
};
