import React, { FC, Fragment, Ref, useCallback, useMemo } from 'react';
import { TokenOption } from '../types';
import { motion } from 'framer-motion';
import { Cascader } from 'shared/data/Cascader';
import { CascaderItem } from 'shared/data/Cascader/types';
import { Datastore, Flow } from 'core/types/API';
import { buildTokenTree, transformTokens } from './utils';
import css from './TokenCascader.module.css';
import { Block } from 'shared/layout/Block';
import { DatastorePreview } from 'shared/internal/Datastore';
import classNames from 'classnames';

export type TokenCascaderProps = {
  tokens: TokenOption[];
  keyword?: string;
  style?: React.CSSProperties;
  allowModifiers?: boolean;
  allowFlowTrigger?: boolean;
  allowExpressions?: boolean;
  className?: string;
  supplementalData?: { stores?: Datastore[]; flows?: Flow[] };
  panelRef?: Ref<HTMLDivElement>;
  onHome?: () => void;
  onSelectToken?: (token: TokenOption) => void;
  onInsertModifier?: (token: TokenOption) => void;
  onInsertExpression?: () => void;
};

export const TokenCascader: FC<TokenCascaderProps> = ({
  tokens,
  keyword,
  className,
  allowModifiers = true,
  allowFlowTrigger = true,
  allowExpressions = true,
  supplementalData = {},
  onHome = () => undefined,
  onSelectToken = () => undefined,
  onInsertExpression = () => undefined,
  onInsertModifier = () => undefined,
  ...rest
}) => {
  const additionalActions = allowModifiers
    ? [
        {
          id: 'modifer',
          text: 'Add Modifier',
          onClick: onInsertModifier
        }
      ]
    : [];

  const getAdditionalDetails = useCallback(
    (item: CascaderItem) => {
      const store = supplementalData.stores?.find(s => s.id === item.id);

      if (store) {
        return (
          <Fragment>
            {/* <Block label="Datastore Type">{store.type.toLowerCase()}</Block> */}
            <Block label="Preview" className={css.datastoreValue}>
              <DatastorePreview value={store.value} header={false} />
            </Block>
          </Fragment>
        );
      }

      return null;
    },
    [supplementalData.stores]
  );

  const items = useMemo(() => {
    return keyword
      ? transformTokens(tokens as CascaderItem[])
      : buildTokenTree(tokens, {
          allowFlowTrigger,
          allowExpressions,
          onInsertExpression
        });
  }, [keyword, tokens, allowFlowTrigger, allowExpressions, onInsertExpression]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={classNames(css.menu, className)}
    >
      <Cascader
        {...rest}
        items={items}
        keyword={keyword}
        additionalActions={additionalActions}
        additionalDetail={getAdditionalDetails}
        onSelection={onSelectToken}
        onHome={onHome}
      />
    </motion.div>
  );
};
