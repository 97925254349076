import { JsonTreeNodeData } from './JsonTreeNode';
import isDate from 'lodash/isDate';
import {
  isSensitiveKey,
  isSensitiveValue,
  obfuscateText
} from '@getcrft/janitor';
import orderBy from 'lodash/orderBy';
import isImageUrl from 'is-image-url';
import { matchType, isBase64Image } from '@getcrft/jsonata-ext';

export function getType(data: any) {
  const dataType = typeof data;

  if (data === null || data === 'null') {
    return 'null';
  } else if (Array.isArray(data)) {
    return 'array';
  } else if (isDate(data)) {
    return 'date';
  } else if (dataType === 'string') {
    // TODO: Improve the cache on this check
    if (isImageUrl(data)) {
      return 'image';
    } else if (isBase64Image(data)) {
      return 'image';
    }
  }

  return dataType;
}

export function parseJson(
  data: any,
  label = '',
  config,
  nodeId = 'root',
  index?,
  parentId?
) {
  const {
    showEmpty = true,
    showExtendedTypes = true,
    maskSensitive = false,
    ellipsisText,
    ellipsisTextLength
  } = config;

  const type = getType(data);
  label = label === undefined ? type : label;

  switch (type) {
    case 'object':
      const keys = Object.keys(data);
      const parsedData = keys.reduce((parsedItems, key, idx) => {
        const value = data[key];
        const parsed = parseJson(
          value,
          key,
          config,
          `${nodeId}.${key}`,
          undefined,
          nodeId
        );

        if (showEmpty || (!showEmpty && parsed !== null)) {
          parsedItems.push(parsed);
        }

        return parsedItems;
      }, [] as JsonTreeNodeData[]);

      if (showEmpty || (!showEmpty && parsedData.length > 0)) {
        const sortedData = orderBy(parsedData, m => m.label?.toLowerCase());

        return {
          type,
          id: nodeId,
          data: sortedData,
          raw: data,
          count: parsedData.length,
          label,
          index,
          parentId
        } as JsonTreeNodeData;
      }

      return null;
    case 'array':
      // JSONata array indexes are not in dot notation
      const parsed = data.map((d, idx) =>
        parseJson(d, '', config, `${nodeId}[${idx}]`, idx, nodeId)
      );

      if (parsed !== null) {
        return {
          type,
          id: nodeId,
          data: parsed,
          raw: data,
          count: parsed.length,
          index,
          label,
          parentId
        } as JsonTreeNodeData;
      }

      return null;
    case 'string':
      if (showEmpty || (!showEmpty && data.length > 0)) {
        const subtype = showExtendedTypes
          ? matchType(data, { strict: true })?.[0]
          : undefined;

        const obfuscated =
          maskSensitive === true
            ? isSensitiveValue(data) || isSensitiveKey(label)
            : false;

        let value = data.length === 0 ? '""' : data;
        if (obfuscated) {
          value = obfuscateText(value);
        }

        const ellipsis =
          ellipsisText && value.length > ellipsisTextLength
            ? value.substring(0, ellipsisTextLength)
            : null;

        return {
          type: 'string',
          id: nodeId,
          raw: data,
          data: value,
          obfuscated,
          ellipsis,
          label,
          subtype,
          index,
          parentId
        } as JsonTreeNodeData;
      }

      return null;
    case 'null':
      if (showEmpty) {
        return {
          type: 'string',
          id: nodeId,
          raw: data,
          data: 'null',
          label,
          index,
          parentId
        } as JsonTreeNodeData;
      }

      return null;
    case 'image':
      return {
        type: 'image',
        id: nodeId,
        raw: data,
        data: data.data,
        label,
        index,
        parentId,
        // TODO: Improve the cache on this check
        subtype: isImageUrl(data) ? 'urlImage' : 'base64Image'
      } as JsonTreeNodeData;
    default:
      return {
        type,
        id: nodeId,
        raw: data,
        data,
        label,
        index,
        parentId
      } as JsonTreeNodeData;
  }
}

export function stringifyTree(treeNodes: JsonTreeNodeData[]) {
  return treeNodes.map(({ type, label, data }) => {
    const key = label ? `"${label}":` : '';
    const dataIsNull = data === '""';

    switch (type) {
      case 'object':
        return `${key}{${stringifyTree(data)}}`;
      case 'array':
        return `${key}[${stringifyTree(data)}]`;
      case 'number':
      case 'boolean':
        return dataIsNull ? `${key}null` : `${key}${data}`;
      default:
        return dataIsNull
          ? `${key}null`
          : `${key}"${data.replaceAll('"', '\\"')}"`;
    }
  });
}
