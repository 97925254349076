import { OktaAuth } from '@okta/okta-auth-js';
import { CLIENT_ID, ISSUER, REDIRECT_URI, SCOPES } from './config';

export const oktaAuth = new OktaAuth({
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES,
  pkce: true,
  // Reference: https://github.com/okta/okta-auth-js#autorenew
  tokenManager: {
    expireEarlySeconds: 120
  }
});
