import { axios } from 'core/Axios';
import {
  DataDelDatastoreParams,
  DataDelDatastoreResponse,
  DataListDatastoresResponse
} from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_DATASTORE;

export const req = async (
  params: DataDelDatastoreParams
): Promise<DataDelDatastoreResponse> => {
  return axios
    .delete<DataDelDatastoreResponse>(Route.DEL_DATASTORE, { params })
    .then(data => data.data);
};

export const useDelDatastore = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<
  DataDelDatastoreResponse,
  DataDelDatastoreParams
> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    async onMutate(params) {
      if (options.onMutate) {
        return options.onMutate(params);
      }

      await client.cancelQueries(Route.LIST_DATASTORES);
      const previousData = client.getQueriesData(Route.LIST_DATASTORES);
      client.setQueriesData(
        Route.LIST_DATASTORES,
        (oldData: DataListDatastoresResponse) => {
          oldData.items = oldData.items.filter(item => item.id !== params.id);
          return oldData;
        }
      );
      return previousData;
    },
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_DATASTORES);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
