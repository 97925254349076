import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import isString from 'lodash/isString';
import emptyIcon from 'assets/svg/empty.svg';

type ImgProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src'
> & {
  src: string | ReactNode;
  alt?: string;
};

export const Img: FC<ImgProps> = ({ src, alt, ...rest }) => {
  const [imgSrc, setImgSrc] = useState<string | ReactNode>(src);
  const imgRef = useRef<HTMLImageElement>();

  useEffect(() => {
    if (imgSrc === null || imgSrc === undefined || imgSrc === '') {
      setImgSrc(emptyIcon);
    }
  }, [imgSrc]);

  if (!isString(imgSrc)) {
    const Component = imgSrc as any;
    return <Component {...rest} />;
  }

  return (
    <img
      {...rest}
      alt={alt}
      ref={imgRef}
      src={imgSrc}
      onLoad={() => {
        if (imgRef.current?.clientWidth === 0) {
          // Broken image
          setImgSrc('');
        }
      }}
      onError={() => {
        setImgSrc('');
      }}
    />
  );
};
