export const getType = (data: any) => {
  const dataType = typeof data;

  if (data == null) {
    return 'null';
  } else if (Array.isArray(data)) {
    return 'array';
  }

  return dataType;
};
