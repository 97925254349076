import React, {
  forwardRef,
  Ref,
  useCallback,
  useState,
  useEffect,
  FC
} from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { Button } from 'shared/elements/Button';
import css from './Card.module.css';

export type CardVariant = 'default' | 'secondary' | 'border';

export type CardProps = React.DOMAttributes<{}> & {
  disablePadding?: boolean;
  showDismissButton?: boolean;
  dismissKey?: string;
  className?: string;
  style?: React.CSSProperties;
  header?: string | JSX.Element | JSX.Element[];
  subtext?: string;
  variant?: CardVariant;
  onDismiss?: () => void;
};

export const Card: FC<CardProps & { ref?: Ref<HTMLDivElement> }> = forwardRef(
  (
    {
      variant = 'default',
      children,
      disablePadding,
      className,
      header,
      subtext,
      showDismissButton,
      onDismiss,
      dismissKey,
      ...rest
    }: CardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [visible, setVisible] = useState<boolean | null>(
      dismissKey ? null : true
    );

    useEffect(() => {
      if (dismissKey) {
        const storeVal = window.localStorage.getItem(`DISMISS_${dismissKey}`);
        setVisible(storeVal !== 'false');
      }
    }, [dismissKey]);

    const onDismissClick = useCallback(() => {
      if (dismissKey) {
        window.localStorage.setItem(`DISMISS_${dismissKey}`, 'false');
      }

      setVisible(false);
      onDismiss?.();
    }, [onDismiss, dismissKey]);

    if (!visible) {
      return null;
    }

    return (
      <div
        {...rest}
        ref={ref}
        className={classNames(className, css.container, {
          [css.disablePadding]: disablePadding,
          [css.default]: variant === 'default',
          [css.secondary]: variant === 'secondary',
          [css.border]: variant === 'border'
        })}
      >
        {header && (
          <h4
            className={classNames(css.header, {
              [css.withSubtext]: subtext
            })}
          >
            <span className={css.headerText}>{header}</span>
            {showDismissButton && (
              <Button
                title="Dismiss"
                className={css.dismissBtn}
                variant="text"
                onClick={onDismissClick}
              >
                <CloseIcon />
              </Button>
            )}
          </h4>
        )}
        {subtext && <span className={css.subtext}>{subtext}</span>}
        <div className={css.content}>
          <div className={css.contentBody}>{children}</div>
          {showDismissButton && !header && (
            <Button
              title="Dismiss"
              className={css.dismissBtn}
              variant="text"
              onClick={onDismissClick}
            >
              <CloseIcon />
            </Button>
          )}
        </div>
      </div>
    );
  }
);
