import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { StoreValueType } from 'core/types/API';
// import {
//   buildSelectOptions,
//   getAsyncContext,
//   getUIAttribute,
//   validateTokenSelectOption
// } from './utils';
import { ArrayInput } from './ArrayInput';
import { DataStoreInput } from './DataStoreInput';
import { UploadsListInputContainer } from 'shared/form/Upload/UploadsListInput';
import { TokenEditor, TokenOption } from 'shared/form/TokenEditor';
import { Toggle } from 'shared/form/Toggle';
import { CronInput } from 'shared/form/CronInput';
import { CodeEditor, Suggestion } from 'shared/form/CodeEditor';
import { KeyValues, strategies } from 'shared/form/KeyValues';
import orderBy from 'lodash/orderBy';
import { Input } from 'shared/form/Input';
import { AsyncSelectContainer } from './AsyncSelectInput';
import { TemplateBox } from './TemplateBox';
import { DateTimeInput, DateTimeType } from 'shared/form/DateTimeInput';
import { OAuthInputContainer } from './OAuthInput';
import { ConditionInput } from 'shared/form/ConditionInput';
import css from './JsonSchemaForm.module.css';
import { BranchInput } from '../BranchInput';
import { JsonTree } from 'shared/data/Tree';
import { buildSelectOptions, validateTokenSelectOption } from './utils/token';
import { getAsyncContext, getUIAttribute } from './utils/form';

export type FormFieldProps = {
  tokens: TokenOption[];
  allTokens: TokenOption[];
  placeholder: string;
  disabled: boolean;
  allowModifiers: boolean;
  allowExpressions: boolean;
  allowFlowTrigger: boolean;
  singleSelection: boolean;
  value: any;
  hasError: boolean;
  property: string;
  field: any;
  context: any;
  flowId?: string;
  dependencies: any;
  flows: any[];
  stores: any[];
  setValues: (values: any) => void;
  setFieldError: (field: string, value: string | undefined) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  onPortUpdate: (conditionId: string, toAdd: boolean) => void;
};

const TokenField: FC<FormFieldProps> = ({
  property,
  singleSelection,
  setFieldTouched,
  setFieldValue,
  ...rest
}) => {
  // TODO: This is a hack to improve typing performance
  const lastValRef = useRef<string | null>(null);

  return (
    <TokenEditor
      className={css.editor}
      {...rest}
      singleSelection={singleSelection}
      onBlur={() => {
        setFieldTouched(property, true);
        if (lastValRef.current !== null) {
          setFieldValue(property, lastValRef.current);
          lastValRef.current = null;
        }
      }}
      onAddModifier={v => {
        // This is required because of the last val ref hack
        setFieldValue(property, v);
      }}
      onChange={v => {
        lastValRef.current = v;
        if (singleSelection) {
          setFieldValue(property, v);
        }
        // setFieldValue(property, v)
      }}
    />
  );
};

const BooleanField: FC<FormFieldProps> = ({
  value,
  property,
  disabled,
  setFieldValue,
  setFieldTouched
}) => {
  const onChange = useCallback(
    v => setFieldValue(property, v),
    [property, setFieldValue]
  );

  const onBlur = useCallback(
    () => setFieldTouched(property, true),
    [property, setFieldTouched]
  );

  return (
    <Toggle
      checked={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

/*
const JsonField: FC<FormFieldProps> = ({ value, property, setFieldValue }) => (
  <Card disablePadding>
    <JsonTree
      data={value}
      onUpdateData={data => setFieldValue(property, data)}
    />
  </Card>
);
*/

const KeyValueField: FC<FormFieldProps & { strategy?: any }> = ({
  property,
  field,
  tokens,
  setFieldValue,
  strategy = strategies.object,
  ...rest
}) => {
  const options = useMemo(
    () =>
      getUIAttribute(field.attributes, 'ui:key-options', []).map(k => ({
        text: k,
        value: k
      })),
    [field.attributes]
  );

  const keyType = getUIAttribute(field.attributes, 'ui:kv-key-type', 'text');
  const valueType = getUIAttribute(
    field.attributes,
    'ui:kv-value-type',
    'text'
  );

  const onChange = useCallback(
    v => setFieldValue(property, v),
    [property, setFieldValue]
  );

  return (
    <KeyValues
      {...rest}
      keyType={keyType}
      valueType={valueType}
      strategy={strategy}
      keyOptions={options}
      valueOptions={tokens}
      onChange={onChange}
    />
  );
};

const KeyValueListField: FC<FormFieldProps> = props => (
  <KeyValueField {...props} strategy={strategies.array} />
);

const CronField: FC<FormFieldProps> = ({
  property,
  setFieldValue,
  setFieldTouched,
  ...rest
}) => {
  const onChange = useCallback(
    v => setFieldValue(property, v),
    [property, setFieldValue]
  );

  const onBlur = useCallback(
    () => setFieldTouched(property, true),
    [property, setFieldTouched]
  );

  return <CronInput {...rest} onChange={onChange} onBlur={onBlur} />;
};

const PasswordField: FC<FormFieldProps> = ({
  property,
  setFieldValue,
  setFieldTouched,
  placeholder,
  disabled,
  value,
  hasError
}) => (
  <Input
    type="password"
    error={hasError}
    disabled={disabled}
    value={value}
    fullWidth={true}
    placeholder={placeholder}
    autoCorrect="false"
    autoCapitalize="false"
    autoComplete="false"
    onBlur={() => setFieldTouched(property, true)}
    onChange={event => setFieldValue(property, event.target.value?.trim())}
  />
);

const TextField: FC<FormFieldProps> = ({
  property,
  setFieldValue,
  setFieldTouched,
  value,
  ...rest
}) => (
  <Input
    type="text"
    fullWidth={true}
    autoCorrect="false"
    autoCapitalize="false"
    autoComplete="false"
    {...rest}
    value={value}
    onBlur={event => {
      setFieldTouched(property, true);
      setFieldValue(property, event.target.value?.trim());
    }}
    onChange={event => setFieldValue(property, event.target.value)}
  />
);

const ArrayField: FC<FormFieldProps & { valuesOverride: any[] }> = ({
  property,
  setFieldValue,
  setFieldTouched,
  ...rest
}) => (
  <ArrayInput
    {...rest}
    property={property}
    onChange={value => setFieldValue(property, value)}
    onBlur={() => setFieldTouched(property, true)}
  />
);

const RemoteSelectField: FC<FormFieldProps> = ({
  property,
  dependencies,
  field,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  ...rest
}) => {
  const asyncContext = useMemo(
    () => getAsyncContext(field.attributes),
    [field.attributes]
  );
  return (
    <AsyncSelectContainer
      {...rest}
      multiple={field.type === 'array'}
      dependenciesContext={dependencies}
      asyncContext={asyncContext}
      onMarkInvalid={message => setFieldError(property, message)}
      onChange={value => setFieldValue(property, value)}
      onBlur={() => setFieldTouched(property, true)}
    />
  );
};

const DatastoreField: FC<
  FormFieldProps & { type?: StoreValueType; isObject?: boolean }
> = ({
  context,
  property,
  type,
  isObject,
  setFieldValue,
  setFieldTouched,
  ...rest
}) => (
  <DataStoreInput
    {...rest}
    isObject={isObject}
    type={type}
    flowId={context?.flow?.id}
    property={property}
    onBlur={() => setFieldTouched(property, true)}
    onChange={value => setFieldValue(property, value)}
  />
);

const DatastoreSingleField: FC<FormFieldProps> = props => (
  <DatastoreField {...props} type={StoreValueType.Single} />
);

const DatastoreListField: FC<FormFieldProps> = props => (
  <DatastoreField {...props} type={StoreValueType.List} />
);

const DatastoreObjectField: FC<FormFieldProps> = props => (
  <DatastoreField {...props} type={StoreValueType.Single} isObject />
);

const JsonTreeField: FC<FormFieldProps> = ({
  field,
  property,
  value,
  disabled,
  tokens,
  setFieldValue,
  ...rest
}) => {
  let initialValue;
  const type: string =
    getUIAttribute(field.attributes, 'ui:datastoreType', '') ||
    getUIAttribute(field.attributes, 'ui:treeType', '');

  if (value) {
    initialValue = value;
  } else if (type === 'array') {
    initialValue = [];
  } else if (type === 'object') {
    initialValue = {};
  } else {
    initialValue = '';
  }

  const [internalValue, setInternalValue] = useState<
    any[] | { [key: string]: any }
  >(initialValue);

  return (
    <JsonTree
      {...rest}
      data={internalValue}
      editable={!disabled}
      type={type}
      showCount
      showEmpty
      showType="icons"
      allowTokens
      tokens={tokens}
      onUpdateData={data => {
        setInternalValue(data);
        setFieldValue(property, data);
      }}
    />
  );
};

const FlowsInput: FC<FormFieldProps & { flows: any[] }> = ({
  flows,
  context,
  ...rest
}) => {
  const options = useMemo(() => {
    // You can't use the current Flow for Flow inputs
    const filteredFlows = flows.filter(f => f.id !== context?.flow?.id);

    return orderBy([...filteredFlows], [m => m.name?.toLowerCase()]).map(f => ({
      label: f.name,
      value: f.id
    }));
  }, [context?.flow?.id, flows]);

  return (
    <ArrayField
      {...rest}
      flows={flows}
      context={context}
      valuesOverride={options}
    />
  );
};

const FilesField: FC<FormFieldProps> = ({
  setFieldValue,
  property,
  field,
  ...rest
}) => {
  const allowedTypes = getUIAttribute(
    field.attributes,
    'ui:allowedTypes',
    null
  );

  return (
    <UploadsListInputContainer
      {...rest}
      allowedTypes={allowedTypes}
      onChange={uploadIds => setFieldValue(property, uploadIds)}
    />
  );
};

const FileField: FC<FormFieldProps> = ({
  value,
  property,
  field,
  setFieldValue
}) => {
  const allowedTypes = getUIAttribute(
    field.attributes,
    'ui:allowedTypes',
    null
  );

  return (
    <UploadsListInputContainer
      value={value ? [value] : []}
      isMultiple={false}
      allowedTypes={allowedTypes}
      onChange={ids => setFieldValue(property, ids.pop())}
    />
  );
};

const CodeField: FC<
  FormFieldProps & { language?: string; suggestions?: Suggestion[] }
> = ({
  property,
  value,
  disabled,
  setFieldValue,
  setFieldTouched,
  hasError,
  suggestions,
  language = 'javascript'
}) => {
  // TODO: This is a hack to improve typing performance
  const lastValRef = useRef<string | null>(null);

  return (
    <div className={css.code}>
      <CodeEditor
        disabled={disabled}
        value={value?.toString()}
        language={language}
        suggestions={suggestions}
        error={hasError}
        onBlur={() => {
          setFieldTouched(property, true);
          if (lastValRef.current !== null) {
            setFieldValue(property, lastValRef.current);
            lastValRef.current = null;
          }
        }}
        onChange={value => {
          lastValRef.current = value;
          // setFieldValue(property, v);
        }}
      />
    </div>
  );
};

const CodeJsonataField: FC<FormFieldProps> = ({ tokens, ...rest }) => {
  const suggestions: Suggestion[] = useMemo(
    () =>
      tokens.map(t => ({
        label: `${t.group} ‣ ${t.text}`,
        value: t.value,
        detail: t.value,
        documentation: t.description
      })),
    [tokens]
  );

  return (
    <CodeField
      {...rest}
      tokens={tokens}
      language="jsonata"
      suggestions={suggestions}
    />
  );
};

const CodeTextField: FC<FormFieldProps> = props => (
  <CodeField {...props} language="jsonata" />
);

const CodeJsonField: FC<FormFieldProps> = props => (
  <CodeField {...props} language="json" />
);

const CodeGraphqlField: FC<FormFieldProps> = props => (
  <CodeField {...props} language="graphql" />
);

const SelectAllTokensField: FC<FormFieldProps & { fieldsFilter: any }> = ({
  tokens,
  fieldsFilter,
  ...rest
}) => {
  const options = useMemo(() => {
    const selectOptions = buildSelectOptions(tokens, null, fieldsFilter);

    // The select options need to be wrapped in {{}} to be parsed
    return selectOptions.map(o => ({
      ...o,
      value: `{{${o.value}}}`
    }));
  }, [fieldsFilter, tokens]);
  return <ArrayField {...rest} tokens={tokens} valuesOverride={options} />;
};

const SelectFieldTokensField: FC<FormFieldProps & { fieldsFilter: any }> = ({
  tokens,
  fieldsFilter,
  ...rest
}) => {
  const options = useMemo(() => {
    const validTokens = rest.allTokens.filter(t =>
      validateTokenSelectOption(t, fieldsFilter)
    );

    // passing null instead of 'node' here so we have access to things outside of just the parent outputs
    // like all datastores
    const selectOptions = buildSelectOptions(validTokens, null, fieldsFilter);

    // The select options need to be wrapped in {{}} to be parsed
    return selectOptions.map(o => ({
      ...o,
      value: `{{${o.value}}}`
    }));
  }, [fieldsFilter, rest.allTokens]);
  return <ArrayField {...rest} tokens={tokens} valuesOverride={options} />;
};

const TimeField: FC<FormFieldProps> = ({
  disabled,
  value,
  property,
  placeholder,
  setFieldValue,
  setFieldTouched
}) => (
  <Input
    type="time"
    disabled={disabled}
    placeholder={placeholder}
    fullWidth={true}
    value={value}
    onChange={event => setFieldValue(property, event.target.value)}
    onBlur={() => setFieldTouched(property, true)}
  />
);

const DateTimeField: FC<FormFieldProps & { type?: DateTimeType }> = ({
  value,
  disabled,
  type = 'date-time',
  property,
  setFieldValue,
  setFieldTouched
}) => (
  <DateTimeInput
    value={value}
    disabled={disabled}
    type={type}
    onBlur={() => setFieldTouched(property, true)}
    onChange={newValue => setFieldValue(property, newValue)}
  />
);

const DateField: FC<FormFieldProps> = props => (
  <DateTimeField {...props} type="date" />
);

const OAuthField: FC<FormFieldProps> = ({ field, context, setValues }) => (
  <OAuthInputContainer
    url={field.default}
    connection={context.connection}
    onChange={setValues}
  />
);

const ConditionField: FC<FormFieldProps & { fieldsFilter: any }> = ({
  property,
  disabled,
  fieldsFilter,
  tokens,
  allTokens,
  field,
  value,
  allowFlowTrigger,
  allowExpressions,
  allowModifiers,
  stores,
  flows,
  setFieldValue,
  onPortUpdate
}) => {
  const conditionType = getUIAttribute(
    field.attributes,
    'ui:condition-type',
    'complex'
  );

  const allowMultiple = getUIAttribute(
    field.attributes,
    'ui:condition-multiple',
    true
  );

  const conditionRule = getUIAttribute(
    field.attributes,
    'ui:condition-rule',
    null
  );

  const options = useMemo(() => {
    // TODO: This is kinda hacky piggy backing on
    // on the fields filter to do this. Should expand
    // conditions where you can pass an enum
    if (fieldsFilter === 'enum') {
      return field.items.enums || field.items.enum;
    } else {
      return tokens;
    }
  }, [fieldsFilter, tokens, field]);

  const onChange = useCallback(
    v => setFieldValue(property, v),
    [property, setFieldValue]
  );

  // NOTE: The `undefined` value below is to prevent nulls being passed
  return (
    <ConditionInput
      disabled={disabled}
      value={value || undefined}
      allowComplex={conditionType === 'complex'}
      allowMultiple={allowMultiple}
      allowTrigger={allowFlowTrigger}
      allowExpressions={allowExpressions}
      allowModifiers={allowModifiers}
      fieldOptions={options}
      valueOptions={allTokens}
      operatorOptions={field.operators}
      inputType={fieldsFilter}
      conditionRule={conditionRule}
      stores={stores}
      flows={flows}
      onChange={onChange}
      onPortUpdate={onPortUpdate}
    />
  );
};

const BranchField: FC<FormFieldProps & { fieldsFilter: any }> = ({
  property,
  disabled,
  fieldsFilter,
  tokens,
  allTokens,
  allowExpressions,
  allowFlowTrigger,
  allowModifiers,
  value,
  field,
  setFieldValue,
  onPortUpdate
}) => {
  const options = useMemo(() => {
    // TODO: This is kinda hacky piggy backing on
    // on the fields filter to do this. Should expand
    // conditions where you can pass an enum
    if (fieldsFilter === 'enum') {
      return field.items.enums || field.items.enum;
    } else {
      return tokens;
    }
  }, [field, fieldsFilter, tokens]);

  const onChange = useCallback(
    v => setFieldValue(property, v),
    [property, setFieldValue]
  );

  // NOTE: The `undefined` value below is to prevent nulls being passed
  return (
    <BranchInput
      disabled={disabled}
      value={value || undefined}
      fieldOptions={options}
      valueOptions={allTokens}
      allowTrigger={allowFlowTrigger}
      allowExpressions={allowExpressions}
      allowModifiers={allowModifiers}
      onChange={onChange}
      onPortUpdate={onPortUpdate}
    />
  );
};

export const fieldComponents = {
  string: TokenField,
  'string:text': TextField,
  ipv4: TokenField,
  ipv6: TokenField,
  integer: TokenField,
  number: TokenField,
  uri: TokenField,
  url: TokenField,
  tel: TokenField,
  hostname: TokenField,
  email: TokenField,

  boolean: BooleanField,
  template: TemplateBox,
  cron: CronField,
  array: ArrayField,
  password: PasswordField,
  flows: FlowsInput,
  condition: ConditionField,
  branch: BranchField,

  oauth: OAuthField,

  'key-value': KeyValueField,
  'key-value-list': KeyValueListField,

  datastore: DatastoreField,
  'datastore:single': DatastoreSingleField,
  'datastore:list': DatastoreListField,
  'datastore:object': DatastoreObjectField,
  'datastore:value': JsonTreeField,
  tree: JsonTreeField,

  file: FileField,
  files: FilesField,

  // NOTE: JSON Tree Editor is buggy and complicated
  // falling this back to code editor for now...
  // json: JsonField,
  json: CodeJsonField,

  'code:javascript': CodeField,
  'code:text': CodeTextField,
  'code:jsonata': CodeJsonataField,
  'code:json': CodeJsonField,
  'code:graphql': CodeGraphqlField,

  'select:all': SelectAllTokensField,
  'select:field': SelectFieldTokensField,
  'select:remote': RemoteSelectField,

  date: DateField,
  time: TimeField,
  'date-time': DateTimeField,
  datetime: DateTimeField,

  // TODO: Deprecated should use code prefix now
  mustache: CodeJsonataField,

  // TODO: Deprecated, should use jsonata now
  'code:handlebars': CodeJsonataField,

  // TODO: Deprecated 'code', must include types now
  code: CodeField,

  // TODO: Update schema to map to select:remote value
  'remote-select': RemoteSelectField
};
