import { Helper } from '.';

export const stringHelpers: Helper[] = [
  {
    name: '$contains',
    label: 'Contains',
    description:
      'Return "true" if an expression matches against a string, and "false" if not. This also supports regular expressions.',
    examples: [`$contains("abracadabra", "bra")`, `$contains(number, /^077/)`],
    valueType: 'string',
    returnType: 'boolean',
    params: {
      type: 'object',
      requires: ['pattern'],
      properties: {
        pattern: {
          type: 'string',
          title: 'Pattern',
          description:
            'The pattern parameter can either be a string or a regular expression. Regex flags i and m are allowed.',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#contains'
  },
  {
    name: '$join',
    label: 'Join',
    description:
      'Join an array of strings together with an optional "separator" parameter.',
    examples: [`$join(['a','b','c']) => "abc"`],
    valueType: 'array',
    returnType: 'string',
    returnSubType: 'string',
    params: {
      type: 'object',
      properties: {
        separator: {
          type: 'string',
          title: 'Separator',
          description: 'The separator to split the string on',
          default: ',',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#join'
  },
  {
    name: '$length',
    label: 'Length',
    description: 'Returns the length of a string.',
    examples: [`$length("Hello World") => 11`],
    valueType: 'string',
    returnType: 'number',
    reference: 'https://docs.jsonata.org/string-functions#length'
  },
  {
    name: '$base64encode',
    label: 'Base64 Encode',
    description: 'Base64 encode a string.',
    examples: [`$base64encode("myuser:mypass") => "bXl1c2VyOm15cGFzcw=="`],
    valueType: 'string',
    returnType: 'string',
    reference: 'https://docs.jsonata.org/string-functions#base64encode'
  },
  {
    name: '$base64decode',
    label: 'Base64 Decode',
    description: 'Decode a base64-encoded string.',
    examples: [`$base64decode("bXl1c2VyOm15cGFzcw==") => "myuser:mypass"`],
    valueType: 'string',
    returnType: 'string',
    reference: 'https://docs.jsonata.org/string-functions#base64decode'
  },
  {
    name: '$lowercase',
    label: 'Lowercase',
    description: 'Convert a string to all lowercase characters.',
    examples: [`$lowercase("Hello World") => "hello world"`],
    valueType: 'string',
    returnType: 'string',
    reference: 'https://docs.jsonata.org/string-functions#lowercase'
  },
  {
    name: '$match',
    label: 'Match',
    description:
      'Apply a regular expression (regex) to a string, and return an array of matches.',
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string',
    params: {
      type: 'object',
      requires: ['expression'],
      properties: {
        expression: {
          type: 'string',
          title: 'Expression',
          description:
            'The expression to match on. Regex flags i and m are allowed.',
          examples: ['/a(b+)/'],
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#match'
  },
  {
    name: '$string',
    label: 'Stringify',
    description: 'Convert the value to a string and optionally prettify it',
    examples: [`$string(5) => "5"`],
    valueType: 'any',
    returnType: 'string',
    params: {
      type: 'object',
      properties: {
        suffix: {
          type: 'boolean',
          title: 'Prettify',
          description: 'Prettify the string',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#string'
  },
  {
    name: '$split',
    label: 'Split',
    description: 'Split a string by a "separator" parameter.',
    examples: [`$split("so many words", " ") => [ "so", "many", "words" ]`],
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string',
    params: {
      type: 'object',
      properties: {
        separator: {
          type: 'string',
          title: 'Separator',
          description: 'The separator to split the string on',
          default: ',',
          attributes: {
            'ui:order': 0
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#split'
  },
  {
    name: '$trim',
    label: 'Trim',
    description: 'Remove whitespace before and after a string.',
    examples: [`$trim(" Hello \n World ") => "Hello World"`],
    valueType: 'string',
    returnType: 'string',
    reference: 'https://docs.jsonata.org/string-functions#trim'
  },
  {
    name: '$uppercase',
    label: 'Uppercase',
    description: 'Convert a string to all uppercase characters.',
    examples: [`$uppercase("Hello World") => "HELLO WORLD"`],
    valueType: 'string',
    returnType: 'string',
    reference: 'https://docs.jsonata.org/string-functions#uppercase'
  },
  {
    name: '$replace',
    label: 'Replace',
    description:
      'Find and replace occurrences of a specific pattern in a string.',
    examples: [`$replace("John Smith and John Jones", "John", "Mr")`],
    valueType: 'string',
    returnType: 'string',
    params: {
      type: 'object',
      requires: ['pattern'],
      properties: {
        pattern: {
          type: 'string',
          title: 'Pattern',
          description:
            'The expression to match on. Regex flags i and m are allowed.',
          examples: ['/a(b+)/'],
          attributes: {
            'ui:order': 0
          }
        },
        replacement: {
          type: 'string',
          title: 'Replacement',
          description: 'The string to replace the expression matches with.',
          default: '',
          attributes: {
            'ui:order': 1
          }
        }
      }
    },
    reference: 'https://docs.jsonata.org/string-functions#replace'
  },
  {
    name: '$defang',
    label: 'Defang IoC',
    description:
      'Return "un-clickable" versions of certain types of strings (i.e. URLs, email addresses).',
    examples: [`$defang("example.com") => 'example[.]com"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$refang',
    label: 'Refang IoC',
    description:
      'Return "clickable" versions of certain types of strings (i.e. URLs, email addresses) that have been previously "de-fanged".',
    examples: [`$refang("example[.]com") => 'example.com"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$card',
    label: 'Is Credit Card',
    description:
      'Returns true if a string is a valid credit card number or false if it is not',
    examples: ['$card("123456789123456") => true'],
    valueType: 'string',
    returnType: 'boolean'
  }
];
