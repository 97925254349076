import React, { FC, Fragment, useState } from 'react';
import { Select, SelectOption } from 'shared/form/Select';
import Highlighter from 'react-highlight-words';
import css from './ArrayInput.module.css';

type ArrayInputProps = {
  field: any;
  value: any;
  placeholder?: string;
  disabled?: boolean;
  property: string;
  valuesOverride?: any[];
  onChange: (value) => void;
  onBlur: () => void;
};

export const ArrayInput: FC<ArrayInputProps> = ({
  field,
  property,
  value,
  placeholder,
  valuesOverride,
  disabled,
  onChange = () => undefined,
  onBlur = () => undefined
}) => {
  function getOptions() {
    return (
      valuesOverride ||
      field.items?.enum?.map(v => {
        if (typeof v === 'string') {
          return { label: v, value: v };
        }
        // TODO: Figure out how to handle formatting in JSON Schema
        else {
          return { ...v, label: v.label, value: v.value };
        }
      }) ||
      []
    );
  }

  const [options, setOptions] = useState<any[]>(getOptions());
  const [inputText, setInputText] = useState<string>('');

  if ((field.items && field.items.enum) || valuesOverride) {
    let values = value;

    if (field.multiple) {
      values = value ? value.trim().split(',') : [];
    }

    return (
      <Select
        name={property}
        clearable
        value={values}
        placeholder={placeholder}
        disabled={disabled}
        multiple={field.multiple}
        onBlur={() => {
          setInputText('');
          onBlur();
        }}
        onChange={val => onChange(field.multiple ? val.join(',') : val)}
        onInputChange={event => setInputText(event.target.value)}
      >
        {options.map(o => (
          <SelectOption
            key={`${o.group}-${o.value}`}
            group={o.group}
            value={o.value}
            inputLabel={
              o.icon ? (
                <Fragment>
                  {o.icon && (
                    <img src={o.icon} className={css.inputIcon} alt={o.label} />
                  )}
                  {o.label}
                </Fragment>
              ) : null
            }
            menuLabel={
              <div className={css.option}>
                {o.icon && (
                  <img src={o.icon} className={css.icon} alt={o.label} />
                )}
                <div className={css.textContainer}>
                  <div>
                    <Highlighter
                      searchWords={[inputText]}
                      autoEscape={true}
                      textToHighlight={o.label}
                    />
                  </div>
                  {o.subtext && (
                    <span className={css.subtext}>{o.subtext}</span>
                  )}
                  {o.description && (
                    <p className={css.description}>{o.description}</p>
                  )}
                </div>
              </div>
            }
          >
            {o.label}
          </SelectOption>
        ))}
      </Select>
    );
  } else {
    const values = value ? value.trim().split(',') : [];

    return (
      <Select
        name={property}
        value={values}
        createable
        clearable
        multiple
        disabled={disabled}
        onBlur={onBlur}
        onChange={val => onChange(val.join(','))}
        onOptionsChange={setOptions}
      >
        {options.map(option => (
          <SelectOption key={option.value} value={option.value}>
            {option.value}
          </SelectOption>
        ))}
      </Select>
    );
  }
};
