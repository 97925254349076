import React, { FC, useRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import Uppy, { UppyOptions, UppyFile } from '@uppy/core';
import { DragDrop, StatusBar } from '@uppy/react';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import './UploadInput.css';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { useOktaAuth } from '@okta/okta-react';

type UploadInputProps = {
  options: UppyOptions;
  flowId?: string;
  height?: string;
  note?: string;
  width?: string;
  url?: string;
  token?: string;
  type?: 'url' | 'local' | 'thumbnail';
  className?: string;
  onUploadSuccess?: (file, response) => any;
  onUploadError?: (file, error, response) => any;
  onRestrictionFailed?: (file, error) => any;
  onComplete?: (result) => any;
};

export const UploadInput: FC<UploadInputProps> = ({
  options,
  flowId,
  type = 'local',
  width = '100%',
  height = '42px',
  note,
  url,
  token,
  className,
  onUploadSuccess = () => undefined,
  onUploadError = () => undefined,
  onRestrictionFailed = () => undefined,
  onComplete = () => undefined
}) => {
  const { authState } = useOktaAuth() || {};

  const uppy = useRef<Uppy>(
    (function () {
      const uppy = new Uppy(options);

      uppy.on('upload-success', async (file: UppyFile, response: any) => {
        onUploadSuccess(file, response);
      });
      uppy.on('restriction-failed', onRestrictionFailed);
      uppy.on('upload-error', onUploadError);
      uppy.on('complete', result => {
        if (type === 'url' || type === 'local') {
          if (result.failed.length === 0) {
            uppy.cancelAll();
          }
          onComplete(result);
        }
      });

      if (type === 'url') {
        // Upload file directly to S3 using presigned url
        uppy.use(AwsS3, {
          getUploadParameters(file) {
            return axios
              .request({
                url: `${process.env.REACT_APP_REST_API_URL_OLD}/file/signed`,
                method: 'POST',
                data: {
                  contentType: file.type,
                  fileName: file.name,
                  size: file.size,
                  flowId
                },
                params: {
                  authorization: authState?.accessToken?.accessToken,
                  accept: 'application/json',
                  'content-type': 'application/json'
                }
              })
              .then(response => {
                return response.data;
              })
              .then(data => {
                return {
                  method: data.method,
                  url: data.url,
                  fields: data.fields,
                  headers: data.headers
                };
              });
          }
        });
      } else if (type === 'thumbnail') {
        uppy.use(ThumbnailGenerator, {
          thumbnailWidth: 200,
          thumbnailHeight: 200,
          waitForThumbnailsBeforeUpload: false
        });

        uppy.on('thumbnail:generated', (file, preview) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.data);
          reader.onloadend = function () {
            uppy.cancelAll();
            onComplete({ file, preview, base64: reader.result });
          };
        });
      }

      return uppy;
    })()
  );

  useEffect(() => {
    // eslint-disable-next-line
    return () => uppy?.current?.close();
  }, [uppy]);

  return (
    <Fragment>
      <DragDrop
        width={width}
        height={height}
        uppy={uppy.current}
        note={note}
        className={className}
      />
      {url && token && (
        <StatusBar
          uppy={uppy.current}
          hideUploadButton={true}
          showProgressDetails={true}
        />
      )}
    </Fragment>
  );
};
