import queryClient from 'core/ReactQuery/client';
import {
  DataGetUserResponse,
  DataListUserGroupsResponse,
  DataListUsersResponse
} from 'core/types/api/Data';
import {
  buildGetUserQueryKey,
  buildListUserGroupsQueryKey,
  buildListUsersQueryKey
} from 'shared/hooks/api';
import { Route } from 'shared/hooks/api/types';

export const matchUser = (model, prop = 'owner') => {
  const curUser = queryClient.getQueryData<DataGetUserResponse>(
    buildGetUserQueryKey()
  );
  const allUsers =
    queryClient.getQueryData<DataListUsersResponse>(
      buildListUsersQueryKey({ limit: 9999 })
    )?.items || [];

  if (model?.[prop]) {
    let user;
    if (allUsers) {
      if (!curUser) {
        throw new Error('Current user query was not passed but expected');
      }

      const users = [...allUsers, curUser];

      user = users.find(user => user.id === model[prop]);
    }

    if (user) {
      model[prop] = user;
    } else {
      model[prop] = {
        name: model?.created?.name || 'Not Available',
        email: 'Not Available',
        id: model[prop]
      };
    }
  }

  return model;
};

export const matchUserGroups = model => {
  model = matchUser(model);
  const allUserGroups =
    queryClient.getQueryData<DataListUserGroupsResponse>(
      buildListUserGroupsQueryKey({ limit: 1000 })
    )?.items || [];

  if (model?.group) {
    let group;
    if (allUserGroups.length) {
      group = allUserGroups.find(g => g.name === model.group);
    }

    if (group) {
      model.group = group;
    } else {
      // DO NOT set the ID if not found as this will result in a 404 on group page
      model.group = {
        name: model.group
      };
    }
  }

  return model;
};

/**
 *  Enrich model owner/group fields
 **/
export const enrichModels = data => {
  const url = data.config.url;
  if (url.includes(Route.LIST_CONNECTIONS)) {
    data.data.items.forEach(item => matchUserGroups(item));
    return data;
  }
  if (url.includes(Route.GET_CONNECTION)) {
    data.data = matchUserGroups(data.data);
    return data;
  }
  if (url.includes(Route.LIST_DATASTORES)) {
    data.data.items.forEach(item => {
      matchUserGroups(item);
      try {
        item.value =
          typeof item.value === 'string' ? JSON.parse(item.value) : item.value;
      } catch (e) {
        // ignore parse errors
      }
    });
    return data;
  }
  if (url.includes(Route.GET_PACKAGE)) {
    data.data = matchUserGroups(data.data);
    return data;
  }
  if (url.includes(Route.LIST_FLOWS)) {
    data.data.items.forEach(item => matchUserGroups(item));
    return data;
  }
  if (url.includes(Route.GET_FLOW)) {
    data.data = matchUserGroups(data.data);
    return data;
  }
  if (url.includes(Route.LIST_EVENT_GROUPS)) {
    data.data.items.forEach(item => matchUser(item, 'triggeredBy'));
    return data;
  }
  if (url.includes(Route.GET_EVENT_GROUP)) {
    data.data = matchUser(data.data, 'triggeredBy');
    return data;
  }
  if (url.includes(Route.LIST_FLOW_REVISIONS)) {
    data.data.items.forEach(item => {
      matchUser(item, 'editedBy');
      if (item.flow) {
        item.flow =
          typeof item.flow === 'string' ? JSON.parse(item.flow) : item.flow;
      }
    });
    return data;
  }
  if (url.includes(Route.LIST_CONNECTION_REVISIONS)) {
    data.data.items.forEach(item => {
      matchUser(item, 'editedBy');
      if (item.connection) {
        item.connection =
          typeof item.connection === 'string'
            ? JSON.parse(item.connection)
            : item.connection;
        matchUserGroups(item.connection);
      }
    });
    return data;
  }

  return data;
};
