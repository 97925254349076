import { axios } from 'core/Axios';
import {
  DataListFlowUsagesParams,
  DataListFlowUsagesResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_FLOW_USAGES;
export const buildQueryKey = (params?: DataListFlowUsagesParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListFlowUsagesParams
): Promise<DataListFlowUsagesResponse> => {
  return axios
    .get<DataListFlowUsagesResponse>(Route.LIST_FLOW_USAGES, { params })
    .then(data => data.data);
};

export const useListFlowUsages = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListFlowUsagesResponse,
  DataListFlowUsagesParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
