import React, { FC, Fragment } from 'react';
import { formatSize } from './utils';

export type DataSizeProps = {
  size: number | string | null | object;
};

export const DataSize: FC<DataSizeProps> = ({ size }) => {
  const [num, suffix] = formatSize(size);
  return (
    <Fragment>
      {num} {suffix}
    </Fragment>
  );
};
