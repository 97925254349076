import humanFormat from 'human-format';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

export function formatSize(size: number | string | null | object) {
  let newSize = size;

  if (isObject(size) || Array.isArray(size)) {
    newSize = Buffer.from(JSON.stringify(size)).length;
  } else if (isString(size)) {
    newSize = parseFloat(size as string);
  } else if (size === null) {
    return 'NA';
  }

  const humanResult = humanFormat.bytes(newSize as number);

  const [num, ...restFormat] = humanResult.split(' ');
  const suffix = restFormat.join(' ');

  return [parseFloat(num), suffix];
}
