/**
 * Validate a action schema w/ its values.
 */
export const validateForm = (schema, values) => {
  const errors = {};
  let hasError = false;

  if (schema?.required) {
    schema.required.forEach(req => {
      const oredRequired = req.includes('|');

      if (oredRequired) {
        const split = req.split('|');
        const oredValue = split.some(s => values && values[s]);

        if (!oredValue) {
          split.forEach(s => {
            const fields = split.map(
              req => `"${schema.properties?.[req]?.title || req}"`
            );
            errors[s] = `requires ${fields
              .slice(0, -1)
              .join(', ')} or ${fields.slice(-1)} but none were provided`;
          });
          hasError = true;
        }
      } else if (
        !values ||
        typeof values[req] === 'undefined' ||
        values[req] === '' ||
        values[req] === null
      ) {
        errors[req] = `requires "${
          schema.properties?.[req]?.title || req
        }" and none was provided`;
        hasError = true;
      }
    });
  }

  return {
    errors,
    hasError
  };
};
