import { ThemeConfiguration } from './themeContext';
import { colors } from './colors';
import { typography } from './typography';

export const lightTheme: Partial<ThemeConfiguration> = {
  name: 'light',
  colors,
  components: {
    nav: {
      background: 'var(--color-white)',
      icon: 'var(--color-icons-icon-default)',
      active: 'var(--color-typography-text-interactive-default)'
    }
  },
  variables: {
    ...typography,
    // Backgrounds
    '--color-backgrounds-bg-primary-canvas':
      'var(--color-global-colors-neutral-100)',
    '--color-backgrounds-bg-primary-action-default':
      'var(--color-global-colors-blurple-500)',
    '--color-backgrounds-bg-primary-action-hover':
      'var(--color-global-colors-blurple-600)',
    '--color-backgrounds-bg-white': 'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-hover': 'var(--color-global-colors-blurple-50)',
    '--color-backgrounds-bg-hover-dark':
      'var(--color-global-colors-blurple-100)',
    '--color-backgrounds-bg-light-1': 'var(--color-global-colors-neutral-100)',
    '--color-backgrounds-bg-divider-1': '#d9d9d9',
    '--color-backgrounds-bg-divider-2': 'var(--color-global-colors-slate-500)',
    '--color-backgrounds-bg-disabled': '#c4c4c5',
    '--color-backgrounds-bg-disabled-light':
      'var(--color-global-colors-neutral-200)',
    '--color-backgrounds-bg-interactive-default':
      'var(--color-global-colors-blurple-500)',
    '--color-backgrounds-bg-interactive-hover':
      'var(--color-global-colors-blurple-700)',
    '--color-backgrounds-bg-interactive-light-hover':
      'var(--color-global-colors-blurple-600)',
    '--color-backgrounds-bg-interactive-pale-hover':
      'var(--color-global-colors-blurple-200)',
    '--color-backgrounds-bg-interactive-selected':
      'var(--color-global-colors-blurple-600)',
    '--color-backgrounds-bg-interactive-disabled':
      'var(--color-global-colors-neutral-800)',
    '--color-backgrounds-bg-interactive-secondary-default':
      'var(--color-global-colors-slate-500)',
    '--color-backgrounds-bg-danger-default':
      'var(--color-global-colors-danger-500)',
    '--color-backgrounds-bg-danger-hover':
      'var(--color-global-colors-danger-800)',
    '--color-backgrounds-bg-danger-light-hover':
      'var(--color-global-colors-danger-50)',
    '--color-backgrounds-bg-selected-dark':
      'var(--color-global-colors-blurple-800)',

    // Added
    '--color-backgrounds-bg-warning-default':
      'var(--color-global-colors-warning-500)',
    '--color-backgrounds-bg-card-default':
      'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-primary-action-secondary':
      'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-primary-action-secondary-hover':
      'var(--color-backgrounds-bg-hover)',
    '--color-backgrounds-bg-input': 'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-list-item': 'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-logo': 'var(--color-global-colors-slate-200)',
    '--color-backgrounds-bg-editor': 'var(--color-global-colors-slate-200)',
    '--color-backgrounds-bg-highlight': 'var(--color-global-colors-blue-50)',
    '--color-backgrounds-bg-success': 'var(--color-global-colors-success-500)',
    '--color-backgrounds-bg-card-primary':
      'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-card-secondary':
      'var(--color-global-colors-neutral-0)',
    '--color-backgrounds-bg-drawer': 'var(--color-global-colors-neutral-0)',

    // Borders
    '--color-borders-border-primary-default':
      'var(--color-global-colors-slate-500)',
    '--color-borders-border-primary-light':
      'var(--color-global-colors-slate-400)',
    '--color-borders-border-primary-dark':
      'var(--color-global-colors-slate-600)',
    '--color-borders-border-disabled': '#c4c4c5',
    '--color-borders-border-light': 'var(--color-global-colors-slate-100)',
    '--color-borders-border-interactive-default':
      'var(--color-global-colors-blurple-500)',
    '--color-borders-border-interactive-hover':
      'var(--color-global-colors-blurple-600)',
    '--color-borders-border-interactive-light-hover':
      'var(--color-global-colors-blurple-400)',
    '--color-borders-border-interactive-selected':
      'var(--color-global-colors-blurple-600)',
    '--color-borders-border-interactive-focused':
      'var(--color-global-colors-blurple-700)',
    '--color-borders-border-danger': 'var(--color-global-colors-danger-500)',
    '--color-borders-border-danger-dark-hover':
      'var(--color-global-colors-danger-800)',
    '--color-borders-border-warning': 'var(--color-global-colors-warning-500)',

    // Added
    '--color-borders-border-input-default':
      'var(--color-borders-border-primary-default)',
    '--color-borders-border-input-focus':
      'var(--color-borders-border-interactive-default)',
    '--color-borders-border-input-disabled':
      'var(--color-borders-border-light)',
    '--color-borders-border-input-error': 'var(--color-borders-border-danger)',
    '--color-borders-border-success': 'var(--color-global-colors-success-500)',
    '--color-borders-border-active':
      'var(--color-borders-border-interactive-default)',
    '--color-borders-border-tabs-selected':
      'var(--color-borders-border-interactive-selected)',
    '--color-borders-border-tabs-hover':
      'var(--color-borders-border-interactive-hover)',

    // Typography
    '--color-typography-heading-default':
      'var(--color-global-colors-charcoal-400)',
    '--color-typography-label-default':
      'var(--color-global-colors-charcoal-700)',
    '--color-typography-label-light': 'var(--color-global-colors-charcoal-300)',
    '--color-typography-label-disabled':
      'var(--color-global-colors-neutral-800)',
    '--color-typography-label-disabled-light':
      'var(--color-global-colors-neutral-500)',
    '--color-typography-label-active':
      'var(--color-global-colors-charcoal-400)',
    '--color-typography-label-active-hover':
      'var(--color-global-colors-charcoal-500)',
    '--color-typography-label-inverse-default':
      'var(--color-global-colors-neutral-0)',
    '--color-typography-text-interactive-default':
      'var(--color-global-colors-blurple-500)',
    '--color-typography-text-interactive-hover':
      'var(--color-global-colors-blurple-600)',
    '--color-typography-text-interactive-dark-hover':
      'var(--color-global-colors-blurple-700)',
    '--color-typography-text-interactive-selected':
      'var(--color-global-colors-blurple-600)',
    '--color-typography-text-interactive-secondary-default':
      'var(--color-global-colors-blue-700)',
    '--color-typography-body-default':
      'var(--color-global-colors-charcoal-900)',
    '--color-typography-body-default-light':
      'var(--color-global-colors-charcoal-600)',
    '--color-typography-body-disabled':
      'var(--color-global-colors-neutral-600)',
    '--color-typography-body-placeholder':
      'var(--color-global-colors-charcoal-300)',
    '--color-typography-helper-default':
      'var(--color-global-colors-charcoal-600)',
    '--color-typography-helper-danger': 'var(--color-global-colors-danger-700)',
    '--color-typography-helper-warning':
      'var(--color-global-colors-warning-500)',

    // Icons
    '--color-icons-icon-default': 'var(--color-global-colors-slate-500)',
    '--color-icons-icon-hover': 'var(--color-global-colors-neutral-200)',
    '--color-icons-icon-warning': 'var(--color-global-colors-warning-500)',
    '--color-icons-icon-danger': 'var(--color-global-colors-danger-700)',
    '--color-icons-icon-active': 'var(--color-global-colors-blurple-500)',
    '--color-icons-icon-active-dark': 'var(--color-global-colors-slate-800)',
    '--color-icons-icon-success': 'var(--color-global-colors-success-500)',
    '--color-icons-icon-inverse-default':
      'var(--color-global-colors-neutral-0)',
    '--color-icons-icon-light-disabled': '#c4c4c5',
    '--color-icons-icon-disabled': 'var(--color-global-colors-neutral-800)',

    // Shadows
    '--effect-drop-shadows-menus': '0px 7px 7px -3px rgb(0 0 0 / 20%)',
    '--effect-drop-shadows-dialogs': '0px 4px 15px 7px rgb(0 0 0 / 20%)',

    '--color-white': 'var(--color-global-colors-neutral-0)',
    '--color-black': '#000'
  }
};
