import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Select, SelectOption } from 'shared/form/Select';
import { getNestedOptions } from '../utils/nesting';
import PCancelable from 'p-cancelable';
import { useUnmount } from 'react-use';
import { parseNestedQuoting } from 'core/utils/parser';
import { useDataRunAction } from 'shared/hooks/api';
import { req as getActionEvent } from 'shared/hooks/api/crudAction/useGetActionEvent';
import { req as listEvents } from 'shared/hooks/api/crudEvent/useListEvents';
import { Event, GetActionRunDataResult } from 'core/types/API';

export type AsyncSelectContext = {
  actionId: string;
  labelKey: string;
  valueKey: string;
};

type ActionRemoteSelectProps = {
  context: any;
  dependenciesContext?: any;
  asyncContext?: AsyncSelectContext;
  value: string;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  onChange: (record) => void;
  onBlur: () => void;
  onMarkInvalid?: (message: string) => void;
};

const loadingErrorMessage = 'Error retrieving options.';

const setCache = (cacheKey: string, options: any[] | string | null) => {
  const cache = JSON.parse(window.localStorage.getItem('async-select') || '{}');
  window.localStorage.setItem(
    'async-select',
    JSON.stringify({ ...cache, [cacheKey]: options })
  );
};

const getCache = (cacheKey: string) => {
  const cache = JSON.parse(window.localStorage.getItem('async-select') || '{}');
  return cache?.[cacheKey] || [];
};

export const AsyncSelectContainer: FC<ActionRemoteSelectProps> = ({
  context = {},
  dependenciesContext,
  asyncContext,
  value,
  hasError,
  disabled,
  multiple,
  onChange,
  onBlur,
  onMarkInvalid
}) => {
  const [integrationId, actionId] = asyncContext.actionId.split('/');
  const keyValue = asyncContext.valueKey;
  const keyLabel = asyncContext.labelKey;

  const promise = useRef<PCancelable<any> | null>(null);
  const loaded = useRef<boolean>(false);
  const currentConnectionRequest = useRef<string | null>(
    context.connection?.id
  );
  const currentRun = useRef<any | null>();
  const [connectionId, setConnectionId] = useState<string | null>(
    context.connection?.id
  );
  const [connectionStatus, setConnectionStatus] = useState<string | null>(
    context.connection?.status
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('Select...');
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const cacheKey = useMemo(
    () =>
      `${connectionId}-${actionId}-${btoa(
        JSON.stringify(dependenciesContext)
      )}`,
    [connectionId, actionId, dependenciesContext]
  );

  const [options, setOptions] = useState<any[]>([]);

  const { mutateAsync: runAction } = useDataRunAction();

  const contextInputs = useMemo(
    () => ({
      ...(context.connectionInputs || {}),
      ...(dependenciesContext || {})
    }),
    [context.connectionInputs, dependenciesContext]
  );

  const getOptions = useCallback(
    (
      actionId: string,
      input: any,
      connectionId: string | null,
      conStatus: string | null
    ) => {
      const cancelable = new PCancelable<any>(async (resolve, reject) => {
        try {
          if (conStatus === 'Error') {
            throw new Error('Connection is invalid');
          }

          // Store the run on a ref in case we need to fetch agains
          if (!currentRun.current) {
            currentRun.current = await runAction({
              actionId,
              integrationId,
              input,
              connectionId
            });
          }

          const fetchEvent = async () => {
            const event = await listEvents({ runId: currentRun.current.runId });

            return event.items[0];
          };
          let data: Event | GetActionRunDataResult = await fetchEvent();
          const output = data?.output
            ? typeof data.output === 'string'
              ? JSON.parse(data.output)
              : data.output
            : null;

          if (
            // @ts-ignore
            data?.end &&
            (!output || output?.message === 'Data size too large to view.')
          ) {
            data = await getActionEvent({
              runId: currentRun.current.runId,
              eventId: data.id
            });
          }
          // TODO: Figure out how to remove this later
          const res = parseNestedQuoting(data?.output);

          resolve({
            input,
            output: res
          });
        } catch (err) {
          reject(err);
        }
      });

      promise.current = cancelable;

      return cancelable;
    },
    [runAction, integrationId]
  );

  const onGetOptions = useCallback(
    async (
      ctxInputs,
      conId: string | null,
      conStatus: string | null,
      curConnectionRequest,
      cache
    ) => {
      if (!conId && !Object.keys(ctxInputs).length) {
        return;
      }

      setPlaceholder('Select...');
      setLoading(true);
      currentConnectionRequest.current = conId;

      try {
        const res = await getOptions(actionId, ctxInputs, conId, conStatus);

        // If the action hasn't completed we need to fetch again
        if (!res.output) {
          // Add a bit of a delay between listEvents requests
          await new Promise(r => setTimeout(r, 3000));
          return await onGetOptions(
            ctxInputs,
            conId,
            conStatus,
            curConnectionRequest,
            cache
          );
        }
        currentRun.current = null;

        let options = [];
        if (!res.output.success) {
          setPlaceholder(loadingErrorMessage);
          onMarkInvalid(loadingErrorMessage);
          setErrorLoading(true);
          // Cache a failed fetch
          setOptions([]);
          setCache(cache, 'error');
        } else {
          options = getNestedOptions(res.output.data || [], keyValue, keyLabel);
        }
        // If the connection changes while an options request is being made,
        // prevent options from being set to ensure options are set from correct request
        if (conId === curConnectionRequest.current) {
          setLoading(false);
          setOptions(options);
          setErrorLoading(false);
        }
      } catch (e) {
        // @ts-ignore-next-line
        if (!e.isCanceled) {
          setPlaceholder(loadingErrorMessage);
          setLoading(false);
          onMarkInvalid(loadingErrorMessage);
          setErrorLoading(true);
          // Cache a failed fetch
          setOptions([]);
          setCache(cache, 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionId, getOptions, keyLabel, keyValue]
  );

  useEffect(() => {
    // When the options update, let's set the cache
    if (cacheKey && options?.length) {
      setCache(cacheKey, options);
    }
  }, [cacheKey, options]);

  useEffect(() => {
    const cache = getCache(cacheKey);
    if (!connectionId) {
      setOptions([]);
    } else if (cache === 'error') {
      setOptions([]);
      setPlaceholder(loadingErrorMessage);
      setErrorLoading(true);
    } else if (cache?.length) {
      setOptions(cache);
      setPlaceholder('Select...');
      setErrorLoading(false);
    } else {
      onGetOptions(
        contextInputs,
        connectionId || null,
        connectionStatus || null,
        currentConnectionRequest,
        cacheKey
      );
    }
  }, [cacheKey, connectionId, connectionStatus, contextInputs, onGetOptions]);

  useEffect(() => {
    // This check is being called multiple times before connectionId state can change which
    // triggers setConnectionId multiple times, with various connection ids which triggers
    // cacheKey to be redone, triggering the above useEffect which triggers multiple getOption requests
    if (connectionId !== context.connection?.id && !loaded.current) {
      setConnectionId(context.connection?.id);
      setConnectionStatus(context.connection?.status);

      loaded.current = true;
      promise.current?.cancel();
    } else if (
      connectionId !== context.connection?.id &&
      context.connection?.id &&
      loaded.current
    ) {
      // If the select has previously been loaded, but the connection has changed, set the connection and
      // clear the previous options
      setConnectionId(context.connection?.id);
      setConnectionStatus(context.connection?.status);
      setOptions([]);
      promise.current?.cancel();
      // TODO: Remove this hack by updating outer form to reset
      // value instead of trying to make select too smart
      onChange(null);
    }
  }, [
    context.connection?.id,
    context.connection?.status,
    connectionId,
    onChange
  ]);

  useUnmount(() => {
    promise.current?.cancel();
  });

  return (
    <Select
      refreshable={options?.length > 0 || errorLoading}
      filterable
      clearable
      error={hasError}
      loading={loading}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      multiple={multiple}
      onChange={onChange}
      onBlur={onBlur}
      onRefresh={() => {
        setOptions([]);
        setCache(cacheKey, null);
        onMarkInvalid(null);
        setPlaceholder('Select...');
        setErrorLoading(false);
        onGetOptions(
          contextInputs,
          connectionId || null,
          connectionStatus || null,
          currentConnectionRequest,
          cacheKey
        );
      }}
    >
      {options.map(o => (
        <SelectOption key={o.value} value={o.value}>
          {o.label}
        </SelectOption>
      ))}
    </Select>
  );
};
