export enum ManualApprovalActions {
  ManualApprovalEmail = 'MANUAL_APPROVAL_EMAIL'
}

export enum DatastoreActions {
  Set = 'SET_DATA',
  Get = 'GET_DATA',
  Trigger = 'TRIGGER_DATA',
  GetList = 'DATA_LIST_GET',
  FindInList = 'DATA_LIST_FIND',
  FilterList = 'DATA_LIST_FILTER',
  AddList = 'DATA_LIST_ADD',
  RemoveList = 'DATA_LIST_REMOVE',
  ClearList = 'DATA_LIST_CLEAR',
  DedupeList = 'DATA_LIST_DEDUPE',
  AddUpdateProperty = 'DATA_PROP_ADD',
  DeleteProperty = 'DATA_PROP_DELETE'
}

export enum DateActions {
  Trigger = 'CORE_SCHEDULE'
}

export enum EmailActions {
  Send = 'EMAIL_SEND',
  Trigger = 'EMAIL_RECEIVE'
}

export enum SmsActions {
  Send = 'SMS_SEND'
}

export enum FlowActions {
  Trigger = 'TRIGGER_FLOW'
}

export enum DataActions {
  ExtractAndModify = 'DATA_MODIFIER'
}

export enum LogicActions {
  Condition = 'CORE_CONDITION',
  Branch = 'CORE_BRANCH',
  ListHasItems = 'CORE_ARRAY_FILTER',
  Loop = 'CORE_LOOP',
  ListFilter = 'CORE_FILTER_LIST',
  ListDifference = 'CORE_LIST_DIFFERENCE'
}

export enum WebhookActions {
  Trigger = 'WEBHOOK_RECEIVE'
}
