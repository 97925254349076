import { ReactComponent as WorkflowIcon } from 'assets/svg/workflow.svg';
import { ReactComponent as LightningIcon } from 'assets/svg/lightning-bolt.svg';
import { ReactComponent as MicrochipIcon } from 'assets/svg/microchip.svg';
import { ReactComponent as DatabaseIcon } from 'assets/svg/database.svg';
import { ReactComponent as ActionsIcon } from 'assets/svg/branch.svg';
import { ReactComponent as FormulaIcon } from 'assets/svg/formula.svg';

export const expressionToken = {
  text: 'Expression',
  value: 'expression',
  path: 'expression',
  description: 'Create a dynamic value using Expressions',
  icon: FormulaIcon,
  selectable: false
};

export const nodesToken = {
  text: 'Nodes',
  value: 'nodes',
  path: 'nodes',
  description: 'The Actions of the Workflow',
  icon: ActionsIcon,
  selectable: false
};

export const actionToken = {
  text: 'Action',
  value: 'action',
  path: 'action',
  description: 'Metadata about the Action',
  icon: MicrochipIcon,
  type: 'action',
  selectable: false
};

export const datastoreToken = {
  text: 'Datastore',
  value: 'store',
  path: 'store',
  description: 'List of all Datastores available',
  icon: DatabaseIcon,
  selectable: false
};

export const flowToken = {
  text: 'Workflow',
  value: 'flow',
  path: 'flow',
  description: 'Metadata about the Workflow',
  icon: WorkflowIcon,
  selectable: false
};

export const eventToken = {
  text: 'Event',
  value: 'event',
  path: 'event',
  description: 'Metadata about the Event',
  icon: LightningIcon,
  selectable: false
};

export const flowTriggerToken = {
  text: 'Workflow Trigger Data',
  value: 'triggerData',
  path: 'triggerData',
  description:
    'Data passed into the Workflow by a another Workflow\'s "Trigger Workflow" action',
  icon: WorkflowIcon,
  selectable: true
};
