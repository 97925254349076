import React, { Fragment, FC, useState, useCallback, useEffect } from 'react';
import { Select, SelectOption } from 'shared/form/Select';
import { Input } from 'shared/form/Input';
import { buildCronExpression, buildCronObject, IntervalTypes } from './parser';
import awsCronParser from 'aws-cron-parser';
import { Block } from 'shared/layout/Block';
import { Moment } from 'shared/data/Moment';
import moment from 'moment';
import css from './CronInput.module.css';

type CronInputProps = {
  value?: string;
  disabled?: boolean;
  localTime?: boolean;
  onChange?: (event) => void;
  onBlur?: (event) => void;
};

const WEEK_OF_DAY_OPTIONS = moment
  .weekdays()
  .map((day: string, index: number) => ({ label: day, value: index + 1 }));

const MONTH_OPTIONS = moment
  .months()
  .map((month: string, index: number) => ({ label: month, value: index + 1 }));

export const CronInput: FC<CronInputProps> = ({
  value,
  disabled,
  localTime = true,
  onChange = () => undefined,
  onBlur = () => undefined
}) => {
  const [internalValue, setInternalValue] = useState<string | null>(value);
  const [interval, setInterval] = useState<IntervalTypes | null>(null);
  const [dayOfMonth, setDayOfMonth] = useState<number | null>(null);
  const [daysOfWeek, setDaysOfWeek] = useState<number[] | null>(null);
  const [timeOfDay, setTimeOfDay] = useState<any | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  const [hour, setHour] = useState<number | null>(null);
  const [minute, setMinute] = useState<number | null>(null);
  const [next, setNext] = useState<Date | null>(null);

  useEffect(() => {
    const newExpression = buildCronExpression(
      {
        interval,
        dayOfMonth,
        daysOfWeek,
        hour,
        minute,
        month,
        timeOfDay
      },
      localTime
    );
    if (newExpression && internalValue !== newExpression) {
      try {
        const cron = awsCronParser.parse(newExpression);
        const nextDate = awsCronParser.next(cron, new Date());
        setNext(nextDate);
      } catch (e) {
        // noop
      }
      setInternalValue(newExpression);
      onChange(newExpression);
    }
  }, [
    dayOfMonth,
    daysOfWeek,
    hour,
    internalValue,
    interval,
    minute,
    month,
    onChange,
    timeOfDay,
    localTime
  ]);

  useEffect(() => {
    const emptyDaysOfWeek = WEEK_OF_DAY_OPTIONS.map(d => d.value);
    const updatedObj = buildCronObject(value, localTime);
    setInterval(updatedObj.interval);
    setDaysOfWeek(updatedObj.daysOfWeek || emptyDaysOfWeek);
    setDayOfMonth(updatedObj.dayOfMonth);
    setTimeOfDay(updatedObj.timeOfDay);
    setMinute(updatedObj.minute);
    setHour(updatedObj.hour);
    setMonth(updatedObj.month);
  }, [value, localTime]);

  const renderMinuteInput = useCallback(
    () => (
      <Block label="On Every x Minutes" className={css.block}>
        <Input
          min="0"
          max="9999999"
          type="number"
          value={minute != null ? minute : ''}
          disabled={disabled}
          fullWidth={true}
          placeholder="How often? Example: 5"
          onChange={event => setMinute(parseInt(event.target.value))}
        />
        {minute != null && minute <= 5 && (
          <p className={css.errorText}>
            Warning: Schedule will be run very frequently
          </p>
        )}
      </Block>
    ),
    [disabled, minute]
  );

  const renderHourInput = useCallback(
    () => (
      <Fragment>
        <Block label="On x Minute(s)" className={css.block}>
          <Input
            min="0"
            max="59"
            type="number"
            value={minute != null ? minute : ''}
            disabled={disabled}
            fullWidth={true}
            placeholder="At what minute? Example: 5"
            onChange={event => setMinute(parseInt(event.target.value))}
          />
        </Block>
        <Block label="Every x Hour(s)" className={css.block}>
          <Input
            min="1"
            max="9999999"
            type="number"
            disabled={disabled}
            value={hour || ''}
            fullWidth={true}
            placeholder="How often? Example: 1"
            onChange={event => setHour(parseInt(event.target.value))}
          />
        </Block>
      </Fragment>
    ),
    [disabled, hour, minute]
  );

  const renderDayInput = useCallback(() => {
    const daysOfWeekValueObj = daysOfWeek
      ? WEEK_OF_DAY_OPTIONS.filter(o => daysOfWeek.includes(o.value))
      : WEEK_OF_DAY_OPTIONS;

    const daysOfWeekValue = daysOfWeekValueObj.map(v => v.value.toString());

    return (
      <Fragment>
        <Block label="Each day at" className={css.block}>
          <Input
            type="time"
            value={timeOfDay || ''}
            disabled={disabled}
            fullWidth={true}
            placeholder="At what time? Example: 12:00"
            onChange={event => setTimeOfDay(event.target.value)}
          />
        </Block>
        {!localTime && <Block className={css.hint}>Time is in UTC</Block>}
        <Block label="On these day(s) of the Week" className={css.block}>
          <Select
            multiple
            value={daysOfWeekValue}
            placeholder="On which days?"
            disabled={disabled}
            onChange={value =>
              setDaysOfWeek(value ? value.map(v => parseInt(v, 10)) : [])
            }
          >
            {WEEK_OF_DAY_OPTIONS.map(v => (
              <SelectOption value={v.value.toString()} key={v.value}>
                {v.label}
              </SelectOption>
            ))}
          </Select>
        </Block>
      </Fragment>
    );
  }, [daysOfWeek, disabled, timeOfDay, localTime]);

  const renderMonthInput = useCallback(
    () => (
      <Fragment>
        <Block label="Every Day at" className={css.block}>
          <Input
            type="time"
            value={timeOfDay || ''}
            fullWidth={true}
            disabled={disabled}
            placeholder="At what time? Example: 12:00"
            onChange={event => setTimeOfDay(event.target.value)}
          />
          {!localTime && <Block className={css.hint}>Time is in UTC</Block>}
        </Block>
        <Block label="On Day of the Month" className={css.block}>
          <Input
            type="number"
            min="1"
            max="31"
            value={dayOfMonth || ''}
            fullWidth={true}
            disabled={disabled}
            placeholder="On which day? Example: 15"
            onChange={event => setDayOfMonth(parseInt(event.target.value))}
          />
        </Block>
      </Fragment>
    ),
    [dayOfMonth, disabled, timeOfDay, localTime]
  );

  const renderYearInput = useCallback(() => {
    const monthValue = MONTH_OPTIONS.find(o => o.value === month);

    return (
      <Fragment>
        <Block label="Every Day at" className={css.block}>
          <Input
            type="time"
            value={timeOfDay || ''}
            fullWidth={true}
            disabled={disabled}
            placeholder="At what time? Example: 12:00"
            onChange={event => setTimeOfDay(event.target.value)}
          />
          {!localTime && <Block className={css.hint}>Time is in UTC</Block>}
        </Block>
        <Block label="On Day of the Month" className={css.block}>
          <Input
            type="number"
            min="1"
            max="31"
            value={dayOfMonth || ''}
            fullWidth={true}
            disabled={disabled}
            placeholder="On which day? Example: 15"
            onChange={event => setDayOfMonth(parseInt(event.target.value))}
          />
        </Block>
        <Block label="On Month of the Year" className={css.block}>
          <Select
            value={monthValue?.value?.toString()}
            placeholder="On which month?"
            disabled={disabled}
            onChange={value => setMonth(parseInt(value, 10))}
          >
            {MONTH_OPTIONS.map(v => (
              <SelectOption value={v.value.toString()} key={v.value}>
                {v.label}
              </SelectOption>
            ))}
          </Select>
        </Block>
      </Fragment>
    );
  }, [dayOfMonth, disabled, month, timeOfDay, localTime]);

  return (
    <div className={css.container}>
      <Block label="Frequency" className={css.block}>
        <Select
          value={interval}
          placeholder="Select a frequency"
          onChange={setInterval}
          onBlur={onBlur}
        >
          <SelectOption value="minute">Minute</SelectOption>
          <SelectOption value="hour">Hour</SelectOption>
          <SelectOption value="day">Day</SelectOption>
          <SelectOption value="month">Month</SelectOption>
          <SelectOption value="year">Year</SelectOption>
        </Select>
      </Block>
      {interval === 'minute' && renderMinuteInput()}
      {interval === 'hour' && renderHourInput()}
      {interval === 'day' && renderDayInput()}
      {interval === 'month' && renderMonthInput()}
      {interval === 'year' && renderYearInput()}
      {next && (
        <Block label="Next Run" className={css.block}>
          <Moment date={next} format="MM/DD/YY hh:mm A" />
        </Block>
      )}
    </div>
  );
};
