import React, { FC, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import FileSaver from 'file-saver';
import { List, ListItem } from 'shared/layout/List';
import { DataSize } from 'shared/data/DataSize';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg';
import { ReactComponent as RotationIcon } from 'assets/svg/rotation.svg';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from 'shared/elements/Button';
import { useNotification } from 'shared/layers/Notification';
import css from './UploadsList.module.css';

type UploadsListProps = {
  list: {
    id: string;
    fileName: string;
    size: number;
  }[];
  onDelete?: (id: string) => void;
};

export const UploadsList: FC<UploadsListProps> = ({
  list,
  onDelete = () => undefined
}) => {
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>(
    {}
  );
  const { authState } = useOktaAuth();
  const { addError } = useNotification();

  return (
    list?.length > 0 && (
      <List className={css.filesList}>
        {list.map(upload => (
          <ListItem key={upload.id} className={css.item}>
            <Button
              disablePadding={true}
              title="Delete"
              className={css.deleteBtn}
              variant="text"
              onClick={() => onDelete(upload.id)}
            >
              <CloseIcon className={css.deleteIcon} />
            </Button>
            <Button
              disablePadding={true}
              title="Download"
              variant="text"
              className={classNames(css.downloadButton, {
                [css.downloading]: downloading[upload.id]
              })}
              onClick={() => {
                setDownloading({ ...downloading, [upload.id]: true });
                axios
                  .request({
                    url: `${process.env.REACT_APP_REST_API_URL_OLD}/file/${upload.id}/download`,
                    method: 'GET',
                    params: {
                      authorization: authState.accessToken?.accessToken
                    }
                  })
                  .then(response =>
                    axios
                      .request({
                        url: response.data,
                        method: 'GET',
                        responseType: 'blob'
                      })
                      .then(response => {
                        FileSaver.saveAs(
                          new Blob([response.data]),
                          upload.fileName
                        );
                        setDownloading({ ...downloading, [upload.id]: false });
                      })
                  )
                  .catch(error => addError(error.message));
              }}
            >
              {downloading[upload.id] ? (
                <RotationIcon className={css.icon} />
              ) : (
                <DownloadIcon className={css.icon} />
              )}
            </Button>
            {upload.fileName} (<DataSize size={upload.size} />)
          </ListItem>
        ))}
      </List>
    )
  );
};
