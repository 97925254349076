import { TokenOption } from 'shared/form/TokenEditor';
import loopIcon from 'assets/svg/loop-white.svg';
import orderBy from 'lodash/orderBy';
import getSchemaFromPath from 'json-schema-from-path';
import ellipsize from 'ellipsize';
import { getType } from 'shared/internal/Datastore';
import { schemaGenerator } from '@getcrft/jsonizer';
import { getTypeIconComponent } from 'shared/internal/TypeIcons';
import { StoreValueType } from 'core/types/API';

/**
 * Detect if a string has invalid json key chars.
 * Reference: https://stackoverflow.com/questions/11896599/javascript-code-to-check-special-characters
 */
function isValid(str: string) {
  return !/[ ~`!#$%\\^&*+=\-\\[\]\\';,/{}|\\":<>\\?]/g.test(str);
}

/**
 * If a value has invalid keys, let's bracket it.
 */
function encodeValue(value: string) {
  const result: string[] = [];

  const splits = value.split('.');
  for (let split of splits) {
    if (!isValid(split)) {
      split = '`' + split + '`';
    }
    result.push(split);
  }

  return result.join('.');
}

/**
 * Given a node, child and parents get the property and name for all the parents (if passed).
 */
function getFieldProps(node, child, type?: string, parents: any[] = []) {
  let text = '';
  let value = '';

  for (const parent of parents) {
    // if (parent.key !== 'data') {
    //  text = `${text}${startCase(parent.title || parent.key)} ‣ `;
    // }
    value = `${value}${parent.key}.`;
  }

  const childString = child.title || child.key;
  text = `${text}${childString.charAt(0).toUpperCase() + childString.slice(1)}`;

  value = `${value}${child.key}`;

  // Modifier Output items don't have types
  if (type) {
    value = `${type}.${value}`;
  }

  // Loop items don't have ids
  if (node.id) {
    value = `${node.id}.${value}`;
  }

  return {
    text,
    value: encodeValue(value)
  };
}

/**
 * Recursively build a option list, going through each type and adding them recursively.
 */
function recursiveAdd(parentProps, node, type?: string) {
  const results = [];

  const add = (properties, parents = [], jsonataArray = false) => {
    for (const key in properties) {
      const val = properties[key];
      const hidden = val?.attributes?.['ui:hidden'] === true;

      if (!hidden) {
        const has = results.find(f => f.value === key && f.type === type);
        if (!has) {
          let description = val.description;
          /*
          let description = val.description || '';
          if (val.examples?.length) {
            if (val.description) {
              description += ' - ';
            }
            description += `Example: ${val.examples[0]}`;
          }
          */

          const obj = getFieldProps(node, { ...val, key }, type, parents);
          //  eslint-disable-next-line
          const [id, ...rest] = obj.value.split('.');
          let subType = val.type === 'array' && val.items?.type;
          subType = subType || (jsonataArray && val.type);

          let subtext = '';
          if (val.type) {
            subtext += `[${jsonataArray ? 'array' : val.type}${
              subType ? `<${subType}>` : ''
            }] `;
          }

          // Note: This is not needed anymore since we have grouping
          // if (node.label) {
          //  subtext += `${node.label} ‣ `;
          // }

          if (rest.length) {
            subtext += rest.join('.');
          }

          results.push({
            ...obj,
            subtext,
            icon: node.logo,
            group: node.label,
            description,
            schema: val,
            // Attach the value type for filtering
            valueType: jsonataArray ? 'array' : val.type,
            // TODO: This is a temp hack for the select input to get the name of it...
            node,
            type,
            subType
          });
        }

        if (val.type === 'object') {
          add(val.properties, [...parents, { ...val, key }], jsonataArray);
        } else if (val.type === 'array') {
          if (val?.items?.properties) {
            add(val.items.properties, [...parents, { ...val, key }], true);
          }
        }
      }
    }
  };

  add(parentProps);

  return results;
}

/**
 * Add loop outputs to the option lists.
 */
export function addLoopOutputs(
  options: any[],
  parent: string | string[],
  stores?: any[]
) {
  const result = [];

  const nodeOptions = options.filter(o =>
    typeof parent === 'string'
      ? o.node?.id === parent
      : parent.includes(o.node?.id)
  );
  const storeOptions = (stores || []).filter(
    s => s.type === StoreValueType.List
  );

  const loopOptions = [...nodeOptions, ...storeOptions];

  for (const loopOption of loopOptions) {
    let group = 'Loop';
    if (loopOption) {
      group = `Loop Iteration (${loopOption.node?.label})`;
      const loopId = loopOption.node?.input?.itemsExpression;
      if (loopId) {
        const [optionId, path, ...rest] = loopId
          .replace('{{', '')
          .replace('}}', '')
          .split('.');
        const objPath = `${path}.${rest.join('.')}`;
        let isStore = false;
        let parentObj = options.find(o => o.node?.id === optionId);

        if (!parentObj) {
          parentObj = storeOptions.find(s => s.id === optionId);
          isStore = !!parentObj;
        }

        if (parentObj) {
          if (!isStore) {
            const data = getSchemaFromPath({ properties: parentObj }, objPath);

            if (data) {
              const loopItems = [];
              const copy = {
                ...parentObj,
                node: {
                  ...parentObj.node,
                  id: null
                }
              };

              // Array Object types
              if (data.items?.properties) {
                loopItems.push(
                  ...recursiveAdd(data.items.properties, copy.node, 'item')
                );
              } else {
                // String/number/etc types
                const item = data.items || data;
                loopItems.push(...recursiveAdd({ item }, copy.node));
              }

              result.push(
                ...loopItems.map(i => ({
                  ...i,
                  group
                }))
              );
            } else {
              result.push({
                text: 'Loop Item',
                value: `item`,
                icon: loopIcon,
                group,
                type: 'loop'
              });
            }
          } else {
            const storeSchema = schemaGenerator({ value: parentObj.value });
            const newTokens = recursiveAdd(storeSchema.properties, parentObj);
            const updatedTokens = newTokens.map(t => {
              const copy = { ...t };
              copy.value = copy.value.replace(
                `${parentObj.id}.value`,
                parentObj.id
              );
              copy.subtext = copy.subtext.replace(` value.`, ' ');
              return {
                ...copy,
                text: copy.value === optionId ? 'Loop Item' : copy.text,
                group,
                type: 'loop',
                icon: getTypeIconComponent(copy.type, copy.subType) || t.icon
              };
            });
            result.push(...updatedTokens);
          }
        }
      }
    }

    if (typeof parent === 'string' || parent.length > 0) {
      result.push({
        text: 'Loop Item Index',
        value: `index`,
        icon: loopIcon,
        group,
        type: 'loop'
      });
    }
  }

  return result;
}

/**
 * Given an option, build the results from inputs/outputs.
 */
function buildOptionOptions(option) {
  const result = [];

  if (option.modifierOutput) {
    result.push(...recursiveAdd(option.modifierOutput.properties, option.node));
  }

  if (option.output) {
    result.push(
      ...recursiveAdd(option.output.properties, option.node, 'output')
    );
  }

  if (option.input) {
    result.push(...recursiveAdd(option.input.properties, option.node, 'input'));
  }

  return result;
}

/**
 * Build the options for the token input.
 */
export function convertOutputsToTokens(
  options: any[],
  stores?: any[],
  addFlowDetails?: boolean,
  addEventDetails?: boolean,
  addActionDetails?: boolean,
  parent?: string | string[]
) {
  const result: TokenOption[] = [];

  if (parent) {
    result.push(...addLoopOutputs(options, parent, stores));
  }

  if (options) {
    for (const option of options) {
      result.push(...buildOptionOptions(option));

      // TODO: This seems way over coupled to the implementation
      if (option.node?.type === 'map' && option.children) {
        for (const child of option.children) {
          result.push(
            ...buildOptionOptions({
              ...child,
              node: {
                ...child.node,
                id: `${option.node.id}.output.data.${child.node.id}`
              }
            })
          );
        }
      }
    }
  }

  if (stores) {
    stores = orderBy(
      [...stores],
      ['flowId', m => m.name?.toLowerCase()],
      ['desc', 'asc']
    );

    for (const store of stores) {
      if (
        !store.flowId ||
        store.isCurrentFlow ||
        store.isCurrentFlow === undefined
      ) {
        const has = result.find(
          f => f.value === store.id && f.type === 'store'
        );
        if (!has) {
          result.push({
            text: store.name,
            value: store.id,
            group: 'Datastore',
            icon: `${process.env.REACT_APP_LOGO_URL}/database.svg`,
            subtext: `Store: ${store.flowId ? 'This Workflow' : 'Global'}`,
            type: 'store',
            valueType: getType(store.value)
          });
          const storeSchema = schemaGenerator({ value: store.value });
          const newTokens = recursiveAdd(storeSchema.properties, store);
          const updatedTokens = newTokens.map(t => {
            const copy = { ...t };
            copy.value = copy.value.replace(`${store.id}.value`, store.id);
            copy.subtext = copy.subtext.replace(` value.`, ' ');
            return {
              ...copy,
              group: 'Datastore',
              type: 'store',
              icon: getTypeIconComponent(copy.type, copy.subType) || t.icon
            };
          });
          const filteredTokens = updatedTokens.filter(
            t => t.value !== store.id
          );
          result.push(...filteredTokens);
        }
      }
    }
  }

  if (addFlowDetails) {
    result.push(
      {
        text: 'Last Run',
        value: `flow.lastRun`,
        group: 'Workflow',
        icon: getTypeIconComponent('date'),
        description: `The date and time the Workflow was last ran regardless of success or not.`,
        type: 'flow',
        valueType: 'date',
        schema: {
          examples: ['2021-09-12T14:00:18.932Z']
        }
      },
      {
        text: 'Name',
        value: `flow.name`,
        icon: getTypeIconComponent('string'),
        group: 'Workflow',
        description: `The name of the Workflow.`,
        type: 'flow',
        valueType: 'string',
        schema: {
          examples: ['IOC Triage']
        }
      },
      {
        text: 'ID',
        value: `flow.id`,
        icon: getTypeIconComponent('id'),
        group: 'Workflow',
        description: `The ID of the Workflow.`,
        type: 'flow',
        valueType: 'id',
        schema: {
          examples: ['ae8nzgj221ehint']
        }
      },
      {
        text: 'Author Name',
        value: `flow.ownerName`,
        icon: getTypeIconComponent('user'),
        group: 'Workflow',
        description: `The name of the Workflow author.`,
        type: 'flow',
        valueType: 'user',
        schema: {
          examples: ['Austin']
        }
      },
      {
        text: 'Author ID',
        value: `flow.owner`,
        icon: getTypeIconComponent('id'),
        group: 'Workflow',
        description: `The ID of the Workflow author.`,
        type: 'flow',
        valueType: 'id',
        schema: {
          examples: ['ae8nzgj221ehint']
        }
      },
      {
        text: 'Shared With',
        value: `flow.group`,
        icon: getTypeIconComponent('company'),
        group: 'Workflow',
        description: `The name of the Organization the Workflow is shared with.`,
        type: 'flow',
        valueType: 'company',
        schema: {
          examples: ['Flashpoint']
        }
      }
    );
  }

  if (addEventDetails) {
    result.push(
      {
        text: 'ID',
        value: `event.id`,
        icon: getTypeIconComponent('id'),
        group: 'Event',
        description: `The ID of the Workflow Event.`,
        type: 'event',
        valueType: 'id',
        schema: {
          examples: ['ae8nzgj221ehint']
        }
      },
      {
        text: 'Start',
        value: `event.start`,
        icon: getTypeIconComponent('date'),
        group: 'Event',
        description: `The start date and time of the Workflow Event.`,
        type: 'event',
        valueType: 'date',
        schema: {
          examples: ['2021-09-12T14:00:18.932Z']
        }
      },
      {
        text: 'URL',
        value: `event.url`,
        icon: getTypeIconComponent('url'),
        group: 'Event',
        description: `The URL of the Event.`,
        type: 'event',
        valueType: 'url',
        schema: {
          examples: [
            `${window.location.origin}${process.env.PUBLIC_URL}/events/aba3pn2klp71oyt`
          ]
        }
      }
    );
  }

  if (addActionDetails) {
    result.push(
      {
        text: 'ID',
        value: `action.id`,
        icon: getTypeIconComponent('id'),
        group: 'Action',
        description: `The ID of the Action.`,
        type: 'action',
        valueType: 'id',
        schema: {
          examples: ['ae8nzgj221ehint']
        }
      },
      {
        text: 'Name',
        value: `action.name`,
        icon: getTypeIconComponent('string'),
        group: 'Action',
        description: `The name of the Action.`,
        type: 'action',
        valueType: 'string',
        schema: {
          examples: ['Search Reports']
        }
      }
    );
  }

  return result;
}

export function validateTokenSelectOption(token: any, filter: string | null) {
  // TODO: Fix this stupid logic to be more 'dynamic' for uknown types
  // continue...
  const isKnownToken =
    (token.node?.action?.id === 'DATA_MODIFIER' && token.text === 'Data') ||
    (token.node?.action?.id === 'code/nodejs' && token.text === 'Data');

  if (isKnownToken || !filter) {
    return true;
  }

  // if no schema then assuming true for now just to be safe
  const isSchemaTypeValid = token.schema?.type
    ? filter.includes(token.schema?.type)
    : true;

  return isSchemaTypeValid && filter.includes(token.valueType);
}

/**
 * Build select items for options based on filters.
 */
export function buildSelectOptions(
  tokens: any[],
  typeFilter: 'flow' | 'event' | 'store' | 'node' | null,
  valueFilter: string | null
) {
  const options = [];

  for (const token of tokens) {
    let name;
    let type = token.type;

    if (token.node) {
      // Filter out types that don't match
      if (validateTokenSelectOption(token, valueFilter)) {
      } else {
        continue;
      }

      name = token.node.label;
      type = 'node';
    } else if (token.type === 'flow') {
      name = 'Workflow';
    } else if (token.type === 'event') {
      name = 'Event';
    } else if (token.type === 'store') {
      name = 'Datastore';
    } else if (token.type === 'action') {
      name = 'Action';
    }

    if (typeFilter === null || typeFilter === type) {
      options.push({
        text: token.text,
        label: token.text,
        value: token.value,
        // NOTE: Token editor does this automatically, we need to
        // make the react-select handle this here...
        description: ellipsize(token.description, 150),
        subtext: token.subtext,
        icon: token.icon,
        group: name,
        type
      });
    }
  }

  return options;
}
