import { axios } from 'core/Axios';
import {
  DataGetUploadParams,
  DataGetUploadResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_UPLOAD;
export const buildQueryKey = (params?: DataGetUploadParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetUploadParams
): Promise<DataGetUploadResponse> => {
  return axios
    .get<DataGetUploadResponse>(Route.GET_UPLOAD, { params })
    .then(data => data.data);
};

export const useGetUpload = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<DataGetUploadResponse, DataGetUploadParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
