import { axios } from 'core/Axios';
import {
  DataListConnectionsParams,
  DataListConnectionsResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.LIST_CONNECTIONS;
export const buildQueryKey = (params?: DataListConnectionsParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataListConnectionsParams
): Promise<DataListConnectionsResponse> => {
  return axios
    .get<DataListConnectionsResponse>(Route.LIST_CONNECTIONS, { params })
    .then(data => data.data);
};

export const useListConnections = ({
  queryKey,
  queryFn,
  options,
  params = { limit: 1000 }
}: QueryApiHookProps<
  DataListConnectionsResponse,
  DataListConnectionsParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
