import React, { Fragment, FC } from 'react';
import { Helmet } from 'react-helmet-async';
import css from './NotFound.module.css';

export const NotFound: FC = () => (
  <Fragment>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <section className={css.container}>
      <div>
        <h2>Not Found</h2>
      </div>
    </section>
  </Fragment>
);
