import { Helper } from '.';

export const domainHelpers: Helper[] = [
  {
    name: '$subdomainFromUrl',
    label: 'Subdomains',
    description: `Returns an array of subdomains from a URL.`,
    examples: [`$subdomainFromUrl("https://baz.bar.com") => ["baz"]`],
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  },
  {
    name: '$domainFromUrl',
    label: 'Domain',
    description: `Returns the domain name portion of a URL.`,
    examples: [`$domainFromUrl("https://baz.bar.com") => "bar"`],
    valueType: 'string',
    returnType: 'string'
  },
  {
    name: '$topLevelDomainFromUrl',
    label: 'Top Level Domain',
    description: `Returns an array of top-level domains from a URL.`,
    examples: [`$domainFromUrl("https://baz.bar.com") => ["com"]`],
    valueType: 'string',
    returnType: 'array',
    returnSubType: 'string'
  }
];
