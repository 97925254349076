import { axios } from 'core/Axios';
import {
  DataGetPackageConnectionParams,
  DataGetPackageConnectionResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_PACKAGE_CONNECTION;
export const buildQueryKey = (params?: DataGetPackageConnectionParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetPackageConnectionParams
): Promise<DataGetPackageConnectionResponse> => {
  return axios
    .get<DataGetPackageConnectionResponse>(Route.GET_PACKAGE_CONNECTION, {
      params
    })
    .then(data => data.data);
};

export const useGetPackageConnection = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetPackageConnectionResponse,
  DataGetPackageConnectionParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
