import { axios } from 'core/Axios';
import {
  UsageFlowsRunParams,
  UsageFlowsRunResponse
} from 'core/types/api/Usage';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.USER_FLOWS_RUN;
export const buildQueryKey = (params?: UsageFlowsRunParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: UsageFlowsRunParams
): Promise<UsageFlowsRunResponse> => {
  return axios
    .get<UsageFlowsRunResponse>(Route.USER_FLOWS_RUN, { params })
    .then(data => data.data);
};

export const useFlowsRun = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<UsageFlowsRunResponse, UsageFlowsRunParams> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
