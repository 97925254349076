import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { Loader } from 'shared/elements/Loader';
import { useAuth } from './useAuth';

const paths = ['', '/', '/login/callback', '/login', '/logout'];
const PREV_ROUTE = 'PREV_ROUTE';

export const GuardedRoute = ({ children }: { children: JSX.Element }) => {
  const { hasAccess, user, isAuthenticated } = useAuth();
  const prevRoute = window.localStorage.getItem(PREV_ROUTE);
  const { pathname, search } = useLocation();
  const fullPath = pathname + search;

  if (hasAccess && user) {
    // If the user is logged in and has access to Automate, determine which page they should be on
    if (
      (user.allowed_user && pathname === '/denied') ||
      (user.seen_welcome &&
        user.automate_first_login &&
        pathname === '/welcome')
    ) {
      return <Navigate to="/workflows" />;
    } else if (prevRoute && pathname !== prevRoute) {
      window.localStorage.removeItem(PREV_ROUTE);
      return <Navigate to={prevRoute} />;
    }

    return children;
  } else {
    // If the user does not have access to Automate, it may be because the user doesn't have access or authentication has not fully completed
    // Determine which component should be rendered or if the user is on a protected page

    // If isAuthenticated is null, that means auth has not completed yet
    if (isAuthenticated === null) {
      return <Loader />;
    } else if (isAuthenticated === false) {
      // Store prev location for later redirect user to login screen
      if (!prevRoute && pathname && !paths.includes(pathname)) {
        window.localStorage.setItem(PREV_ROUTE, fullPath);
      }

      return <Navigate to="/login" />;
    } else if (
      isAuthenticated &&
      user &&
      hasAccess === false &&
      pathname !== '/denied'
    ) {
      // If the user's authentication is completed and determined they do not have access to Automate, if they are attemtping
      // to access a page other than denied, redirect them back to denied
      return <Navigate to="/denied" />;
    } else if (pathname !== '/denied') {
      // If the above cases are not met, show loading spinner
      return <Loader />;
    }

    return children;
  }
};
