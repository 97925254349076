import React, { FC, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from 'shared/elements/Button';
import { Dialog } from 'shared/layers/Dialog';
import css from './IdleDialog.module.css';
import { Moment } from 'shared/data/Moment';

interface IdleDialogProps {
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
}

export const IdleDialog: FC<IdleDialogProps> = ({
  open,
  onClose,
  onLogout
}) => {
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (open) {
      const accessToken = authState?.accessToken?.expiresAt || Infinity;
      const idToken = authState?.idToken?.expiresAt || Infinity;
      const earliestExpiration = Math.min(accessToken, idToken);
      const expirationDate =
        earliestExpiration === Infinity
          ? null
          : new Date(earliestExpiration * 1000);
      setExpireDate(expirationDate);
    }
  }, [authState?.accessToken?.expiresAt, authState?.idToken?.expiresAt, open]);

  return (
    <Dialog
      size="500px"
      header="Are you still there?"
      showCloseButton={false}
      open={open}
      onClose={onClose}
      className={css.idleDialog}
    >
      <div>
        You will be logged out
        {expireDate && (
          <Moment
            className={css.expiration}
            fromNow
            date={expireDate}
            allowToggle={false}
          />
        )}
        {!expireDate && ' '}
        if you do not respond.
      </div>
      <footer className={css.footer}>
        <Button
          color="primary"
          variant="filled"
          size="medium"
          onClick={onClose}
        >
          I'm Here
        </Button>
        <Button
          color="secondary"
          variant="filled"
          size="medium"
          onClick={() => {
            onClose();
            onLogout();
          }}
        >
          Log Out
        </Button>
      </footer>
    </Dialog>
  );
};
