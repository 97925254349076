import React, { FC, Fragment, useMemo, useRef } from 'react';
import { Select, SelectOption, getOptionValue } from 'shared/form/Select';
import { TokenEditor } from 'shared/form/TokenEditor';
import { ConditionValueRule } from '@getcrft/jsonata-ext';
import { ConditionInputTimestampWrapper } from './ConditionInputTimestampWrapper';
import { ConditionInputBoolean } from './ConditionInputBoolean';
import css from './ConditionInputItem.module.css';
import { Button } from 'shared/elements/Button';
import { Datastore, Flow } from 'core/types/API';
import { DateTypes, NumericTypes, StringTypes } from 'core/types';

export type OperatorOption = {
  label: string;
  value: string;
  noValue?: boolean;
  types?: string[];
};

export type OperatorGroup = {
  label?: string;
  options: OperatorOption[];
};

export type ConditionInputItemProps = {
  value: ConditionValueRule;
  type: 'condition' | 'branch';
  inputType?: string;
  disabled?: boolean;
  fieldOptions?: any[];
  valueOptions?: any[];
  operatorOptions?: OperatorGroup[];
  allowTrigger?: boolean;
  allowExpressions?: boolean;
  allowModifiers?: boolean;
  stores?: Datastore[];
  flows?: Flow[];
  onChange?: (value: ConditionValueRule) => void;
};

const defaultOperatorOptions: OperatorGroup[] = [
  {
    options: [
      { label: 'Equals', value: 'stringEquals', types: StringTypes },
      { label: 'Not Equal', value: 'stringNotEqual', types: StringTypes },
      { label: 'Contains', value: 'stringContains', types: StringTypes },
      {
        label: 'Does Not Contain',
        value: 'stringNotContains',
        types: StringTypes
      },
      { label: 'Starts With', value: 'stringStartsWith', types: StringTypes },
      { label: 'Ends With', value: 'stringEndsWith', types: StringTypes },
      {
        label: 'Is Empty',
        value: 'stringEmpty',
        noValue: true,
        types: StringTypes
      },
      {
        label: 'Is Not Empty',
        value: 'stringNotEmpty',
        noValue: true,
        types: StringTypes
      },
      {
        label: 'Equals',
        value: 'numericEquals',
        types: NumericTypes
      },
      {
        label: 'Not Equal',
        value: 'numericNotEqual',
        types: NumericTypes
      },
      {
        label: 'Less Than',
        value: 'numericLessThan',
        types: NumericTypes
      },
      {
        label: 'Less Than Equals',
        value: 'numericLessThanEquals',
        types: NumericTypes
      },
      {
        label: 'Greater Than',
        value: 'numericGreaterThan',
        types: NumericTypes
      },
      {
        label: 'Greater Than Equals',
        value: 'numericGreaterThanEquals',
        types: NumericTypes
      },
      {
        label: 'Is Empty',
        value: 'numericEmpty',
        noValue: true,
        types: NumericTypes
      },
      {
        label: 'Is Not Empty',
        value: 'numericNotEmpty',
        noValue: true,
        types: NumericTypes
      },
      {
        label: 'Equals',
        value: 'timestampEquals',
        types: DateTypes
      },
      {
        label: 'Not Equal',
        value: 'timestampNotEqual',
        types: DateTypes
      },
      {
        label: 'Before',
        value: 'timestampLessThan',
        types: DateTypes
      },
      {
        label: 'On or Before',
        value: 'timestampLessThanEquals',
        types: DateTypes
      },
      {
        label: 'After',
        value: 'timestampGreaterThan',
        types: DateTypes
      },
      {
        label: 'On or After',
        value: 'timestampGreaterThanEquals',
        types: DateTypes
      },
      {
        label: 'Is Empty',
        value: 'timestampEmpty',
        noValue: true,
        types: DateTypes
      },
      {
        label: 'Is Not Empty',
        value: 'timestampNotEmpty',
        noValue: true,
        types: DateTypes
      },
      { label: 'Equals', value: 'booleanEquals', types: ['boolean'] },
      { label: 'Not Equal', value: 'booleanNotEqual', types: ['boolean'] },
      {
        label: 'Is Empty',
        value: 'booleanEmpty',
        noValue: true,
        types: ['boolean']
      },
      {
        label: 'Is Not Empty',
        value: 'booleanNotEmpty',
        noValue: true,
        types: ['boolean']
      },
      { label: 'Equals', value: 'arrayEquals', types: ['array'] },
      { label: 'Not Equal', value: 'arrayNotEqual', types: ['array'] },
      {
        label: 'Is Empty',
        value: 'arrayEmpty',
        noValue: true,
        types: ['array']
      },
      {
        label: 'Is Not Empty',
        value: 'arrayNotEmpty',
        noValue: true,
        types: ['array']
      },
      { label: 'Equals', value: 'objectEquals', types: ['object'] },
      { label: 'Not Equal', value: 'objectNotEqual', types: ['object'] },
      {
        label: 'Is Empty',
        value: 'objectEmpty',
        noValue: true,
        types: ['object']
      },
      {
        label: 'Is Not Empty',
        value: 'objectNotEmpty',
        noValue: true,
        types: ['object']
      }
    ]
  }
];

export const ConditionInputItem: FC<ConditionInputItemProps> = ({
  value,
  disabled,
  type,
  inputType,
  fieldOptions = [],
  valueOptions = [],
  operatorOptions = defaultOperatorOptions,
  allowTrigger,
  allowExpressions,
  allowModifiers,
  stores = [],
  flows = [],
  onChange
}) => {
  // TODO: This is a hack to improve typing performance
  const lastValRef = useRef<string | null>(null);
  const fieldValue = getOptionValue(fieldOptions, value.variable);
  operatorOptions = useMemo(() => {
    if (fieldValue?.operators) {
      return fieldValue.operators;
    }

    if (fieldValue?.type || fieldValue?.valueType) {
      let options = operatorOptions.map(o => ({
        ...o,
        options: o.options.filter(
          op =>
            !op.types ||
            op.types.includes(fieldValue.type) ||
            op.types.includes(fieldValue.valueType)
        )
      }));

      if (options.length === 0) {
        // Default to string operators
        options = operatorOptions.map(o => ({
          ...o,
          options: o.options.filter(op => op.types.includes('string'))
        }));
      }

      return options;
    }

    return operatorOptions;
  }, [fieldValue, operatorOptions]);
  const operatorValue = getOptionValue(operatorOptions, value.comparator);
  const isDate =
    DateTypes.includes(fieldValue?.type) ||
    DateTypes.includes(fieldValue?.valueType);
  const isBoolean =
    fieldValue?.type === 'boolean' ||
    fieldValue?.valueType === 'boolean' ||
    value.value === true ||
    value.value === 'true' ||
    value.value === false ||
    value.value === 'false';
  const isTokenOverride = isBoolean && value.view?.type === 'field';
  const enumValues = useMemo(
    () => fieldValue?.values?.enums || fieldValue?.values?.enum || [],
    [fieldValue]
  );

  return (
    <div className={css.inputContainer}>
      <div className={css.input}>
        {type === 'condition' && (
          <Fragment>
            <div>
              {inputType === 'enum' ? (
                <Select
                  clearable
                  value={value.variable}
                  placeholder="Field"
                  onChange={v =>
                    onChange({
                      ...value,
                      variable: v
                    })
                  }
                >
                  {fieldOptions.map(v => (
                    <SelectOption
                      value={v.value}
                      key={`${v.label}-${v.value}`}
                      group={v.group || ''}
                    >
                      {v.label}
                    </SelectOption>
                  ))}
                </Select>
              ) : (
                <TokenEditor
                  disabled={disabled}
                  value={value.variable ? `{{${value.variable}}}` : ''}
                  allowFlowTrigger={allowTrigger}
                  allowExpressions={allowExpressions}
                  allowModifiers={allowModifiers}
                  singleSelection
                  tokens={fieldOptions}
                  stores={stores}
                  flows={flows}
                  buttonSelection
                  selectorButton={
                    <Button
                      title="Field"
                      size="small"
                      style={{
                        background: 'var(--color-backgrounds-bg-input)',
                        color: 'var(--color-typography-body-default)'
                      }}
                    >
                      Field
                    </Button>
                  }
                  onChange={v =>
                    onChange({
                      ...value,
                      variable: (v || '').replace('{{', '').replace('}}', ''),
                      comparator: null,
                      value: ''
                    })
                  }
                />
              )}
            </div>
            <div>
              <Select
                clearable={false}
                disabled={disabled || !fieldValue}
                value={value.comparator}
                placeholder="Operator"
                onChange={v =>
                  onChange({
                    ...value,
                    comparator: v
                  })
                }
              >
                {operatorOptions.map(v =>
                  v.options.map(o => (
                    <SelectOption
                      value={o.value}
                      key={`${v.label}-${o.value}`}
                      group={v.label || ''}
                    >
                      {o.label}
                    </SelectOption>
                  ))
                )}
              </Select>
            </div>
          </Fragment>
        )}
        {!operatorValue?.noValue && (
          <div className={css.inputValue}>
            {isDate && (
              <ConditionInputTimestampWrapper
                value={value}
                valueOptions={valueOptions}
                hasEnumValue={enumValues.length > 0}
                disabled={disabled || !operatorValue || !fieldValue}
                onChange={onChange}
              />
            )}
            {isBoolean && (
              <ConditionInputBoolean
                value={value}
                valueOptions={valueOptions}
                disabled={disabled || !operatorValue || !fieldValue}
                onChange={onChange}
              />
            )}
            {((!isDate && !isBoolean && !enumValues.length) ||
              isTokenOverride) && (
              <Fragment>
                <TokenEditor
                  disabled={disabled || !operatorValue || !fieldValue}
                  value={(value?.value as string) || ''}
                  allowExpressions={true}
                  allowModifiers={true}
                  tokens={valueOptions}
                  placeholder="Value"
                  onAddModifier={v =>
                    onChange({
                      ...value,
                      value: v
                    })
                  }
                  onBlur={() => {
                    if (lastValRef.current !== null) {
                      onChange({
                        ...value,
                        value: lastValRef.current
                      });
                      lastValRef.current = null;
                    }
                  }}
                  onChange={v => {
                    lastValRef.current = v;
                  }}
                />
              </Fragment>
            )}
            {enumValues.length > 0 && (
              <Fragment>
                <Select
                  clearable
                  value={String(value.value)}
                  onChange={v =>
                    onChange({
                      ...value,
                      value: v
                    })
                  }
                >
                  {enumValues.map(v => (
                    <SelectOption
                      value={v.value}
                      key={`${v.label}-${v.value}`}
                      group={v.group || ''}
                    >
                      {v.label}
                    </SelectOption>
                  ))}
                </Select>
              </Fragment>
            )}
          </div>
        )}
      </div>
      {inputType === 'enum' && (
        <div className={css.description}>{fieldValue?.description}</div>
      )}
    </div>
  );
};
