import { dark, light } from './theme';
import { jsonataConfig, jsonataTokens } from './jsonata';
import { Monaco } from '@monaco-editor/react';

export function setupEditor(instance: Monaco, themeName) {
  // Set our special theme
  instance.editor.defineTheme(
    'flashpoint',
    themeName === 'dark' ? (dark as any) : (light as any)
  );
  instance.editor.setTheme('flashpoint');

  // Add JSONATA language
  instance.languages.register({ id: 'jsonata' });
  instance.languages.setMonarchTokensProvider('jsonata', jsonataTokens as any);

  instance.languages.setLanguageConfiguration('jsonata', jsonataConfig as any);

  // Add some JavaScript language features
  instance.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
    // Ignore not found errors
    diagnosticCodesToIgnore: [2304]
  });

  instance.languages.typescript.javascriptDefaults.setCompilerOptions({
    target: instance.languages.typescript.ScriptTarget.ES2016,
    allowNonTsExtensions: true,
    strict: false,
    types: ['node']
  });
}
