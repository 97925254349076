import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  }
});

// https://stackoverflow.com/questions/69344045/how-to-access-the-react-query-queryclient-outside-the-provider-e-g-to-invali
// @ts-ignore
if (window.Cypress) {
  // only during testing
  // @ts-ignore
  window.queryClient = queryClient;
}

export default queryClient;
