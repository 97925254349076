import React, {
  FC,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  CloneElement,
  ConnectedOverlay,
  ConnectedOverlayContentRef,
  Placement,
  useId
} from 'rdk';
import { SelectInput, SelectInputProps, SelectInputRef } from './SelectInput';
import { SelectMenu, SelectMenuProps } from './SelectMenu';
import { SelectOptionProps } from './SelectOption';
import { useWidth } from './useWidth';
import { useFuzzy } from '@reaviz/react-use-fuzzy';
import { createOptions, getGroups } from './utils';
import isEqual from 'react-fast-compare';

export interface SelectProps {
  id?: string;
  name?: string;
  style?: React.CSSProperties;
  className?: string;
  menuClassName?: string;
  disabled?: boolean;

  autoFocus?: boolean;
  closeOnSelect?: boolean;

  value?: string | string[];
  required?: boolean;
  multiple?: boolean;
  placeholder?: string;

  filterable?: boolean;
  clearable?: boolean;
  loading?: boolean;
  refreshable?: boolean;
  createable?: boolean;
  error?: boolean;

  menuPlacement?: Placement;
  menuDisabled?: boolean;
  children?: any;

  onInputKeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
  ) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRefresh?: () => void;
  onChange?: (value) => void;
  onOptionsChange?: (options: SelectOptionProps[]) => void;

  input?: ReactElement<SelectInputProps, typeof SelectInput>;
  menu?: ReactElement<SelectMenuProps, typeof SelectMenu>;
}

export type SelectValue = SelectOptionProps | SelectOptionProps[] | null;

export const Select: FC<SelectProps> = ({
  id,
  name,
  autoFocus,
  clearable = true,
  filterable = true,
  menuPlacement = 'bottom-start',
  placeholder,
  disabled,
  createable,
  closeOnSelect = true,
  menuDisabled = false,
  loading,
  multiple,
  error,
  refreshable = false,
  className,
  menuClassName,
  children,
  value,
  required,
  input = <SelectInput />,
  menu = <SelectMenu />,
  onRefresh,
  onChange,
  onBlur: onInputBlur,
  onFocus: onInputFocus,
  onInputKeydown,
  onInputKeyUp,
  onOptionsChange,
  onInputChange
}) => {
  const overlayRef = useRef<ConnectedOverlayContentRef | null>(null);
  const inputRef = useRef<SelectInputRef | null>(null);
  const [internalValue, setInternalValue] = useState<string | string[] | null>(
    value
  );
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(-1);
  const internalId = useId(id);
  const [menuWidth, updateMenuWidth] = useWidth(
    inputRef.current?.containerRef,
    overlayRef
  );
  const [options, setOptions] = useState<SelectOptionProps[]>(
    createOptions(children)
  );

  useEffect(() => {
    // Do this over memo for better perf
    const opts = createOptions(children);
    if (!isEqual(opts, options)) {
      setOptions(opts);
    }
  }, [children, options]);

  const { result, keyword, search, resetSearch } = useFuzzy<SelectOptionProps>(
    options,
    {
      keys: ['children', 'group']
    }
  );

  const groups = useMemo(() => getGroups(result), [result]);

  const selectedOption: SelectValue = useMemo(() => {
    if (multiple) {
      if (internalValue || internalValue === '') {
        return options.filter(o =>
          (internalValue as string[]).includes(o.value)
        );
      }

      return [];
    } else if (internalValue || internalValue === '') {
      return options.find(o => o.value === internalValue);
    }

    return null;
  }, [options, multiple, internalValue]);

  useLayoutEffect(() => {
    updateMenuWidth();
  }, [internalValue, updateMenuWidth]);

  useEffect(() => {
    // This is needed to alllow a select to have a
    // starting variable that is set from state
    if (!isEqual(value, internalValue)) {
      setInternalValue(value);
    }
  }, [value, internalValue]);

  useEffect(() => {
    if (internalValue && createable) {
      if (multiple) {
        for (const v of internalValue) {
          const newOptions = [];

          const has = options.find(o => o.value === v);
          if (!has) {
            newOptions.push({
              children: v,
              value: v
            });
          }

          if (newOptions.length) {
            const updatedOptions = [...options, ...newOptions];

            onOptionsChange?.(updatedOptions);
          }
        }
      } else {
        const has = options.find(o => o.value === internalValue);
        if (!has) {
          const updatedOptions = [
            ...options,
            {
              children: internalValue,
              value: internalValue
            }
          ];

          onOptionsChange?.(updatedOptions);
        }
      }
    }
  }, [createable, internalValue, multiple, options, onOptionsChange]);

  const resetSelect = () => {
    setOpen(false);
    resetInput();
  };

  const resetInput = () => {
    setIndex(-1);
    resetSearch();
  };

  const onInputKeyedUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;

    if (key === 'ArrowUp') {
      onArrowUpKeyUp(event);
    } else if (key === 'ArrowDown') {
      onArrowDownKeyUp(event);
    } else if (key === 'Escape') {
      resetSelect();
    } else if (key === 'Enter' || key === 'Tab') {
      onEnterKeyUp(event);
    }

    onInputKeyUp?.(event);
  };

  const onArrowUpKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIndex(Math.max(index - 1, -1));
  };

  const onArrowDownKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIndex(Math.min(index + 1, groups.itemsCount - 1));
  };

  const onEnterKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.trim().toLowerCase();

    if (index === -1 && createable && !inputValue) {
      return;
    }

    if (index > -1 || createable) {
      const newSelection = result[index]?.value || {
        value: inputValue,
        children: inputValue
      };

      toggleSelectedOption(newSelection);
    }
  };

  const onInputFocused = (
    event: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    if (!disabled && !menuDisabled) {
      setOpen(true);
    }

    onInputFocus?.(event);
  };

  const onInputBlured = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.trim();
    if (menuDisabled && createable && inputValue) {
      const newSelection = {
        value: inputValue,
        children: inputValue
      };

      toggleSelectedOption(newSelection);
    }

    onInputBlur?.(event);
  };

  const onInputExpanded = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();

    if (!disabled && !menuDisabled) {
      setOpen(!open);
    }
  };

  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    search(value);
    onInputChange?.(event);
  };

  const toggleSelectedOption = (option: SelectValue) => {
    let newValue: string | string[] | null;

    if (multiple) {
      const result = toggleSelectedMultiOption(option);
      newValue = result.newValue;
      if (result.newOptions?.length) {
        onOptionsChange?.([...options, ...result.newOptions]);
      }

      if (closeOnSelect) {
        setOpen(false);
      }
    } else {
      const singleOption = option as SelectOptionProps;
      const hasOption = options.find(o => o.value === singleOption?.value);
      newValue = singleOption?.value;

      if (createable && !hasOption && newValue) {
        onOptionsChange?.([...options, singleOption]);
      }

      // If its single select and we have the option
      // let's not honor close on select since its
      // likely a removal not a selection
      if (closeOnSelect && hasOption) {
        setOpen(false);
      }
    }

    setInternalValue(newValue);
    resetInput();
    onChange?.(newValue);
  };

  const toggleSelectedMultiOption = (
    selections: SelectOptionProps | SelectOptionProps[]
  ) => {
    const newOptions: SelectOptionProps[] = [];
    let newSelectedOptions = selectedOption as SelectOptionProps[];

    if (!selections) {
      newSelectedOptions = [];
    } else {
      if (!Array.isArray(selections)) {
        selections = [selections];
      }

      for (const next of selections) {
        const hasOption = options.find(o => o.value === next.value);
        const has = (internalValue || []).includes(next.value);
        if (has) {
          newSelectedOptions = newSelectedOptions.filter(
            o => o.value !== next.value
          );
        } else {
          newSelectedOptions = [...newSelectedOptions, next];
        }

        if (!hasOption && createable) {
          newOptions.push(next);
        }
      }
    }

    return {
      newValue: newSelectedOptions.map(o => o.value),
      newSelectedOptions,
      newOptions
    };
  };

  const onMenuSelectedChange = (option: SelectValue) => {
    toggleSelectedOption(option);

    if (closeOnSelect) {
      setOpen(false);
    } else {
      inputRef.current?.focus();
    }
  };

  const onOverlayClose = () => {
    const inputValue = keyword.trim();
    if (createable && inputValue) {
      const newSelection = {
        value: inputValue,
        children: inputValue
      };

      toggleSelectedOption(newSelection);
    }

    resetSelect();
  };

  return (
    <ConnectedOverlay
      open={open}
      closeOnBodyClick={true}
      closeOnEscape={true}
      appendToBody={true}
      placement={menuPlacement}
      reference={inputRef?.current?.containerRef}
      ref={overlayRef}
      onClose={onOverlayClose}
      content={() => (
        <CloneElement<SelectMenuProps>
          element={menu}
          id={`${internalId}-menu`}
          style={{ width: menuWidth }}
          selectedOption={selectedOption}
          createable={createable}
          disabled={disabled}
          options={result}
          groups={groups}
          index={index}
          multiple={multiple}
          inputSearchText={keyword}
          loading={loading}
          filterable={filterable}
          className={menuClassName}
          onSelectedChange={onMenuSelectedChange}
        />
      )}
    >
      <CloneElement<SelectInputProps>
        element={input}
        id={`${internalId}-input`}
        name={name}
        disabled={disabled}
        reference={inputRef}
        autoFocus={autoFocus}
        options={options}
        error={error}
        inputText={keyword}
        multiple={multiple}
        createable={createable}
        filterable={filterable}
        refreshable={refreshable}
        className={className}
        required={required}
        loading={loading}
        placeholder={placeholder}
        selectedOption={selectedOption}
        clearable={clearable}
        menuDisabled={menuDisabled}
        open={open}
        onSelectedChange={toggleSelectedOption}
        onExpandClick={onInputExpanded}
        onKeyDown={onInputKeydown}
        onKeyUp={onInputKeyedUp}
        onInputChange={onInputChanged}
        onBlur={onInputBlured}
        onFocus={onInputFocused}
        onRefresh={onRefresh}
      />
    </ConnectedOverlay>
  );
};
