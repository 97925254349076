import { axios } from 'core/Axios';
import {
  DataGetDatastoreParams,
  DataGetDatastoreResponse
} from 'core/types/api/Data';
import { useQuery } from 'react-query';
import { QueryApiHookProps, Route } from '../types';

export const baseQueryKey = Route.GET_DATASTORE;
export const buildQueryKey = (params?: DataGetDatastoreParams) => {
  return [baseQueryKey, params];
};

export const req = async (
  params: DataGetDatastoreParams
): Promise<DataGetDatastoreResponse> => {
  return axios
    .get<DataGetDatastoreResponse>(Route.GET_DATASTORE, { params })
    .then(data => data.data);
};

export const useGetDatastore = ({
  queryKey,
  queryFn,
  options,
  params
}: QueryApiHookProps<
  DataGetDatastoreResponse,
  DataGetDatastoreParams
> = {}) => {
  return useQuery(
    queryKey || buildQueryKey(params),
    context => (queryFn ? queryFn(context) : req(params)),
    options
  );
};
