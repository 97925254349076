// This file is used to create URL Parameters for Axios requests that were made in version 0.x. After version 1
// the urls were generated differently which breaks filtering (due to nested object parameters) so we need to
// replicate what was originally used

// https://github.com/axios/axios/blob/v0.x/dist/axios.js#L2373
function forEach(obj, fn) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  // Force an array if not already something iterable
  if (typeof obj !== 'object') {
    /* eslint no-param-reassign:0 */
    obj = [obj];
  }

  if (isArray(obj)) {
    // Iterate over array values
    for (var i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

// https://github.com/axios/axios/blob/v0.x/dist/axios.js#L2149
function isArray(val) {
  return Array.isArray(val);
}

// https://github.com/axios/axios/blob/v0.x/dist/axios.js#L2226
function isObject(val) {
  return val !== null && typeof val === 'object';
}

function isDate(val) {
  return val instanceof Date;
}

// https://github.com/axios/axios/blob/v0.x/dist/axios.js#L1478
function encode(val) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}

// https://github.com/axios/axios/blob/v0.x/dist/axios.js#L1509
export function getSerializedParameters(params) {
  var serializedParams;

  var parts = [];

  forEach(params, function serialize(val, key) {
    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (isArray(val)) {
      key = key + '[]';
    } else {
      val = [val];
    }

    forEach(val, function parseValue(v) {
      if (isDate(v)) {
        v = v.toISOString();
      } else if (isObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(encode(key) + '=' + encode(v));
    });
  });

  serializedParams = parts.join('&');

  return serializedParams;
}
