import {
  ConditionComplexRule,
  ConditionExpressionRule,
  ConditionRule,
  ConditionValueRule
} from '@getcrft/jsonata-ext';
import { modifierDeserializer } from 'shared/form/Modifiers/utils/modifierDeserializer';

export function parseChoiceVariables(current: string[], choice: ConditionRule) {
  let result = [...current];

  const complexRule = choice as ConditionComplexRule;
  const conditionValueRule = choice as ConditionValueRule;
  const expressionRule = choice as ConditionExpressionRule;

  if (complexRule.and?.length || complexRule.or?.length) {
    if (complexRule.and) {
      for (const c of complexRule.and) {
        if (!result.includes(c.variable)) {
          result = result.concat(
            modifierDeserializer(c.variable).reduce(
              (a, { source, sourceValue }) =>
                source ? a.concat({ source, sourceValue }) : a,
              []
            )
          );
        }
      }
    }

    if (complexRule.or) {
      for (const c of complexRule.or) {
        if (!result.includes(c.variable)) {
          result = result.concat(
            modifierDeserializer(c.variable).reduce(
              (a, { source, sourceValue }) =>
                source ? a.concat({ source, sourceValue }) : a,
              []
            )
          );
        }
      }
    }
  } else if (conditionValueRule.variable) {
    if (!result.includes(conditionValueRule.variable)) {
      result = result.concat(
        modifierDeserializer(conditionValueRule.variable).reduce(
          (a, { source, sourceValue }) =>
            source ? a.concat({ source, sourceValue }) : a,
          []
        )
      );
    }
  } else if (expressionRule.expression) {
    if (!result.includes(expressionRule.expression)) {
      result = result.concat(
        modifierDeserializer(expressionRule.expression).reduce(
          (a, { source, sourceValue }) =>
            source ? a.concat({ source, sourceValue }) : a,
          []
        )
      );
    }
  }

  return result;
}
