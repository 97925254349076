import React, {
  FC,
  Ref,
  RefObject,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react';
import classNames from 'classnames';
import { ReactComponent as ExpandIcon } from 'assets/svg/expand.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as RotationIcon } from 'assets/svg/rotation.svg';
import { Button } from 'shared/elements/Button';
import { LoadingDots } from 'shared/elements/LoadingDots';
import ellipsize from 'ellipsize';
import { Tag } from 'shared/elements/Tag';
import { SelectOptionProps } from './SelectOption';
import { SelectValue } from './Select';
import AutosizeInput from 'react-input-autosize';
import css from './SelectInput.module.css';

export interface SelectInputProps {
  id?: string;
  name?: string;
  required?: boolean;
  options: SelectOptionProps[];
  disabled?: boolean;
  inputText: string;
  selectedOption?: SelectOptionProps | SelectOptionProps[];
  autoFocus?: boolean;
  className?: string;
  createable?: boolean;
  filterable?: boolean;
  multiple?: boolean;
  loading?: boolean;
  reference?: Ref<SelectInputRef>;
  placeholder?: string;
  error?: boolean;
  clearable?: boolean;
  refreshable?: boolean;
  menuDisabled?: boolean;
  open?: boolean;
  onSelectedChange: (option: SelectValue) => void;
  onExpandClick: (event: React.MouseEvent<Element>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (
    event: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRefresh?: () => void;
}

export interface SelectInputRef {
  inputRef: RefObject<HTMLInputElement>;
  containerRef: RefObject<HTMLDivElement>;
  focus: () => void;
}

const horiztonalArrowKeys = ['ArrowLeft', 'ArrowRight'];
const verticalArrowKeys = ['ArrowUp', 'ArrowDown'];
const actionKeys = [...verticalArrowKeys, 'Enter', 'Tab', 'Escape'];
const MAX_LENGTH = 20;

export const SelectInput: FC<Partial<SelectInputProps>> = ({
  reference,
  autoFocus,
  selectedOption,
  className,
  disabled,
  placeholder,
  filterable,
  id,
  name,
  inputText,
  required,
  loading,
  clearable,
  multiple,
  refreshable,
  error,
  menuDisabled,
  open,
  onSelectedChange,
  onKeyDown,
  onKeyUp,
  onExpandClick,
  onInputChange,
  onFocus,
  onBlur,
  onRefresh
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<any | null>(null);

  const hasValue =
    (multiple && (selectedOption as SelectOptionProps[])?.length > 0) ||
    (!multiple && selectedOption);

  const placeholderText = hasValue ? '' : placeholder;
  const showClear = clearable && !disabled && hasValue;

  useImperativeHandle(reference, () => ({
    containerRef,
    inputRef,
    focus: () => focusInput()
  }));

  const inputTextValue = useMemo(() => {
    if (!inputText && hasValue) {
      if (!Array.isArray(selectedOption)) {
        const singleOption = selectedOption as SelectOptionProps;
        if (!singleOption.inputLabel) {
          return singleOption.children as string;
        }
      }
      return '';
    }

    return inputText;
  }, [hasValue, inputText, selectedOption]);

  const removeAllValues = () => {
    onSelectedChange(null);
  };

  const focusInput = () => {
    const input = inputRef.current;
    if (input) {
      if (input.value) {
        const len = input.value.length;
        // stupid...
        setTimeout(() => input.setSelectionRange(len, len));
        input.focus();
      } else {
        input.focus();
      }
    }
  };

  const onInputFocus = (
    event: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    // On initial focus, move focus to the last character of the value
    if (!multiple && filterable && selectedOption) {
      // We are handling the selection ourself
      event.preventDefault();

      // Stop parent container click event from double firing
      event.stopPropagation();

      focusInput();
    }

    onFocus?.(event);
  };

  const onContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      focusInput();
    }
  };

  const removeLastValue = () => {
    if (multiple) {
      const selectedOptions = selectedOption as SelectOptionProps[];
      onSelectedChange(selectedOptions[selectedOptions.length - 1]);
    } else {
      onSelectedChange(null);
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;

    const isActionKey = actionKeys.includes(key);
    if (isActionKey) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (clearable && key === 'Backspace' && hasValue) {
      if (!multiple || (multiple && !inputText)) {
        event.preventDefault();
        event.stopPropagation();
        removeLastValue();
      }
    }

    onKeyDown?.(event);
  };

  const onInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const isActionKey = actionKeys.includes(key);
    const isHorzKey = horiztonalArrowKeys.includes(key);

    if ((!filterable && !isActionKey) || isHorzKey) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onKeyUp?.(event);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (filterable) {
      onInputChange(event);
    }
  };

  const onTagKeyDown = (
    event: React.KeyboardEvent<HTMLSpanElement>,
    option: SelectOptionProps
  ) => {
    const key = event.key;
    if (key === 'Backspace' && !disabled && clearable) {
      onSelectedChange(option);
    }
  };

  const renderTag = (option: SelectOptionProps) => {
    const origLabel = option.inputLabel || option.children;
    const label =
      typeof origLabel === 'string'
        ? ellipsize(origLabel, MAX_LENGTH)
        : origLabel;

    return (
      <Tag
        key={option.value}
        className={css.tag}
        title={origLabel}
        tabIndex={-1}
        onKeyDown={event => onTagKeyDown(event, option)}
        icon={
          !disabled &&
          clearable && (
            <Button
              variant="text"
              disableMargins
              disablePadding
              size="small"
              onClick={() => onSelectedChange(option)}
            >
              <CloseIcon className={css.tagRemove} />
            </Button>
          )
        }
      >
        {label}
      </Tag>
    );
  };

  const renderPrefix = () => {
    if (multiple) {
      const multipleOptions = selectedOption as SelectOptionProps[];
      if (multipleOptions?.length) {
        return (
          <div className={css.prefix}>{multipleOptions.map(renderTag)}</div>
        );
      }
    } else {
      const singleOption = selectedOption as SelectOptionProps;
      if (singleOption?.inputLabel && !inputText) {
        return <div className={css.prefix}>{singleOption?.inputLabel}</div>;
      }
    }

    return null;
  };

  return (
    <div
      ref={containerRef}
      className={classNames(css.container, className, {
        [css.disabled]: disabled,
        [css.unfilterable]: !filterable,
        [css.error]: error,
        [css.single]: !multiple,
        [css.multiple]: multiple,
        [css.open]: open
      })}
      onClick={onContainerClick}
    >
      <div className={css.inputContainer} onClick={onInputFocus}>
        {renderPrefix()}
        <AutosizeInput
          inputRef={el => (inputRef.current = el)}
          id={id}
          style={{ fontSize: 13 }}
          name={name}
          disabled={disabled}
          required={required}
          autoFocus={autoFocus}
          placeholder={placeholderText}
          inputClassName={css.input}
          value={inputTextValue}
          autoCorrect="off"
          spellCheck="false"
          autoComplete="off"
          onKeyDown={onInputKeyDown}
          onKeyUp={onInputKeyUp}
          onChange={onChange}
          onFocus={onInputFocus}
          onBlur={onBlur}
        />
      </div>
      <div className={css.suffix}>
        {refreshable && !loading && (
          <Button
            variant="text"
            title="Refresh Options"
            disableMargins
            disabled={disabled}
            disablePadding
            onClick={onRefresh}
          >
            <RotationIcon className={css.refresh} />
          </Button>
        )}
        {loading && <LoadingDots className={css.loader} />}
        {showClear && (
          <Button
            variant="text"
            title="Clear selection"
            disableMargins
            disablePadding
            disabled={disabled}
            onClick={removeAllValues}
          >
            <CloseIcon className={css.close} />
          </Button>
        )}
        {!menuDisabled && (
          <Button
            variant="text"
            title="Toggle options menu"
            disableMargins
            disablePadding
            disabled={disabled}
            onClick={onExpandClick}
          >
            <ExpandIcon className={css.expand} />
          </Button>
        )}
      </div>
    </div>
  );
};
