import { axios } from 'core/Axios';
import { DataDelFlowParams, DataDelFlowResponse } from 'core/types/api/Data';
import { useMutation, useQueryClient } from 'react-query';
import { MutationApiHookProps, Route } from '../types';

export const baseMutationKey = Route.DEL_FLOW;

export const req = async (
  params: DataDelFlowParams
): Promise<DataDelFlowResponse> => {
  return axios
    .delete<DataDelFlowResponse>(Route.DEL_FLOW, { params })
    .then(data => data.data);
};

export const useDelFlow = ({
  mutationFn = req,
  options = {}
}: MutationApiHookProps<DataDelFlowResponse, DataDelFlowParams> = {}) => {
  const client = useQueryClient();

  return useMutation(mutationFn, {
    mutationKey: baseMutationKey,
    ...options,
    onSettled(...args) {
      client.invalidateQueries(Route.LIST_FLOWS);

      if (options.onSettled) {
        options.onSettled(...args);
      }
    }
  });
};
